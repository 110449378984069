import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  InspectorDashboardPage,
  MediaRecovery,
  RehabToolPage,
  ReportsPage,
  PermitsPage,
  SignInPage,
  OnboardingLandingPage,
  OnboardingFormPage,
  OnboardingRedirectPage,
  OnboardingSetPasswordPage,
  DownloadAppPage,
} from './pages';
import RehabToolPdfPage from './pages/RehabToolPdfPage';
import { usePageViews } from './utils/analytics';
import { useCurrentUser } from './utils/user';

const RedirectToExternalUrl: React.FC<{ url: string }> = ({ url }) => {
  window.location.href = url;
  return null;
};

const App = () => {
  usePageViews();
  useCurrentUser();

  return (
    <main>
      <Switch>
        <Route exact path="/">
          <RedirectToExternalUrl url="https://www.inspectify.com/" />
        </Route>
        <Route path="/sign_in" component={SignInPage} exact />
        <Route path="/orders/:orderToken/reports" component={ReportsPage} exact />
        <Route path="/orders/:orderToken/permit_report" component={PermitsPage} exact />
        <Redirect from="/orders/:orderToken/denver_inspection_pdf" to="/orders/:orderToken/reports" exact />
        <Route path="/rehab/:projectId" component={RehabToolPage} exact />
        <Route path="/rehab/:projectId/print" component={RehabToolPdfPage} exact />
        <Route path="/dashboard" component={InspectorDashboardPage} exact />
        <Route path="/download_app" component={DownloadAppPage} exact />
        <Route path="/media_recovery" component={MediaRecovery} exact />
        <Route path="/onboarding/surveyor/new" component={OnboardingRedirectPage} exact />
        <Route path="/onboarding/surveyor/:onboardingId" component={OnboardingLandingPage} exact />
        <Route path="/onboarding/surveyor/:onboardingId/form" component={OnboardingFormPage} exact />
        <Route path="/onboarding/surveyor/:onboardingId/password" component={OnboardingSetPasswordPage} exact />
      </Switch>
    </main>
  );
};

export { App as default };
