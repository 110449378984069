import { IdRef } from 'marketplace-common';

export enum DashboardPageTab {
  Jobs = 'Inspection jobs',
  Calendar = 'Calendar',
  BusinessDetails = 'Business details',
  UserManagement = 'Manage users',
  TipaltiSetupProcess = 'Payment setup',
  TipaltiPaymentHistory = 'Payment history',
  MasterInspectionAndServiceAgreement = 'Master inspection and service agreement',
}

export enum JobsTabs {
  Upcoming = 'Upcoming',
  Completed = 'Completed',
}

export interface FileUploadDetails {
  id: IdRef;
  url?: string | null;
  name?: string | null;
  source?: string | null;
}
