import { colorBaseGreyDark, colorBaseWhitePrimary } from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

export const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%
`;

export const DayContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px 8px;
  background: ${colorBaseWhitePrimary};
`;

export const DayContainerHeaderText = styled.div`
  color: ${colorBaseGreyDark};
  font-size: 14px;
  font-weight: 700;
`;
