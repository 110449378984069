import styled from 'styled-components';
import {
  colorBaseBlackPrimary,
} from 'haven-design-system/build/typescript/es6';

export const SystemName = styled.div`
  font-size: 32px;
  margin: 16px 0;
  color: ${colorBaseBlackPrimary};
`;

export const RehabWorkItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  padding: 24px;
  overflow-y: scroll;
  margin: 0px;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
