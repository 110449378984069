import { Context, Dispatch } from 'react';
import {
  IdRef,
  InspectionWarranty,
  LocalInspection,
  Order,
  Property,
  Report,
  ReportApplianceInformation,
  ReportRecord,
  ReportSystem,
  WorksOrderStakeholder,
  getCurrentUnixTimestamp,
  sortReportSystemOrSubsystemByDisplayIndex,
  ReportInformationInterface,
  ReportApplianceInformationsInterface,
  Works,
} from 'marketplace-common';
import { Contractor, PageErrorType } from '../../types';
import createDataContext from '../../utils/createDataContext';
import { getFormattedDate } from '../../utils/date';
import { hideSystem } from '../../utils/report';
import { getReportApplianceInformations } from '../../utils/reportApplianceInformation';

const SECONDS_IN_DAY = 86400;

export interface ReportContextType {
  orderId: IdRef | null,
  selectedReportId: IdRef | null,
  selectedReportItemId: string | null,
  selectedReportItemType: string | null,
  selectedReportItemSystemGroupId: string | null,
  contractor: Contractor | null,
  displayPrintReady: boolean,
  isPaywalled: boolean,
  noDeficienciesRequired: boolean,
  isAppliancesOnly: boolean,
  allowRehab: boolean,
  reportQueryError: PageErrorType | null,
  existingRehabProjectId: string | null,
  contractorId: IdRef | null,
  orderToken: string,
  order: Order | null,
  orderTeamId: IdRef | null,
  property: Property | null,
  inspections: LocalInspection[],
  inspectionDate: number | null,
  inspectionWarranty?: InspectionWarranty,
  inspectionWarrantyLastDayToVerifyOwnershipDate: string | null,
  inspectionWarrantyExpirationDate: string | null,
  inspectionWarrantyLastDayToFileClaimDate: string | null,
  isInspectionWarrantyExpired: boolean,
  isInspectionWarrantyExpiringSoon: boolean,
  isInspectionWarrantyExpiredAndLastDayToClaimIsExpired: boolean,
  isInspectionWarrantyTimeToProvideProofOfOwnershipExpired: boolean,
  warrantyCoveredAppliances: ReportApplianceInformation[],
  selectedReport: Report | null,
  inspectionTypes: string[],
  activeInspectionTypes: string[],
  clientStakeholder: WorksOrderStakeholder | null,
  reportRecordIds: IdRef[],
  reportRecords: ReportRecord[],
  sortedReportSystems: ReportSystem[],
  selectedReportSystemGroupIds: string[],
  groupedReportSystemsByName: { [name: string]: ReportSystem[] },
  isReInspection: boolean,
  showDrawInspectionBudgetSummary: boolean,
  printMode: boolean,
  isDenverPdf: boolean,
}

export interface ReportActionType {
  type: string,
  payload: { key?: string, value: any },
}

function getReportSystems(
  report: Report,
  systemGroupNames?: string[],
) {
  let selectedReportSystemGroupIds: string[] = [];

  if (systemGroupNames?.length > 0) {
    selectedReportSystemGroupIds = Object.keys(report.systemGroups).filter((id) => {
      const group = report.systemGroups[id];
      return systemGroupNames.includes(group.name);
    }) || null;
  }

  const sortedReportSystems = sortReportSystemOrSubsystemByDisplayIndex(
    Object.values(report.systems).filter((system) => {
      const shouldShow = system.includeOnReport && !hideSystem(system, report);
      if (shouldShow && selectedReportSystemGroupIds.length > 0) {
        return selectedReportSystemGroupIds.includes(system.reportSystemGroupId);
      }
      return shouldShow;
    }),
  ) as ReportSystem[];

  const groupedReportSystemsByName = sortedReportSystems.reduce(
    (acc: { [name: string]: ReportSystem[] }, system) => ({
      ...acc, [system.name]: [...(acc[system.name] || []), system],
    }), {},
  );

  return {
    sortedReportSystems,
    groupedReportSystemsByName,
    selectedReportSystemGroupIds,
  };
}

const reducer = (
  context: ReportContextType,
  action: ReportActionType,
): ReportContextType => {
  const { type, payload: { key, value } } = action;
  switch (type) {
    case 'SET':
      return { ...context, [key]: value };
    case 'MULTI_SET':
      return { ...context, ...value };
    default:
      return context;
  }
};

const set = (dispatch: Dispatch<ReportActionType>) => (key: string, value: any) => {
  dispatch({ type: 'SET', payload: { key, value } });
};

const setOrderPropertyInspectionsAndReportRecords = (
  dispatch: Dispatch<ReportActionType>,
) => (orderId: IdRef, works: Works) => {
  if (works?.orders?.[orderId]) {
    const context = {} as ReportContextType;
    const order = works.orders[orderId];
    const property = works.properties[order.propertyId];

    context.order = order;
    context.property = property;
    context.orderTeamId = order.teamId;

    const inspections = (order?.inspectionIds || []).reduce((acc, id) => {
      if (works?.inspections?.[id]) acc.push(works.inspections[id]);
      return acc;
    }, [] as LocalInspection[]);

    if (inspections.length) {
      context.inspections = inspections;
      context.inspectionTypes = inspections.map(({ customerFacingName }) => customerFacingName);
      context.activeInspectionTypes = inspections.filter(({ status }) => status !== 'cancelled').map(({ customerFacingName }) => customerFacingName);
      context.isDenverPdf = inspections.some(({ inspectionType }) => inspectionType === 'denver_rental_compliance');

      const reportRecordIds = inspections.reduce((acc, { reportIds }) => {
        if (reportIds.length) acc.push(...reportIds);
        return acc;
      }, [] as string[]);
      context.reportRecordIds = reportRecordIds;

      const reportRecords = reportRecordIds.reduce((records, id) => {
        if (works.reports?.[id]) records.push(works.reports[id]);
        return records;
      }, [] as ReportRecord[]);
      context.reportRecords = reportRecords;
    }

    dispatch({ type: 'MULTI_SET', payload: { value: context } });
  }
};

const setInspectionWarranty = (
  dispatch: Dispatch<ReportActionType>,
) => (inspections: LocalInspection[], works: Works) => {
  const inspectionWithWarranty = inspections.find(({ inspectionWarrantyId }: LocalInspection) => (
    inspectionWarrantyId && works.inspectionWarranties[inspectionWarrantyId]
  ));

  if (inspectionWithWarranty) {
    const context = {} as ReportContextType;

    const inspectionWarranty = works.inspectionWarranties[
      inspectionWithWarranty.inspectionWarrantyId
    ];
    context.inspectionWarranty = inspectionWarranty;

    if (inspectionWarranty.startDate) {
      const startDate = new Date(inspectionWarranty.startDate).getTime() / 1000;
      const lastDayToVerifyOwnership = startDate + (SECONDS_IN_DAY * 90);
      context.inspectionWarrantyLastDayToVerifyOwnershipDate = (
        getFormattedDate(lastDayToVerifyOwnership, true)
      );
    }

    if (inspectionWarranty.expirationDate) {
      const expirationDate = new Date(inspectionWarranty.expirationDate).getTime() / 1000;
      const secondsIn30Days = SECONDS_IN_DAY * 30;
      const previous90Day = expirationDate - (SECONDS_IN_DAY * 90);
      const lastDateToFileClaim = expirationDate + secondsIn30Days;
      const isInspectionWarrantyExpired = expirationDate < getCurrentUnixTimestamp();

      context.inspectionWarrantyExpirationDate = getFormattedDate(expirationDate, true);
      context.inspectionWarrantyLastDayToFileClaimDate = (
        getFormattedDate(lastDateToFileClaim, true)
      );
      context.isInspectionWarrantyExpired = isInspectionWarrantyExpired;
      context.isInspectionWarrantyExpiredAndLastDayToClaimIsExpired = (
        isInspectionWarrantyExpired && lastDateToFileClaim < getCurrentUnixTimestamp()
      );

      if (!isInspectionWarrantyExpired) {
        context.isInspectionWarrantyExpiringSoon = previous90Day <= getCurrentUnixTimestamp();

        if (inspectionWarranty.startDate) {
          const startDate = new Date(inspectionWarranty.startDate).getTime() / 1000;
          const next90Day = startDate + (SECONDS_IN_DAY * 90);
          context.isInspectionWarrantyTimeToProvideProofOfOwnershipExpired = (
            next90Day < getCurrentUnixTimestamp() && inspectionWarranty.status !== 'ownership_claimed'
          );
        }
      }
    }

    dispatch({ type: 'MULTI_SET', payload: { value: context } });
  }
};

const setOrderStakeholder = (
  dispatch: Dispatch<ReportActionType>,
) => (orderStakeholderIds: string[], works: Works) => {
  const clientStakeholderId = orderStakeholderIds.find((id) => (
    works.orderStakeholders?.[id]?.isClient
  ));

  if (clientStakeholderId) {
    dispatch({
      type: 'SET',
      payload: { key: 'clientStakeholder', value: works.orderStakeholders[clientStakeholderId] },
    });
  }
};

const setContractorId = (
  dispatch: Dispatch<ReportActionType>,
) => (reportId: IdRef, inspections: LocalInspection[], works: Works) => {
  if (reportId && works.inspections) {
    const inspection = inspections.find((ins) => ins.reportIds?.includes(reportId as string));
    if (
      inspection?.scheduledProposalId
      && works.inspectionProposals?.[inspection.scheduledProposalId]?.contractorId
    ) {
      dispatch({
        type: 'SET',
        payload: {
          key: 'contractorId',
          value: works.inspectionProposals[inspection.scheduledProposalId].contractorId,
        },
      });
    }
  }
};

const setSelectedReport = (
  dispatch: Dispatch<ReportActionType>,
) => (
  report: Report,
  works: Works,
  systemGroupNames?: string[],
  noDeficienciesRequired?: boolean,
  isAppliancesOnly?: boolean,
) => {
  if (report) {
    const context = {
      selectedReportId: report.report.id,
      selectedReport: report,
      noDeficienciesRequired: noDeficienciesRequired || report.report.noDeficienciesAllowed,
      isAppliancesOnly,
      isReInspection: Object.values(report.deficiencies).some(
        (deficiency) => deficiency.originalReportDeficiencyId,
      ),
      ...(report?.systems ? getReportSystems(report, systemGroupNames) : {}),
    } as ReportContextType;

    if (report?.report && works.inspections && works.inspectionProposals) {
      const proposalIds = report.report.inspectionIds.reduce((ids, id) => {
        if (works.inspections?.[id]?.scheduledProposalId) {
          return [...ids, works.inspections?.[id]?.scheduledProposalId];
        }
        return ids;
      }, []);
      const proposalId = proposalIds.find((id) => works.inspectionProposals?.[id]?.availability);
      if (proposalId) {
        context.inspectionDate = works.inspectionProposals[proposalId].availability;
      }
    }

    dispatch({ type: 'MULTI_SET', payload: { value: context } });
  }
};

const setSelectedReportSystemGroupIds = (
  dispatch: Dispatch<ReportActionType>,
) => (report: Report, systemGroupNames?: string[]) => {
  if (report?.systems) {
    dispatch({ type: 'MULTI_SET', payload: { value: getReportSystems(report, systemGroupNames) } });
  }
};

const setSelectedReportItem = (
  dispatch: Dispatch<ReportActionType>,
) => (itemId: string, itemType: string, systemGroupId?: string | null) => {
  const value: any = {
    selectedReportItemId: itemId,
    selectedReportItemType: itemType,
  };

  if (systemGroupId) value.selectedReportItemSystemGroupId = systemGroupId;

  dispatch({ type: 'MULTI_SET', payload: { value } });
};

const setShowDrawInspectionBudgetSummary = (
  dispatch: Dispatch<ReportActionType>,
) => (inspectionTypes: string[], informations: ReportInformationInterface) => {
  if (inspectionTypes.length && informations) {
    const showDrawInspectionBudgetSummary = (
      (inspectionTypes.includes('draw_inspection') || inspectionTypes.includes('sow_inspection'))
      && Object.values(informations).some((info) => info?.tags?.draw_budget)
    );
    dispatch({ type: 'SET', payload: { key: 'showDrawInspectionBudgetSummary', value: showDrawInspectionBudgetSummary } });
  }
};

const setWarrantyCoveredAppliances = (
  dispatch: Dispatch<ReportActionType>,
) => (
  applianceInformations: ReportApplianceInformationsInterface,
  informations: ReportInformationInterface,
) => {
  const warrantyCoveredAppliances = getReportApplianceInformations(
    applianceInformations,
    Object.values(informations),
  ).filter((item) => item.warrantyCovered);
  if (warrantyCoveredAppliances.length) {
    dispatch({
      type: 'SET',
      payload: { key: 'warrantyCoveredAppliances', value: warrantyCoveredAppliances },
    });
  }
};

export const initialReportsPageContext: ReportContextType = {
  orderId: null,
  selectedReportId: null,
  selectedReportItemId: null,
  selectedReportItemType: null,
  selectedReportItemSystemGroupId: null,
  contractor: null,
  displayPrintReady: false,
  isPaywalled: false,
  noDeficienciesRequired: false,
  isAppliancesOnly: false,
  allowRehab: false,
  reportQueryError: null,
  existingRehabProjectId: null,
  contractorId: null,
  orderToken: '',
  order: null,
  orderTeamId: null,
  property: null,
  inspections: [],
  inspectionDate: null,
  inspectionWarranty: undefined,
  inspectionWarrantyLastDayToVerifyOwnershipDate: null,
  inspectionWarrantyExpirationDate: null,
  inspectionWarrantyLastDayToFileClaimDate: null,
  isInspectionWarrantyExpired: false,
  isInspectionWarrantyExpiringSoon: false,
  isInspectionWarrantyExpiredAndLastDayToClaimIsExpired: false,
  isInspectionWarrantyTimeToProvideProofOfOwnershipExpired: false,
  warrantyCoveredAppliances: [],
  selectedReport: null,
  inspectionTypes: [],
  activeInspectionTypes: [],
  clientStakeholder: null,
  reportRecordIds: [],
  reportRecords: [],
  sortedReportSystems: [],
  selectedReportSystemGroupIds: [],
  groupedReportSystemsByName: {},
  isReInspection: false,
  showDrawInspectionBudgetSummary: false,
  printMode: false,
  isDenverPdf: false,
};

export const reportsPageActions = {
  set,
  setOrderPropertyInspectionsAndReportRecords,
  setInspectionWarranty,
  setOrderStakeholder,
  setContractorId,
  setSelectedReport,
  setSelectedReportSystemGroupIds,
  setSelectedReportItem,
  setShowDrawInspectionBudgetSummary,
  setWarrantyCoveredAppliances,
};

type ContextType = { state: ReportContextType } & {
  set: (key: string, value: any) => void,
  setOrderPropertyInspectionsAndReportRecords: (orderId: IdRef, works: Works) => void,
  setInspectionWarranty: (inspections: LocalInspection[], works: Works) => void,
  setOrderStakeholder: (orderStakeholderIds: string[], works: Works) => void,
  setContractorId: (reportId: IdRef, inspections: LocalInspection[], works: Works) => void,
  setSelectedReport: (
    report: Report,
    works: Works,
    systemGroupNames?: string[],
    noDeficienciesRequired?: boolean,
    isAppliancesOnly?: boolean,
  ) => void,
  setSelectedReportSystemGroupIds: (report: Report, systemGroupNames?: string[]) => void,
  setSelectedReportItem: (itemId: string, itemType: string, systemGroupId: string) => void,
  setShowDrawInspectionBudgetSummary: (
    inspectionTypes: string[],
    informations: ReportInformationInterface,
  ) => void,
  setWarrantyCoveredAppliances: (
    applianceInformations: ReportApplianceInformationsInterface,
    informations: ReportInformationInterface,
  ) => void,
};

export type ReportContextAndActionsType = {
  Context: Context<ContextType>,
  Provider: ({ children }: any) => JSX.Element,
};

export const {
  Context: ReportContext,
  Provider: ReportProvider,
}: ReportContextAndActionsType = createDataContext(
  reducer,
  reportsPageActions,
  initialReportsPageContext,
);
