import React from 'react';
import {
  BorderBottom, TabButton, TabDiv, TabText as TabButtonLabel,
} from './styles';
import { TabsProps } from './types';

const Tabs: React.FC<TabsProps> = ({
  tab,
  tabs,
  setTab,
  isFullWidth,
}) => (
  <TabDiv isFullWidth={isFullWidth}>
    {tabs.map((key) => {
      const isSelected = key === tab;
      return (
        <TabButton
          id={`tab-button-${key}`}
          key={key}
          type="button"
          selected={isSelected}
          onClick={() => {
            setTab(key);
          }}
          isFullWidth={isFullWidth}
        >
          <TabButtonLabel selected={isSelected} isFullWidth={isFullWidth}>
            {key}
          </TabButtonLabel>
          <BorderBottom selected={isSelected} isFullWidth={isFullWidth} />
        </TabButton>
      );
    })}
  </TabDiv>
);

export default Tabs;
