import {
  addDays,
  format,
  isBefore,
  isValid,
  startOfToday,
} from 'date-fns';
import {
  zonedTimeToUtc,
  utcToZonedTime,
  format as formatTz,
} from 'date-fns-tz';
import { LocalInspection, ReportRecord, WorksReportsInterface } from 'marketplace-common';

export const SHORT_DATE_FORMAT = 'yyyy-MM-dd';
export const SHORT_TIME_FORMAT = 'h:mm aaa';

export const getUsersTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const convertEpochUTCSecondsToDate = (seconds: number): Date => {
  const date = new Date(0);
  date.setUTCSeconds(seconds);
  return date;
};

export const convertLocalDateToUTCDate = (date: Date, timezone: string): Date => {
  if (!date || !isValid(date)) return null;
  const localDate = zonedTimeToUtc(date, getUsersTimezone());
  const utcDate = zonedTimeToUtc(localDate, timezone);
  return utcDate;
};

export const convertUTCDateToZonedDate = (date: Date, timezone: string): Date => {
  if (!date || !isValid(date)) return null;
  const zonedDate = utcToZonedTime(date, timezone);
  return zonedDate;
};

export const convertLocalDateToZonedDate = (date: Date, timezone: string): Date => {
  if (!date || !isValid(date)) return null;
  const utcDate = convertLocalDateToUTCDate(date, getUsersTimezone());
  const zonedDate = convertUTCDateToZonedDate(utcDate, timezone);
  return zonedDate;
};

export const isBeforeToday = (date: Date): boolean => {
  if (!date || !isValid(date)) return null;
  return isBefore(date, startOfToday());
};

export const formatDate = (
  date: Date,
  options: { timezone?: string, format?: string } = null,
): string => {
  if (!date || !isValid(date)) return null;
  const dateFormat = options?.format ? options.format : SHORT_DATE_FORMAT;
  if (options?.timezone) {
    const zonedDate = convertLocalDateToZonedDate(date, options.timezone);
    return formatTz(zonedDate, dateFormat, { timeZone: options.timezone });
  }
  return format(date, dateFormat);
};

export const get42DayMonthOffsets = (date: Date): Date[] => {
  if (!date || !isValid(date)) return null;

  const year = date.getFullYear();
  const month = date.getMonth();

  // get start and end dates of the month
  const monthStart = new Date(year, month, 1);

  // calculate the date offset needed to start on the beginning of a week
  const startDateOffset = monthStart.getDate() - monthStart.getDay();

  const startDate = new Date(year, month, startDateOffset);

  // the calendar displays 42 days
  const endDate = addDays(startDate, 42);

  return [startDate, endDate];
};

export const toReadableTime = (time: number, paramType: string) => new Date(time * (paramType === 'mils' ? 1 : 1000)).toLocaleString();

// Format: Sun 5/21/2021 - 3:00PM OR full length format: Sunday, May 5th, 2021 - 3:00PM
export const getFormattedDateTime = (unformattedDate: number, fullLength?: boolean) => {
  const date = convertEpochUTCSecondsToDate(unformattedDate);
  return fullLength ? format(date, 'PPPP - p') : format(date, 'ccc P - p');
};

// Format: Sun 5/21/2021 OR full length format: Sunday, May 5th, 2021
export const getFormattedDate = (unformattedDate: number, fullLength?: boolean) => {
  const date = convertEpochUTCSecondsToDate(unformattedDate);
  return fullLength ? format(date, 'PPPP') : format(date, 'ccc P');
};

export const getFormattedMonthDayYear = (date: Date) => format(date, 'MMM d, yyyy');

export const getFormattedShortDate = (unformattedDate: number) => {
  const date = convertEpochUTCSecondsToDate(unformattedDate);
  return format(date, SHORT_DATE_FORMAT);
};

// Format: Jun 27, 2022, 12:19 PM
export const formatReportDate = (record: ReportRecord) => {
  if (!record.publishedAt && !record.updatedAt) return '';
  return formatDate(convertEpochUTCSecondsToDate(record.publishedAt || record.updatedAt), { format: 'MMM d, yyyy, h:mm a' });
};

export const getDatePublished = (
  inspection : LocalInspection, reports : WorksReportsInterface, url : string,
) => {
  let formattedDate = '';

  Object.values(reports).some((reportRecord: ReportRecord) => {
    // there will only be one match
    if (reportRecord && url.includes(reportRecord.id)) {
      formattedDate = formatReportDate(reportRecord);
      return true; // break out of loop
    }
    return false;
  });
  return formattedDate;
};

export const getMonthName = (monthNumber: number) => {
  const date = new Date();
  // Reset to first day of the month or else "setMonth" will go to following month if
  // current day is 31st
  date.setDate(1);
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-US', { month: 'long' });
};
