import styled from 'styled-components';
import {
  colorBaseBlackLight,
  colorBaseBlackPrimary,
  colorBaseGreyPrimary,
  colorBaseTealDark,
  colorBaseTealLight,
} from 'haven-design-system/build/typescript/es6';

export const WorkItemTableAttachment = styled.td`
  text-align: center;
  width: 50%;
  padding: 10px;
`;

export const WorkItemTableHeaderName = styled.th<{ isBold?: boolean }>`
  color: ${(props) => (props.isBold ? colorBaseBlackPrimary : colorBaseBlackLight)};
  font-size: 18px;
  font-weight: ${(props) => (props.isBold ? 700 : 400)};
  padding-bottom: ${(props) => (props.isBold ? '24px' : 'inherit')};
  padding-left: 10px;
`;

export const WorkItemTableHeaderCost = styled.th<{ isBold?: boolean }>`
  color: ${(props) => (props.isBold ? colorBaseBlackPrimary : colorBaseBlackLight)};
  font-size: 18px;
  font-weight: ${(props) => (props.isBold ? 700 : 400)};
  padding-bottom: ${(props) => (props.isBold ? '24px' : 'inherit')};
  text-align: right;
  padding-right: 10px;
`;

export const WorkItemTableDetail = styled.td`
  color: ${colorBaseGreyPrimary};
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
`;

export const WorkItemTableBreakdown = styled.td`
  color: ${colorBaseGreyPrimary};
  font-size: 16px;
  font-weight: 400;
  text-align: right;
  padding-right: 10px;
`;

export const WorkItemTags = styled.td`
  padding-left: 10px;
`;

export const WorkItemTag = styled.div<{ $color?: string, $background?: string }>`
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.$color || colorBaseTealDark};
  background: ${(props) => props.$background || colorBaseTealLight};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 4px 8px;
  margin: 0px 4px;
`;
