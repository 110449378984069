import React from 'react';
import Button from '../Button';
import { ConfirmationModalProps } from './types';
import './styles.scss';

const ConfirmationModal = ({
  opened,
  header,
  subheader,
  backButton,
  confirmButton,
  imageUrl,
  backIconButton,
  onConfirm,
  onBack,
} : ConfirmationModalProps) => (
  !!opened && (
    <div className="modalBackdrop" aria-hidden="true" onClick={onBack}>
      <div className="modalContainer" aria-hidden="true" onClick={(e) => e.stopPropagation()}>
        {!!backIconButton && (
          <button type="button" onClick={(onBack)} className="modalContainer__closeContainer">&#x2715;</button>
        )}
        <div className="modalContainer__imageContainer">
          {!!imageUrl && (
            <img src={imageUrl} alt="confirmationModalImage" className="modalContainer__imageContainer__modalImage" />
          )}
        </div>
        <div className="modalContainer__textContainer">
          {!!header && (
            <h3 className="modalContainer__textContainer__modalHeader">{header}</h3>
          )}
          {!!subheader && (
            <p className="modalContainer__textContainer__modalSubheader">{subheader}</p>
          )}
        </div>
        <div className="modalContainer__actionsContainer">
          {!!backButton && (
            <Button className="modalContainer__actionsContainer__button button--secondary" onClick={onBack}>{backButton}</Button>
          )}
          {!!confirmButton && (
            <Button className="modalContainer__actionsContainer__button button--primary" onClick={onConfirm}>{confirmButton}</Button>
          )}
        </div>
      </div>
    </div>
  )
);

export default ConfirmationModal;
