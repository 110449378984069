// Values are set to match Bootstrap: https://getbootstrap.com/docs/4.1/layout/overview/
export const size = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const mediaBreakpointUp = {
  sm: `(min-width: ${size.sm}px)`,
  md: `(min-width: ${size.md}px)`,
  lg: `(min-width: ${size.lg}px)`,
  xl: `(min-width: ${size.xl}px)`,
};

export const mediaBreakpointDown = {
  xs: `(max-width: ${size.sm - 0.02}px)`,
  sm: `(max-width: ${size.md - 0.02}px)`,
  md: `(max-width: ${size.lg - 0.02}px)`,
  lg: `(max-width: ${size.xl - 0.02}px)`,
};

export const mediaBreakpointOnly = {
  xs: `(max-width: ${size.sm - 0.02}px)`,
  sm: `(min-width: ${size.sm}px) and (max-width: ${size.md - 0.02}px)`,
  md: `(min-width: ${size.md}px) and (max-width: ${size.lg - 0.02}px)`,
  lg: `(min-width: ${size.lg}px) and (max-width: ${size.xl - 0.02}px)`,
  xl: `(min-width: ${size.xl}px)`,
};
