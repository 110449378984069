import React from 'react';
import './styles.scss';
import { Tooltip } from 'react-tooltip';
import QuestionIcon from 'haven-design-system/build/assets/svgs/display/question_filled.svg?component';
import { PermissionsSections, UserPermissionProps } from './types';
import ToggleSwitch from '../../../../../components/ToggleSwitch';
import { UserPermissionEnum } from '../../../../../types';

const UserPermissions: React.FC<UserPermissionProps> = ({
  adminPermission,
  offersPermission,
  pricingPermission,
  handleUpdatePermission,
}) => (
  <div className="userPermissions">
    <div className="userPermissions__section">
      <div className="userPermissions__section__title">
        {PermissionsSections.Title}
      </div>
      <div className="userPermissions___section__description">
        {PermissionsSections.Description}
      </div>
    </div>
    <div className="userPermissions__section--large">
      <div className="userPermissions__section__row">
        <div className="userPermissions__section__row__text--disabled">
          {UserPermissionEnum.Admin}
          <div id="admin-permission-tooltip" className="userPermissions__section__row__text__tooltip--disabled">
            <QuestionIcon />
          </div>
        </div>
        <ToggleSwitch isOn={adminPermission} tag="admin" disabled />
      </div>
      <div className="userPermissions__section__divider" />
      <div className="userPermissions__section__row">
        <div className="userPermissions__section__row__text">
          {UserPermissionEnum.Offers}
          <div id="offers-permission-tooltip" className="userPermissions__section__row__text__tooltip">
            <QuestionIcon />
          </div>
        </div>
        <ToggleSwitch
          isOn={offersPermission}
          tag="offers"
          toggle={() => handleUpdatePermission('offersPermission', !offersPermission)}
        />
      </div>
      <div className="userPermissions__section__divider" />
      <div className="userPermissions__section__row">
        <div className="userPermissions__section__row__text">
          {UserPermissionEnum.Pricing}
          <div id="pricing-permission-tooltip" className="userPermissions__section__row__text__tooltip">
            <QuestionIcon />
          </div>
        </div>
        <ToggleSwitch
          isOn={pricingPermission}
          tag="pricing"
          toggle={() => handleUpdatePermission('pricingPermission', !pricingPermission)}
        />
      </div>
    </div>
    <Tooltip
      anchorSelect="#admin-permission-tooltip"
      place="right"
      className="userPermissions__tooltip"
      content={PermissionsSections.AdminDescription}
    />
    <Tooltip
      anchorSelect="#offers-permission-tooltip"
      place="right"
      className="userPermissions__tooltip"
      content={PermissionsSections.OffersDescription}
    />
    <Tooltip
      anchorSelect="#pricing-permission-tooltip"
      place="right"
      className="userPermissions__tooltip"
      content={PermissionsSections.PricingDescription}
    />
  </div>
);

export default UserPermissions;
