import React, { useCallback, useMemo } from 'react';
import { ReportApplianceInformation } from 'marketplace-common';
import BetaTag from 'haven-design-system/build/assets/svgs/images/warranty/tag--beta.svg?component';
import EligibleTag from 'haven-design-system/build/assets/svgs/images/warranty/tag--eligible.svg?component';
import IneligibleTag from 'haven-design-system/build/assets/svgs/images/warranty/tag--ineligible.svg?component';
import PolicyExpiredTag from 'haven-design-system/build/assets/svgs/images/warranty/tag--expired.svg?component';
import {
  isApplianceNotFound,
  getAge,
  getApplianceTitle,
  getBrandName,
  getEstimatedRemainingLife,
  getExpectedLife,
  getManufactureDate,
  getModelNumber,
  getRecallStatus,
  getSerialNumber,
} from '../../../../utils/reportApplianceInformation';
import './styles.scss';
import { useTypedSelector } from '../../../../redux/store';
import { selectReports } from '../../../../redux/selectors';

interface Props {
  applianceInformations: ReportApplianceInformation[];
  isPrint: boolean;
  inspectifyAdmin: boolean;
  isInspectionWarrantyExpired: boolean;
  hasInspectionWarranty: boolean;
}

const ApplianceInfo: React.FC<Props> = ({
  applianceInformations,
  isPrint,
  inspectifyAdmin,
  isInspectionWarrantyExpired,
  hasInspectionWarranty,
}) => {
  const reports = useTypedSelector(selectReports);
  const hasWarrantyAndIsNotExpired = useMemo(() => (
    hasInspectionWarranty && !isInspectionWarrantyExpired
  ), [isInspectionWarrantyExpired, hasInspectionWarranty]);

  const displayableApplianceInformations = useMemo(() => (
    applianceInformations.filter((applianceInfo) => {
      const report = reports[applianceInfo.reportId];
      const information = report ? report.informations[applianceInfo.reportInformationId] : null;
      return information ? !information.cannotAnswer : false;
    })
  ), [applianceInformations, reports]);

  const renderApplianceInformationTag = useCallback((
    applianceInformation: ReportApplianceInformation,
  ) => {
    if (hasWarrantyAndIsNotExpired && applianceInformation.warrantyCovered) {
      return <EligibleTag data-testid={`${applianceInformation.id}--eligible`} />;
    }
    if (hasWarrantyAndIsNotExpired && !applianceInformation.warrantyCovered) {
      return <IneligibleTag data-testid={`${applianceInformation.id}--ineligible`} />;
    }
    if (hasInspectionWarranty && isInspectionWarrantyExpired) {
      return <PolicyExpiredTag data-testid={`${applianceInformation.id}--expired`} />;
    }
    return <BetaTag data-testid={`${applianceInformation.id}--beta`} />;
  }, [hasWarrantyAndIsNotExpired, hasInspectionWarranty, isInspectionWarrantyExpired]);

  return (
    <div className="applianceInformation">
      {displayableApplianceInformations.map((applianceInformation, index) => (
        <div
          data-testid={applianceInformation.id}
          key={applianceInformation.id}
          id={applianceInformation.id}
        >
          <div className="applianceInformation__header">
            <h4 className="applianceInformation__header__title">
              {getApplianceTitle(applianceInformation)}
              {isApplianceNotFound(applianceInformation) && (<span> not found</span>)}
            </h4>

            <div className="applianceInformation__header__tag">
              {renderApplianceInformationTag(applianceInformation)}
              {inspectifyAdmin && !isPrint && (
                <div>
                  <a
                    data-testid={`${applianceInformation.id}--adminEdit`}
                    className="applianceInformation__header__tag__adminEdit"
                    href={`${process.env.MARKETPLACE_URL}/activeadmin/report_appliance_informations/${applianceInformation.id}/edit`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Edit
                  </a>
                </div>
              )}
            </div>
          </div>

          {isApplianceNotFound(applianceInformation) ? (
            <p className="applianceInformation__notFound">
              Unable to retrieve appliance data from the model / serial number photo.
            </p>
          ) : (
            <div className="applianceInformation__content">
              <div className="applianceInformation__content__info">
                <p className="applianceInformation__content__info__label">
                  Recall status
                </p>
                <p className="applianceInformation__content__info__value">
                  {getRecallStatus(applianceInformation)}
                </p>
              </div>

              <div className="applianceInformation__content__info">
                <p className="applianceInformation__content__info__label">
                  Manufacture date
                </p>
                <p className="applianceInformation__content__info__value">
                  {getManufactureDate(applianceInformation)}
                </p>
              </div>

              <div className="applianceInformation__content__info">
                <p className="applianceInformation__content__info__label">
                  Expected life
                </p>
                <p className="applianceInformation__content__info__value">
                  {getExpectedLife(applianceInformation)}
                </p>
              </div>

              <div className="applianceInformation__content__info">
                <p className="applianceInformation__content__info__label">
                  Model number
                </p>
                <p className="applianceInformation__content__info__value">
                  {getModelNumber(applianceInformation)}
                </p>
              </div>

              <div className="applianceInformation__content__info">
                <p className="applianceInformation__content__info__label">
                  Brand
                </p>
                <p className="applianceInformation__content__info__value">
                  {getBrandName(applianceInformation)}
                </p>
              </div>

              <div className="applianceInformation__content__info">
                <p className="applianceInformation__content__info__label">
                  Age
                </p>
                <p className="applianceInformation__content__info__value">
                  {getAge(applianceInformation)}
                </p>
              </div>

              <div className="applianceInformation__content__info">
                <p className="applianceInformation__content__info__label">
                  Estimated remaining life
                </p>
                <p className="applianceInformation__content__info__value">
                  {getEstimatedRemainingLife(applianceInformation)}
                </p>
              </div>

              <div className="applianceInformation__content__info">
                <p className="applianceInformation__content__info__label">
                  Serial number
                </p>
                <p className="applianceInformation__content__info__value">
                  {getSerialNumber(applianceInformation)}
                </p>
              </div>
            </div>
          )}

          {index !== applianceInformations.length - 1 && (
            <div className="applianceInformation__content__divider" />
          )}
        </div>
      ))}
    </div>
  );
};

export default ApplianceInfo;
