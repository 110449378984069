import styled from 'styled-components';
import { colorBaseBlackLight, colorBaseBluePrimary, colorPaletteGrayscale50 } from 'haven-design-system/build/typescript/es6';
import Button from '../../../../../components/Button';

export const HeaderText = styled.h3`
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: ${colorBaseBlackLight};
`;

export const Details = styled.div`
  margin: 24px 0;
`;

export const RowHeader = styled.h6`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
`;

export const RowInfoText = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
`;

export const Link = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: ${colorBaseBluePrimary};
  cursor: pointer;
`;

export const Divider = styled.div`
  height: 1px;
  width: 327px;
  background-color: ${colorPaletteGrayscale50};
`;

export const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 36px;
`;

export const CloseButton = styled(Button)`
  width: 68px;
`;
