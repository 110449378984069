import { ReportAttachment, ReportSeverityType } from 'marketplace-common';
import { WorkItem, WorkItemAttachment } from '../../types';

export enum RehabTabs {
  Report = 'Report',
  Rehab = 'Rehab',
}

export interface PriceBySystem {
  id: string
  price: number
}

export type ModalInformations = { label: string, value: string | null | undefined }[];

export type WorkItemModalOptions = {
  isOpen: boolean
  attachments?: (ReportAttachment | WorkItemAttachment)[]
  systemName?: string
  subsystemName?: string
  informations?: {label: string, value: string | null | undefined}[]
  noteTitle?: string
  noteDetails?: string
  note?: string
  severity?: ReportSeverityType
  limitation?: boolean
  itemType?: string
  itemId?: string
  pricingExternalId?: string
  templateWorkItemPricingId?: string
  workItem?: WorkItem
};

export type WorkItemInput = {
  systemName?: string
  title?: string
  details?: string
  unit?: string | null
  quantity?: number | null
  pricePerUnit?: number | null
  totalPrice?: number | null
  projectId?: string
  templateWorkItemPricingId?: string
  pricingExternalId?: string
  teamId?: number
  saveToLibrary?: boolean
  tags?: string[]
};
