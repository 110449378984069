import React from 'react';
import './styles.scss';
import UserRow from './UserRow/UserRow';
import { UserTableProps } from './types';

const UserTable: React.FC<UserTableProps> = ({
  userData,
  showArchivedUsers,
  setSingleUser,
  setManageUsersView,
}) => (
  <div className="userTable">
    <div className="userTable__header">
      <div className="userTable__header__section">User name</div>
      <div className="userTable__header__section">Status</div>
      <div className="userTable__header__section">Permissions</div>
      <div className="userTable__header__placeholder" />
    </div>
    {userData.map((user) => (
      <UserRow
        key={user.email}
        singleUserData={user}
        showArchivedUsers={showArchivedUsers}
        setSingleUser={setSingleUser}
        setManageUsersView={setManageUsersView}
      />
    ))}
  </div>
);

export default UserTable;
