import React, { useContext, useMemo } from 'react';
import styles from './styles.module.scss';
import {
  DrawInspectionBudgetSummaryProps,
  FINANCIAL_COLUMNS,
  FINANCIAL_COLUMNS_WITH_DISBURSEMENT,
  FINANCIAL_COLUMNS_WITH_UNITS,
  FINANCIAL_COLUMNS_WITH_UNITS_AND_DISBURSEMENT,
  NON_FINANCIAL_COLUMNS,
  NON_FINANCIAL_COLUMNS_WITH_UNITS,
  ReportInformationRowData,
} from './types';
import DrawRow from './DrawRow';
import DrawTotalRow from './DrawTotalRow';
import { ReportContext } from '../../context';

const DrawInspectionBudgetSummary: React.FC<DrawInspectionBudgetSummaryProps> = ({
  displayWithTotal,
}) => {
  const { state } = useContext(ReportContext);

  const drawRowData = useMemo(() => {
    if (!state.selectedReport || !state?.groupedReportSystemsByName) return [];
    return Object.keys(state.groupedReportSystemsByName).reduce(
      (acc: ReportInformationRowData[], systemName) => {
        state.groupedReportSystemsByName[systemName].forEach((system, systemIndex) => {
          system.reportSubsystemIds.forEach((subsystemId) => {
            const subsystem = state.selectedReport.subsystems[subsystemId];
            subsystem.reportInformationIds.forEach((infoId) => {
              const info = state.selectedReport.informations[infoId];

              if (!info.dataName.includes('division_progress')) return;

              const progress = parseInt(info.value || '0', 10) / 100;
              const budget = info?.tags?.draw_budget ? parseFloat(info.tags.draw_budget) : null;
              const used = (budget || 0) * progress;
              const remaining = budget !== null ? budget - used : 0;
              const disbursement = budget ? budget - remaining : 0;
              const unit = state?.selectedReport?.systemGroups?.[system.reportSystemGroupId]?.name;

              acc.push({
                id: info.id,
                division: systemIndex === 0 ? systemName : '',
                description: subsystem.name,
                budget,
                progress: parseFloat(info.value) || 0,
                disbursement: disbursement || 0,
                remaining: remaining || 0,
                unit,
              });
            });
          });
        });
        return acc;
      }, [],
    );
  }, [state?.selectedReport, state?.groupedReportSystemsByName]);

  const displayUnitColumns = useMemo(() => (
    (drawRowData || []).some(({ unit }) => unit !== null && unit !== undefined)
  ), [drawRowData]);

  // hides financial columns if all rows have missing budget data
  const displayFinancialColumns = useMemo(() => (
    (drawRowData || []).some(({ budget, division }) => budget !== null && division !== 'Total')
  ), [drawRowData]);

  const drawTableHeaders = useMemo(() => {
    if (!displayFinancialColumns) {
      return displayUnitColumns
        ? NON_FINANCIAL_COLUMNS_WITH_UNITS
        : NON_FINANCIAL_COLUMNS;
    }

    if (displayWithTotal) {
      return displayUnitColumns
        ? FINANCIAL_COLUMNS_WITH_UNITS_AND_DISBURSEMENT
        : FINANCIAL_COLUMNS_WITH_DISBURSEMENT;
    }

    return displayUnitColumns ? FINANCIAL_COLUMNS_WITH_UNITS : FINANCIAL_COLUMNS;
  }, [displayFinancialColumns, displayUnitColumns, displayWithTotal]);

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            {drawTableHeaders.map((header) => (
              <th className={styles.dataCell} key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {drawRowData.map((row) => (
            <DrawRow
              key={row.id}
              data={row}
              displayFinancialColumns={displayFinancialColumns}
              displayWithTotal={displayWithTotal}
              displayUnitColumns={displayUnitColumns}
            />
          ))}
          {displayWithTotal && (
            <DrawTotalRow
              data={drawRowData}
              displayWithTotal={displayWithTotal}
              displayFinancialColumns={displayFinancialColumns}
              displayUnitColumns={displayUnitColumns}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DrawInspectionBudgetSummary;
