// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo__1YxPIZLG7ORYRCXPiPm7tA{height:32px}@media(max-width: 350px){.logo__1YxPIZLG7ORYRCXPiPm7tA{width:90px}}.logoLarge__2zqqn5FPHyfm9h94AxJxQU{height:64px;max-width:124px}.verticalLine__2I6AQsREmy_HiOXPOOhvwm{margin-right:13px;margin-left:13px}", "",{"version":3,"sources":["webpack://./src/components/InspectifyCobrandingLogo/InspectifyCobrandingLogo.module.scss"],"names":[],"mappings":"AAAA,8BACE,WAAA,CACA,yBAFF,8BAGI,UAAA,CAAA,CAIJ,mCACE,WAAA,CACA,eAAA,CAGF,sCACE,iBAAA,CACA,gBAAA","sourcesContent":[".logo {\n  height: 32px;\n  @media (max-width: 350px) {\n    width: 90px;\n  }\n}\n\n.logoLarge {\n  height: 64px;\n  max-width: 124px;\n}\n\n.verticalLine {\n  margin-right: 13px;\n  margin-left: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo__1YxPIZLG7ORYRCXPiPm7tA",
	"logoLarge": "logoLarge__2zqqn5FPHyfm9h94AxJxQU",
	"verticalLine": "verticalLine__2I6AQsREmy_HiOXPOOhvwm"
};
export default ___CSS_LOADER_EXPORT___;
