import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Close from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import CheckMark from '../../../../assets/icons/checkmark_white.svg?component';
import { ReportDeliveryFilter } from '../../../../types';
import styles from './styles.module.scss';
import Button from '../../../../components/Button';

interface Props {
  filter: ReportDeliveryFilter | null
  groupNames: string[] | null
  groupNameOptions: string[],
  isReInspection: boolean
  changeFilter: (value: ReportDeliveryFilter) => void
  changeGroupNames: (value: string[] | null) => void
  close: () => void
}

const ReportFilter: React.FC<Props> = ({
  filter,
  groupNames,
  groupNameOptions,
  isReInspection,
  changeFilter,
  changeGroupNames,
  close,
}) => {
  const [groups, setGroups] = useState(groupNames || []);
  const [value, setValue] = useState(filter);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    changeFilter(value);
    changeGroupNames(groups);
    close();
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 className={styles.filterHeader}>Filter inspection report</h1>
        <Close style={{ cursor: 'pointer' }} onClick={() => close()} />
      </div>
      <Form onSubmit={handleSubmit}>
        {groupNameOptions.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              paddingBottom: 20,
              overflowX: 'scroll',
            }}
          >
            {groupNameOptions.map((option) => (
              <Button
                key={option}
                onClick={() => {
                  if (!option) {
                    setGroups([]);
                  } else if (groups.includes(option)) {
                    setGroups(groups.filter((group) => group !== option));
                  } else {
                    setGroups([...groups, option]);
                  }
                }}
                style={{
                  borderRadius: '20px',
                  width: 'auto',
                }}
                secondary={option ? !groups.includes(option) : groups.length > 0}
              >
                {(groups.includes(option) || (groups.length === 0 && !option)) && (
                  <CheckMark style={{ marginRight: 8 }} />
                )}
                {option || 'All units'}
              </Button>
            ))}
          </div>
        )}

        <Form.Check id={ReportDeliveryFilter.FullReport}>
          <Form.Check.Input
            type="radio"
            name="report-filter"
            checked={value === ReportDeliveryFilter.FullReport}
            className={styles.filterInput}
            onChange={() => setValue(ReportDeliveryFilter.FullReport)}
          />
          <Form.Check.Label className={styles.filterLabel}>
            <div>{ReportDeliveryFilter.FullReport}</div>
            <div className={styles.filterDescription}>
              See all data points captured on the inspection.
            </div>
          </Form.Check.Label>
        </Form.Check>

        <Form.Check id={ReportDeliveryFilter.IssuesOnly}>
          <Form.Check.Input
            type="radio"
            name="report-filter"
            checked={value === ReportDeliveryFilter.IssuesOnly}
            className={styles.filterInput}
            onChange={() => setValue(ReportDeliveryFilter.IssuesOnly)}
          />
          <Form.Check.Label className={styles.filterLabel}>
            <div>{ReportDeliveryFilter.IssuesOnly}</div>
            <div className={styles.filterDescription}>
              {'See all deficiencies and limitations captured by the inspector, '
              + 'plus additional concerns identified by Inspectify.'}
            </div>
          </Form.Check.Label>
        </Form.Check>

        {isReInspection && (
          <Form.Check id={ReportDeliveryFilter.RemainingDefectsOnly}>
            <Form.Check.Input
              type="radio"
              name="report-filter"
              checked={value === ReportDeliveryFilter.RemainingDefectsOnly}
              className={styles.filterInput}
              onChange={() => setValue(ReportDeliveryFilter.RemainingDefectsOnly)}
            />
            <Form.Check.Label className={styles.filterLabel}>
              <div>Outstanding issues</div>
              <div className={styles.filterDescription}>
                See just the issues that remain unresolved.
              </div>
            </Form.Check.Label>
          </Form.Check>
        )}

        {isReInspection && (
          <Form.Check id={ReportDeliveryFilter.FixedDefectsOnly}>
            <Form.Check.Input
              type="radio"
              name="report-filter"
              checked={value === ReportDeliveryFilter.FixedDefectsOnly}
              className={styles.filterInput}
              onChange={() => setValue(ReportDeliveryFilter.FixedDefectsOnly)}
            />
            <Form.Check.Label className={styles.filterLabel}>
              <div>Fixed issues</div>
              <div className={styles.filterDescription}>
                See just the issues that have been addressed.
              </div>
            </Form.Check.Label>
          </Form.Check>
        )}

        <Form.Check id={ReportDeliveryFilter.MediaOnly}>
          <Form.Check.Input
            type="radio"
            name="report-filter"
            checked={value === ReportDeliveryFilter.MediaOnly}
            className={styles.filterInput}
            onChange={() => setValue(ReportDeliveryFilter.MediaOnly)}
          />
          <Form.Check.Label className={styles.filterLabel}>
            <div>{ReportDeliveryFilter.MediaOnly}</div>
            <div className={styles.filterDescription}>
              See just the photos and videos captured on the inspection.
            </div>
          </Form.Check.Label>
        </Form.Check>

        <Form.Check id={ReportDeliveryFilter.AppliancesOnly}>
          <Form.Check.Input
            type="radio"
            name="report-filter"
            checked={value === ReportDeliveryFilter.AppliancesOnly}
            className={styles.filterInput}
            onChange={() => {
              setValue(ReportDeliveryFilter.AppliancesOnly);
            }}
          />
          <Form.Check.Label className={styles.filterLabel}>
            <div>{ReportDeliveryFilter.AppliancesOnly}</div>
            <div className={styles.filterDescription}>
              See all appliances captured on the inspection.
            </div>
          </Form.Check.Label>
        </Form.Check>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingTop: 20,
          }}
        >
          <Button
            secondary
            style={{ width: '100px', marginRight: '8px' }}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button type="submit" style={{ width: '100px' }}>Save</Button>
        </div>
      </Form>
    </>
  );
};

export default ReportFilter;
