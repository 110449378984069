import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import {
  Alert,
  Spinner,
  Button,
  Container,
  Row,
  Col,
  Image,
  ProgressBar,
} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import styles from './styles.module.scss';
import { useUpload, UploadsInterface, UploadedMedia } from './useUpload';
import Thumbnail from '../PDF/Thumbnail';

interface Props {
  setUploadedFiles: React.Dispatch<React.SetStateAction<UploadsInterface>>;
}

const Uploader: React.FC<Props> = ({
  setUploadedFiles,
}) => {
  const hiddenFileInput = React.useRef(null);

  const {
    upload,
    uploads,
    remainingCount,
    error,
    setError,
  } = useUpload('private', true);

  const handleClick = (e: any) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleChange = async (acceptedFiles: File[]) => {
    await upload(acceptedFiles, true);
  };

  const renderTile = (file: UploadedMedia) => {
    switch (file.contentType) {
      case 'video':
        return (
          <video
            className={styles.imageGridItem}
            src={file.signedUrl}
            controls
            muted
          />
        );
      case 'pdf':
        return (
          <Thumbnail
            klassNames={[styles.imageGridItem]}
            fileUrl={file.signedUrl}
          />
        );
      default:
        return (
          <Image
            className={styles.imageGridItem}
            src={file.signedUrl}
            fluid
          />
        );
    }
  };

  useEffect(() => {
    if (uploads) setUploadedFiles(uploads);
  }, [uploads]);

  return (
    <>
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          There was an issue uploading the file.
          Please try again or contact customer service for support.
          Error: {error}.
        </Alert>
      )}

      <div className={styles.tabContainer}>
        <Dropzone onDrop={(acceptedFiles) => handleChange(acceptedFiles)}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} ref={hiddenFileInput} />
              <Button
                className={styles.uploadButton}
                onClick={handleClick}
                variant="outline-primary"
                disabled={isDragActive}
              >
                <FontAwesomeIcon icon={faUpload} size="sm" /> Upload file
              </Button>
            </div>
          )}
        </Dropzone>

        {uploads && !error && remainingCount > 0 && (
          <div className={styles.spinner}><Spinner animation="border" /></div>
        )}

        <Container className={styles.tabContainer}>
          <Row>
            {Object.keys(uploads).map((key) => (
              <Col key={key} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <div className={styles.imageContainer}>
                  <ProgressBar now={uploads[key]?.progress} />
                  {renderTile(uploads[key])}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Uploader;
