import React from 'react';
import './styles.scss';
import { ToggleSwitchProps } from './types';

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isOn, toggle, disabled, tag,
}) => (
  <>
    <label
      className={disabled ? 'toggleSwitch--disabled' : 'toggleSwitch'}
      htmlFor={`toggle-switch-${tag}`}
      aria-hidden="true"
    >
      <input
        id={`toggle-switch-${tag}`}
        name={tag}
        type="checkbox"
        className="toggleSwitch__input"
        checked={isOn}
        disabled={disabled}
        onChange={toggle}
      />
      <span className={disabled ? 'toggleSwitch__slider--disabled' : 'toggleSwitch__slider'} />
    </label>
  </>
);

export default ToggleSwitch;
