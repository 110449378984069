import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { captureException } from '../../utils/error';
import { CREATE_SURVEYOR_ONBOARDING } from '../../graphql/mutations/surveyorOnboarding';
import { useUrlQuery } from '../../utils/hooks';

const OnboardingRedirectPage: React.FC = () => {
  const query = useUrlQuery();
  const qUtmSource = query.get('utm_source');
  const qUtmCampaign = query.get('utm_campaign');

  const [createSurveyorOnboarding] = useMutation(
    CREATE_SURVEYOR_ONBOARDING,
    {
      onCompleted: (data) => {
        if (data) {
          window.location.href = `/onboarding/surveyor/${data.createSurveyorOnboarding.surveyorOnboarding.id}`;
        }
      },
      onError: () => {
        captureException(new Error('Graphql failed to create surveyor onboarding'));
      },
    },
  );

  useEffect(() => {
    try {
      createSurveyorOnboarding({
        variables: {
          input: {
            utmSource: qUtmSource,
            utmCampaign: qUtmCampaign,
          },
        },
      });
    } catch (error) {
      captureException(error);
    }
  }, []);

  return <div />;
};

export default OnboardingRedirectPage;
