import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { setActiveProject } from '../../redux/actions';
import { useUrlQuery } from '../../utils/hooks';
import { PermitsProvider } from './context';

import PermitsPage from './page/PermitsPage';
import PermitsPdfPage from './page/PermitsPdfPage';

interface Props extends RouteComponentProps<{
  orderToken: string,
  projectId: string
}>{}

const PermitsPageContainer: React.FC<Props> = ({ match }) => {
  const { projectId, orderToken } = match.params;
  const query = useUrlQuery();
  const isPrintMode = query.get('print') === 'true';

  const dispatch = useDispatch();
  useEffect(() => {
    if (projectId) dispatch(setActiveProject(projectId, {}));
  }, [projectId]);

  return (
    <PermitsProvider>
      {!isPrintMode ? (
        <PermitsPage orderToken={orderToken} />
      ) : (
        <PermitsPdfPage orderToken={orderToken} />
      )}
    </PermitsProvider>
  );
};

export default PermitsPageContainer;
