import React, { useMemo } from 'react';
import Modal from 'react-modal';
import {
  getClient, getFormattedPhoneNumber, getFormattedString,
  INSPECTIFY_PHONE_NUMBER, isHelloInspectifyEmail,
} from 'marketplace-common';
import {
  HeaderText, Details, RowHeader, RowInfoText, Link, Divider,
  BottomButtonContainer,
  CloseButton,
} from './styles';
import { useTypedSelector } from '../../../../../redux/store';
import { selectWorksOrder, selectWorksOrderProperty, selectWorksOrderStakeholders } from '../../../../../redux/selectors';

interface Props {
  modalIsOpen: boolean;
  closeModal: () => void;
  orderId: number;
}

const ContactsModal: React.FC<Props> = ({ modalIsOpen, closeModal, orderId }) => {
  const order = useTypedSelector((state) => selectWorksOrder(state, orderId));
  const orderStakeholders = useTypedSelector(selectWorksOrderStakeholders);
  const property = useTypedSelector((state) => selectWorksOrderProperty(state, orderId));

  const client = useMemo(
    () => ((orderStakeholders && order?.orderStakeholderIds)
      ? getClient(orderStakeholders, order.orderStakeholderIds)
      : null),
    [order.orderStakeholderIds, orderStakeholders],
  );

  const stakeholders = useMemo(
    () => order.orderStakeholderIds?.map(
      (stakeHolderId) => orderStakeholders[stakeHolderId],
    ).filter((stakeholder) => {
      if (client) {
        return (
          ((stakeholder.name !== null && stakeholder.name !== client.name)
            || (stakeholder.phone !== null && stakeholder.phone !== client.phone)
            || (stakeholder.email !== null && stakeholder.email !== client.email))
            && !stakeholder.isClient
        );
      }
      return true;
    }), [order, client],
  );

  const emailSubject = `Inspection - ${property.street || ''} ${property.street2 || ''}\n`;

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className="InspectorDashboardModal"
      overlayClassName="InspectorDashboardOverlay"
      preventScroll={false}
    >
      <div>
        <HeaderText>Contacts</HeaderText>
        {(stakeholders && client)
          && [client, ...stakeholders].map((stakeholder, index) => (
            <div key={stakeholder.id}>
              <Details>
                <RowHeader>
                  {getFormattedString(stakeholder.stakeholderType)}&apos;s name
                </RowHeader>
                <RowInfoText>{stakeholder.name || '--'}</RowInfoText>
              </Details>
              {stakeholder.phone
                && (stakeholder.phone !== INSPECTIFY_PHONE_NUMBER || stakeholder.isClient)
                && (
                <Details>
                  <RowHeader>Phone</RowHeader>
                  <Link href={`tel:+1${stakeholder.phone}`}>
                    {getFormattedPhoneNumber(stakeholder.phone)}
                  </Link>
                </Details>
                )}
              {stakeholder.email
                && (!isHelloInspectifyEmail(stakeholder.email) || stakeholder.isClient)
                && (
                <Details>
                  <RowHeader>Email</RowHeader>
                  <Link href={`mailto:${stakeholder.email}?subject=${emailSubject}&body=`}>
                    {stakeholder.email}
                  </Link>
                </Details>
                )}
              {index !== stakeholders.length
                && <Divider />}
            </div>
          ))}
        <BottomButtonContainer>
          <CloseButton onClick={closeModal} secondary>
            Close
          </CloseButton>
        </BottomButtonContainer>
      </div>
    </Modal>
  );
};

export default ContactsModal;
