import { colorPaletteGrayscale50, colorBaseRedPrimary } from 'haven-design-system/build/typescript/es6';
import React from 'react';
import { formatPhoneNumber, unformatPhoneNumber } from '../../utils/string';
import './styles.scss';

interface Props {
  name?: string,
  value: string | number,
  onChange: (e: any) => void,
  type?: string,
  inputRef?: any,
  placeholder?: string,
  required?: boolean,
  indicateRequired?: boolean,
  style?: React.CSSProperties,
  inputStyle?: React.CSSProperties,
  phone?: boolean,
  min?: string,
  max?: string,
  datalist?: string[]
}

const blur = (e: any) => {
  if (e.target instanceof HTMLElement) {
    e.target.blur();
  }
};

const FormInput: React.FC<Props> = ({
  name, value, onChange, type, inputRef, placeholder, required, datalist,
  indicateRequired, phone, inputStyle, min, max,
}) => (
  <div className="inputContainer">
    <label htmlFor={name} className="label">
      {name && `${required ? '*' : ''} ${name}`}
    </label>
    <input
      id={name}
      ref={inputRef}
      type={type || 'text'}
      className="input"
      value={(phone ? formatPhoneNumber(value as string) : value) || ''}
      onChange={(e) => (phone ? onChange(unformatPhoneNumber(e.target.value)) : onChange(e))}
      placeholder={placeholder || ''}
      required={required}
      onWheel={(e) => blur(e)}
      list={`${name}-datalist`}
      style={{
        borderColor: indicateRequired
          ? colorBaseRedPrimary : colorPaletteGrayscale50,
        ...inputStyle,
      }}
      min={min}
      max={max}
    />
    {datalist && (
      <datalist id={`${name}-datalist`}>
        {datalist.map((item: string) => (<option key={item} value={item} aria-label={item} />))}
      </datalist>
    )}
  </div>
);

export default FormInput;
