import { gql } from '@apollo/client';

export const DIRECT_UPLOAD = gql`
  mutation DirectUpload(
    $input: DirectUploadInput! # filename: String!, byteSize: Int!, checksum: String!, contentType: String!, metadata: JsonAsString
  ) {
    directUpload(input: $input) {
      blobSignedId
      contentMd5
      contentType
      url
    }
  }
`;