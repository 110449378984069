import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colorBaseBluePrimary, colorBaseGreyDark, colorBaseWhitePrimary } from 'haven-design-system/build/typescript/es6';
import styled, { css } from 'styled-components';
import { CommonTextStyles } from '../../../shared/styles';

export const InspectionRow = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LeftLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoadingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
  gap: 10px;
  flex: 2;
`;

export const StatusLogo = styled.img`
  width: 20px;
  align-self: auto;
  margin-right: 15px;
`;

export const RowButton = styled.button<{
  completed: boolean,
}>`
  width: fit-content;
  align-self: center;
  margin: 16px;
  height: 25px;
  padding: 4px 16px;
  border-radius: 2px;
  border: 0;
  ${CommonTextStyles};
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${colorBaseBluePrimary};
  background-color: transparent;
  ${(props) => (!props.completed && css`
   color: ${colorBaseWhitePrimary};
   background-color: ${colorBaseBluePrimary};
  `)}
`;

export const ErrorText = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${colorBaseGreyDark};
`;

export const ModalIcon = styled(FontAwesomeIcon)`
  align-self: flex-start;
`;

export const ModalErrorText = styled.div`
  color: red;
  font-size: 12px;
`;
