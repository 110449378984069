import { gql } from '@apollo/client';

export const SUBMIT_HOME_OWNERSHIP_FORM = gql`
  mutation SubmitHomeOwnershipForm($input: WarrantyProveOwnershipInput!) {
    warrantyProveOwnership(input: $input) {
      inspectionWarranty {
        createdAt
        expirationDate
        id
        inspectionId
        startDate
        status
      }
    }
  }
`;