import { gql } from '@apollo/client';
import { FULL_CONTRACTOR_FIELDS } from '../queries';

export const CREATE_USER_CONTRACTOR = gql`
    mutation CreateUserContractor($createUserInput: CreateUserInput!) {
      createUser(createUserInput: $createUserInput) {
        success
        code
        message
        errors
      }
  }
`;

export const UPDATE_CONTRACTOR = gql`
  ${FULL_CONTRACTOR_FIELDS}
  mutation UpdateContractor($input: UpdateContractorInput!) {
    updateContractor(input: $input) {
      contractor {
        ...FullContractorFields
      }
    }
  }
`;
