import styled from 'styled-components';
import { Container as BootstrapContainer } from 'react-bootstrap';
import {
  colorBaseBlackDark,
  colorBaseBlackLight,
  colorBaseBluePrimary,
  colorBaseGreyDark,
  colorBaseSandPrimary,
  colorBaseWhitePrimary,
} from 'haven-design-system/build/typescript/es6';

export const Page = styled(BootstrapContainer)`
  height: 100%;
  background-color: ${colorBaseWhitePrimary};
  padding: 0;
`;

export const HeaderPrint = styled.div`
  width: 100%;
  margin-bottom: 44px;
`;

export const FooterPrint = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export const FooterPrintText = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

export const Container = styled.div`
  padding: 48px;
  background-color: ${colorBaseWhitePrimary};
  page-break-after: always;
`;

export const Header = styled.div`
  font-size: 56px;
  font-weight: 700;
  line-height: 78px;
  color: ${colorBaseBlackLight};
  white-space: pre-line;
`;

export const Subheader = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  color: ${colorBaseGreyDark};
  white-space: pre-line;
  padding-top: 24px;
`;

export const Divider = styled.div`
  height: 3px;
  width: 124px;
  background-color: ${colorBaseBluePrimary};
  margin: 64px 0;
  object-fit: contain;
`;

export const PropertyImage = styled.img`
  height: 485px;
  width: 882px;
  object-fit: cover;
  overflow: hidden;
`;

export const InspectifyLogo = styled.img`
  height: 34px;
  margin-bottom: 16;
`;

export const BetterLogo = styled.img`
  height: 34px;
  margin-bottom: 16px;
  max-width: 190px;
`;

export const InspectifyContainer = styled.div`
  padding-top: 74px;
`;

export const InspectifyRow = styled.div`
  margin: 16px 0;
`;

export const InspectifyText = styled.div`
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: ${colorBaseGreyDark};
  margin-bottom: 0;
  margin-left: 16px;
`;

export const DetailContainer = styled.div<{ backgroundColor?: string }>`
  position: relative;
  bottom: 25px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colorBaseSandPrimary)};
  padding: 36px 12px 24px 36px;
  width: 540px;
  float: right;
`;

export const DetailRow = styled.div`
  padding: 5px 0;
  width: 100%;
`;

export const DetailLabel = styled.div`
  display: inline-block;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  color: ${colorBaseBlackLight};
  width: 40%;
`;

export const DetailValue = styled.div`
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  color: ${colorBaseBlackLight};
  width: 60%;
`;

export const MenuPoweredByText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${colorBaseBlackDark};
  margin-bottom: 8px;
`;

export const CoverPageContainer = styled.div`
`;
