import React, { useMemo } from 'react';
import { flatten } from 'lodash';
import {
  colorPaletteGrayscale50,
} from 'haven-design-system/build/typescript/es6';
import { WorkItem as WorkItemType } from '../../../../types';
import {
  Table, TableBody, TableHead, TableRow,
} from '../../styles';
import WorkItem from './WorkItem';
import { WorkItemTableHeaderName, WorkItemTableHeaderCost } from './styles';
import { useTypedSelector } from '../../../../redux/store';
import { selectRehabWorkItemAttachments } from '../../../../redux/selectors';

interface Props {
  workItems: { [systemName: string]: WorkItemType[] },
  imageWidth: number,
}

const Content: React.FC<Props> = ({ workItems, imageWidth }) => {
  const workItemAttachments = useTypedSelector(selectRehabWorkItemAttachments);
  const items = useMemo(() => (workItems ? flatten(Object.values(workItems)) : []), [workItems]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <WorkItemTableHeaderName isBold>Work Item</WorkItemTableHeaderName>
          <WorkItemTableHeaderCost isBold>Cost</WorkItemTableHeaderCost>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, i) => (
          <TableRow backgroundColor={i % 2 === 0 ? 'transparent' : colorPaletteGrayscale50}>
            <td colSpan={2}>
              <WorkItem
                key={item.id}
                item={item}
                imageWidth={imageWidth}
                workItemAttachments={workItemAttachments}
              />
            </td>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Content;
