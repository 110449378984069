import React from 'react';
import { Alert } from 'react-bootstrap';
import { ApolloError } from '@apollo/client';
import {
  SectionTitle, FormWrapper, Row, Title, ReverseRow,
} from './styles';
import {
  FormInput, FormSelect, FormFile, UpdateButton,
} from '../../../components/FormElements';
import { ContractorFileAttachment, FileTypes } from '../../../types';
import { ContractorState } from './types';
import { BOOLEAN_OPTIONS } from './constant';
import { Container } from '../shared/styles';

interface Props {
  state: ContractorState,
  dispatch: React.Dispatch<any>,
  loading: boolean,
  error: ApolloError,
  sectionTitleRef: any,
  certFiles: ContractorFileAttachment[],
  contractFiles: ContractorFileAttachment[],
  errorEditingInfo: any,
  handleClick: () => void,
  indicateRequiredFields: boolean,
  data: boolean,
  setErrorEditingInfo: (value: any) => void,
  mapsRef: any,
  addFile: (uploadedFile: File, category: string) => void;
  loadingCert: boolean;
}

const BusinessDetails: React.FC<Props> = ({
  state,
  dispatch,
  loading,
  error,
  sectionTitleRef,
  certFiles,
  contractFiles,
  errorEditingInfo,
  handleClick,
  indicateRequiredFields,
  data,
  setErrorEditingInfo,
  mapsRef,
  addFile,
  loadingCert,
}) => (
  <Container>
    {(errorEditingInfo || error) && (
      <Alert variant="danger" onClose={() => setErrorEditingInfo(null)} dismissible>
        There was an issue updating your info.
        Please try again or contact customer service for support.
      </Alert>
    )}
    <Title>Business details</Title>
    <form noValidate onSubmit={(e) => { e.preventDefault(); handleClick(); }}>
      <FormWrapper>
        <SectionTitle ref={sectionTitleRef}>Business details</SectionTitle>
        <Row>
          <FormInput name="Business name" value={state.contractor.businessName} required indicateRequired={indicateRequiredFields && state.contractor.businessName === ''} onChange={(e:any) => dispatch({ type: 'businessName', payload: e.target.value })} />
        </Row>
        <Row>
          <FormInput name="Business contact name" value={state.contractor.businessContactName} onChange={(e:any) => dispatch({ type: 'businessContactName', payload: e.target.value })} />
          <FormInput name="Business Email" value={state.contractor.businessEmail} required indicateRequired={indicateRequiredFields && state.contractor.businessEmail === ''} onChange={(e:any) => dispatch({ type: 'businessEmail', payload: e.target.value })} />
          <FormInput name="Business Phone number" phone value={state.contractor.businessPhone} onChange={(value:any) => dispatch({ type: 'businessPhone', payload: value })} />
        </Row>
        <Row>
          <FormInput name="Scheduler name" value={state.contractor.schedulerName} onChange={(e:any) => dispatch({ type: 'schedulerName', payload: e.target.value })} />
          <FormInput name="Scheduler Email" value={state.contractor.schedulerEmail} required indicateRequired={indicateRequiredFields && state.contractor.schedulerEmail === ''} onChange={(e:any) => dispatch({ type: 'schedulerEmail', payload: e.target.value })} />
          <FormInput name="Scheduler Phone number" phone value={state.contractor.schedulerPhone} onChange={(value:any) => dispatch({ type: 'schedulerPhone', payload: value })} />
        </Row>
        <Row>
          <FormInput name="Website" value={state.contractor.businessSite} onChange={(e:any) => dispatch({ type: 'businessSite', payload: e.target.value })} />
        </Row>
        <Row>
          <FormInput inputRef={mapsRef} name="Business address" value={state.contractor.businessAddress} onChange={(e:any) => dispatch({ type: 'businessAddress', payload: e.target.value })} />
        </Row>
        <SectionTitle>Certifications</SectionTitle>
        <Row>
          <FormInput name="State License Number" value={state.contractor.stateLicenseNumber} onChange={(e:any) => dispatch({ type: 'stateLicenseNumber', payload: e.target.value })} />
          <FormInput name="ASHI License Number" value={state.contractor.ashiLicenseNumber} onChange={(e:any) => dispatch({ type: 'ashiLicenseNumber', payload: e.target.value })} />
        </Row>
        <Row>
          <FormInput name="InterNACHI License Number" value={state.contractor.internachiLicenseNumber} onChange={(e:any) => dispatch({ type: 'internachiLicenseNumber', payload: e.target.value })} />
          <FormInput name="Other License Number" value={state.contractor.otherLicenseNumber} onChange={(e:any) => dispatch({ type: 'otherLicenseNumber', payload: e.target.value })} />
        </Row>
        <SectionTitle>Serviceable areas</SectionTitle>
        <Row>
          <FormInput name="Farthest distance willing to drive, in miles" type="number" value={state.contractor.serviceAreaRadiusMileage} onChange={(e:any) => dispatch({ type: 'serviceAreaRadiusMileage', payload: e.target.value })} />
        </Row>
        <SectionTitle>Inspection tools</SectionTitle>
        <Row>
          <FormSelect name="Do you have supra access?" options={BOOLEAN_OPTIONS} value={state.contractor.hasSupra ? 'true' : 'false'} onChange={(e:any) => dispatch({ type: 'hasSupra', payload: e.target.value })} />
          <FormInput name="Report writing software" value={state.contractor.reportWritingSoftware ? state.contractor.reportWritingSoftware.charAt(0).toUpperCase() + state.contractor.reportWritingSoftware.slice(1) : ''} onChange={(e:any) => dispatch({ type: 'reportWritingSoftware', payload: e.target.value })} />
        </Row>
        <SectionTitle>Documents</SectionTitle>
        { (state?.contractor?.msaSigned)
          && (
            <Row style={{ marginBottom: 12 }}>
              <FormFile name="Master Inspection Agreement" category={FileTypes.CONTRACT} files={contractFiles} upload={false} />
            </Row>
          )}
        <Row noMargin>
          <FormFile name="Insurance certificate form" category={FileTypes.INSURANCE} files={certFiles} loading={loadingCert} addFile={addFile} upload />
        </Row>
      </FormWrapper>
      <ReverseRow>
        <UpdateButton
          onClick={handleClick}
          loading={loading}
          success={data}
          hasStateChanged={state.hasStateChanged}
        />
      </ReverseRow>
    </form>
  </Container>
);

export default BusinessDetails;
