import {
  colorBaseBlackLight,
  colorBaseBlackPrimary,
  colorBaseBluePrimary,
  colorPaletteGrayscale50,
  colorBaseGreyPrimary,
} from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

export const LibContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-right: 22px;
`;

export const WorkItemContainer = styled.div`
  width: 100%;
  height: 128px;
  border: 1px solid ${colorPaletteGrayscale50};
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
  &:hover {
    cursor: pointer;
  }
`;

export const WorkItemTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: ${colorBaseBlackLight};
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const WorkItemDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colorBaseBlackLight};
  position: relative;
  top: 3px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const WorkItemPrice = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
`;

export const SearchLibraryWorkItems = styled.input`
  width: 100%;
  min-height: 48px;
  border: 1px solid #A0A0A0;
  border-radius: 4px;
  padding-left: 45px;
  margin-bottom: 16px;
`;

export const Icon = styled.img`
  position: relative;
  align-self: flex-start;
  margin-left: 16px;
  top: 32px; 
`;

export const SelectedWorkItemContainer = styled.div`
  width: 100%;
  border: 3px solid ${colorBaseBluePrimary};
  border-radius: 4px;
  padding: 20px;
  display: column;
  margin-bottom: 16px;
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EditItemButton = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: ${colorBaseBluePrimary};
  &:hover {
    cursor: pointer;
  }
`;

export const Checkmark = styled.img`
  position: relative;
  margin-left: auto;
  top: 17px;
  left: 14px;
  z-index: 10;
  margin-top: -17px;
  width: 33px;
  height: 33px;
`;

export const WorkItemText = styled.div`
  font-size: 16px;
  color: ${colorBaseBlackLight};
  align-self: center;
  width: 30%;
`;

export const WorkItemDescriptionText = styled(WorkItemText)`
  align-self: flex-start;
  margin-top: 10px;
  width: 30%;
`;

export const DescriptionInput = styled.textarea`
  min-height: 96px;
  width: 70%;
  border: 1px solid ${colorBaseGreyPrimary};
  border-radius: 4px;
  color: ${colorBaseBlackPrimary};
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  resize: none;
`;

export const QuantityInput = styled.input`
  min-height: 48px;
  width: 70%;
  border: 1px solid ${colorBaseGreyPrimary};
  border-radius: 4px;
  color: ${colorBaseBlackPrimary};
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
`;

export const InfoRow = styled(TopRow)`
  margin-top: 15px;
`;

export const WorkItemFinalPrice = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  align-self: center;
  justify-self: left;
`;

export const FinalRow = styled(TopRow)`
  width: 70%;
  justify-self: right;
`;

export const LumpSumContainer = styled.div`
  justify-self: right;
  display: flex;
  flex-direction: row;
`;

export const LumpSumText = styled.div`
  font-size: 14px;
  margin-left: 6px;
  align-self: center;
`;

export const BottomRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 12px 0px 0px;
  gap: 24px;
`;
