import { Orders } from '../../types';
import {
  ADD_ORDER,
  REMOVE_ORDER,
  CLEAR_ORDERS,
} from '../actions/types';

export const initialOrderState = null as Orders;

export default function orders(state: any = initialOrderState, action: any) {
  switch (action.type) {
    case ADD_ORDER: {
      const ordersState = { ...state };
      ordersState[action.payload.token || action.payload.readOnlyToken] = action.payload;
      return ordersState;
    }
    case REMOVE_ORDER: {
      const ordersState = { ...state };
      delete ordersState[action.payload];
      return ordersState;
    }
    case CLEAR_ORDERS:
      return null;
    default:
      return state;
  }
}
