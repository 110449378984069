import { IdRef } from 'marketplace-common';
import {
  SET_ACTIVE_ITEM,
  SET_ACTIVE_ITEMS,
} from './types';

export const setActiveReport = (id: IdRef, params: any = {}) => (dispatch: any) => {
  dispatch({ type: SET_ACTIVE_ITEM, payload: { type: 'report', id, params } });
};

export const setActiveSystemGroupFilters = (ids: IdRef[], params: any = {}) => (dispatch: any) => {
  dispatch({ type: SET_ACTIVE_ITEMS, payload: { type: 'systemGroupFilters', ids, params } });
};

export const setActiveOrder = (id: IdRef, params: any = {}) => (dispatch: any) => {
  dispatch({ type: SET_ACTIVE_ITEM, payload: { type: 'order', id, params } });
};

export const setActiveProject = (id: IdRef, params: any = {}) => (dispatch: any) => {
  dispatch({ type: SET_ACTIVE_ITEM, payload: { type: 'project', id, params } });
};

export const setActiveProperty = (id: IdRef, params: any = {}) => (dispatch: any) => {
  dispatch({ type: SET_ACTIVE_ITEM, payload: { type: 'property', id, params } });
};
