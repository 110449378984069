import React, {
  useEffect, useState, useMemo,
} from 'react';
import { useMutation } from '@apollo/client';
import { usePlacesWidget } from 'react-google-autocomplete';
import { UPDATE_CONTRACTOR } from '../../../graphql/mutations';
import { captureException } from '../../../utils/error';
import { ContractorFileAttachment, UpdateContractorReturnDataType } from '../../../types';
import { BusinessDetailsLoaderProps } from './types';
import { EventName, track } from '../../../utils/analytics';
import BusinessDetails from './BusinessDetails';
import { isUserUsingMobile } from '../../../utils/user';
import { StateCodeEnum, StateEnum } from '../../../types/address';
import { getStateNameFromStateCode } from '../../../utils/map';

const BusinessDetailsLoader: React.FC<BusinessDetailsLoaderProps> = ({
  contractor,
  certificationsRef,
  basicInformationRef,
  serviceableAreaRef,
  accessAndSoftwareRef,
  documentsRef,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState<StateEnum | StateCodeEnum>();
  const [zipCode, setZipCode] = useState('');
  const [serviceAreaRadiusInMiles, setServiceAreaRadiusInMiles] = useState(0);
  const [hasSupra, setHasSupra] = useState<boolean>();
  const [inspectorLicenseHeld, setInspectorLicenseHeld] = useState(false);
  const [contractorLicenseHeld, setContractorLicenseHeld] = useState(false);
  const [realEstateLicenseHeld, setRealEstateLicenseHeld] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showZipCodeError, setShowZipCodeError] = useState(false);
  const [address, setAddress] = useState('');
  const [certificationFiles, setCertificationFiles] = useState([] as ContractorFileAttachment[]);
  const [misaFiles, setMisaFiles] = useState([] as ContractorFileAttachment[]);

  const isMobile = useMemo(() => isUserUsingMobile(), []);

  useEffect(() => {
    if (contractor) {
      setFirstName(contractor.businessName.split(' ')[0]);
      setLastName(contractor.businessName.split(' ')[1]);
      setEmail(contractor.businessEmail);
      setPhoneNumber(contractor.businessPhone);
      setAddress(contractor.businessAddress);
      const splitAddress: string[] = contractor.businessAddress.split(',');
      const splitStateZip: string[] = splitAddress[2].split(' ');
      setStreetAddress(splitAddress[0]);
      setCity(splitAddress[1]);
      setState(getStateNameFromStateCode(splitStateZip[1]) as StateEnum);
      setZipCode(splitStateZip[2]);
      setServiceAreaRadiusInMiles(contractor.serviceAreaRadiusMileage);
      setHasSupra(contractor.hasSupra);
      setInspectorLicenseHeld(contractor.inspectorLicenseHeld);
      setContractorLicenseHeld(contractor.contractorLicenseHeld);
      setRealEstateLicenseHeld(contractor.realEstateLicenseHeld);
    }
  }, [contractor]);

  const sortFiles = () => {
    if (contractor.fileAttachments) {
      const tempCert: ContractorFileAttachment[] = [];
      const tempMisa: ContractorFileAttachment[] = [];
      contractor.fileAttachments.forEach((file: ContractorFileAttachment) => {
        if (file.category === 'insurance_certificate') {
          tempCert.push(file);
        } else if (file.category === 'contract') {
          tempMisa.push(file);
        }
      });
      setCertificationFiles(tempCert);
      setMisaFiles(tempMisa);
    }
  };

  useEffect(() => {
    sortFiles();
  }, [contractor.fileAttachments]);

  const { ref } = usePlacesWidget({
    apiKey: process.env.GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place: any) => {
      const formattedAddress: string[] = place.formatted_address.split(',');
      setAddress(place.formatted_address);
      setStreetAddress(formattedAddress[0]);
      setCity(formattedAddress[1]);
      setState(getStateNameFromStateCode(formattedAddress[2].split(' ')[1]) as StateEnum);
      setZipCode(formattedAddress[2].split(' ')[2]);
    },
    options: {
      types: ['address'],
      field: 'place_id',
    },
  });

  // TODO: add an error state to the UI when the mutation fails
  const [update, {
    // data,
    loading,
    // error,
  }] = useMutation<UpdateContractorReturnDataType>(
    UPDATE_CONTRACTOR,
  );

  const businessDetailsComplete = () : boolean => {
    if (firstName.length > 0 && lastName.length > 0
      && !showPhoneError && !showEmailError
      && streetAddress.length > 0 && city.length > 0
      && state.length > 0 && !showZipCodeError
    ) return true;
    return false;
  };

  const updateBusinessDetails = async () => {
    if (businessDetailsComplete()) {
      try {
        await update({
          variables: {
            input: {
              id: contractor.id,
              businessName: `${firstName} ${lastName}`,
              businessPhone: phoneNumber,
              businessEmail: email,
              businessAddress: address,
              serviceAreaRadiusMileage: serviceAreaRadiusInMiles,
              hasSupra,
              inspectorLicenseHeld,
              contractorLicenseHeld,
              realEstateLicenseHeld,
            },
          },
        });
        track(EventName.InspectorUpdatedDetails, { contractor });
      } catch (e: any) {
        captureException(e);
      }
    }
  };

  return (
    <BusinessDetails
      loading={loading}
      certificationsRef={certificationsRef}
      basicInformationRef={basicInformationRef}
      serviceableAreaRef={serviceableAreaRef}
      accessAndSoftwareRef={accessAndSoftwareRef}
      documentsRef={documentsRef}
      certificationFiles={certificationFiles}
      misaFiles={misaFiles}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      streetAddress={streetAddress}
      setStreetAddress={setStreetAddress}
      city={city}
      setCity={setCity}
      state={state}
      setState={setState}
      zipCode={zipCode}
      setZipCode={setZipCode}
      serviceAreaRadiusInMiles={serviceAreaRadiusInMiles}
      setServiceAreaRadiusInMiles={setServiceAreaRadiusInMiles}
      hasSupra={hasSupra}
      setHasSupra={setHasSupra}
      isMobile={isMobile}
      inspectorLicenseHeld={inspectorLicenseHeld}
      setInspectorLicenseHeld={setInspectorLicenseHeld}
      contractorLicenseHeld={contractorLicenseHeld}
      setContractorLicenseHeld={setContractorLicenseHeld}
      realEstateLicenseHeld={realEstateLicenseHeld}
      setRealEstateLicenseHeld={setRealEstateLicenseHeld}
      showEmailError={showEmailError}
      setShowEmailError={setShowEmailError}
      showPhoneError={showPhoneError}
      setShowPhoneError={setShowPhoneError}
      showZipCodeError={showZipCodeError}
      setShowZipCodeError={setShowZipCodeError}
      addressRef={ref}
      msaSigned={contractor.msaSigned}
      updateBusinessDetails={updateBusinessDetails}
      businessDetailsComplete={businessDetailsComplete}
    />
  );
};

export default BusinessDetailsLoader;
