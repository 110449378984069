import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { usePlacesWidget } from 'react-google-autocomplete';
import OnboardingFormPage from './OnboardingFormPage';
import InspectifyLayout from '../../../components/InspectifyLayout';
import { OnboardingCompletedStep, OnboardingFormInput } from '../types';
import { isUserUsingMobile } from '../../../utils/user';
import { StateCodeEnum, StateEnum } from '../../../types/address';
import { captureException } from '../../../utils/error';
import { handleOnboardingPageNavigation } from '../../../utils/onboarding';
import { SURVEYOR_ONBOARDING } from '../../../graphql/queries';
import { getStateNameFromStateCode } from '../../../utils/map';
import { UPDATE_SURVEYOR_ONBOARDING } from '../../../graphql/mutations/surveyorOnboarding';

const OnboardingFormPageLoader: React.FC = () => {
  const history = useHistory();
  const isMobile = useMemo(() => isUserUsingMobile(), []);
  const { onboardingId } = useParams<{ onboardingId: string }>();
  const [completedStep, setCompletedStep] = React.useState<OnboardingCompletedStep>();
  const [canSetPassword, setCanSetPassword] = React.useState<boolean>();
  const [formInput, setFormInput] = useState<OnboardingFormInput>();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState<StateEnum | StateCodeEnum>();
  const [zipCode, setZipCode] = useState('');
  const [hasModeOfTransportation, setHasModeOfTransporation] = useState<boolean>();
  const [hasReliablePhone, setHasReliablePhone] = useState<boolean>();
  const [sendAutomatedSms, setSendAutomatedSms] = useState<boolean>();
  const [serviceAreaRadiusInMiles, setServiceAreaRadiusInMiles] = useState<number>(0);
  const [inspectorLicenseHeld, setInspectorLicenseHeld] = useState(false);
  const [contractorLicenseHeld, setContractorLicenseHeld] = useState(false);
  const [realEstateLicenseHeld, setRealEstateLicenseHeld] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showZipCodeError, setShowZipCodeError] = useState(false);

  useEffect(() => {
    setFormInput({
      firstName,
      lastName,
      email,
      phoneNumber,
      address: address || `${streetAddress}, ${city}, ${state} ${zipCode}, USA`,
      hasReliableModeOfTransportation: hasModeOfTransportation,
      hasReliablePhone,
      sendAutomatedSms,
      serviceAreaRadiusMileage: serviceAreaRadiusInMiles,
      inspectorLicenseHeld,
      contractorLicenseHeld,
      realEstateLicenseHeld,
    });
  }, [firstName, lastName, email, phoneNumber, address, city, state, zipCode,
    hasModeOfTransportation, hasReliablePhone, sendAutomatedSms, serviceAreaRadiusInMiles,
    inspectorLicenseHeld, contractorLicenseHeld, realEstateLicenseHeld,
  ]);

  const { data: surveyorOnboardingData } = useQuery(SURVEYOR_ONBOARDING, {
    variables: { id: onboardingId },
    context: { headers: { onboarding_id: onboardingId } },
    onCompleted: () => {
      if (surveyorOnboardingData?.surveyorOnboarding) {
        setCompletedStep(surveyorOnboardingData.surveyorOnboarding.completedStep);
        setCanSetPassword(surveyorOnboardingData.surveyorOnboarding.canSetPassword);
      } else {
        window.location.href = '/onboarding/surveyor/new';
      }
    },
    onError: (error) => {
      captureException(error, { source: 'Fetching surveyor onboarding' });
    },
  });

  // Prevent the user from returning to the form once submitted
  useEffect(() => {
    if (completedStep) {
      handleOnboardingPageNavigation(completedStep, canSetPassword, onboardingId, history);
    }
  }, [completedStep]);

  const [updateSurveyorOnboarding, { loading }] = useMutation(
    UPDATE_SURVEYOR_ONBOARDING,
    {
      onCompleted: (data) => {
        if (data?.updateSurveyorOnboarding?.surveyorOnboarding) {
          setCanSetPassword(data?.updateSurveyorOnboarding?.surveyorOnboarding.canSetPassword);
          setCompletedStep(data?.updateSurveyorOnboarding?.surveyorOnboarding.completedStep);
        }
      },
      onError: () => {
        captureException(new Error('Graphql failed to update surveyor onboarding'));
      },
    },
  );

  const formComplete = () : boolean => {
    if (firstName?.length > 0 && lastName?.length > 0
      && !showPhoneError && !showEmailError
      && streetAddress?.length > 0 && city?.length > 0
      && state?.length > 0 && zipCode?.length > 0 && !showZipCodeError
      && hasModeOfTransportation !== undefined
      && hasReliablePhone !== undefined
      && sendAutomatedSms !== undefined
      && serviceAreaRadiusInMiles > 0
    ) return true;
    return false;
  };

  useEffect(() => {
    if (!showEmailError) {
      localStorage.setItem('inspectifyUsername', email);
    }
  }, [email, showEmailError]);

  const { ref } = usePlacesWidget({
    apiKey: process.env.GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place: any) => {
      const formattedAddress: string[] = place.formatted_address.split(',');
      setAddress(place.formatted_address);
      setStreetAddress(formattedAddress[0]);
      setCity(formattedAddress[1]);
      setState(getStateNameFromStateCode(formattedAddress[2].split(' ')[1]) as StateEnum);
      setZipCode(formattedAddress[2].split(' ')[2]);
    },
    options: {
      types: ['address'],
      field: 'place_id',
    },
  });

  const handleSubmitIntakeForm = () : void => {
    if (formComplete()) {
      try {
        updateSurveyorOnboarding({
          variables: {
            input: {
              id: onboardingId,
              completedStep: OnboardingCompletedStep.FormSubmitted,
              onboardingFormInput: formInput,
            },
          },
          context: { headers: { onboarding_id: onboardingId } },
        });
      } catch (error) {
        captureException(error, { source: 'User clicked Sign Up' });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Onboarding Form</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showSignIn={false} hideHeaderOnMobile />
      <OnboardingFormPage
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        email={email}
        setEmail={setEmail}
        streetAddress={streetAddress}
        setStreetAddress={setStreetAddress}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        zipCode={zipCode}
        setZipCode={setZipCode}
        hasModeOfTransportation={hasModeOfTransportation}
        setHasModeOfTransporation={setHasModeOfTransporation}
        hasReliablePhone={hasReliablePhone}
        setHasReliablePhone={setHasReliablePhone}
        sendAutomatedSms={sendAutomatedSms}
        setSendAutomatedSms={setSendAutomatedSms}
        serviceAreaRadiusInMiles={serviceAreaRadiusInMiles}
        setServiceAreaRadiusInMiles={setServiceAreaRadiusInMiles}
        inspectorLicenseHeld={inspectorLicenseHeld}
        setInspectorLicenseHeld={setInspectorLicenseHeld}
        contractorLicenseHeld={contractorLicenseHeld}
        setContractorLicenseHeld={setContractorLicenseHeld}
        realEstateLicenseHeld={realEstateLicenseHeld}
        setRealEstateLicenseHeld={setRealEstateLicenseHeld}
        showEmailError={showEmailError}
        setShowEmailError={setShowEmailError}
        showPhoneError={showPhoneError}
        setShowPhoneError={setShowPhoneError}
        showZipCodeError={showZipCodeError}
        setShowZipCodeError={setShowZipCodeError}
        formComplete={formComplete}
        isMobile={isMobile}
        addressRef={ref}
        submitIntakeForm={handleSubmitIntakeForm}
        loading={loading}
      />
    </>
  );
};

export default OnboardingFormPageLoader;
