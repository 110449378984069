import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import './styles.scss';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  allowNegative: true,
  allowLeadingZeroes: false,
};

interface Props {
  name: string,
  value: number,
  tag?: string,
  className?: string,
  includeLabel?: boolean,
  onValueChange: (value: number) => void,
}

const FormInputCurrency: React.FC<Props> = ({
  name, value, className, includeLabel = true, onValueChange,
}) => {
  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <div className="inputContainer">
      {includeLabel && <label htmlFor={name} className="label"> {name} </label>}
      <MaskedInput
        id={name}
        inputMode="text"
        className={className || 'input'}
        mask={currencyMask}
        defaultValue={value}
        onChange={(e) => onValueChange(parseFloat(e.target.value.replace(/[$,]/g, '')))}
      />
    </div>
  );
};

export default FormInputCurrency;
