import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import type { Plugin, RenderViewer } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import cn from 'classnames';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement;
}

const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
  const { PageThumbnail } = props;

  return {
    renderViewer: (renderProps: RenderViewer) => {
      const { slot } = renderProps;

      slot.children = PageThumbnail;

      slot.subSlot.attrs = {};
      slot.subSlot.children = <></>;

      return slot;
    },
  };
};

const Thumbnail: React.FC<{ klassNames?: string[], fileUrl: string }> = ({ klassNames, fileUrl }) => {
  if (!fileUrl) return null;

  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />,
  });

  return (
    <div className={cn(klassNames)}>
      {/*
        Use the pdf worker to handle pdf operations
        https://react-pdf-viewer.dev/docs/basic-usage/
        We can host this on our server if we want to...
      */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
        <Viewer
          fileUrl={fileUrl}
          plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
        />
      </Worker>
    </div>
  );
};

export default Thumbnail;
