import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { ReportAttachment } from 'marketplace-common';
import Preview from '../../../../../components/PDF/Preview';
import { ZOOM_SCALE_MAX, ZOOM_SCALE_MIN } from './Controls/Zoom';
import Controls from './Controls';
import './styles.scss';

interface Props {
  show: boolean,
  handleClose: () => void,
  attachments: ReportAttachment[],
  currentAttachmentIndex: number,
  incrementCurrentAttachment: (incrementBy: number) => void,
  arrowHandler: (event: KeyboardEvent) => void,
}

const MediaModal: React.FC<Props> = ({
  show,
  handleClose,
  attachments,
  currentAttachmentIndex,
  incrementCurrentAttachment,
  arrowHandler,
}) => {
  const zoomRef = useRef<ReactZoomPanPinchRef>(null);

  const [sliderValue, setSliderValue] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [videoLoadErrored, setVideoLoadErrored] = useState(false);

  const attachment = useMemo(() => (
    attachments[currentAttachmentIndex]
  ), [attachments, currentAttachmentIndex]);

  const gpsCoordinates = useMemo(() => (
    attachment?.metadata?.gps || null
  ), [attachment?.metadata?.gps]);

  const attachmentSource = useMemo(() => {
    if (videoLoadErrored) return attachment?.attachmentUrl;

    // Imagekit can be expensive for videos, so not using it if there is no transformation
    // and the type is not ".mov" (which have issues playing in Chrome)
    if (attachment.mediaType === 'video' && rotate === 0 && !attachment?.fileName?.toLocaleLowerCase()?.endsWith('.mov')) {
      return attachment?.attachmentUrl;
    }

    if (attachment && attachment.rotatedUrls?.[`${rotate}`]) {
      return attachment.rotatedUrls[`${rotate}`];
    }
    return attachment?.rotatedUrls?.original;
  }, [attachment, rotate, videoLoadErrored]);

  const onVideoError = useCallback(() => {
    // If the video is too large, it might fail to load using Imagekit url (rotatedUrls.original)
    // In this case, fallback to the direct url to S3
    // It would also mean we cannot rotate the video so hiding the button in that situation
    setVideoLoadErrored(true);
  }, []);

  const renderMedia = () => {
    switch (attachment?.mediaType) {
      case 'video':
        return (
          <video
            src={attachmentSource}
            className="mediaModal__media"
            onError={onVideoError}
            controls
          />
        );
      case 'pdf':
        return <Preview klassNames={['mediaModal__media']} fileUrl={attachment.attachmentUrl} />;
      default:
        return (
          <TransformWrapper
            ref={zoomRef}
            doubleClick={{ disabled: true }}
            wheel={{ step: 0.6 }}
            minScale={ZOOM_SCALE_MIN}
            maxScale={ZOOM_SCALE_MAX}
            onWheel={(e) => setSliderValue(e.state.scale)}
          >
            <TransformComponent
              wrapperStyle={{ height: '90vh', width: '100%' }}
              contentStyle={{ height: '100%', width: '100%' }}
            >
              <>
                <img
                  src={attachmentSource}
                  alt="attachment"
                  className="mediaModal__media"
                />
                {gpsCoordinates ? (
                  <span className="mediaModal__gpsTag">
                    {gpsCoordinates.latitude}, {gpsCoordinates.longitude}
                  </span>
                ) : null}
              </>
            </TransformComponent>
          </TransformWrapper>
        );
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('keydown', arrowHandler);
      setSliderValue(1);
      setRotate(0);
    }
    return () => document.removeEventListener('keydown', arrowHandler);
  }, [show]);

  useEffect(() => {
    // Reset zoom on attachment index change
    setSliderValue(1);
    // Reset zoom on attachment index change
    setRotate(0);
    if (zoomRef && zoomRef.current) {
      zoomRef.current.resetTransform();
    }
  }, [currentAttachmentIndex]);

  useEffect(() => {
    if (zoomRef?.current) zoomRef.current.centerView(sliderValue);
  }, [sliderValue, zoomRef?.current]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="mediaModal">
          <Controls
            show={show}
            setRotate={setRotate}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            rotate={rotate}
            attachmentSource={attachmentSource}
            gpsCoordinates={gpsCoordinates}
            attachment={attachment}
            videoLoadErrored={videoLoadErrored}
            handleClose={handleClose}
          />

          {attachmentSource && renderMedia()}

          {attachments.length > 1 && (
            <div className="mediaModal__arrows">
              {currentAttachmentIndex !== 0 && (
                <button
                  title="Previous"
                  className="mediaModal__arrows__icon"
                  style={{ left: 0 }}
                  type="button"
                  onClick={() => {
                    incrementCurrentAttachment(-1);
                  }}
                >
                  &#10094;
                </button>
              )}
              {currentAttachmentIndex !== attachments.length - 1 && (
                <button
                  title="Next"
                  className="mediaModal__arrows__icon"
                  style={{ right: 0 }}
                  type="button"
                  onClick={() => {
                    incrementCurrentAttachment(1);
                  }}
                >
                  &#10095;
                </button>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MediaModal;
