// https://community.hubspot.com/t5/APIs-Integrations/HELP-Embed-a-form-in-a-React-component/m-p/520436/highlight/true#M48362

import React, { useEffect, useState } from 'react';

const HubspotContactForm: React.FC = () => {
  const [formIsReady, setFormIsReady] = useState(false);

  const addForm = (hbspt: any) => {
    hbspt.forms.create({
      portalId: '7917544',
      formId: '32fa9170-5b79-4c95-8508-276e4e4b700a',
      target: '#hubspotForm',
    });

    setFormIsReady(true);
  };

  useEffect(() => {
    if (!formIsReady) {
      if ((window as any).hbspt) {
        addForm((window as any).hbspt);
      } else {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
          if ((window as any).hbspt) {
            addForm((window as any).hbspt);
          }
        });
      }
    }
  }, [window.hbspt, formIsReady]);

  return (
    <div style={{ marginBottom: 10 }}>
      <div id="hubspotForm" />
    </div>
  );
};

export default HubspotContactForm;
