/* Format phone number for Amplify
 function assumes the length of the phone number string is 10 or 11 */
export const formatPhoneNumber = (phone_number: string) => {
  if (phone_number.length === 11) {
    return `+${phone_number}`;
  }
  return `+1${phone_number}`;
};

export const isValidEmailFormat = (email: string) => {
  const reg = /\S+@\S+\.\S+/;
  return reg.test(email);
};
