import {
  SET_DISPLAY_HEADER,
  SET_LAYOUT_COBRANDED_LOGO,
  RESET_LAYOUT,
  SET_LAYOUT_IS_LOADING,
  SET_LAYOUT_FADED_FOOTER,
  SET_LAYOUT_SHOW_FOOTER,
  SET_LAYOUT_HIDE_HEADER_ON_MOBILE,
  SET_LAYOUT_SHOW_SIGNIN,
} from './types';

export const setDisplayNavbar = (
  displayNavbar: boolean,
) => (dispatch: any) => {
  dispatch({
    type: SET_DISPLAY_HEADER,
    payload: displayNavbar,
  });
};

export const setLayoutIsLoading = (
  isLoading: boolean,
) => (dispatch: any) => {
  dispatch({
    type: SET_LAYOUT_IS_LOADING,
    payload: isLoading,
  });
};

export const setLayoutCobrandedLogo = (
  layoutCobrandedLogo: string,
) => (dispatch: any) => {
  dispatch({
    type: SET_LAYOUT_COBRANDED_LOGO,
    payload: layoutCobrandedLogo,
  });
};

export const setLayoutFadedFooter = (
  layoutFadedFooter: boolean,
) => (dispatch: any) => {
  dispatch({
    type: SET_LAYOUT_FADED_FOOTER,
    payload: layoutFadedFooter,
  });
};

export const setLayoutShowFooter = (
  showFooter: boolean,
) => (dispatch: any) => {
  dispatch({
    type: SET_LAYOUT_SHOW_FOOTER,
    payload: showFooter,
  });
};

export const setLayoutShowSignIn = (
  showSignIn: boolean,
) => (dispatch: any) => {
  dispatch({
    type: SET_LAYOUT_SHOW_SIGNIN,
    payload: showSignIn,
  });
};

export const setLayoutHideHeaderOnMobile = (
  hideHeaderOnMobile: boolean,
) => (dispatch: any) => {
  dispatch({
    type: SET_LAYOUT_HIDE_HEADER_ON_MOBILE,
    payload: hideHeaderOnMobile,
  });
};

export const resetLayout = () => (dispatch: any) => {
  dispatch({
    type: RESET_LAYOUT,
  });
};
