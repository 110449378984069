import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { InspectionInterface, LocalInspectionProposal, getFormattedString } from 'marketplace-common';
import styles from './styles.module.scss';
import { getInspectionDateLabel } from '../../../../utils/report';
import { formatMoney } from '../../../../utils/money';

interface Props {
  contractorBusinessName: string,
  paywallUrl: string,
  inspectionProposals: LocalInspectionProposal[],
  totalPrice: number,
  inspections: InspectionInterface,
}

const Paywall: React.FC<Props> = ({
  paywallUrl, inspectionProposals, contractorBusinessName, totalPrice, inspections,
}) => (
  <div className={styles.paywall}>
    <div className={styles.paywallBanner}>
      <div className={styles.paywallContent}>
        <h2 className={styles.paywallTitle}>Your report is complete and ready!</h2>
        <div className={styles.paywallDescription}>
          In order to unlock and view the full report, please complete the
          inspection payment of&nbsp;
          <span className={styles.paywallTotalPrice}>{formatMoney(totalPrice)}</span>
        </div>
        <div className={styles.paywallInspectionsBox}>
          {inspectionProposals.map((inspectionProposal) => (
            <Row key={inspectionProposal.id} className={styles.paywallInspectionRow}>
              <Col xs={8}>
                <div className={styles.paywallInspectionType}>
                  {getFormattedString(inspections[inspectionProposal.inspectionId].inspectionType)}
                </div>
                <div className={styles.paywallInspectionTime}>
                  Inspected&nbsp;
                  {getInspectionDateLabel(inspectionProposal.availability)}
                  &nbsp;by
                </div>
                <div className={styles.paywallInspectorName}>
                  {contractorBusinessName}
                </div>
              </Col>
              <Col xs={4}>
                <div className={styles.paywallInspectionCost}>
                  {formatMoney(inspectionProposal.totalFee)}
                </div>
              </Col>
            </Row>
          ))}
        </div>
        <a
          href={paywallUrl}
          className={styles.submitButton}
          type="submit"
        >
          Pay Now
        </a>
      </div>
    </div>
  </div>
);

export default Paywall;
