import React, { useMemo, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Tab,
  Tabs,
  Image,
  ButtonGroup,
} from 'react-bootstrap';
import UnselectedIcon from 'haven-design-system/build/assets/svgs/camera/photo/shape.svg?component';
import SelectedIcon from 'haven-design-system/build/assets/svgs/display/confirmed-circle_filled.svg?component';
import styles from './styles.module.scss';
import Uploader from './Uploader';
import { UploadedMedia, UploadsInterface } from './useUpload';
import Thumbnail from '../PDF/Thumbnail';

export type Media = { id: string, src: string, mediaType: string };

type Props = {
  title: string;
  selectFromTitle: string;
  visible: boolean;
  media: Media[];
  handleAdd: (media: { attachmentIds: string[], uploadedMedia: UploadedMedia[] }) => void;
  handleClose: () => void;
};

const MediaUploader: React.FC<Props> = ({
  title,
  selectFromTitle,
  visible,
  media,
  handleAdd,
  handleClose,
}) => {
  const [key, setKey] = useState<string>('source');
  const [selectedMedia, setSelectedMedia] = useState<Set<string>>(new Set());
  const [uploadedFiles, setUploadedFiles] = useState<UploadsInterface>({});

  const isUploading = useMemo(() => (
    Object.values(uploadedFiles).some((file) => file.progress < 100)
  ), [uploadedFiles]);

  const isDisabled = useMemo(() => (
    (key === 'source' && selectedMedia.size === 0)
    || (key === 'device' && (isUploading || Object.keys(uploadedFiles).length === 0))
  ), [key, selectedMedia, isUploading, uploadedFiles]);

  const handleMediaSelect = (id: string) => {
    const newSelectedMedia = new Set(selectedMedia);
    if (newSelectedMedia.has(id)) {
      newSelectedMedia.delete(id);
    } else {
      newSelectedMedia.add(id);
    }
    setSelectedMedia(newSelectedMedia);
  };

  const close = () => {
    setSelectedMedia(new Set());
    setUploadedFiles({});
    handleClose();
  };

  const handleAddMedia = () => {
    handleAdd({
      attachmentIds: Array.from(selectedMedia),
      uploadedMedia: Object.values(uploadedFiles),
    });
    close();
  };

  const renderTile = (src: string, mediaType: string) => {
    switch (mediaType) {
      case 'pdf':
        return <Thumbnail klassNames={[styles.imageGridItem]} fileUrl={src} />;
      case 'video':
        return <video className={styles.imageGridItem} src={src} controls muted />;
      default:
        return <Image src={src} fluid className={styles.imageGridItem} />;
    }
  };

  return (
    <Modal show={visible} onHide={close}>
      <Modal.Header className={styles.header} closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} fill>
          <Tab eventKey="source" title={selectFromTitle}>
            <Container className={styles.tabContainer}>
              <Row>
                {media.map(({ id, src, mediaType }) => (
                  <Col
                    key={id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="mb-4"
                    onClick={() => handleMediaSelect(id)}
                  >
                    <div className={styles.imageContainer}>
                      {selectedMedia.has(id)
                        ? <SelectedIcon className={styles.selected} />
                        : <UnselectedIcon className={styles.unselected} />}
                      {renderTile(src, mediaType)}
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="device" title="Upload from device">
            <Uploader setUploadedFiles={setUploadedFiles} />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup className={styles.footerButtonGroup}>
          <Button
            className={styles.footerButton}
            variant="outline-secondary"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            className={styles.footerButton}
            variant="primary"
            onClick={handleAddMedia}
            disabled={isDisabled}
          >
            Add media
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default MediaUploader;
