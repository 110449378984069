import styled, { css } from 'styled-components';
import {
  colorBaseBlackLight,
  colorBaseBlackPrimary,
  colorBaseWhitePrimary,
  colorPaletteOrange400,
  colorBaseBlackDark,
} from 'haven-design-system/build/typescript/es6';

export const LeftContainer = styled.div`
  background-color: ${colorBaseWhitePrimary};
  width: 50%;
  height: 100%;
`;

export const MediaContainer = styled.div`
  height: 65%;
  background-color: ${colorBaseBlackLight};
`;

export const MediaControlsContainer = styled.div`
  display: flex;
  background-color: ${colorBaseBlackDark};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ZoomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 50%;
  min-width: 270px;
  margin: 0 auto;
  padding: 8px 0;
`;

export const MediaControlButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const AttachmentContainer = styled.div`
  width: 100%;
  height: ${'calc(100% - 97px)'}; // 97px is the height of ZoomContainer and CarouselContainer
  background-color: ${colorBaseBlackDark};
  text-align: center;
`;

export const AttachmentImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const SystemInformationsContainer = styled.div`
  padding: 16px 32px;
  font-size: 20px;
  height: 35%;
  overflow-y: scroll;
`;

export const InformationsContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
`;

export const Information = styled.div<{ isBold?: boolean }>`
  margin-top: 16px;
  font-size: 16px;
  flex: 50%;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
`;

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  top: 30%;
`;

export const ArrowButton = styled.button`
  position: absolute;
  cursor: pointer;
  width: auto;
  color: ${colorBaseWhitePrimary};
  padding: 16px;
  font-weight: bold;
  font-size: 30px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  border: none;
  background: none;
  &:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
  }  
`;

export const CarouselContainer = styled.div`
  width: 100%;
  height: 54px;
  background-color: ${colorBaseBlackDark};
  display: flex;
  flex-direction: row;
`;

const CommonMediaStyles = css`
  width: 72px;
  height: 54px;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
`;

export const CarouselImage = styled.img<{ isSelected: boolean }>`
  ${CommonMediaStyles}
  ${(props) => props.isSelected && css`
    border: 3px solid ${colorPaletteOrange400};
  `}
`;

export const CarouselVideo = styled.video<{ isSelected: boolean }>`
  ${CommonMediaStyles}
  ${(props) => props.isSelected && css`
    border: 3px solid ${colorPaletteOrange400};
  `}
`;

export const NoMediaText = styled.div`
  background-color: ${colorBaseBlackLight};
  width: 100%;
  height: 60%;
  padding-top: 40%;
  text-align: center;
  color: ${colorBaseWhitePrimary};
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const DefLimTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

export const NotesDetails = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

export const SectionBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const NotesNote = styled(NotesDetails)`
  margin-top: 12px;  
`;

export const EmptyTopContainer = styled.div`
  width: 100%;
  height: 44px;
  background-color: ${colorBaseBlackPrimary};
`;

export const AddMediaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
