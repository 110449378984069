import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
  from,
  DefaultOptions,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context'; // eslint-disable-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error'; // eslint-disable-line import/no-extraneous-dependencies
import { Auth } from 'aws-amplify';
import React from 'react';
import { captureException } from '../utils/error';

export const DEPRECATED = true;

const getMarketPlaceGqlApi = (): string => process.env.MARKETPLACE_GRAPHQL_API;
const getRailsGqlApi = (): string => process.env.RAILS_GQL_URL;

const httpLink = createHttpLink({
  uri: getMarketPlaceGqlApi(),
});

const railsGraphqlHttpLink = createHttpLink({
  uri: getRailsGqlApi(),
});

const splitHttpLink = ApolloLink.split(
  operation => operation.getContext().DEPRECATED === DEPRECATED,
  httpLink,
  railsGraphqlHttpLink,
);

const authLink = setContext(async ({ operationName }, { headers }) => {
  const nowInSeconds = Date.now() / 1000;
  let token = '';

  const fetchJwtToken = async () => {
    try {
      // make api request to get the current user's jwtToken
      const session = await Auth.currentSession();
      token = session.getIdToken().getJwtToken();
      const exp = session.getIdToken().getExpiration();
      if (token) {
        window.appInjectedJwtToken = token;
        window.appInjectedJwtTokenExp = exp;
      }
    } catch (err) {
      window.appInjectedJwtToken = undefined;
      window.appInjectedJwtTokenExp = undefined;
      captureException('Failed getting the current session', err);
    }
  };

  const shouldFetchJwtToken = () => !(
    window.appInjectedJwtToken
    && window.appInjectedJwtTokenExp
    && window.appInjectedJwtTokenExp >= nowInSeconds
  );

  if (operationName !== 'HealthCheck') {
    if (shouldFetchJwtToken()) {
      await fetchJwtToken();
    } else {
      token = window.appInjectedJwtToken;
    }
  }

  const newHeaders = { ...headers };
  if (token) newHeaders.authorization = `Bearer ${token}`;

  const foundUserId = localStorage.getItem('inspectifySelectedUser');
  if (foundUserId) {
    newHeaders['X-Inspectify-Behave-As'] = parseInt(foundUserId);
  }

  return { headers: newHeaders };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { operationName, extensions } = operation;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      captureException('GraphQL Error', {
        source: 'errorLink',
        operationName,
        message,
        location: JSON.stringify(locations),
        path,
        extensions,
      });
    });
  }

  if (networkError) {
    captureException('GraphQL Network Error', {
      source: 'errorLink',
      operationName,
      networkError: JSON.stringify(networkError),
    });
  }
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    // eslint-disable-next-line
    operation.variables = omitDeep(operation.variables, "typename");
  }

  return forward(operation);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
} as DefaultOptions;

export const client = new ApolloClient({
  link: from([cleanTypenameLink, errorLink, authLink, splitHttpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
});

export default () => ({ children }: any) => (
  <ApolloProvider client={client}>
    {children}
  </ApolloProvider>
);

const omitDeepArrayWalk = (arr: any[], key: any) : any => {
  return arr.map(val => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === "object") return omitDeep(val, key);
    return val;
  });
};

const omitDeep = (obj: Record<string, any>, key: string) => {
  const keys = Object.keys(obj);
  const newObj: { [key: string]: any } = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === 'object' && val !== null) {
        newObj[i] = omitDeep(val, key);
      } else newObj[i] = val;
    }
  });
  return newObj;
};
