import { colorPaletteGrayscale50 } from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

export const ModalStyle = {
  overlay: {
    backgroundColor: colorPaletteGrayscale50,
    zIndex: 5,
  },
  content: {
    border: 'none',
    padding: 0,
  },
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 1024px;
  height: 100%;
`;
