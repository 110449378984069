import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { fromUnixTime, max } from 'date-fns';
import { colorPaletteYellow200 } from 'haven-design-system/build/typescript/es6';
import InspectifyLogo from '../../../assets/inspectify/Inspectify_Logo_Color.svg';
import {
  DetailContainer,
  DetailLabel,
  DetailRow,
  DetailValue,
  Divider,
  FooterPrint,
  FooterPrintText,
  HeaderPrint,
} from '../../../components/PDF/styles';
import { saveWorksForOrders, updateSplitioAttributes } from '../../../redux/actions';
import { selectWorks, selectWorksPropertyPermits } from '../../../redux/selectors';
import { useTypedSelector } from '../../../redux/store';
import { PermitsContext } from '../context';
import styles from '../../Reports/pdf/styles.module.scss';
import { ORDER_BY_TOKEN } from '../../../graphql/queries';
import { PageErrorType } from '../../../types';
import Spinner from '../../../components/Spinner';
import CoverPage from '../../../components/PDF/CoverPage';
import { PermitDetailsTable } from '../components/table/PermitDetailsTable';
import { formatDate, getUsersTimezone } from '../../../utils/date';
import { getAddressLabel } from '../../../utils/report';
import PageErrorIndicator from '../../../components/PageError';

interface Props {
  orderToken: string;
}

const PermitsPdfPage: React.FC<Props> = ({
  orderToken,
}) => {
  const dispatch = useDispatch();
  const works = useTypedSelector(selectWorks);
  const history = useHistory();

  const {
    state,
    set,
    setOrderProperty,
  } = useContext(PermitsContext);

  const [displayPrintReady, setDisplayPrintReady] = useState(false);

  const isBetterInspect = useMemo(() => window.location.hostname.includes('betterinspect'), []);
  const href = `${window.location.origin}/orders/${orderToken}/permit_report`;

  useEffect(() => {
    if (orderToken) set('orderToken', orderToken);
  }, [orderToken]);

  useEffect(() => {
    if (state.orderId) setOrderProperty(state.orderId, works);
  }, [state.orderId, works]);

  useEffect(() => {
    if (state?.orderTeamId) {
      dispatch(updateSplitioAttributes({ teamId: state.orderTeamId }));
    }
  }, [state?.orderTeamId]);

  const permits = useTypedSelector(
    (reduxState) => selectWorksPropertyPermits(reduxState, state.orderId as number),
  );

  useEffect(() => {
    if (permits) {
      setTimeout(() => setDisplayPrintReady(true), 6000);
    }
  }, [permits]);

  const lastUpdate = useMemo(() => {
    const createdAtDates = permits.map(
      (permit) => (permit.createdAt != null ? fromUnixTime(permit.createdAt) : null),
    );

    if (createdAtDates.length === 0) {
      return { date: null, time: null };
    }

    const date = formatDate(max(createdAtDates), { format: 'MMM d, yyyy', timezone: getUsersTimezone() });
    const time = formatDate(max(createdAtDates), { format: 'h:mm a zzz', timezone: getUsersTimezone() });

    return { date, time };
  }, [permits]);

  const { loading } = useQuery(ORDER_BY_TOKEN, {
    variables: { token: orderToken },
    context: { headers: { order_token: orderToken } },
    onCompleted: (data) => {
      if (data?.order) {
        set('orderId', data.order.id);
        dispatch(saveWorksForOrders(data, works));
      }
    },
    onError: () => {
      set('permitsQueryError', PageErrorType.GeneralError);
    },
  });

  useEffect(() => {
    if (!loading && state.property && permits.length === 0) {
      history.push(`/orders/${state.orderToken}/reports`);
    }
  }, [history, loading, permits, state.orderToken, state.property]);

  if (loading) return <Spinner style={{ width: '100vw', height: '100vh' }} />;

  return (
    <>
      <Container fluid>
        <table>
          <thead>
            <tr>
              <td>
                <HeaderPrint className="hideOnFirstPage">
                  <img src={InspectifyLogo} alt="Inspectify" />
                  <a
                    style={{ display: 'block', padding: 16 }}
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View permit report
                  </a>

                  <div className="float-right">
                    {isBetterInspect && (
                      <>
                        <div className={styles.menuPoweredBytext}>
                          Powered by
                        </div>
                        <a href="https://www.inspectify.com">
                          <img src={InspectifyLogo} alt="Inspectify" />
                        </a>
                      </>
                    )}
                  </div>
                </HeaderPrint>
              </td>
            </tr>
          </thead>
          <tbody className={styles.body}>
            <tr>
              <td>
                <Container fluid>
                  <CoverPage property={state.property}>
                    <DetailContainer backgroundColor={colorPaletteYellow200}>
                      <DetailRow>
                        <DetailLabel>Permits Available</DetailLabel>
                        <DetailValue>{permits.length}</DetailValue>
                      </DetailRow>
                      <DetailRow>
                        <DetailLabel>Date Retrieved</DetailLabel>
                        <DetailValue>{lastUpdate.date ?? 'N/A'}</DetailValue>
                      </DetailRow>
                      <DetailRow>
                        <DetailLabel>Time retrieved</DetailLabel>
                        <DetailValue>{lastUpdate.time ?? 'N/A'}</DetailValue>
                      </DetailRow>
                    </DetailContainer>
                  </CoverPage>

                  <Container fluid>
                    {permits
                      ? permits.map((permit) => (
                        <>
                          <PermitDetailsTable key={permit.id} permit={permit} />
                          <Divider />
                        </>
                      ))
                      : null}
                  </Container>

                </Container>

                {state.permitsQueryError && (
                  <PageErrorIndicator errorType={state.permitsQueryError} />
                )}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <FooterPrint>
                  <FooterPrintText>
                    {getAddressLabel(state.property)} | Permit Data Record
                  </FooterPrintText>
                </FooterPrint>
              </td>
            </tr>
          </tfoot>
        </table>
      </Container>

      {displayPrintReady && (
        <span id="printReady" />
      )}
    </>
  );
};

export default PermitsPdfPage;
