import {
  Properties,
} from '../../types';
import {
  ADD_PROPERTY,
} from '../actions/types';

export const initialPropertyState = null as Properties;

export default function properties(state: any = initialPropertyState, action: any) {
  switch (action.type) {
    case ADD_PROPERTY: {
      const propertiesState = { ...state };
      const { orderToken, property } = action.payload;
      if (propertiesState[property.id]) {
        propertiesState[property.id] = {
          ...propertiesState[property.id],
          ...property,
          orderToken,
        };
      } else if (orderToken) {
        propertiesState[property.id] = { ...property, orderToken };
      } else {
        propertiesState[property.id] = { ...property };
      }
      return { ...propertiesState };
    }
    default:
      return state;
  }
}
