import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface ButtonProps {
  id?: string,
  onClick?: (value?: any) => void,
  onKeyPress?: (value?: any) => void,
  className?: string,
  style?: React.CSSProperties,
  disabled?: boolean,
  type?: 'button' | 'submit' | 'reset',
  secondary?: boolean,
  tabIndex?: number,
}

const Button: React.FC<ButtonProps> = ({
  id,
  onClick,
  onKeyPress,
  className,
  style,
  disabled,
  type,
  children,
  secondary,
  tabIndex,
}) => (
  <button
    id={id}
    // eslint-disable-next-line react/button-has-type
    type={type || 'button'}
    onClick={onClick}
    onKeyPress={onKeyPress}
    className={className || cn([secondary ? styles.secondary : styles.primary])}
    style={style}
    disabled={disabled || false}
    tabIndex={tabIndex}
  >
    {children}
  </button>
);

export default Button;
