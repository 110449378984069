import React from 'react';
import { Dropdown } from 'react-bootstrap';
import ConfirmIcon from 'haven-design-system/build/assets/svgs/action/confirm.svg?component';
import { useQuery } from '@apollo/client';
import { FETCH_AVAILABLE_USERS } from '../../graphql/queries/user';
import { handleSwitchAvailableUser } from '../../utils/user';
import { captureException, errorMessage } from '../../utils/error';
import { AvailableUser } from '../../types';
import './styles.scss';
import { getStateCodeFromStateName } from '../../utils/map';
import Spinner from '../Spinner';

const AccountSwitcher: React.FC = () => {
  const { loading, error, data: availableUsersData } = useQuery(FETCH_AVAILABLE_USERS, {
    variables: { onlyContractors: false },
  });

  if (loading) {
    return (
      <Spinner />
    );
  }

  if (error) {
    captureException(error, { source: 'Loading available users' });
    return (
      <div className="accountSwitcher__error">
        {errorMessage('loading available users')}
      </div>
    );
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className="accountSwitcher__dropdown__toggle">
        Accounts
      </Dropdown.Toggle>

      <Dropdown.Menu className="accountSwitcher__dropdown__menu">
        {availableUsersData.availableUsers?.map(
          (availableUser: AvailableUser) => (
            <div key={availableUser.id}>
              {availableUser.contractor ? (
                <Dropdown.Item
                  className="accountSwitcher__dropdown__menu__item"
                  onClick={() => handleSwitchAvailableUser(availableUser)}
                >
                  <div className="accountSwitcher__dropdown__menu__item__container">
                    <div className="accountSwitcher__dropdown__menu__item__container__text">
                      <div className="accountSwitcher__dropdown__menu__item__container__text__businessName">
                        {availableUser?.contractor?.businessName}
                      </div>
                      <div className="accountSwitcher__dropdown__menu__item__container__text__location">
                        {`${availableUser?.contractor?.city}, ${getStateCodeFromStateName(availableUser?.contractor?.state)}`}
                      </div>
                    </div>
                    <div className="accountSwitcher__dropdown__menu__item__container__selected">
                      {localStorage.getItem('inspectifySelectedUser') === availableUser?.id
                        ? (<ConfirmIcon className="accountSwitcher__dropdown__menu__item__container__selected__icon" />)
                        : null }
                    </div>
                  </div>
                </Dropdown.Item>
              ) : null}
            </div>
          ),
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AccountSwitcher;
