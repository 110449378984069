import { colorPaletteGrayscale50, colorBaseRedPrimary } from 'haven-design-system/build/typescript/es6';
import React from 'react';
import {
  InputArea, Label, Name,
} from './styles';

interface Props {
  name?: string,
  value: string | number,
  onChange: (e:any) => void,
  type?: string,
  inputRef?: any,
  placeholder?: string,
  required?: boolean,
  indicateRequired?: boolean,
  style?: React.CSSProperties,
  inputStyle?: React.CSSProperties,
}

const blur = (e: any) => {
  if (e.target instanceof HTMLElement) {
    e.target.blur();
  }
};

const FormInputArea: React.FC<Props> = ({
  name,
  value,
  onChange,
  inputRef,
  placeholder,
  required,
  indicateRequired,
  style,
  inputStyle,
}) => (
  <Label style={style}>
    {name && <Name>{name + (required ? '*' : '')}</Name>}
    <InputArea
      id={name}
      ref={inputRef}
      value={value || ''}
      onChange={(e) => onChange(e)}
      placeholder={placeholder || ''}
      required={required}
      onWheel={(e) => blur(e)}
      style={{
        borderColor: indicateRequired
          ? colorBaseRedPrimary
          : colorPaletteGrayscale50,
        ...inputStyle,
      }}
    />
  </Label>
);

export default FormInputArea;
