import React, { useMemo } from 'react';
import {
  ReportAttachment,
  ReportInformation,
} from 'marketplace-common';
import cn from 'classnames';
import playIcon from '../../../../assets/icons/play_button.svg';
import mediaIcon from '../../../../assets/icons/media.svg';
import {
  CircularAddWorkItemButton as WorkItemButton,
} from '../../../RehabToolPage/components/AddWorkItemButton/AddWorkItemButton';
import styles from './styles.module.scss';
import Thumbnail from '../../../../components/PDF/Thumbnail';

interface Props {
  attachment: ReportAttachment,
  imageWidth: number,
  informations?: ReportInformation[],
  isRehab?: boolean,
  handleWorkItemModalClick?: (attachment: ReportAttachment) => void,
  handleOpenMediaModal?: () => void;
}

const Attachment: React.FC<Props> = ({
  attachment,
  imageWidth,
  informations,
  isRehab,
  handleWorkItemModalClick,
  handleOpenMediaModal,
}) => {
  const label = useMemo(() => (
    (informations || []).find((information) => information.id === attachment.itemId)?.title
  ), [attachment?.itemId, informations]);

  const renderTile = () => {
    switch (attachment.mediaType) {
      case 'video':
        return attachment.attachmentUrl && attachment.thumbnailUrl ? (
          <img
            alt="PropertyImage"
            className={cn(styles.media, styles.video)}
            src={attachment.thumbnailUrl}
            onError={({ currentTarget }) => {
              // TODO: Please find an alternative to this
              // There might be an error when ImageKit doesn't want
              // to generate a thumbnail due to the size of the video.
              currentTarget.onerror = null; // eslint-disable-line no-param-reassign
              currentTarget.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8/B8AAssB5CY77SMAAAAASUVORK5CYII='; // eslint-disable-line no-param-reassign, max-len
            }}
          />
        ) : (
          <div className={cn(styles.media, styles.image)}>
            <img src={mediaIcon} alt="video" />
          </div>
        );
      case 'pdf':
        return <Thumbnail klassNames={[styles.media]} fileUrl={attachment.attachmentUrl} />;
      default:
        return attachment?.resizedUrls?.[imageWidth.toString()] ? (
          <img
            className={styles.media}
            src={attachment.resizedUrls[imageWidth.toString()]}
            alt="media"
          />
        ) : (
          <div className={cn(styles.media, styles.image)}>
            <img src={mediaIcon} alt="media" />
          </div>
        );
    }
  };

  return (
    <div key={attachment.id} data-testid="attachment" className={styles.attachment}>
      {isRehab && <WorkItemButton onClick={() => handleWorkItemModalClick(attachment)} />}
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key !== 'Enter') return;
          handleOpenMediaModal();
        }}
        onClick={handleOpenMediaModal}
      >
        {attachment.mediaType === 'video' && (
          attachment.attachmentUrl && <img src={playIcon} alt="play" className={styles.play} />
        )}
        {attachment.itemType === 'ReportInformation' && informations !== null && (
          <span className={styles.label}>{label}</span>
        )}
        {renderTile()}
      </div>
    </div>
  );
};

export default Attachment;
