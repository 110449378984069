import { gql } from '@apollo/client';

export const SUBMIT_FEEDBACK = gql`
  mutation SubmitFeedback($input: SubmitFeedbackInput!) {
    submitFeedback(input: $input) {
      id
      reviewableId
      reviewableType
      score
      note
      initiatedAt
      submittedAt
      userId
    }
  }
`;
