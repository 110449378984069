import { SET_ACTIVE_ITEM, SET_ACTIVE_ITEMS } from '../actions/types';

export type ActiveState = {
  report: { id: string | null, params: any },
  systemGroupFilters: {ids: string[], params: any },
  order: { id: number | null, params: any },
  project: { id: string | null, params: any },
  property: { id: string | null, params: any },
};

export const initialActiveState: ActiveState = {
  report: { id: null, params: {} },
  systemGroupFilters: { ids: [], params: {} },
  order: { id: null, params: {} },
  project: { id: null, params: {} },
  property: { id: null, params: {} },
};

export default function active(state: any = initialActiveState, action: any) {
  switch (action.type) {
    case SET_ACTIVE_ITEM: {
      return {
        ...state,
        [action.payload.type]: { id: action.payload.id, params: action.payload.params },
      };
    }
    case SET_ACTIVE_ITEMS: {
      return {
        ...state,
        [action.payload.type]: { ids: action.payload.ids, params: action.payload.params },
      };
    }
    default:
      return state;
  }
}
