import React from 'react';
import googleButtonSvg from '../../assets/icons/google-signin.svg';

interface Props {
  onClick: () => void;
}

const GoogleButton: React.FC<Props> = ({
  onClick,
}) => (
  <div style={{ display: 'flex', justifyContent: 'center', margin: '16px 0px' }}>
    <button onClick={onClick} type="button" style={{ all: 'unset', cursor: 'pointer' }}>
      <img src={googleButtonSvg} alt="google-signin-button" />
    </button>
  </div>
);

export default GoogleButton;
