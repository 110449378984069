import { getTreatments } from '@splitsoftware/splitio-redux';
import { CurrentUser } from '../../types';
import { getUID } from '../../utils/splitio';

export enum SplitFlags {
  FeatureRehabHistory = 'feature_rehab_history',
  FeatureReportFeedback = 'feature_report_feedback',
}

export const SPLIT_NAMES = [
  SplitFlags.FeatureRehabHistory,
  SplitFlags.FeatureReportFeedback,
];

export const fetchAllTreatments = (currentUser: CurrentUser) => async (
  dispatch: any, getState: () => { splitioAttributes: { attributes: any } },
) => {
  const userAttributes: {
    email: string | null
    phone: string | null
    name: string | null
  } = {
    email: null,
    phone: null,
    name: null,
  };

  if (currentUser) {
    userAttributes.email = currentUser.email;
    userAttributes.phone = currentUser.phoneNumber;
    userAttributes.name = currentUser.name;
  }

  const uid = getUID();
  dispatch(getTreatments({
    splitNames: Object.values(SPLIT_NAMES),
    key: uid,
    attributes: { ...userAttributes, ...getState().splitioAttributes.attributes },
    evalOnUpdate: true,
  }));
};
