import React from 'react';
import { Job } from 'marketplace-common';
import { isNull } from 'lodash';
import { Tabs } from '../../../components/Tabs';
import JobRow from './JobRow';
import Spinner from '../../../components/Spinner';
import { JobsTabs } from '../types';
import { JobsHeadingWrapper } from './styles';
import { Container as JobsContainer } from '../shared/styles';
import OnboardingTracker from './OnboardingTracker';
import { SurveyorOnboardingType } from '../../Onboarding/types';

interface Props {
  tab: JobsTabs,
  setTab: (tab: JobsTabs) => void,
  jobs: Job[],
  loading: boolean,
  onboardingData: SurveyorOnboardingType,
  children?: React.ReactNode,
}

const Jobs: React.FC<Props> = ({
  tab,
  setTab,
  jobs,
  loading,
  onboardingData,
  children,
}) => (
  <JobsContainer>
    {isNull(onboardingData?.onboardingTrackerDismissedAt) && (
      <OnboardingTracker
        onboardingData={onboardingData}
      />
    )}
    <JobsHeadingWrapper />
    <Tabs tab={tab} setTab={setTab} tabs={Object.values(JobsTabs)} />
    {jobs.map((job) => (
      <JobRow job={job} key={job.id} />
    ))}
    {children}
    {loading && <Spinner />}
  </JobsContainer>
);

export default Jobs;
