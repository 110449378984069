import {
  Works,
  LocalInspection,
  Job,
  InspectionWarranty,
  normalizeJobsIntoWorksInterface,
  IdRef,
  JobStatus,
} from 'marketplace-common';
import { captureException } from '../../utils/error';
import {
  APPEND_WORKS,
  UPDATE_JOB,
  CLEAR_COMPLETED_JOBS,
  CLEAR_UPCOMING_JOBS,
  REPLACE_WORKS,
  SET_WARRANTY,
} from './types';
import { normalizeGraphqlOrderByTokenResponse } from '../normalizers/order';
import { ReduxState, TypedAction, TypedDispatch } from '../store';

export const saveWorksForOrders = (
  data: any,
  previousWorks: Works,
) => async (dispatch: TypedDispatch) => {
  const normalizedWorks = normalizeGraphqlOrderByTokenResponse(data, {
    previousWorks,
    captureException,
  });

  if (normalizedWorks) {
    dispatch({ type: REPLACE_WORKS, payload: normalizedWorks });
  }
};

export const saveJobs = (data: any): TypedAction => async (dispatch: TypedDispatch) => {
  const works = normalizeJobsIntoWorksInterface(data, captureException);
  if (!works) return;

  dispatch({ type: APPEND_WORKS, payload: works });
};

export const updateInspectionsForWorks = (
  inspections: LocalInspection[],
) => async (dispatch: TypedDispatch, getState: () => ReduxState) => {
  if (inspections) {
    const { works: previousWorks } = getState();
    const works: Works = {
      ...previousWorks,
      inspections: Object.assign(
        previousWorks.inspections,
        ...inspections.map((inspection) => ({ [inspection.id]: inspection })),
      ),
    };
    dispatch({ type: APPEND_WORKS, payload: works });
  }
};

export const clearCompletedJobs = () => (dispatch: TypedDispatch) => {
  dispatch({ type: CLEAR_COMPLETED_JOBS });
};

export const clearUpcomingJobs = () => (dispatch: TypedDispatch) => {
  dispatch({ type: CLEAR_UPCOMING_JOBS });
};

export const setWarranty = (warranty: InspectionWarranty) => (dispatch: TypedDispatch) => {
  dispatch({ type: SET_WARRANTY, payload: warranty });
};

export const updateJob = (
  job: Job,
  values: {
    id?: IdRef;
    orderId?: IdRef;
    reportIds?: string[] | null;
    status?: JobStatus;
    scheduledTime?: number;
    inspectionIds?: IdRef[];
    isFee?: boolean;
    isGoAnytime?: boolean;
  },
) => (dispatch: TypedDispatch) => {
  dispatch({ type: UPDATE_JOB, payload: { job, values } });
};
