import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RehabToolProvider } from './context';
import Loader from './loader';

interface Props extends RouteComponentProps<{
  projectId: string,
}> {}

const RehabTool: React.FC<Props> = ({ match }) => {
  const { projectId } = match.params;

  return (
    <RehabToolProvider>
      <Loader projectId={projectId} />
    </RehabToolProvider>
  );
};

export default RehabTool;
