import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { LocalInspection } from 'marketplace-common';
import React, {
  Dispatch, SetStateAction,
} from 'react';
import { colorBaseGreenPrimary, colorBaseRedPrimary } from 'haven-design-system/build/typescript/es6';
import {
  Divider,
  DividerContainer,
  ModalHeader,
  ModalSubText,
  OrDiv,
} from '../styles';
import { FormInput } from '../../../../../components/FormElements';
import Checkbox from '../../../../../components/General/Checkbox';
import FileUpload from '../../../../../components/General/FileUpload';
import { CheckboxesContainer } from '../../../../../components/General/style';
import Spinner from '../../../../../components/Spinner';
import {
  LeftLayoutContainer,
  LoadingTextContainer,
  ErrorText,
  ModalIcon,
  ModalErrorText,
} from './styles';

// step 1 in modal, add a url or a file as an inspection report
export const selectUrlOrFileStep = (
  modalSubText: string,
  showSpectoraUpload: boolean,
  urlForUpload: string,
  setUrlForUpload: Dispatch<SetStateAction<string>>,
  fileForUpload: File,
  setFileForUpload: Dispatch<SetStateAction<File>>,
  modalErrorSubtext: string,
) => (
  <>
    <ModalHeader>Upload Report</ModalHeader>
    <ModalSubText shrink={modalErrorSubtext !== ''}>{modalSubText}</ModalSubText>
    {modalErrorSubtext && (
      <ModalErrorText>{modalErrorSubtext}</ModalErrorText>
    )}
    <FormInput
      placeholder={`Insert ${showSpectoraUpload ? 'Spectora ' : ''}URL here${
        !showSpectoraUpload
          ? ' (Spectora, Vimeo, Youtube or Matterport)'
          : ''
      }`}
      value={urlForUpload}
      onChange={(e:any) => setUrlForUpload(e.target.value)}
    />
    {!showSpectoraUpload && (
      <>
        <DividerContainer>
          <Divider />
          <OrDiv>OR</OrDiv>
          <Divider />
        </DividerContainer>
        <FileUpload
          loading={false}
          file={fileForUpload}
          setFile={setFileForUpload}
        />
      </>
    )}
  </>
);

// helper method to add or remove applicable inspections
const addOrRemoveFromSelected = (
  selected: boolean,
  inspection: LocalInspection,
  otherSelectedInspections: LocalInspection[],
  setOtherSelectedInspections: Dispatch<React.SetStateAction<LocalInspection[]>>,
) => {
  if (selected === false) { // false for checked, undefined for unchecked
    setOtherSelectedInspections([...otherSelectedInspections, inspection]);
  } else {
    setOtherSelectedInspections(
      otherSelectedInspections.filter((ins) => ins.id !== inspection.id),
    );
  }
};

// step 2 in modal, select if the report applies to other inspections.
// this step will be skipped (via ReportUploadModal) if there is only
// one inspection a report can apply to.
export const selectApplicableInspectionsStep = (
  inspectionsNeedingUpload: LocalInspection[],
  selectedInspection: LocalInspection,
  otherSelectedInspections: LocalInspection[],
  setOtherSelectedInspections: Dispatch<React.SetStateAction<LocalInspection[]>>,
) => (
  <>
    <ModalHeader>Does this upload include other reports?</ModalHeader>
    <ModalSubText>
      Select any additional reports included as partof the home inspection report.
    </ModalSubText>
    <CheckboxesContainer>
      {inspectionsNeedingUpload.map((inspection) => (
        <Checkbox
          onClick={(checked) => addOrRemoveFromSelected(
            checked,
            inspection,
            otherSelectedInspections,
            setOtherSelectedInspections,
          )}
          inspection={inspection}
          disabled={inspection.id === selectedInspection.id}
          key={inspection.id}
        />
      ))}
    </CheckboxesContainer>
  </>
);

// step 3 in modal, upload the inspection url or file for selected reports.
export const uploadingStep = (
  isUploadDone: boolean,
  finalModalText: string,
  finalModalSubtext: string,
  error: string,
) => (
  <>
    {isUploadDone ? (
      <>
        <ModalHeader>{finalModalText}</ModalHeader>
        <LeftLayoutContainer>
          {error === '' ? (
            <ModalIcon icon={faCheckCircle} size="lg" color={colorBaseGreenPrimary} />
          ) : (
            <ModalIcon icon={faExclamationCircle} size="lg" color={colorBaseRedPrimary} />
          )}
          <LoadingTextContainer>
            <ModalSubText>{finalModalSubtext}</ModalSubText>
            {error && (
              <ErrorText>{error}</ErrorText>
            )}
          </LoadingTextContainer>
        </LeftLayoutContainer>
      </>
    ) : (
      <Spinner size="lg" />
    )}
  </>
);
