import { OnboardingCompletedStep } from '../pages/Onboarding/types';

export const handleOnboardingPageNavigation = (
  completedStep: OnboardingCompletedStep,
  canSetPassword: boolean,
  onboardingId: string,
  history: any,
) => {
  switch (completedStep) {
    case OnboardingCompletedStep.FormSubmitted:
      if (canSetPassword === true) {
        history.push(`/onboarding/${onboardingId}/password`);
      }
      if (canSetPassword === false) {
        history.push('/sign_in?next=/dashboard');
      }
      break;
    case OnboardingCompletedStep.PasswordSet:
      if (canSetPassword === false) {
        history.push('/sign_in?next=/dashboard');
      }
      break;
    default:
      break;
  }
};
