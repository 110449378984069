import styled from 'styled-components';

export const MenuItemsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
