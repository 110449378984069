import React, { useContext, useEffect } from 'react';
import { InspectorDashboardContext } from './context';
import { DashboardPageTab } from './types';
import {
  InspectorDashboardContent,
  InspectorDashboardGlobalStyles,
} from './styles';
import './styles.scss';
import styles from '../../components/Button/styles.module.scss';
import BadNavigationImage from '../../assets/images/badNavigationImage.svg';
import JobsLoader from './Jobs';
import MobileMenu from './MobileMenu';
import Calendar from './Calendar';
import Spinner from '../../components/Spinner';
import TipaltiIframe from './TipaltiIframe';
import MsaReview from './MsaReview';
import { UserContractor } from '../../types';
import BusinessDetailsLoader from './BusinessDetails';
import Menu from './Menu';
import UserManagement from './UserManagement';
import { canAccessMSAPage } from '../../utils/user';

interface Props {
  user: UserContractor,
  isLoading: boolean,
  error: string,
  refetchContractor: () => void
}

const InspectorDashboardPage: React.FC<Props> = ({
  user,
  isLoading,
  error,
  refetchContractor,
}) => {
  const { state, setPage } = useContext(InspectorDashboardContext);

  useEffect(() => {
    if (canAccessMSAPage(user)
      && state.page === DashboardPageTab.Jobs
      && !user.currentUser.contractor?.currentOnboarding) {
      setPage(DashboardPageTab.MasterInspectionAndServiceAgreement);
    }
  }, [user?.currentUser?.contractor]);

  const renderPageContent = (content: DashboardPageTab) => {
    switch (content) {
      case DashboardPageTab.Jobs:
        return <JobsLoader user={user} />;
      case DashboardPageTab.Calendar:
        return <Calendar />;
      case DashboardPageTab.BusinessDetails:
        return <BusinessDetailsLoader />;
      case DashboardPageTab.UserManagement:
        return <UserManagement />;
      case DashboardPageTab.TipaltiSetupProcess:
        return <TipaltiIframe iframeType="setup_process" />;
      case DashboardPageTab.TipaltiPaymentHistory:
        return <TipaltiIframe iframeType="payment_history" />;
      case DashboardPageTab.MasterInspectionAndServiceAgreement:
        return <MsaReview refetchContractor={refetchContractor} />;
      default:
        return <BusinessDetailsLoader />;
    }
  };

  if (isLoading && error.length === 0) return <Spinner style={{ width: '100vw', height: '100vh' }} />;

  if (error.length) {
    return (
      <div className="errorMessageContainer">
        <img src={BadNavigationImage} alt="" className="imageContainer" />
        <div className="errorMessageHeader">Are you in the wrong place?</div>
        <div className="errorMessageContent">{error}</div>
        <a href="https://app2.inspectify.com" className={styles.primary}>
          Login as a client
        </a>
      </div>
    );
  }

  return (
    <div>
      <InspectorDashboardGlobalStyles />
      <Menu user={user} />
      <MobileMenu user={user} />

      <InspectorDashboardContent>
        {renderPageContent(state.page)}
      </InspectorDashboardContent>
    </div>
  );
};

export default InspectorDashboardPage;
