import React from 'react';
import humps from 'humps';
import { useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';

export enum EventName {
  UserAccountCreated = 'User Account Created',
  UserAccountConfirmed = 'User Account Confirmed',
  DownloadInspectionReport = 'Download inspection report',
  CreateRehabProject = 'REHAB_TOOL::Create rehab project',
  DownloadRehabProjectPDF = 'REHAB_TOOL::Download rehab pdf',
  DownloadRehabProjectCSV = 'REHAB_TOOL::Download rehab csv',
  InspectionWarrantyProofOfOwnershipSubmitted = 'INSPECTION_WARRANTY_PROOF_OF_OWNERSHIP::Inspection Warranty Proof Of Ownership Submitted',
  InspectionWarrantyClaimSubmitted = 'INSPECTION_WARRANTY_CLAIM::Inspection Warranty Claim Submitted',
  InspectorRedirectToSignIn = 'INSPECTOR_DASHBOARD::Inspector Redirect To Sign In',
  InspectorUpdatedDetails = 'INSPECTOR_DASHBOARD::Inspector Details Updated',
  InspectorUploadedFile = 'INSPECTOR_DASHBOARD::Inspector Uploaded File',
  InspectorDashboardTabUsed = 'INSPECTOR_DASHBOARD::Inspector Clicked a Tab',
  InviteNewUser = 'INSPECTOR_DASHBOARD::Admin invited a new user',
  ResendInviteToUser = 'INSPECTOR_DASHBOARD::Admin resent an invite to a user',
  ArchiveUser = 'INSPECTOR_DASHBOARD::Admin archived a user',
  UnarchiveUser = 'INSPECTOR_DASHBOARD::Admin unarchived a user',
  EditUserEmail = 'INSPECTOR_DASHBOARD::Admin edited a user`s email',
  EditUserPermissions = 'INSPECTOR_DASHBOARD::Admin edited an existing user`s permissions',
}

// Reset (For logout)
export const resetAnalytics = () => {
  if (window.analytics) {
    window.analytics.reset();
  }
};

// Identify traits of the current user in Segment
export const identify = (userId: string, traits: Record<string, any> = {}) => {
  if (window.analytics) {
    window.analytics.identify(userId, humps.decamelizeKeys(traits));
  }

  LogRocket.identify(userId,
    Object.entries(humps.decamelizeKeys(traits)).reduce((map, obj) => {
      const [key, value] = obj;
      return { ...map, [humps.decamelize(key)]: value };
    }, {}));
};

// Track an analytics event in Segment
export const track = (event: EventName, properties: Record<string, any> = {}) => {
  if (window.analytics) {
    window.analytics.track(event, humps.decamelizeKeys(properties));
  }
};

// React Hook to track page changes
export const usePageViews = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (window.analytics && location.pathname) {
      const category = 'Page view';
      const urlParams = location.search ? new URLSearchParams(location.search) : null;
      const properties: {
        orderToken?: string,
        reportId?: string,
        rehabId?: string,
      } = {};

      let name = location.pathname;

      if (location.pathname.includes('/reports')) {
        name = 'Reports page view';

        const pathArr = location.pathname.match(/\/orders\/(.*?)\/reports/);
        const orderToken = pathArr.length && pathArr.length > 1 ? pathArr[1] : null;

        if (orderToken) properties.orderToken = orderToken;
        if (urlParams?.has('report_id')) properties.reportId = urlParams.get('report_id');
      } else if (location.pathname.includes('/rehab')) {
        name = 'Rehab tool page view';

        const pathArr = location.pathname.match(/\/rehab\/(.*?)$/);
        const rehabId = pathArr.length && pathArr.length > 1 ? pathArr[1] : null;

        if (rehabId) properties.rehabId = rehabId;
      }

      window.analytics.page(category, name, properties);
    }
  }, [location]);
};
