import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  display: flex;
  align-items: flex-end;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,0.5);
  z-index: 100;
`;
