import { client } from '../graphql/createApolloClient';
import { GENERATE_PERMIT_PDF } from '../graphql/mutations/generatePermitPdf';
import { captureException } from './error';

export const downloadPermitPdf = async (propertyId: string, params: any = {}) => {
  const input: any = { propertyId };
  if (params?.tags) input.tags = params.tags;

  const { data, errors } = await client.mutate({
    mutation: GENERATE_PERMIT_PDF,
    variables: { input },
  });

  const url = data?.generatePropertyPermitPdfReport?.permitPdfReportActiveStorageBlob.url;
  if (url) {
    window.open(url, '_blank');
  } else {
    captureException(new Error(`Failed to generate pdf for permit report property id: ${propertyId}`), data);
  }

  if (errors) captureException(errors);
};
