// import * as Sentry from '@sentry/browser';
import uniq from 'lodash/uniq';
import { HttpStatusCode } from '../enums';

/**
 * Capture an error and send it to Sentry. Ignores 401 unauthorized errors, since they are expected
 * and we handle them in an axios interceptor.
 */
export const captureException = (error: any, data?: any) => {
  if (error.response && error.response.status === HttpStatusCode.Unauthorized) return;
  if (error instanceof Error) {
    // https://stackoverflow.com/questions/48863441/apollo-client-how-to-simply-debug-a-400-code-error
    console.log('Capturing Exception:', error, JSON.stringify(error, null, 2), data); // eslint-disable-line no-console
  } else {
    console.error('Capturing Exception:', error, data); // eslint-disable-line no-console
  }
  // Sentry.captureException(error);
};

/**
 * Get a user-friendly error message when an unexpected error occurs.
 */
export const errorMessage = (taskDescription: string) => (
  `Uh oh, something went wrong while ${taskDescription}. Please try again or contact us.`
);

/**
 * Get a user-friendly representation of an error object returned from Rails. Falls back to
 * JSON.stringify when the object cannot be parsed.
 */
export const formatErrors = (errors: any) => {
  try {
    const friendlyErrors = Object.keys(errors).map((attribute: string) => {
      const friendlyName = attribute.split('.').pop();
      return `${friendlyName} ${errors[attribute]}`;
    });
    return uniq(friendlyErrors).join(', ');
  } catch (e) {
    return JSON.stringify(errors);
  }
};
