import { gql } from '@apollo/client';

export const ADD_INSPECTION_DOCUMENT = gql`
  mutation AddInspectionDocument($input: InspectionDocumentInput!) {
    addInspectionDocument(inspectionDocumentInput: $input) {
      success
      code
      message
      errors
      inspection_document {
        id
        inspection_ids
        name
        url
        external_document_url
        created_at
        repair_estimate_ready_email_sent_at
        service_url
        images {
          id
          name
          page_number
          url
          service_url
        }
      }
    }
  }
`;
