import React, { useState } from 'react';
// eslint-disable-next-line max-len
import assetIconControlChevronDown from 'haven-design-system/build/assets/svgs/controls/chevron_down.svg';
import assetIconControlChevronUp from 'haven-design-system/build/assets/svgs/controls/chevron_up.svg';
import IconButton from '../../../../../components/IconButton';
import { ProjectHistoryCard } from '../../../../../types';
import WorkHistoryCard from '../WorkHistoryCard';
import {
  DayContainer,
  DayContainerHeader,
  DayContainerHeaderText,
} from './styles';
import { RehabTabs } from '../../../types';

interface Props {
  date: string;
  workHistoryCards: ProjectHistoryCard[];
  isOpen: boolean;
  tab: RehabTabs;
  setTab?: (tab: RehabTabs) => void;
  fetchProjectHistory: (firstFetch: boolean) => void;
}

const CollapsibleHistoryContainer: React.FC<Props> = ({
  date,
  workHistoryCards,
  isOpen = false,
  tab,
  setTab,
  fetchProjectHistory,
}) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(isOpen);

  const toggleCollapsible = () => setIsCollapsibleOpen(!isCollapsibleOpen);

  return (
    <DayContainer>
      <IconButton onClick={() => toggleCollapsible()}>
        <DayContainerHeader>
          <DayContainerHeaderText>{date}</DayContainerHeaderText>
          <img
            src={isCollapsibleOpen ? assetIconControlChevronUp : assetIconControlChevronDown}
            alt={isCollapsibleOpen ? 'chevron-up' : 'chevron-down'}
          />
        </DayContainerHeader>
      </IconButton>

      {isCollapsibleOpen && workHistoryCards.map((historyCard: ProjectHistoryCard, index) => (
        <WorkHistoryCard
          key={`${historyCard.id}-${index + 5}`}
          workHistoryCard={historyCard}
          isLastItemInHistoryPanel={index === workHistoryCards.length - 1}
          tab={tab}
          setTab={setTab}
          fetchMoreWorkHistory={() => {
            fetchProjectHistory(false);
          }}
        />
      ))}
    </DayContainer>
  );
};

export default CollapsibleHistoryContainer;
