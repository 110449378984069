import React, { useMemo } from 'react';
import Modal from 'react-modal';
import {
  getFormattedString,
  getPlatformFee,
  getPlatformFeePercentage,
  LocalInspection,
  LocalInspectionProposal,
} from 'marketplace-common';
import {
  Divider,
  PaymentInfoBox,
  PaymentInfoContainer,
  PaymentInfoRow,
  PaymentInfoText,
  YourPayInfoText,
} from './styles';
import { BottomButtonContainer, CloseButton, HeaderText } from '../ContactsModal/styles';
import { useTypedSelector } from '../../../../../redux/store';
import { selectWorksInspectionProposals } from '../../../../../redux/selectors';

interface Props {
  modalIsOpen: boolean;
  closeModal: () => void;
  inspections: LocalInspection[];
  pay: number;
}

const PayModal: React.FC<Props> = ({
  modalIsOpen, closeModal, inspections, pay,
}) => {
  const inspectionProposals = useTypedSelector(selectWorksInspectionProposals);
  const scheduledProposalIds = inspections.map((ins) => ins.scheduledProposalId);
  const proposals = scheduledProposalIds.map((id) => inspectionProposals[id]);

  const infoBoxText = 'We will release your payment within 1 business day once the report is uploaded and published.';
  const platformFee = useMemo(() => getPlatformFee(proposals), [proposals]);
  const getPlatformFeeLabel = (fee: number) => {
    if (fee <= 0) {
      return `$${(-1 * fee).toFixed(2)}`;
    }
    return `-$${fee.toFixed(2)}`;
  };

  const PaymentRow = (proposal: LocalInspectionProposal, inspectionType: string) => (
    <PaymentInfoRow key={proposal.inspectionId}>
      <PaymentInfoText>{getFormattedString(inspectionType)}</PaymentInfoText>
      <PaymentInfoText paid={!!proposal.contractorPaidAt}>
        ({proposal.contractorPaidAt ? 'P' : 'Unp'}aid) ${proposal.totalFee?.toFixed(2) || '0.00'}
      </PaymentInfoText>
    </PaymentInfoRow>
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className="InspectorDashboardModal"
      overlayClassName="InspectorDashboardOverlay"
      preventScroll={false}
    >
      <div>
        <HeaderText>Your pay</HeaderText>
        <PaymentInfoBox>{infoBoxText}</PaymentInfoBox>
        <PaymentInfoContainer>
          {proposals.map(
            (prop) => PaymentRow(prop, inspections.find(
              (ins) => ins.id === prop.inspectionId,
            ).inspectionType),
          )}
          <PaymentInfoRow>
            <PaymentInfoText>{getPlatformFeePercentage(proposals)}% Platform Fee</PaymentInfoText>
            <PaymentInfoText>
              {getPlatformFeeLabel(platformFee)}
            </PaymentInfoText>
          </PaymentInfoRow>
          <Divider />
          <PaymentInfoRow>
            <YourPayInfoText>Your pay</YourPayInfoText>
            <YourPayInfoText>
              ${pay.toFixed(2)}
            </YourPayInfoText>
          </PaymentInfoRow>
        </PaymentInfoContainer>
        <BottomButtonContainer>
          <CloseButton onClick={closeModal} secondary>
            Close
          </CloseButton>
        </BottomButtonContainer>
      </div>
    </Modal>
  );
};

export default PayModal;
