import React, { useContext } from 'react';
import { useReportsPageContext } from '../../../../utils/reportsPageContext';
import MenuSystem from './MenuSystem';
import { MenuItem, MenuItemsContainer, MenuText } from '../../../Reports/components/Menu/styles';
import { RehabToolContext } from '../../context';

interface Props {
  isMobileView?: boolean
  onMobileItemPress?: () => void
}

const MenuItems: React.FC<Props> = ({
  isMobileView,
  onMobileItemPress,
}) => {
  const { state } = useContext(RehabToolContext);
  const { state: menuState, dispatch } = useReportsPageContext();

  return (
    <MenuItemsContainer>
      <MenuItem
        href="#Summary"
        isActive={menuState.activeMenuItem === 'Summary'}
        isMobileView={isMobileView}
        onClick={() => {
          if (onMobileItemPress) { onMobileItemPress(); }
          dispatch({ type: 'set_active_item', payload: 'Summary' });
        }}
      >
        <MenuText>
          Summary
        </MenuText>
      </MenuItem>

      {Object.keys(state.groupedReportSystemsByName).map((systemName: string) => (
        <MenuSystem
          key={systemName}
          systemName={systemName}
          filter={state.filter}
          onMobileItemPress={onMobileItemPress}
        />
      ))}
    </MenuItemsContainer>
  );
};

export default MenuItems;
