import {
  Contractors,
} from '../../types';
import {
  ADD_CONTRACTOR,
} from '../actions/types';

export const initialContractorState = null as Contractors;

export default function contractors(state: any = initialContractorState, action: any) {
  switch (action.type) {
    case ADD_CONTRACTOR: {
      const contractorsState = { ...state };
      const { contractor } = action.payload;
      if (contractorsState[contractor.id]) {
        contractorsState[contractor.id] = {
          ...contractorsState[contractor.id],
          ...contractor,
        };
      } else {
        contractorsState[contractor.id] = { ...contractor };
      }
      return contractorsState;
    }
    default:
      return state;
  }
}
