import { gql } from '@apollo/client';

export const FETCH_AVAILABLE_USERS = gql`
query($onlyContractors: Boolean!) {
  availableUsers(onlyContractors: $onlyContractors) {
    id
    contractorId
    contractor {
      id
      businessAddress
      businessName
      city
      state
    }
    team {
      id
      name
    }
  }
}
`;

export const MANAGEABLE_USERS = gql`
  query manageableUsers {
    users {
      edges {
        node {
          id
          name
          email
          phoneNumber
          grantedPermissions
          archivedAt
          activatedAt
        }
      }
    }
  }
`;
