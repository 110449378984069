import React from 'react';
import styled from 'styled-components';
import { colorBaseWhitePrimary } from 'haven-design-system/build/typescript/es6';
import Info from '../../../assets/icons/info.svg';

const ComplianceContainer = styled.div`
  background-color: ${colorBaseWhitePrimary};
  border-radius: 4px;
  padding: 24px;
  width: 100%;
  justify-content: center;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  @include media-breakpoint-down(md) {
    padding-left: 16px;
  }
`;

const StatusLogo = styled.img`
  margin: 25px;
`;

const IconContainer = styled.div`
  width: 100px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  margin-right: 25px;
`;

const TextContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: auto;
  font-size: 20px;
  white-space: pre-wrap;
`;

interface Props {
  stateComplianceText: string,
}

const StateCompliance: React.FC<Props> = ({
  stateComplianceText,
}) => (
  <ComplianceContainer>
    <IconContainer>
      <StatusLogo src={Info} alt="info" />
    </IconContainer>
    <TextContainer>
      {stateComplianceText}
    </TextContainer>
  </ComplianceContainer>
);

export default StateCompliance;
