import React, { useState } from 'react';
import './styles.scss';
import ArchiveIcon from 'haven-design-system/build/assets/svgs/action/archive_outlined.svg?component';
import { Tooltip } from 'react-tooltip';
import { formatPhoneNumber, validateEmail } from '../../../../../utils/string';
import { ProfileInformationSections, UserProfileInformationProps } from './types';
import Button from '../../../../../components/Button';
import { ModalType } from '../types';
import { UserStatusEnum } from '../../../../../types';

const UserProfileInformation: React.FC<UserProfileInformationProps> = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  status,
  isEditUserView,
  isAdmin,
  openModal,
  handleUnarchiveUser,
}) => {
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);

  const handleArchiveButton = () => (
    status === UserStatusEnum.Archived
      ? handleUnarchiveUser
      : () => openModal(ModalType.ARCHIVE)
  );

  return (
    <div className="userProfile">
      <div className="userProfile__section">
        <div className="userProfile__section__title">
          {ProfileInformationSections.Title}
        </div>
        <div className="userProfile___section__description">
          {ProfileInformationSections.Description}
        </div>
        {isEditUserView ? (
          <>
            <div id="archive-tooltip">
              <Button
                className={`userProfile__section__archiveButton${status === UserStatusEnum.Archived ? '--unarchive' : ''}`}
                onClick={handleArchiveButton()}
                disabled={isAdmin && status === UserStatusEnum.Active}
              >
                <ArchiveIcon />
                {status === UserStatusEnum.Archived ? 'Unarchive user' : 'Archive user'}
              </Button>
            </div>
            {isAdmin && status === UserStatusEnum.Active ? (
              <Tooltip
                anchorSelect="#archive-tooltip"
                place="bottom-start"
                className="userProfile__section__tooltip"
                content={ProfileInformationSections.ArchiveDescription}
              />
            ) : null}
          </>
        ) : null}
      </div>
      <div className="userProfile__section--large">
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">{ProfileInformationSections.Required}</div>
              {ProfileInformationSections.FirstName}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={firstName}
              name="first_name"
              onChange={() => {}}
            />
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">{ProfileInformationSections.Required}</div>
              {ProfileInformationSections.LastName}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={lastName}
              name="last_name"
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">{ProfileInformationSections.Required}</div>
              {ProfileInformationSections.Phone}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="tel"
              value={formatPhoneNumber(phoneNumber)}
              onBlur={() => setShowPhoneError(phoneNumber.length > 0 && phoneNumber.length < 10)}
              name="phone_number"
              required
              onChange={() => {}}
            />
            {showPhoneError && (
              <div className="userProfile__section__inputRow__inputContainer__errorText">
                {ProfileInformationSections.PhoneError}
              </div>
            )}
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">{ProfileInformationSections.Required}</div>
              {ProfileInformationSections.Email}
            </div>
            <input
              className={showEmailError ? 'userProfile__section__inputRow__inputContainer__input--error' : 'userProfile__section__inputRow__inputContainer__input'}
              type="email"
              value={email}
              required
              onBlur={() => setShowEmailError(email.length > 0 && !validateEmail(email))}
              name="email"
              onChange={() => {}}
            />
            {showEmailError && (
              <div className="userProfile__section__inputRow__inputContainer__errorText">
                {ProfileInformationSections.EmailError}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileInformation;
