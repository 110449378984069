import {
  Inspections,
} from '../../types';
import {
  ADD_INSPECTION,
  CLEAR_INSPECTIONS,
} from '../actions/types';

export const initialInspectionState = null as Inspections;

export default function inspections(state: any = initialInspectionState, action: any) {
  switch (action.type) {
    case ADD_INSPECTION: {
      const inspectionsState = { ...state };
      const { orderToken, inspection } = action.payload;
      inspectionsState[orderToken] = {
        ...inspectionsState[orderToken],
        [inspection.id]: { ...inspection, orderToken },
      };
      return inspectionsState;
    }
    case CLEAR_INSPECTIONS:
      return null;
    default:
      return state;
  }
}
