import React from 'react';
import { StateCodeEnum, StateEnum } from '../../../types/address';

export interface OnboardingFormPageProps {
  firstName: string,
  setFirstName: (e: string) => void,
  lastName: string,
  setLastName: (e: string) => void,
  phoneNumber: string,
  setPhoneNumber: (e: string) => void,
  email: string,
  setEmail: (e: string) => void,
  streetAddress: string,
  setStreetAddress: (e: string) => void,
  city: string,
  setCity: (e: string) => void,
  state: StateEnum | StateCodeEnum,
  setState: (e: StateEnum | StateCodeEnum) => void,
  zipcode: string,
  setZipcode: (e: string) => void,
  hasModeOfTransportation: boolean,
  setHasModeOfTransporation: (e: boolean) => void,
  hasReliablePhone: boolean,
  setHasReliablePhone: (e: boolean) => void,
  sendAutomatedSms: boolean,
  setSendAutomatedSms: (e: boolean) => void,
  serviceAreaRadiusInMiles: number;
  setServiceAreaRadiusInMiles: (e: number) => void,
  inspectorLicenseHeld: boolean,
  setInspectorLicenseHeld: (e: boolean) => void,
  contractorLicenseHeld: boolean,
  setContractorLicenseHeld: (e: boolean) => void,
  realEstateLicenseHeld: boolean,
  setRealEstateLicenseHeld: (e: boolean) => void,
  showEmailError: boolean,
  setShowEmailError: (e: boolean) => void,
  showPhoneError: boolean,
  setShowPhoneError: (e: boolean) => void,
  showZipcodeError: boolean,
  setShowZipcodeError: (e: boolean) => void,
  formComplete: () => boolean;
  isMobile: boolean,
  calculateSliderWidth: () => number;
  addressRef: React.RefObject<HTMLInputElement>,
  submitIntakeForm: () => void;
  loading: boolean;
}

export enum BasicInformation {
  Title = 'Basic Information',
  Required = '*',
  FirstName = 'First name',
  LastName = 'Last name',
  Email = 'Email',
  Phone = 'Phone number',
  Address = 'Address',
  City = 'City',
  State = 'State',
  Zipcode = 'ZIP code',
  EmailError = 'Please enter a valid email address.',
  PhoneError = 'Please enter a valid 10-digit phone number.',
  ZipcodeError = 'Please enter a valid zipcode',
}

export enum JobSpecificQuestions {
  Title = 'Job Specific Questions',
  Required = '*',
  Yes = 'Yes',
  No = 'No',
  Transportation = 'Do you have a reliable mode of transportation such as a car, van, or truck?',
  Smartphone = 'Do you have a reliable smartphone and are able to download apps from the Google play or Apple app store?',
  Sms = 'Do you agree to receive automated transactional messages about your jobs from Inspectify. Message and data rates may apply. Number of messages may very. To opt-out, reply STOP to any text message.',
  SmsYes = 'Yes, I consent to receiving SMS messages',
  SmsNo= 'No, I do not consent to receiving SMS messages',
  Distance = 'How far are you willing to drive?',
  DistanceSelect = '(Select the furthest distance in miles)',
  DistanceMin = '0 miles',
  DistanceMax = '200 miles',
  License = 'Do you currently hold any of the following licenses?',
  LicenseSelect = '(Select all that apply)',
  Inspector = 'Inspector',
  Contractor = 'Contractor',
  Realtor = 'Real Estate Agent/Realtor',
}
