import { Report, ReportsInterface } from 'marketplace-common';
import { ADD_REPORT, ADD_REPORT_ATTACHMENT } from '../actions/types';

export const initialReportsState = {} as ReportsInterface;

export default function reports(state: any = initialReportsState, action: any) {
  switch (action.type) {
    case ADD_REPORT: {
      return {
        ...state,
        [action.payload.report.report.id]: action.payload.report,
      };
    }
    case ADD_REPORT_ATTACHMENT: {
      const { reportId, attachment } = action.payload;
      const newState = { ...state };
      const report: Report = newState[reportId];
      if (report && attachment) {
        report.attachments[attachment.id] = attachment;

        switch (attachment.itemType) {
          case 'ReportSystem':
            if (report.systems[attachment.itemId]) {
              report.systems[attachment.itemId].reportAttachmentIds.push(attachment.id);
            }
            break;
          case 'ReportSubsystem':
            if (report.subsystems[attachment.itemId]) {
              report.subsystems[attachment.itemId].reportAttachmentIds.push(attachment.id);
            }
            break;
          case 'ReportInformation':
            if (report.informations[attachment.itemId]) {
              report.informations[attachment.itemId].reportAttachmentIds.push(attachment.id);
            }
            break;
          case 'ReportApplianceInformation':
            if (report.applianceInformations[attachment.itemId]) {
              report.applianceInformations[attachment.itemId].reportAttachmentIds.push(attachment.id);
            }
            break;
          case 'ReportDeficiency':
            if (report.deficiencies[attachment.itemId]) {
              report.deficiencies[attachment.itemId].reportAttachmentIds.push(attachment.id);
            }
            break;
          case 'ReportLimitation':
            if (report.limitations[attachment.itemId]) {
              report.limitations[attachment.itemId].reportAttachmentIds.push(attachment.id);
            }
            break;
          default:
            break;
        }
        return {
          ...newState,
          [reportId]: report,
        };
      }
      return state;
    }
    default:
      return state;
  }
}
