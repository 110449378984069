import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Update } from './styles';

interface Props {
  loading: boolean,
  onClick: () => void,
  success: boolean,
  hasStateChanged: boolean,
}

const UpdateButton: React.FC<Props> = ({
  loading, onClick, success, hasStateChanged,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setShowSuccess(success);
    if (success) {
      setTimeout(() => setShowSuccess(false), 2000);
    }
  }, [success]);

  return (
    <Update type="button" onClick={onClick} disabled={!hasStateChanged || loading || showSuccess} showLightColor={!hasStateChanged}>
      {loading && !showSuccess && <Spinner animation="border" />}
      {showSuccess && !loading && <FontAwesomeIcon icon={faCheck} />}
      {!loading && !showSuccess && 'Update'}
    </Update>
  );
};

export default UpdateButton;
