import {
  CurrentUser,
} from '../../types';
import {
  API_END,
  API_START,
  API_ERROR,
  CREATE_ORDER,
  CREATE_INSPECTION,
  CREATE_INSPECTION_PROPOSAL,
  GET_ORDER,
  GET_INSPECTIONS,
  GET_INSPECTION_PROPOSALS,
  GET_BIDS,
  GET_AVAILABILITIES,
  GET_PROPERTY,
  GET_CONTRACTOR,
  GET_STAKEHOLDER,
  GET_STAKEHOLDERS,
  PUT_PROPERTY,
  PUT_STAKEHOLDER,
  GET_TEAM,
  PUT_ORDER,
} from '../actions/types';

export const initialApiState = {
  currentUser: null as CurrentUser,
  isLoadingContractor: false,
  isLoadingOrder: false,
  isLoadingReport: 0,
  isLoadingInspection: 0,
  isLoadingBid: 0,
  isLoadingProposal: 0,
  isLoadingProperty: false,
  isLoadingStakeholder: 0,
  isLoadingTeam: 0,
};

export default function reducer(state: any = initialApiState, action: any) {
  switch (action.type) {
    case API_START:
      if (action.payload === CREATE_ORDER) {
        return { ...state, isLoadingOrder: true };
      }
      if (action.payload === CREATE_INSPECTION) {
        const { isLoadingInspection } = state;
        return { ...state, isLoadingInspection: isLoadingInspection + 1 };
      }
      if (action.payload === CREATE_INSPECTION_PROPOSAL) {
        const { isLoadingProposal } = state;
        return { ...state, isLoadingProposal: isLoadingProposal + 1 };
      }
      if (action.payload === GET_ORDER) {
        return { ...state, isLoadingOrder: true };
      }
      if (action.payload === GET_INSPECTIONS) {
        const { isLoadingInspection } = state;
        return { ...state, isLoadingInspection: isLoadingInspection + 1 };
      }
      if (action.payload === GET_INSPECTION_PROPOSALS) {
        const { isLoadingProposal } = state;
        return { ...state, isLoadingProposal: isLoadingProposal + 1 };
      }
      if (action.payload === GET_BIDS) {
        const { isLoadingBid } = state;
        return { ...state, isLoadingBid: isLoadingBid + 1 };
      }
      if (action.payload === GET_AVAILABILITIES) {
        return { ...state, isLoadingAvailabilities: true };
      }
      if (action.payload === GET_PROPERTY) {
        return { ...state, isLoadingProperty: true };
      }
      if (action.payload === GET_CONTRACTOR) {
        return { ...state, isLoadingContractor: true };
      }
      if (action.payload === GET_STAKEHOLDER) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder + 1 };
      }
      if (action.payload === GET_STAKEHOLDERS) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder + 1 };
      }
      if (action.payload === PUT_PROPERTY) {
        return { ...state, isLoadingProperty: true };
      }
      if (action.payload === PUT_STAKEHOLDER) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder + 1 };
      }
      if (action.payload === GET_TEAM) {
        const { isLoadingTeam } = state;
        return { ...state, isLoadingTeam: isLoadingTeam + 1 };
      }
      if (action.payload === PUT_ORDER) {
        return { ...state, isLoadingOrder: true };
      }
      break;
    case API_END:
      if (action.payload === CREATE_ORDER) {
        return { ...state, isLoadingOrder: false };
      }
      if (action.payload === CREATE_INSPECTION) {
        const { isLoadingInspection } = state;
        return { ...state, isLoadingInspection: isLoadingInspection - 1 };
      }
      if (action.payload === CREATE_INSPECTION_PROPOSAL) {
        const { isLoadingProposal } = state;
        return { ...state, isLoadingProposal: isLoadingProposal - 1 };
      }
      if (action.payload === GET_ORDER) {
        return { ...state, isLoadingOrder: false };
      }
      if (action.payload === GET_INSPECTIONS) {
        const { isLoadingInspection } = state;
        return { ...state, isLoadingInspection: isLoadingInspection - 1 };
      }
      if (action.payload === GET_INSPECTION_PROPOSALS) {
        const { isLoadingProposal } = state;
        return { ...state, isLoadingProposal: isLoadingProposal - 1 };
      }
      if (action.payload === GET_BIDS) {
        const { isLoadingBid } = state;
        return { ...state, isLoadingBid: isLoadingBid - 1 };
      }
      if (action.payload === GET_AVAILABILITIES) {
        return { ...state, isLoadingAvailabilities: false };
      }
      if (action.payload === GET_PROPERTY) {
        return { ...state, isLoadingProperty: false };
      }
      if (action.payload === GET_CONTRACTOR) {
        return { ...state, isLoadingContractor: false };
      }
      if (action.payload === GET_STAKEHOLDER) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder - 1 };
      }
      if (action.payload === GET_STAKEHOLDERS) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder - 1 };
      }
      if (action.payload === PUT_PROPERTY) {
        return { ...state, isLoadingProperty: false };
      }
      if (action.payload === PUT_STAKEHOLDER) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder - 1 };
      }
      if (action.payload === GET_TEAM) {
        const { isLoadingTeam } = state;
        return { ...state, isLoadingTeam: isLoadingTeam - 1 };
      }
      if (action.payload === PUT_ORDER) {
        return { ...state, isLoadingOrder: false };
      }
      break;
    case API_ERROR:
      if (action.payload === CREATE_ORDER) {
        return { ...state, isLoadingOrder: false };
      }
      if (action.payload === CREATE_INSPECTION) {
        const { isLoadingInspection } = state;
        return { ...state, isLoadingInspection: isLoadingInspection - 1 };
      }
      if (action.payload === CREATE_INSPECTION_PROPOSAL) {
        const { isLoadingProposal } = state;
        return { ...state, isLoadingProposal: isLoadingProposal - 1 };
      }
      if (action.payload === GET_ORDER) {
        return { ...state, isLoadingOrder: false };
      }
      if (action.payload === GET_INSPECTIONS) {
        const { isLoadingInspection } = state;
        return { ...state, isLoadingInspection: isLoadingInspection - 1 };
      }
      if (action.payload === GET_INSPECTION_PROPOSALS) {
        const { isLoadingProposal } = state;
        return { ...state, isLoadingProposal: isLoadingProposal - 1 };
      }
      if (action.payload === GET_BIDS) {
        const { isLoadingBid } = state;
        return { ...state, isLoadingBid: isLoadingBid - 1 };
      }
      if (action.payload === GET_AVAILABILITIES) {
        return { ...state, isLoadingAvailabilities: false };
      }
      if (action.payload === GET_PROPERTY) {
        return { ...state, isLoadingProperty: false };
      }
      if (action.payload === GET_CONTRACTOR) {
        return { ...state, isLoadingContractor: false };
      }
      if (action.payload === GET_STAKEHOLDER) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder - 1 };
      }
      if (action.payload === GET_STAKEHOLDERS) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder - 1 };
      }
      if (action.payload === PUT_PROPERTY) {
        return { ...state, isLoadingProperty: false };
      }
      if (action.payload === PUT_STAKEHOLDER) {
        const { isLoadingStakeholder } = state;
        return { ...state, isLoadingStakeholder: isLoadingStakeholder - 1 };
      }
      if (action.payload === GET_TEAM) {
        const { isLoadingTeam } = state;
        return { ...state, isLoadingTeam: isLoadingTeam - 1 };
      }
      if (action.payload === PUT_ORDER) {
        return { ...state, isLoadingOrder: false };
      }
      break;
    default:
      return state;
  }

  return state;
}
