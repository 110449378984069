import React from 'react';
import './styles.scss';
import '../styles.scss';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import {
  formatPhoneNumber, unformatPhoneNumber, validateEmail, validateZipcode,
} from '../../../utils/string';
import {
  BasicInformation, JobSpecificQuestions, OnboardingFormPageProps,
} from './types';
import { StateEnum, StateCodeEnum } from '../../../types/address';
import Spinner from '../../../components/Spinner';

const OnboardingFormPage: React.FC<OnboardingFormPageProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  streetAddress,
  setStreetAddress,
  city,
  setCity,
  state,
  setState,
  zipcode,
  setZipcode,
  hasModeOfTransportation,
  setHasModeOfTransporation,
  hasReliablePhone,
  setHasReliablePhone,
  sendAutomatedSms,
  setSendAutomatedSms,
  serviceAreaRadiusInMiles,
  setServiceAreaRadiusInMiles,
  inspectorLicenseHeld,
  setInspectorLicenseHeld,
  contractorLicenseHeld,
  setContractorLicenseHeld,
  realEstateLicenseHeld,
  setRealEstateLicenseHeld,
  showEmailError,
  setShowEmailError,
  showPhoneError,
  setShowPhoneError,
  showZipcodeError,
  setShowZipcodeError,
  formComplete,
  isMobile,
  calculateSliderWidth,
  addressRef,
  submitIntakeForm,
  loading,
}) => (
  <div className="backdrop">
    <div className="container">
      <p className="text__header">Sign up for Inspectify</p>
      <p className="text__instructions">
        After you fill out the form below, you&apos;ll be directed to create a
        password, login, and complete your Inspectify account.
      </p>
      <div className="contentContainer">
        <div className="userProfile">
          <div className="userProfile__section">
            <div className="userProfile__section__title">
              {BasicInformation.Title}
            </div>
          </div>
          <div className="userProfile__section--large">
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.FirstName}
                </div>
                <input
                  className="userProfile__section__inputRow__inputContainer__input"
                  type="text"
                  value={firstName}
                  name="first_name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.LastName}
                </div>
                <input
                  className="userProfile__section__inputRow__inputContainer__input"
                  type="text"
                  value={lastName}
                  name="last_name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.Phone}
                </div>
                <input
                  className={
                    showPhoneError
                      ? 'userProfile__section__inputRow__inputContainer__input--error'
                      : 'userProfile__section__inputRow__inputContainer__input'
                  }
                  type="tel"
                  value={formatPhoneNumber(phoneNumber)}
                  onBlur={
                      () => setShowPhoneError(phoneNumber.length > 0
                        && unformatPhoneNumber(phoneNumber).length < 10)
                    }
                  name="phone_number"
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {showPhoneError && (
                <div className="userProfile__section__inputRow__inputContainer__errorText">
                  {BasicInformation.PhoneError}
                </div>
                )}
              </div>
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.Email}
                </div>
                <input
                  className={
                      showEmailError
                        ? 'userProfile__section__inputRow__inputContainer__input--error'
                        : 'userProfile__section__inputRow__inputContainer__input'
                    }
                  type="email"
                  value={email}
                  required
                  onBlur={
                      () => setShowEmailError(email.length > 0
                        && !validateEmail(email))
                    }
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {showEmailError && (
                <div className="userProfile__section__inputRow__inputContainer__errorText">
                  {BasicInformation.EmailError}
                </div>
                )}
              </div>
            </div>
            { /* TODO GA9-1381: add address autocomplete */ }
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.Address}
                </div>
                <input
                  className="userProfile__section__inputRow__inputContainer__input"
                  type="text"
                  ref={addressRef}
                  value={streetAddress}
                  name="address"
                  onChange={(e) => setStreetAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.City}
                </div>
                <input
                  className="userProfile__section__inputRow__inputContainer__input"
                  type="text"
                  value={city}
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              {isMobile ? (
                <div className="stateZipcodeContainer">
                  <div className="userProfile__section__inputRow__inputContainer">
                    <div className="userProfile__section__inputRow__inputContainer__label">
                      <div className="userProfile__section__inputRow__inputContainer__label--required">
                        {BasicInformation.Required}
                      </div>
                      {BasicInformation.State}
                    </div>
                    <select
                      className="userProfile__section__inputRow__inputContainer__input"
                      value={state}
                      name="state"
                      onChange={(e) => setState(e.target.value as StateEnum | StateCodeEnum)}
                    >
                      <option key="blank">{' '}</option>
                      {Object.values(StateEnum).map((stateName: StateEnum | StateCodeEnum) => (
                        <option key={stateName}>{stateName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="userProfile__section__inputRow__inputContainer">
                    <div className="userProfile__section__inputRow__inputContainer__label">
                      <div className="userProfile__section__inputRow__inputContainer__label--required">
                        {BasicInformation.Required}
                      </div>
                      {BasicInformation.Zipcode}
                    </div>
                    <input
                      className={
                        showZipcodeError
                          ? 'userProfile__section__inputRow__inputContainer__input--error'
                          : 'userProfile__section__inputRow__inputContainer__input'
                      }
                      type="text"
                      value={zipcode}
                      name="zipcode"
                      onChange={(e) => setZipcode(e.target.value)}
                      onBlur={
                      () => setShowZipcodeError(zipcode.length > 0
                        && !validateZipcode(zipcode))
                    }
                    />
                    {showZipcodeError && (
                    <div className="userProfile__section__inputRow__inputContainer__errorText">
                      {BasicInformation.ZipcodeError}
                    </div>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="userProfile__section__inputRow__inputContainer">
                    <div className="userProfile__section__inputRow__inputContainer__label">
                      <div className="userProfile__section__inputRow__inputContainer__label--required">
                        {BasicInformation.Required}
                      </div>
                      {BasicInformation.State}
                    </div>
                    <select
                      className="userProfile__section__inputRow__inputContainer__input"
                      value={state}
                      name="state"
                      onChange={(e) => setState(e.target.value as StateEnum | StateCodeEnum)}
                    >
                      <option key="blank">{' '}</option>
                      {Object.values(StateEnum).map((stateName: StateEnum | StateCodeEnum) => (
                        <option key={stateName}>{stateName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="userProfile__section__inputRow__inputContainer">
                    <div className="userProfile__section__inputRow__inputContainer__label">
                      <div className="userProfile__section__inputRow__inputContainer__label--required">
                        {BasicInformation.Required}
                      </div>
                      {BasicInformation.Zipcode}
                    </div>
                    <input
                      className={
                        showZipcodeError
                          ? 'userProfile__section__inputRow__inputContainer__input--error'
                          : 'userProfile__section__inputRow__inputContainer__input'
                      }
                      type="text"
                      value={zipcode}
                      name="zipcode"
                      onChange={(e) => setZipcode(e.target.value)}
                      onBlur={
                      () => setShowZipcodeError(zipcode.length > 0
                        && !validateZipcode(zipcode))
                    }
                    />
                    {showZipcodeError && (
                    <div className="userProfile__section__inputRow__inputContainer__errorText">
                      {BasicInformation.ZipcodeError}
                    </div>
                    )}
                  </div>
                </>
              )}

            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="userProfile">
          <div className="userProfile__section">
            <div className="userProfile__section__title">
              {JobSpecificQuestions.Title}
            </div>
          </div>
          <div className="userProfile__section--large">
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {JobSpecificQuestions.Required}
                  </div>
                  {JobSpecificQuestions.Transportation}
                </div>
                <label htmlFor="transportation_yes" className="radioButtonContainer">
                  <input type="radio" id="transportation_yes" className="radioButton" name="transportation" checked={hasModeOfTransportation} onChange={(e) => setHasModeOfTransporation(e.target.checked)} />
                  <div className={hasModeOfTransportation ? 'responseTextSelected' : 'responseText'}>{JobSpecificQuestions.Yes}</div>
                </label>
                <label htmlFor="transportation_no" className="radioButtonContainer">
                  <input type="radio" id="transportation_no" className="radioButton" name="transportation" checked={hasModeOfTransportation === false} onChange={(e) => setHasModeOfTransporation(!e.target.checked)} />
                  <div className={hasModeOfTransportation === false ? 'responseTextSelected' : 'responseText'}>{JobSpecificQuestions.No}</div>
                </label>
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {JobSpecificQuestions.Required}
                  </div>
                  {JobSpecificQuestions.Smartphone}
                </div>
                <label htmlFor="phone_yes" className="radioButtonContainer">
                  <input type="radio" id="phone_yes" className="radioButton" name="smartphone" checked={hasReliablePhone} onChange={(e) => setHasReliablePhone(e.target.checked)} />
                  <div className={hasReliablePhone ? 'responseTextSelected' : 'responseText'}>{JobSpecificQuestions.Yes}</div>
                </label>
                <label htmlFor="phone_no" className="radioButtonContainer">
                  <input type="radio" id="phone_no" className="radioButton" name="smartphone" checked={hasReliablePhone === false} onChange={(e) => setHasReliablePhone(!e.target.checked)} />
                  <div className={hasReliablePhone === false ? 'responseTextSelected' : 'responseText'}>{JobSpecificQuestions.No}</div>
                </label>
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {JobSpecificQuestions.Required}
                  </div>
                  {JobSpecificQuestions.Sms}
                </div>
                <label htmlFor="sms_yes" className="radioButtonContainer">
                  <input type="radio" id="sms_yes" className="radioButton" name="sms" checked={sendAutomatedSms} onChange={(e) => setSendAutomatedSms(e.target.checked)} />
                  <div className={sendAutomatedSms ? 'responseTextSelected' : 'responseText'}>{JobSpecificQuestions.SmsYes}</div>
                </label>
                <label htmlFor="sms_no" className="radioButtonContainer">
                  <input type="radio" id="sms_no" className="radioButton" name="sms" checked={sendAutomatedSms === false} onChange={(e) => setSendAutomatedSms(!e.target.checked)} />
                  <div className={sendAutomatedSms === false ? 'responseTextSelected' : 'responseText'}>{JobSpecificQuestions.SmsNo}</div>
                </label>
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {JobSpecificQuestions.Required}
                  </div>
                  <div className="questionContainer">
                    <span className="questionText">{JobSpecificQuestions.Distance}</span>&nbsp;
                    <span className="questionTextSecondary">{JobSpecificQuestions.DistanceSelect}</span>
                  </div>
                </div>
                <div className="sliderContainer">
                  <div
                    id="handle_follower"
                    className="sliderTooltip"
                    style={{
                      position: 'absolute',
                      left: calculateSliderWidth(),
                      top: 5,
                    }}
                  >
                    {serviceAreaRadiusInMiles.toString().concat(' miles')}
                  </div>
                  <input
                    id="distance_slider"
                    type="range"
                    min={0}
                    max={200}
                    step={10}
                    value={serviceAreaRadiusInMiles}
                    onChange={(e) => setServiceAreaRadiusInMiles(parseInt(e.target.value, 10))}
                  />
                  <div className="sliderLabelContainer">
                    <div>{JobSpecificQuestions.DistanceMin}</div>
                    <div>{JobSpecificQuestions.DistanceMax}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="questionContainer">
                  <span className="questionText">{JobSpecificQuestions.License}</span>&nbsp;
                  <span className="questionTextSecondary">{JobSpecificQuestions.LicenseSelect}</span>
                </div>

                <Checkbox
                  isChecked={inspectorLicenseHeld}
                  toggle={() => setInspectorLicenseHeld(!inspectorLicenseHeld)}
                  label={JobSpecificQuestions.Inspector}
                />
                <Checkbox
                  isChecked={contractorLicenseHeld}
                  toggle={() => setContractorLicenseHeld(!contractorLicenseHeld)}
                  label={JobSpecificQuestions.Contractor}
                />
                <Checkbox
                  isChecked={realEstateLicenseHeld}
                  toggle={() => setRealEstateLicenseHeld(!realEstateLicenseHeld)}
                  label={JobSpecificQuestions.Realtor}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="buttonContainer">
          <Button
            onClick={() => submitIntakeForm()}
            disabled={loading || !formComplete()}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {loading ? <Spinner /> : 'Sign up'}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default OnboardingFormPage;
