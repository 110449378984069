import { CognitoUser } from '@aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormInput } from '../../components/FormElements';
import { signIn } from '../../utils/amplify_auth';
import {
  Background, LoadingContainer, Header, SubmitButton, ErrorMessage, ErrorSection,
} from './styles';

interface Props {
  email: string,
  onSignInSuccess: () => void,
  onTemporaryPasswordSuccess: (user: CognitoUser) => void,
}

const VerifyTempPasswordPage: React.FC<Props> = ({
  email, onSignInSuccess,
  onTemporaryPasswordSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleEnterTemporaryPassword = async () => {
    setIsLoading(true);
    try {
      await signIn(email.trim(), temporaryPassword,
        onSignInSuccess, onTemporaryPasswordSuccess);
    } catch (err: any) {
      setIsLoading(false);
      setErrorMessage(err.message);
    }
  };

  useEffect(() => () => {
    setIsLoading(false);
  }, []);

  return (
    <Background>
      <LoadingContainer isLoading={isLoading} style={{ height: 326 }}>
        <Header>{`Temporary password has been sent${email ? ` to\n${email}` : '.'}`}</Header>
        <form>
          <FormInput
            name="Temporary password"
            value={temporaryPassword}
            onChange={(e) => {
              setTemporaryPassword(e.target.value);
              if (errorMessage) setErrorMessage('');
            }}
            style={{ marginTop: 24, marginBottom: 12 }}
            indicateRequired={errorMessage !== ''}
          />
          <ErrorSection style={{ marginBottom: 16 }}>
            {errorMessage && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
          </ErrorSection>
          <SubmitButton
            type="submit"
            disabled={temporaryPassword === '' || isLoading}
            onClick={(e) => {
              e.preventDefault();
              handleEnterTemporaryPassword();
            }}
            style={{
              marginTop: 12,
            }}
          >
            {isLoading ? <Spinner animation="border" /> : 'Next'}
          </SubmitButton>
        </form>
      </LoadingContainer>
    </Background>
  );
};

export default VerifyTempPasswordPage;
