import { Report } from 'marketplace-common';

export const includesSowInspection = (report: Report) => Boolean(
  Object.values(report?.informations ?? {}).some(
    (info) => (info?.dataName).includes('sow_completed'),
  ),
);

export const includesDrawInspection = (report: Report) => Boolean(
  Object.values(report?.informations ?? {}).some(
    (info) => (info?.dataName).includes('division_progress'),
  ),
);
