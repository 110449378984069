import React from 'react';
import { formatMoney } from '../../../../utils/money';
import { DrawRowProps } from './types';
import styles from './styles.module.scss';

const DrawRow: React.FC<DrawRowProps> = ({
  data,
  displayWithTotal,
  displayFinancialColumns,
  displayUnitColumns,
}) => {
  const disbursement = (data?.budget && data?.disbursement !== null) ? `${formatMoney(Math.round(data.disbursement * 100) / 100)}` : '$ - ';
  const remaining = (data?.budget && data?.remaining !== null) ? `${formatMoney(Math.round(data.remaining * 100) / 100)}` : '$ - ';
  const progress = data?.progress ? `${(Math.round(data.progress))}%` : '0%';
  const budget = data?.budget !== null ? `${formatMoney(Math.round(data.budget * 100) / 100)}` : '$ - ';

  const isTotalRow = data?.division === 'Total';
  const firstColumnStyle = !isTotalRow ? styles.dataCell : styles.totalDataCell;
  const descriptionStyle = styles.dataCell;
  const cellFontStyle = !isTotalRow ? styles.dataCellNoWrap : styles.totalDataCell;

  return (
    <tr key={data.id}>
      <td className={firstColumnStyle}>{data.division}</td>
      <td className={descriptionStyle}>{data.description}</td>
      {displayUnitColumns ? <td className={cellFontStyle}>{data.unit}</td> : null}
      {displayFinancialColumns ? <td className={cellFontStyle}>{budget}</td> : null}
      {displayFinancialColumns && displayWithTotal
        ? <td className={cellFontStyle}>{disbursement}</td> : null}
      <td className={cellFontStyle}>{progress}</td>
      {displayFinancialColumns
        ? <td className={cellFontStyle}>{remaining}</td> : null}
    </tr>
  );
};

export default DrawRow;
