import React, {
  useContext, useEffect, useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { Spinner } from 'react-bootstrap';
import { captureException } from '@sentry/react';
import {
  BottomContainer, Label, MsaIframe, CheckboxDiv, ErrorDiv, DownloadLink, DownloadIcon,
} from './styles';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import { AGREE_MSA } from '../../../graphql/mutations';
import { InspectorDashboardContext } from '../context';
import { DashboardPageTab } from '../types';
import Download from '../../../assets/icons/download.svg';

interface Props {
  refetchContractor: () => void,
}

const MsaReviewLoader: React.FC<Props> = ({ refetchContractor }) => {
  const { state, setPage } = useContext(InspectorDashboardContext);
  const [isChecked, setIsChecked] = useState(false);

  const [
    agreeMsa, { loading, error },
  ] = useMutation(
    AGREE_MSA, {
      onCompleted: () => {
        refetchContractor();

        if (!state.contractor.tipaltiIsPayable) {
          setPage(DashboardPageTab.TipaltiSetupProcess);
        } else {
          setPage(DashboardPageTab.Jobs);
        }
      },
    },
  );

  useEffect(() => {
    if (error) {
      captureException(error);
    }
  }, [error]);

  const submit = () => {
    agreeMsa({
      variables: {
        input: { contractorId: state.contractor.id },
      },
    });
  };

  return (
    <>
      <MsaIframe src={`${process.env.MARKETPLACE_URL}/contractors/${state.contractor.token}/msa`} />

      <BottomContainer>
        <DownloadLink
          href={`${process.env.MARKETPLACE_URL}/contractors/${state.contractor.token}/msa_download`}
          target="_blank"
          rel="noreferrer"
        >
          <DownloadIcon src={Download} alt="download" />
        </DownloadLink>
        <CheckboxDiv onClick={() => { setIsChecked(!isChecked); }}>
          <Checkbox isChecked={isChecked} toggle={() => { }} />
          <Label>
            I have read and agree to the Master Inspection And Services Agreement.
          </Label>
        </CheckboxDiv>
        <div>
          <Button
            type="button"
            disabled={!isChecked || loading}
            onClick={submit}
          >
            {loading ? <Spinner animation="border" /> : 'Submit'}
          </Button>
        </div>
        <ErrorDiv>
          {error && (
            <>
              There was an issue submitting your request.
              Please try again or contact customer service for support.
            </>
          )}
        </ErrorDiv>
      </BottomContainer>
    </>
  );
};

export default MsaReviewLoader;
