import { IdRef, Property } from 'marketplace-common';
import {
  ADD_PROPERTY,
  GET_PROPERTY,
  PUT_PROPERTY,
} from './types';
import { ApiAction } from './api';
import { captureException } from '../../utils/error';

export const addProperty = (
  property: Property,
) => (dispatch: any) => {
  dispatch({
    type: ADD_PROPERTY,
    payload: { property },
  });
};

export const addPropertyWithOrderToken = (
  property: Property,
  orderToken: string,
) => (dispatch: any) => {
  dispatch({
    type: ADD_PROPERTY,
    payload: { property, orderToken },
  });
};

export interface GetPropertyArgs {
  propertyId: IdRef,
  orderToken: string,
}

export const getProperty = ({
  propertyId,
  orderToken,
}: GetPropertyArgs) => ApiAction({
  method: 'GET',
  url: `/properties/${propertyId}`,
  data: {
    token: orderToken,
  },
  onSuccess: (property) => addPropertyWithOrderToken(property, orderToken),
  onFailure: (error) => {
    captureException(error);
    throw error.response;
  },
  label: GET_PROPERTY,
});

export interface PropertyDetails {
  home_type?: string,
  bedrooms?: number,
  bathrooms?: number,
  year_built?: number,
  total_square_feet?: string,
}

export interface PutPropertyArgs {
  propertyId: IdRef,
  orderToken: string,
  details: PropertyDetails,
}

export const putProperty = ({
  propertyId,
  orderToken,
  details,
}: PutPropertyArgs) => ApiAction({
  method: 'PUT',
  url: `/properties/${propertyId}`,
  data: {
    token: orderToken,
    ...details,
  },
  onSuccess: (property) => addPropertyWithOrderToken(property, orderToken),
  onFailure: (error) => {
    captureException(error);
    throw error.response;
  },
  label: PUT_PROPERTY,
});
