import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetLayout, setDisplayNavbar, setLayoutCobrandedLogo, setLayoutFadedFooter,
  setLayoutHideHeaderOnMobile, setLayoutIsLoading,
  setLayoutShowFooter,
  setLayoutShowSignIn,
} from '../../redux/actions';
import { InspectifyLayoutProps } from './types';

const InspectifyLayout: React.FC<InspectifyLayoutProps> = ({
  isLoading, displayNavBar, layoutCobrandedLogoSrc, fadedFooter,
  showFooter, showSignIn, hideHeaderOnMobile,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading !== undefined) {
      dispatch(setLayoutIsLoading(isLoading));
    }
  }, [isLoading]);

  useEffect(() => {
    if (displayNavBar !== undefined) {
      dispatch(setDisplayNavbar(displayNavBar));
    }
  }, [displayNavBar]);

  useEffect(() => {
    if (layoutCobrandedLogoSrc !== undefined) {
      dispatch(setLayoutCobrandedLogo(layoutCobrandedLogoSrc));
    }
  }, [layoutCobrandedLogoSrc]);

  useEffect(() => {
    if (fadedFooter !== undefined) {
      dispatch(setLayoutFadedFooter(fadedFooter));
    }
  }, [fadedFooter]);

  useEffect(() => {
    if (showFooter !== undefined) {
      dispatch(setLayoutShowFooter(showFooter));
    }
  }, [showFooter]);

  useEffect(() => {
    if (showSignIn !== undefined) {
      dispatch(setLayoutShowSignIn(showSignIn));
    }
  }, [showSignIn]);

  useEffect(() => {
    if (hideHeaderOnMobile !== undefined) {
      dispatch(setLayoutHideHeaderOnMobile(hideHeaderOnMobile));
    }
  }, [hideHeaderOnMobile]);

  // Unmount
  useEffect(() => () => {
    dispatch(resetLayout());
  }, []);

  return (<></>);
};

export default InspectifyLayout;
