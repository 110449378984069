import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  MdZoomIn,
  MdZoomOut,
  MdAddPhotoAlternate,
} from 'react-icons/md';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { colorBaseWhitePrimary } from 'haven-design-system/build/typescript/es6';
import IssueBadge from '../../../../Reports/components/IssueBadge';
import styles from '../../../../Reports/components/styles.module.scss';
import {
  LeftContainer,
  AttachmentImage,
  AttachmentContainer,
  MediaControlsContainer,
  MediaControlButton,
  ZoomContainer,
  SystemInformationsContainer,
  InformationsContainer,
  Information,
  ArrowContainer,
  ArrowButton,
  CarouselContainer,
  CarouselImage,
  CarouselVideo,
  NoMediaText,
  NotesContainer,
  DefLimTitle,
  NotesDetails,
  SectionBadgeContainer,
  NotesNote,
  MediaContainer,
  AddMediaContainer,
} from './styles';
import { RehabToolContext } from '../../../context';
import { ModalInformations } from '../../../types';

const LeftPanel: React.FC = () => {
  const { state, set } = useContext(RehabToolContext);

  const zoomRef = useRef<ReactZoomPanPinchRef>(null);
  const sliderRef = useRef<HTMLInputElement | null>(null);
  const [sliderValue, setSliderValue] = useState(1);
  const [currentAttachmentIndex, privateSetCurrentAttachmentIndex] = useState(0);
  // Accessing React State in Event Listeners
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const attachmentIndexRef = useRef(currentAttachmentIndex);

  const setCurrentAttachment = (newIndex: number) => {
    if (newIndex < 0) {
      attachmentIndexRef.current = newIndex % state.workItemModalAttachments.length;
    } else {
      attachmentIndexRef.current = newIndex;
    }

    privateSetCurrentAttachmentIndex(attachmentIndexRef.current);
  };

  const incrementCurrentAttachment = (incrementBy: number) => {
    const newIndex = (
      (attachmentIndexRef.current + incrementBy) % state.workItemModalAttachments.length
    );
    if (newIndex < 0) {
      attachmentIndexRef.current = state.workItemModalAttachments.length - 1;
    } else {
      attachmentIndexRef.current = newIndex;
    }

    privateSetCurrentAttachmentIndex(attachmentIndexRef.current);
  };

  const arrowHandler = (event: KeyboardEvent) => {
    const { key } = event;
    switch (key) {
      case 'ArrowLeft':
        incrementCurrentAttachment(-1);
        break;
      case 'ArrowRight':
        incrementCurrentAttachment(1);
        break;
      default:
        break;
    }
  };

  const ZOOM_SCALE_MIN = 1; // Default min,max scale for react-zoom-pan-pinch
  const ZOOM_SCALE_MAX = 8; // https://prc5.github.io/react-zoom-pan-pinch/?path=/story/docs-props--page

  const onZoomIn = () => {
    zoomRef.current.zoomIn(0.3);
    setSliderValue((prev) => Math.min(prev + 1, ZOOM_SCALE_MAX));
  };

  const onZoomOut = () => {
    zoomRef.current.zoomOut(0.3);
    setSliderValue((prev) => Math.max(prev - 1, ZOOM_SCALE_MIN));
  };

  const onSliderInput = () => {
    const { value } = sliderRef.current;
    const sliderValueInt = parseInt(value, 10);
    setSliderValue(sliderValueInt);
    zoomRef.current.centerView(sliderValueInt);
  };

  useEffect(() => {
    if (state.isWorkItemModalOpen) {
      setSliderValue(1);
      document.addEventListener('keydown', arrowHandler);
    }
    return () => document.removeEventListener('keydown', arrowHandler);
  }, [state.isWorkItemModalOpen]);

  return (
    <LeftContainer>
      <MediaContainer>
        {state.workItemModalAttachments[currentAttachmentIndex] === undefined ? (
          <>
            <MediaControlsContainer>
              <AddMediaContainer>
                <MediaControlButton
                  type="button"
                  onClick={() => set('isUploadMediaModalOpen', true)}
                  aria-label="addMedia"
                >
                  <MdAddPhotoAlternate size={24} color={colorBaseWhitePrimary} />
                </MediaControlButton>
                {/* <MediaControlButton
                  type="button"
                  onClick={() => {}}
                  aria-label="deleteMedia"
                >
                  <HiTrash size={24} color={colorBaseWhitePrimary} />
                </MediaControlButton> */}
              </AddMediaContainer>
            </MediaControlsContainer>
            <NoMediaText>No media available</NoMediaText>
          </>
        ) : (
          <>
            <MediaControlsContainer>
              {state.workItemModalAttachments[currentAttachmentIndex]
                && state.workItemModalAttachments[currentAttachmentIndex].s3ObjectKey
                && (
                  <ZoomContainer>
                    {!state.workItemModalAttachments[currentAttachmentIndex].s3ObjectKey.includes('mp4') && (
                      <>
                        <MediaControlButton
                          type="button"
                          onClick={onZoomOut}
                          aria-label="zoomIn"
                        >
                          <MdZoomOut size={24} color={colorBaseWhitePrimary} />
                        </MediaControlButton>
                        <input
                          ref={sliderRef}
                          type="range"
                          min={ZOOM_SCALE_MIN}
                          max={ZOOM_SCALE_MAX}
                          value={sliderValue}
                          className={styles.slider}
                          onInput={onSliderInput}
                        />
                        <MediaControlButton
                          type="button"
                          onClick={onZoomIn}
                          aria-label="zoomOut"
                        >
                          <MdZoomIn size={24} color={colorBaseWhitePrimary} />
                        </MediaControlButton>
                        <div className={styles.zoomPercentageContainer}>
                          <p className={styles.zoomPercentageLabel}>
                            {(sliderValue * 100).toFixed(0)}%
                          </p>
                        </div>
                      </>
                    )}
                    <AddMediaContainer>
                      <MediaControlButton
                        type="button"
                        onClick={() => set('isUploadMediaModalOpen', true)}
                        aria-label="addMedia"
                      >
                        <MdAddPhotoAlternate size={24} color={colorBaseWhitePrimary} />
                      </MediaControlButton>
                      {/* <MediaControlButton
                        type="button"
                        onClick={() => {}}
                        aria-label="deleteMedia"
                      >
                        <HiTrash size={24} color={colorBaseWhitePrimary} />
                      </MediaControlButton> */}
                    </AddMediaContainer>
                  </ZoomContainer>
                )}
            </MediaControlsContainer>
            <AttachmentContainer>
              <>
                {state.workItemModalAttachments[currentAttachmentIndex]
                && state.workItemModalAttachments[currentAttachmentIndex].s3ObjectKey
                && state.workItemModalAttachments[currentAttachmentIndex].s3ObjectKey.includes('mp4') ? (
                  <video
                    src={state.workItemModalAttachments[currentAttachmentIndex].attachmentUrl}
                    className={styles.modalMedia}
                    controls
                    data-testid="rehabVideo"
                  />
                  ) : (
                    <TransformWrapper
                      ref={zoomRef}
                      doubleClick={{ disabled: true }}
                      wheel={{ step: 0.9 }}
                      onWheel={(e) => {
                        setSliderValue(e.state.scale);
                      }}
                    >
                      <TransformComponent
                        wrapperStyle={{ height: '100%', width: '100%' }}
                        contentStyle={{ height: '100%', width: '100%' }}
                      >
                        <AttachmentImage
                          src={
                            state.workItemModalAttachments[currentAttachmentIndex]
                            && state.workItemModalAttachments[currentAttachmentIndex].attachmentUrl
                          }
                          alt="work item image"
                          data-testid="rehabImage"
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  )}
                {state.workItemModalAttachments.length > 1 && (
                <ArrowContainer>
                  {currentAttachmentIndex !== 0 && (
                  <ArrowButton
                    style={{ left: 0 }}
                    type="button"
                    onClick={() => { incrementCurrentAttachment(-1); }}
                  >
                    &#10094;
                  </ArrowButton>
                  )}
                  {currentAttachmentIndex !== state.workItemModalAttachments.length - 1 && (
                  <ArrowButton
                    style={{ right: '50%' }}
                    type="button"
                    onClick={() => { incrementCurrentAttachment(1); }}
                    data-testid="nextMedia"
                  >
                    &#10095;
                  </ArrowButton>
                  )}
                </ArrowContainer>
                )}
              </>
            </AttachmentContainer>
            <CarouselContainer>
              {(state.workItemModalAttachments as any).map(
                (att: any, index: number) => (
                  (att?.s3ObjectKey || '').includes('mp4') ? (
                    <CarouselVideo
                      title="carousel video"
                      src={`${att.attachmentUrl}#t=0.01`}
                      isSelected={index === currentAttachmentIndex}
                      onClick={() => setCurrentAttachment(index)}
                      key={att.id}
                      data-testid="rehabCarouselVideo"
                    />
                  ) : (
                    <CarouselImage
                      src={att.attachmentUrl}
                      alt="carousel image"
                      isSelected={index === currentAttachmentIndex}
                      onClick={() => setCurrentAttachment(index)}
                      key={att.id}
                      data-testid="rehabCarouselImage"
                    />
                  )
                ),
              )}
            </CarouselContainer>
          </>
        )}
      </MediaContainer>
      <SystemInformationsContainer data-testid="rehabInformationContainer">
        <SectionBadgeContainer>
          <p>{state.modalSystemName}<b>{state.modalSubsystemName && ` / ${state.modalSubsystemName}`}</b></p>
          {state.isLimitation && <IssueBadge type="limitation" />}
          {state.modalBadgeSeverity && <IssueBadge type={state.modalBadgeSeverity} />}
        </SectionBadgeContainer>
        {state.modalInformations && state.modalInformations.length > 0 ? (
          <>
            <InformationsContainer>
              {(state.modalInformations as ModalInformations).map(
                ({ label, value }) => (
                  <React.Fragment
                    key={label + value}
                  >
                    <Information>
                      {label}
                    </Information>
                    <Information isBold>
                      {value}
                    </Information>
                  </React.Fragment>
                ),
              )}
            </InformationsContainer>
          </>
        ) : (
          <NotesContainer>
            {state.modalNoteTitle && <DefLimTitle>{state.modalNoteTitle}</DefLimTitle>}
            {state.modalNoteDetails && <NotesDetails>{state.modalNoteDetails}</NotesDetails>}
          </NotesContainer>
        )}
        {state.modalNote && <NotesNote>Note: {state.modalNote}</NotesNote>}
      </SystemInformationsContainer>
    </LeftContainer>
  );
};

export default LeftPanel;
