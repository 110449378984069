import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  normalizeGraphqlReportResponse,
  Report,
  ReportNested,
  getCurrentUnixTimestamp,
  ReportAttachment,
  IdRef,
} from 'marketplace-common';
import { ADD_REPORT, ADD_REPORT_ATTACHMENT } from './types';
import { captureException } from '../../utils/error';

export const addReport = (report: Report) => (dispatch: any) => {
  dispatch({
    type: ADD_REPORT,
    payload: { report },
  });
};

export const addReportAttachment = (
  reportId: IdRef,
  attachment: ReportAttachment,
) => (dispatch: any) => {
  dispatch({ type: ADD_REPORT_ATTACHMENT, payload: { reportId, attachment } });
};

export const saveReport = (data: any) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
) : Promise<any> => {
  const timestamp = getCurrentUnixTimestamp();
  const normalizedReport = normalizeGraphqlReportResponse(data.report as ReportNested, {
    existingAttachments: {},
    captureException,
  });

  if (normalizedReport) {
    const report: Report = {
      ...normalizedReport,
      deletions: [],
      updatedAt: timestamp,
      lastSync: timestamp,
    };

    return dispatch(addReport(report));
  }

  return Promise.resolve();
};
