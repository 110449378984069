import styled from 'styled-components';
import {
  colorBaseWhitePrimary,
} from 'haven-design-system/build/typescript/es6';

export const Container = styled.div`
  padding: 48px;
  background-color: ${colorBaseWhitePrimary};
  page-break-after: always;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 48px;
  page-break-after: always;
`;

export const TableWithoutBreak = styled.table`
  width: 100%;
  border-spacing: 48px;
  margin: 24px 0px;
`;

export const TableHead = styled.thead`
  width: 100%;
`;

export const TableBody = styled.tbody`
  width: 100%;
`;

export const TableRow = styled.tr<{ backgroundColor?: string; }>`
  width: 100%;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
`;
