import { useState } from 'react';
import { Auth, Storage } from 'aws-amplify';
import uuid from 'react-uuid';
import { captureException } from '../../utils/error';

export interface UploadedMedia {
  id: string,
  fileName: string,
  updatedAt: Date,
  progress: number,
  error?: string,
  signedUrl?: string,
  s3ObjectKey?: string,
  contentType?: 'photo' | 'video' | 'pdf',
}

export interface UploadsInterface {
  [name: string]: UploadedMedia,
}

export const useUpload = (level: 'public' | 'private' = 'public', addIdentifier = false) => {
  const [uploads, setUploads] = useState<UploadsInterface>({});
  const [error, setError] = useState(false);
  const [remainingCount, setRemainingCount] = useState(0);

  const getContentType = (file: File) => {
    if (file.type?.includes('video')) return 'video';
    if (file.type?.includes('pdf')) return 'pdf';
    return 'photo';
  };

  const upload = async (files: File[], shouldGetSignedUrl = false) => {
    const credentials = await Auth.currentUserCredentials();

    if (!credentials.identityId) throw new Error('UploadError - Could not get credentials');

    setRemainingCount(files.length);

    for (let i = 0; i < files.length; i += 1) {
      const id = uuid();
      const file = files[i];
      const fileName = addIdentifier ? `${id}-${file.name}` : file.name;
      const contentType = getContentType(file);
      setUploads((cur) => ({
        ...cur,
        [fileName]: {
          id,
          fileName,
          updatedAt: new Date(),
          progress: 0,
          contentType,
        },
      }));

      try {
        // eslint-disable-next-line no-await-in-loop
        const result: any = await Storage.put(fileName, file, {
          level,
          contentType: file.type,
          progressCallback(progress: { loaded: number, total: number }) {
            setUploads((cur) => ({
              ...cur,
              [fileName]: {
                id,
                fileName,
                contentType,
                updatedAt: new Date(),
                progress: (progress.loaded * 100) / progress.total,
              },
            }));
          },
        });

        const s3ObjectKey = `private/${credentials.identityId}/${result.key}`;

        const signedUrl = shouldGetSignedUrl
          // eslint-disable-next-line no-await-in-loop
          ? await Storage.get(fileName, { level, expires: 60 }) as string
          : undefined;

        setUploads((cur) => ({
          ...cur,
          [fileName]: {
            id,
            fileName,
            contentType,
            updatedAt: new Date(),
            progress: 100,
            s3ObjectKey,
            signedUrl,
          },
        }));
      } catch (e: any) {
        setUploads((cur) => ({
          ...cur,
          [fileName]: {
            id,
            fileName,
            contentType,
            updatedAt: new Date(),
            progress: 0,
            error: e.toString(),
          },
        }));

        setError(true);
        captureException(e);
      }

      setRemainingCount(files.length - i - 1);
    }
  };

  return {
    upload,
    uploads,
    setUploads,
    remainingCount,
    error,
    setError,
  };
};
