import { normalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import {
  getCurrentUnixTimestamp,
  Works,
} from 'marketplace-common';

const PermitSchema = new schema.Entity('permits');

const PropertySchema = new schema.Entity('properties', {
  permits: [PermitSchema],
});

const InspectionWarrantySchema = new schema.Entity('inspectionWarranties');

const InspectionProposalSchema = new schema.Entity('inspectionProposals');

const ReportSystemSchema = new schema.Entity('reportSystems');

const ReportSchema = new schema.Entity('reports', {
  reportSystems: [ReportSystemSchema],
});

const InspectionReportSchema = new schema.Entity('inspectionReports', {
  report: ReportSchema,
});

const OrderStakeholderSchema = new schema.Entity('orderStakeholders');

const OrderUnitsSchema = new schema.Entity('orderUnits');

const InspectionSchema = new schema.Entity('inspections', {
  inspectionWarranty: InspectionWarrantySchema,
  scheduledProposal: InspectionProposalSchema,
  inspectionReports: [InspectionReportSchema],
});

const OrderSchema = new schema.Entity('orders', {
  property: PropertySchema,
  inspections: [InspectionSchema],
  orderStakeholders: [OrderStakeholderSchema],
  orderUnits: [OrderUnitsSchema],
});

const DataSchema = new schema.Entity('data', {
  orders: [OrderSchema],
});

interface Options {
  previousWorks?: Works,
  captureException?: (error: string, data?: any) => void;
}

export const normalizeGraphqlOrderByTokenResponse = (
  response: any,
  {
    previousWorks = {
      jobs: {},
      bundleOffers: {},
      reports: {},
      orders: {},
      properties: {},
      permits: {},
      inspections: {},
      inspectionProposals: {},
      orderStakeholders: {},
      orderUnits: {},
      inspectionWarranties: {},
      lastSync: null,
      lastFilters: [],
    },
    captureException = () => {},
  }: Options,
): Works | null => {
  if (!response.order) return null;

  const { entities } = normalize(camelizeKeys({ orders: [response.order] }), DataSchema);

  const {
    orders,
    properties,
    permits,
    orderStakeholders,
    orderUnits,
    inspections,
    inspectionWarranties,
    inspectionProposals,
    inspectionReports,
    reports: rReports,
  } = entities;

  if (
    orders === undefined
    || properties === undefined
    || inspections === undefined
    // || inspectionProposals === undefined
  ) {
    captureException(
      'Orders normalized doesn\'t have every required entities:',
      { entities },
    );

    return null;
  }

  const works: Works = {
    jobs: previousWorks.jobs,
    bundleOffers: {},
    orders: Object.assign(
      previousWorks.orders,
      ...Object.keys(orders).map((orderId) => {
        const {
          property: propertyId,
          inspections: inspectionIds,
          orderStakeholders: orderStakeholderIds,
          orderUnits: orderUnitIds,
          ...stateOrder
        } = orders[orderId];
        return (
          {
            [stateOrder.id]: {
              ...stateOrder,
              propertyId,
              inspectionIds,
              orderStakeholderIds,
              orderUnitIds,
            },
          }
        );
      }),
    ),
    inspections: Object.assign(
      previousWorks.inspections,
      ...Object.keys(inspections).map((inspectionId) => {
        const {
          scheduledProposal: scheduledProposalId,
          inspectionWarranty: inspectionWarrantyId,
          inspectionReports: inspectionReportIds,
          ...inspection
        } = inspections[inspectionId];

        const reportIds = inspectionReportIds.reduce(
          (acc: string[], id: string) => (
            inspectionReports[id].report ? [...acc, inspectionReports[id].report] : acc
          ), [],
        );

        return (
          {
            [inspection.id]: {
              ...inspection,
              reportIds,
              scheduledProposalId,
              inspectionWarrantyId,
            },
          }
        );
      }),
    ),
    reports: Object.assign(
      previousWorks.reports,
      ...Object.keys(rReports || {}).map((reportId) => {
        const {
          reportSystems: reportSystemIds,
          inspectionReports: iRs,
          reportSystemGroups: rSGs,
          ...report
        } = rReports?.[reportId];

        const inspectionIds = iRs?.reduce((acc: string[], inspectionReport: any) => (
          inspectionReport?.inspection?.id ? [...acc, inspectionReport.inspection.id] : acc
        ), []) || [];

        const reportSystemGroupIds = rSGs?.reduce((acc: string[], reportSystemGroup: any) => (
          [...acc, reportSystemGroup.id]
        ), []) || [];

        return (
          {
            [reportId]: {
              ...report,
              inspectionIds,
              reportSystemIds,
              reportSystemGroupIds,
            },
          }
        );
      }),
    ),
    inspectionProposals: Object.assign(
      previousWorks.inspectionProposals,
      ...Object.keys(inspectionProposals || {}).map((id) => ({
        [id]: inspectionProposals[id],
      })),
    ),
    inspectionWarranties: Object.assign(
      (previousWorks?.inspectionWarranties || {}),
      (inspectionWarranties || {}),
    ),
    properties: Object.assign(
      previousWorks.properties,
      ...Object.keys(properties).map((id) => {
        const {
          photo,
          permits: propertyPermits,
          ...property
        } = properties[id];
        return {
          [id]: {
            ...property,
            permitIds: propertyPermits,
            photo: {
              url: photo?.cdnUrl || null,
            },
            localPhotoUrl: null,
            localAssetId: null,
            localAlbumId: null,
          },
        };
      }),
    ),
    permits: Object.assign(
      (previousWorks?.permits || {}),
      (permits || {}),
    ),
    orderStakeholders: Object.assign(
      previousWorks.orderStakeholders,
      ...Object.keys(orderStakeholders || {}).map((id) => ({
        [id]: orderStakeholders[id],
      })),
    ),
    orderUnits: Object.assign(
      previousWorks.orderUnits,
      ...Object.keys(orderUnits || {}).map((id) => ({
        [id]: orderUnits[id],
      })),
    ),
    lastSync: getCurrentUnixTimestamp(),
    commonlyUsedDeficiencies: undefined,
    lastCompletedCursor: '',
    lastFilters: [],
    lastSearchQuery: '',
    lastUpcomingCursor: '',
  };

  return works;
};
