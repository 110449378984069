// eslint-disable-next-line import/prefer-default-export
export enum HttpStatusCode {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  ServiceUnavailable = 503,
}

export enum SECTIONS {
  'Roof' = 'Roof',
  'Exterior' = 'Exterior',
  'Basement, Foundation, Crawlspace & Structure' = 'Basement, Foundation, Crawlspace & Structure',
  'HVAC' = 'HVAC',
  'Plumbing' = 'Plumbing',
  'Electrical' = 'Electrical',
  'Fireplace' = 'Fireplace',
  'Attic, Insulation & Ventilation' = 'Attic, Insulation & Ventilation',
  'Doors, Windows & Interior' = 'Doors, Windows & Interior',
  'Built-In Appliance' = 'Built-In Appliance',
  'Garage' = 'Garage',
}
