export const NON_FINANCIAL_COLUMNS = ['Division', 'Description', 'Progress'];
export const NON_FINANCIAL_COLUMNS_WITH_UNITS = ['Division', 'Description', 'Unit', 'Progress'];
export const FINANCIAL_COLUMNS = ['Division', 'Description', 'Budget', 'Progress', 'Remaining'];
export const FINANCIAL_COLUMNS_WITH_UNITS = ['Division', 'Description', 'Unit', 'Budget', 'Progress', 'Remaining'];
export const FINANCIAL_COLUMNS_WITH_DISBURSEMENT = ['Division', 'Description', 'Budget', 'Disbursement', 'Progress', 'Remaining'];
export const FINANCIAL_COLUMNS_WITH_UNITS_AND_DISBURSEMENT = ['Division', 'Description', 'Unit', 'Budget', 'Disbursement', 'Progress', 'Remaining'];

export interface DrawInspectionBudgetSummaryProps {
  displayWithTotal: boolean,
}

export interface DrawRowProps {
  data: ReportInformationRowData
  displayWithTotal: boolean,
  displayFinancialColumns: boolean,
  displayUnitColumns: boolean,
}

export interface DrawTotalRowProps {
  data: ReportInformationRowData[]
  displayWithTotal: boolean,
  displayFinancialColumns: boolean,
  displayUnitColumns: boolean,
}

export interface ReportInformationRowData {
  id?: string;
  division: string;
  description: string;
  budget: number | null;
  progress: number;
  disbursement: number;
  remaining: number;
  unit?: string;
  total?: number;
}
