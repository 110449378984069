import { useLazyQuery } from '@apollo/client';
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  getClient,
  getInspectionTemplateIds,
  getInspectionTemplates,
  getInspectionTypes,
  getIsPaid,
  getJobStatus,
  getPay,
  getProposals,
  InspectionDocument,
  InspectionProposal,
  Job,
  JobStatus,
  WorksOrderStakeholder,
  LocalInspection,
} from 'marketplace-common';
import { camelizeKeys } from 'humps';
import { InspectorDashboardContext } from '../../context';
import { DEPRECATED } from '../../../../graphql/createApolloClient';
import { INSPECTION_DOCUMENTS } from '../../../../graphql/queries/inspectionDocument';
import { getFormattedDate, getFormattedDateTime } from '../../../../utils/date';
import { addInspectionUrl, uploadInspectionFile } from '../../../../utils/file_upload';
import JobDetails from './JobDetails';
import {
  fetchTemplate,
  updateInspectionsForWorks,
} from '../../../../redux/actions';
import { captureException } from '../../../../utils/error';
import { DashboardPageTab } from '../../types';
import { EventName, track } from '../../../../utils/analytics';
import { UserContractor } from '../../../../types';
import { useTypedSelector } from '../../../../redux/store';
import { selectTemplates, selectWorks } from '../../../../redux/selectors';

interface Props {
  user: UserContractor,
  orderId: number;
  job: Job;
  reportUpload: boolean | null;
}

const JobDetailsLoader: React.FC<Props> = ({
  user,
  orderId,
  job,
  reportUpload,
}) => {
  const works = useTypedSelector(selectWorks);
  const templates = useTypedSelector(selectTemplates);
  const { state: contextState, setPage } = useContext(InspectorDashboardContext);
  const { contractor } = contextState;
  const dispatch = useDispatch();

  const {
    orderStakeholders,
    inspectionProposals,
    inspections,
    orders,
    properties,
    reports: reportRecords,
  } = works;

  const order = useMemo(() => orders[orderId] || null, [orders, orderId]);
  const property = useMemo(() => (
    (order?.propertyId && properties ? properties[order?.propertyId] : null) || null
  ), [properties, order?.propertyId]);

  const [jobStatus, setJobStatus] = useState<JobStatus>(null);
  const [accessInformation, setAccessInformation] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [inspectionTypes, setInspectionTypes] = useState('');
  const [proposals, setProposals] = useState([] as InspectionProposal[]);
  const [pay, setPay] = useState(0);
  const [isPaid, setIsPaid] = useState(false);
  const [client, setClient] = useState(null as WorksOrderStakeholder | null);
  const [allInspections, setAllInspections] = useState([] as LocalInspection[]);
  const [useSpectora, setUseSpectora] = useState(false);
  const [mainText, setMainText] = useState('');
  const [subText, setSubText] = useState('');
  const [selectedInspection, setSelectedInspection] = useState(null as LocalInspection | null);
  const [otherSelectedInspections, setOtherSelectedInspections] = useState([] as LocalInspection[]);
  const [fileForUpload, setFileForUpload] = useState(null as File);
  const [urlForUpload, setUrlForUpload] = useState('');
  const [error, setError] = useState('');
  const [inspectionDocuments, setInspectionDocuments] = useState([] as InspectionDocument[]);
  const [templateNames, setTemplateNames] = useState(null);
  const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const [scheduledTime, setScheduledTime] = useState('');

  const [fetchDocuments, { loading: loadingDocuments, data: documents }] = useLazyQuery(
    INSPECTION_DOCUMENTS, {
      context: { DEPRECATED },
      variables: {
        inspectionIds: allInspections.map((inspection) => parseInt(inspection.id.toString(), 10)),
      },
    },
  );

  const upload = async () => {
    const inspectionsForUpload = [...otherSelectedInspections, selectedInspection];
    const inspectionIds = inspectionsForUpload.map((ins) => ins.id);

    try {
      let ret: any;
      if (fileForUpload) {
        ret = await uploadInspectionFile(fileForUpload, inspectionIds);
      } else {
        ret = await addInspectionUrl(urlForUpload, inspectionIds);
      }

      const formattedDocumentObj = camelizeKeys(ret) as unknown as InspectionDocument;
      setInspectionDocuments([...inspectionDocuments, formattedDocumentObj]);

      const updatedInspections = inspectionsForUpload.map(
        (ins) => ({ ...ins, status: 'completed' }),
      );

      track(EventName.InspectorUploadedFile, {
        contractorId: contractor?.id,
        category: fileForUpload ? 'report' : 'report_url',
        inspectionIds,
      });

      dispatch(updateInspectionsForWorks(updatedInspections));
    } catch (uploadError: any) {
      if (uploadError instanceof Error) {
        setError(`Error: ${uploadError.message}`);
      } else {
        setError('Something went wrong, please contact customer support.');
      }
    }
  };

  const fetchTemplates = async () => {
    const ids = getInspectionTemplateIds(inspections, job.inspectionIds);
    const promises = ids.map((id) => dispatch(fetchTemplate(id)));
    try {
      await Promise.all(promises);
    } catch (e) {
      captureException(e);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    if (reportUpload && allInspections && allInspections.length > 0) {
      setSelectedInspection(allInspections[0]);
    }
  }, [reportUpload, allInspections]);

  useEffect(() => {
    if (inspections && job?.inspectionIds?.length && !loadingTemplates && templateNames === null) {
      setLoadingTemplates(true);
      fetchTemplates();
    }
  }, [inspections, job?.inspectionIds, loadingTemplates, templateNames]);

  useEffect(() => {
    if (templates && inspections && job?.inspectionIds?.length) {
      setTemplateNames(getInspectionTemplates(inspections, job.inspectionIds, templates));
    }
  }, [inspections, job?.inspectionIds, templates]);

  useEffect(() => {
    if (job && order && property && reportRecords) {
      setJobStatus(getJobStatus(job, reportRecords));
      setAccessInformation(order.accessInformation);
    }
  }, [job, order, property, reportRecords]);

  useEffect(() => {
    if (orderStakeholders && order?.orderStakeholderIds?.length) {
      setClient(getClient(orderStakeholders, order.orderStakeholderIds));
    }
  }, [orderStakeholders, order?.orderStakeholderIds]);

  useEffect(() => {
    if (inspections && inspectionProposals && job?.inspectionIds?.length) {
      setProposals(
        getProposals(inspections, inspectionProposals, job.inspectionIds),
      );
    }
  }, [inspections, inspectionProposals, job?.inspectionIds]);

  useEffect(() => {
    if (proposals) {
      setPay(getPay(proposals));
      setIsPaid(getIsPaid(proposals));
      if (proposals.find((p) => p.isGoAnytime)) {
        setScheduledTime(job?.scheduledTime ? `Complete by ${getFormattedDate(job.scheduledTime, true)} - Anytime` : '--');
      } else {
        setScheduledTime(job?.scheduledTime ? getFormattedDateTime(job.scheduledTime, true) : '--');
      }
    }
  }, [proposals]);

  useEffect(() => {
    if (inspections && job?.inspectionIds?.length) {
      setInspectionTypes(getInspectionTypes(inspections, job.inspectionIds));
      setAllInspections(job.inspectionIds
        .map((id) => inspections[id])
        .sort((a) => (a.inspectionType === 'home_inspection' ? -1 : 1)));
    }
  }, [inspections, job?.inspectionIds]);

  useEffect(() => {
    if (
      order
      && property
      && inspections
      && allInspections
      && !loadingDocuments
      && inspectionDocuments) setLoading(false);
  }, [order, property, inspections]);

  useEffect(() => {
    if (jobStatus === JobStatus.Completed) {
      setMainText('Inspection Complete');
      setSubText('');
    } else {
      setMainText('Complete your inspection reports.');
      setSubText('Your payment will only be processed once your inspection report has been recieved.');
    }
  }, [jobStatus]);

  useEffect(() => {
    if (
      contractor
      && contractor.reportWritingSoftware
      && contractor.reportWritingSoftware.toLowerCase() === 'spectora'
    ) {
      setUseSpectora(true);
    }
  }, [contractor]);

  useEffect(() => {
    if (allInspections.length) fetchDocuments();
  }, [allInspections]);

  useEffect(() => {
    if (documents && documents.inspectionDocuments.length > 0) {
      const formattedDocuments = camelizeKeys(
        documents.inspectionDocuments,
      ) as InspectionDocument[];
      setInspectionDocuments(formattedDocuments);
    }
  }, [documents]);

  return (
    <JobDetails
      job={job}
      onBack={() => setPage(DashboardPageTab.Jobs)}
      loading={loading}
      jobStatus={jobStatus}
      orderId={orderId}
      time={scheduledTime}
      hidePay={!user.currentUser.grantedPermissions.includes('view_your_pay_field')}
      pay={pay}
      isPaid={isPaid}
      inspectionTypes={inspectionTypes}
      client={client}
      info={accessInformation}
      propertyInfo={property}
      inspections={allInspections}
      openReportUploadModal={(inspection: LocalInspection) => {
        setSelectedInspection(inspection);
      }}
      closeReportUploadModal={() => {
        setSelectedInspection(null);
      }}
      isContactsModalOpen={isContactsModalOpen}
      openContactsModal={() => {
        setIsContactsModalOpen(true);
      }}
      closeContactsModal={() => {
        setIsContactsModalOpen(false);
      }}
      isPayModalOpen={isPayModalOpen}
      openPayModal={() => {
        setIsPayModalOpen(true);
      }}
      closePayModal={() => {
        setIsPayModalOpen(false);
      }}
      selectedInspection={selectedInspection}
      useSpectora={useSpectora}
      mainText={mainText}
      subText={subText}
      fileForUpload={fileForUpload}
      urlForUpload={urlForUpload}
      otherSelectedInspections={otherSelectedInspections}
      setFileForUpload={setFileForUpload}
      setUrlForUpload={setUrlForUpload}
      setOtherSelectedInspections={setOtherSelectedInspections}
      upload={upload}
      error={error}
      setError={setError}
      inspectionDocuments={inspectionDocuments}
      templateNames={templateNames}
    />
  );
};

export default JobDetailsLoader;
