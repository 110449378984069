import { AnyAction } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import reduxThunk, { ThunkDispatch } from 'redux-thunk';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { initSplitSdk } from '@splitsoftware/splitio-redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import apiMiddleware from '../middleware/api';
import rootReducer from '../reducers';
import { getUID } from '../../utils/splitio';

const enhancers = Sentry?.createReduxEnhancer?.({}) || [];
const middleware = [reduxThunk, apiMiddleware, LogRocket.reduxMiddleware()];

const store = configureStore({
  reducer: rootReducer,
  middleware: (defaults) => (
    defaults({ serializableCheck: false, immutableCheck: false }).concat(...middleware)
  ),
  enhancers: (defaults) => defaults.concat(enhancers),
  devTools: __DEV__,
});

(async () => {
  // Initialize the SDK by calling the initSplitSdk and passing the config in the parameters object.
  const uid = getUID();
  store.dispatch(initSplitSdk({
    config: {
      core: {
        authorizationKey: process.env.SPLITIO_KEY,
        key: uid,
      },
    },
  }));
})();

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export type ReduxState = ReturnType<typeof store.getState>;
export type TypedAction = ThunkAction<void, ReduxState, any, AnyAction>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;

export type ThunkAction<
  R, // Return type of the thunk function
  S, // state type used by getState
  E, // any "extra argument" injected into the thunk
  A extends AnyAction, // known types of actions that can be dispatched
> = (dispatch: ThunkDispatch<S, E, A>, getState: () => S, extraArgument: E) => R;

export default store;
