import React, { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import { IdRef } from 'marketplace-common';
import Footer from '../../components/PageLayout/Footer';
import Menu from './components/Menu/Menu';
import ReportFilter from '../Reports/components/Menu/ReportFilter';
import MobileMenu from './components/Menu/MobileMenu';
import { ModalStyles } from '../Reports/components/Menu/styles';
import styles from '../Reports/components/styles.module.scss'; // TODO - move styles here
import { RehabToolContext } from './context';

interface Props {
  publishProject: () => void;
  handleChangeTeamMarket: (teamMarketId: IdRef) => void;
}

const RehabToolLayout: React.FC<Props> = ({
  publishProject,
  handleChangeTeamMarket,
  children,
}) => {
  const [isFilterReportModalOpen, setIsFilterReportModalOpen] = useState(false);
  const { state, set } = useContext(RehabToolContext);

  return (
    <Container fluid className={styles.reportsPage}>
      <Modal
        isOpen={isFilterReportModalOpen}
        onRequestClose={() => setIsFilterReportModalOpen(false)}
        ariaHideApp={false}
        style={ModalStyles}
        preventScroll={false}
      >
        <ReportFilter
          filter={state.filter}
          changeFilter={(value) => set('filter', value)}
          close={() => setIsFilterReportModalOpen(false)}
          isReInspection={state.isReInspection}
          groupNames={[]}
          groupNameOptions={[]}
          changeGroupNames={() => {}}
        />
      </Modal>
      <Menu
        toggleReportFilterModal={setIsFilterReportModalOpen}
        publishProject={publishProject}
        handleChangeTeamMarket={handleChangeTeamMarket}
      />
      <MobileMenu
        toggleReportFilterModal={setIsFilterReportModalOpen}
        publishProject={publishProject}
      />
      {children}
      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </Container>
  );
};

export default RehabToolLayout;
