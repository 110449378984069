import React, { useContext, useMemo } from 'react';
import { DashboardPageTab } from '../types';
import { InspectorDashboardContext } from '../context';
import ContractorDetails from './ContractorDetails';
import { Container, MenuButton, MenuButtonLabel } from './styles';
import Footer from '../../../components/PageLayout/Footer';
import { UserContractor } from '../../../types';
import { canAccessMSAPage } from '../../../utils/user';

interface Props {
  user: UserContractor,
}

const Menu: React.FC<Props> = ({ user }) => {
  const { state, setPage } = useContext(InspectorDashboardContext);

  const showMSA = useMemo(() => (
    canAccessMSAPage(user)
  ), [user]);

  const canSetupPayment = useMemo(() => (
    user.currentUser.grantedPermissions.includes('view_payment_setup_page')
  ), [user]);

  const isContractorAdmin = useMemo(() => user.currentUser.grantedPermissions.includes('contractor_admin'),
    [user]);

  const handleClickTab = (tab: DashboardPageTab) => {
    setPage(tab);
  };

  return (
    <Container>
      <div>
        <ContractorDetails contractor={user.currentUser.contractor} />

        <MenuButton
          type="button"
          selected={state.page === DashboardPageTab.Jobs}
          onClick={() => handleClickTab(DashboardPageTab.Jobs)}
        >
          <MenuButtonLabel selected={state.page === DashboardPageTab.Jobs}>
            {DashboardPageTab.Jobs}
          </MenuButtonLabel>
        </MenuButton>

        <MenuButton
          type="button"
          selected={state.page === DashboardPageTab.BusinessDetails}
          onClick={() => handleClickTab(DashboardPageTab.BusinessDetails)}
        >
          <MenuButtonLabel selected={state.page === DashboardPageTab.BusinessDetails}>
            {DashboardPageTab.BusinessDetails}
          </MenuButtonLabel>
        </MenuButton>

        {isContractorAdmin ? (
          <MenuButton
            type="button"
            selected={state.page === DashboardPageTab.UserManagement}
            onClick={() => handleClickTab(DashboardPageTab.UserManagement)}
          >
            <MenuButtonLabel selected={state.page === DashboardPageTab.UserManagement}>
              {DashboardPageTab.UserManagement}
            </MenuButtonLabel>
          </MenuButton>
        ) : (
          null
        )}

        {showMSA ? (
          <MenuButton
            type="button"
            selected={state.page === DashboardPageTab.MasterInspectionAndServiceAgreement}
            onClick={() => handleClickTab(DashboardPageTab.MasterInspectionAndServiceAgreement)}
          >
            <MenuButtonLabel
              selected={state.page === DashboardPageTab.MasterInspectionAndServiceAgreement}
            >
              {DashboardPageTab.MasterInspectionAndServiceAgreement}
            </MenuButtonLabel>
          </MenuButton>
        ) : (
          null
        )}

        {canSetupPayment ? (
          <>
            <MenuButton
              type="button"
              selected={state.page === DashboardPageTab.TipaltiSetupProcess}
              onClick={() => handleClickTab(DashboardPageTab.TipaltiSetupProcess)}
            >
              <MenuButtonLabel selected={state.page === DashboardPageTab.TipaltiSetupProcess}>
                {DashboardPageTab.TipaltiSetupProcess}
              </MenuButtonLabel>
            </MenuButton>

            <MenuButton
              type="button"
              selected={state.page === DashboardPageTab.TipaltiPaymentHistory}
              onClick={() => handleClickTab(DashboardPageTab.TipaltiPaymentHistory)}
            >
              <MenuButtonLabel selected={state.page === DashboardPageTab.TipaltiPaymentHistory}>
                {DashboardPageTab.TipaltiPaymentHistory}
              </MenuButtonLabel>
            </MenuButton>
          </>
        ) : (
          null
        )}
      </div>

      <Footer sidebarFooter />
    </Container>
  );
};

export default Menu;
