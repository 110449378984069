import { UserStatusEnum } from '../../../../../types';
import { ModalType } from '../types';

export interface UserProfileInformationProps {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  status: UserStatusEnum;
  isEditUserView: boolean;
  isAdmin: boolean;
  openModal: (e: ModalType) => void;
  handleUnarchiveUser: () => void;
}

export enum ProfileInformationSections {
  Title = 'Profile information (required)',
  Description = 'The user\'s contact info is needed to create their account on the Inspectify app.',
  ArchiveDescription = 'For security purposes, to archive or remove an admin user, please call Inspectify at 1 (855) 271-6876. Our customer support team will gladly assist you.',
  Required = '*',
  FirstName = 'First name',
  LastName = 'Last name',
  Email = 'User\'s email',
  Phone = 'User\'s phone number',
  EmailError = 'Please enter a valid email address.',
  PhoneError = 'Please enter a valid 10-digit phone number.',
}
