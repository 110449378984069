import React, { useEffect, useRef } from 'react';
import { HiZoomIn, HiZoomOut } from 'react-icons/hi';
import styles from './styles.module.scss';

export const ZOOM_SCALE_MIN = 1; // Default min,max scale for react-zoom-pan-pinch
export const ZOOM_SCALE_MAX = 8; // https://prc5.github.io/react-zoom-pan-pinch/?path=/story/docs-props--page

interface Props {
  show: boolean,
  zoomValue: number,
  setZoomValue: (value: React.SetStateAction<number>) => void,
}

const Zoom: React.FC<Props> = ({
  show,
  zoomValue,
  setZoomValue,
}) => {
  // const zoomRef = useRef<ReactZoomPanPinchRef>(null);
  const sliderRef = useRef<HTMLInputElement | null>(null);

  const onZoomIn = () => {
    setZoomValue((prev) => Math.min(prev + 1, ZOOM_SCALE_MAX));
  };

  const onZoomOut = () => {
    setZoomValue((prev) => Math.max(prev - 1, ZOOM_SCALE_MIN));
  };

  const onSliderInput = () => {
    const { value } = sliderRef.current;
    const sliderValueInt = parseFloat(value);
    setZoomValue(sliderValueInt);
  };

  // register keyboard shortcut:
  // z for zoom in
  // x for zoom out
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (!show) return;

      switch (event.key) {
        case 'x':
          onZoomIn();
          break;
        case 'z':
          onZoomOut();
          break;
        default:
          break;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onZoomIn, onZoomOut, show]);

  return (
    <>
      <button
        title="Zoom out (Z)"
        className={styles.controlButton}
        type="button"
        onClick={onZoomOut}
      >
        (Z)<HiZoomOut size={24} color="white" />
      </button>
      <input
        ref={sliderRef}
        type="range"
        min={ZOOM_SCALE_MIN}
        max={ZOOM_SCALE_MAX}
        step={0.3}
        value={zoomValue}
        className={styles.controlSlider}
        onInput={onSliderInput}
      />
      <button
        title="Zoom in (X)"
        className={styles.controlButton}
        type="button"
        onClick={onZoomIn}
      >
        (X)<HiZoomIn size={24} color="white" />
      </button>
      <div className={styles.controlZoom}>
        <span className={styles.controlZoomLabel}>
          {(zoomValue * 100).toFixed(0)}%
        </span>
      </div>
    </>
  );
};

export default Zoom;
