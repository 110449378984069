import React from 'react';
import Select, { GroupedOptionsType, OptionsType } from 'react-select';
import CreateableSelect from 'react-select/creatable';
import {
  colorBaseTealLight,
  colorBaseBlackPrimary,
  colorBaseTealDark,
  colorBaseRedPrimary,
  colorBaseRedLight,
  colorPaletteGrayscale100,
  colorPaletteTeal200,
} from 'haven-design-system/build/typescript/es6';

interface Props {
  onChange: (options: any) => void
  value?: { label: string; value: string; } | OptionsType<{ label: string; value: string; }>
  options?: (
    OptionsType<{ label: string; value: string; }>
    | GroupedOptionsType<{ label: string; value: string; }>
  )
  placeholder?: string
  width?: string
  isMulti?: boolean
  isSearchable?: boolean
  isCreateable?: boolean
}

const getStyles = (width: string, isMulti = false) => ({
  dropdownIndicator: (provided: any) => ({
    ...provided,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided: any) => ({
    ...provided,
    minHeight: '48px',
    minWidth: '192px',
    width: width || '70%',
  }),
  control: (provided: any) => ({
    ...provided,
    background: 'none',
    border: `1px solid ${colorPaletteGrayscale100}`,
    outline: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    width: '100%',
    padding: '4px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontWeight: isMulti ? 'bold' : 'normal',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    fontWeight: isMulti ? 'bold' : 'normal',
  }),
  option: (provided: any) => ({
    ...provided,
    justifyContent: 'center',
    cursor: 'pointer',
  }),
});

const getTheme = (theme: any, isMulti = false) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colorBaseTealDark,
    primary50: colorPaletteTeal200,
    primary25: colorBaseTealLight,
    danger: colorBaseRedPrimary,
    dangerLight: colorBaseRedLight,
    neutral0: colorPaletteGrayscale100,
    neutral10: isMulti ? colorBaseTealLight : theme.colors.neutral10,
    neutral20: isMulti ? colorBaseTealDark : theme.colors.neutral20,
    neutral30: isMulti ? colorBaseTealDark : theme.colors.neutral30,
    neutral40: isMulti ? colorBaseBlackPrimary : theme.colors.neutral40,
    neutral50: isMulti ? colorBaseBlackPrimary : theme.colors.neutral50,
    neutral60: isMulti ? colorBaseBlackPrimary : theme.colors.neutral60,
    neutral70: isMulti ? colorBaseTealDark : theme.colors.neutral70,
    neutral80: isMulti ? colorBaseTealDark : theme.colors.neutral80,
  },
});

const ReactSelect: React.FC<Props> = ({
  isCreateable,
  width,
  value,
  placeholder,
  isMulti,
  isSearchable,
  options,
  onChange,
}) => (
  isCreateable
    ? (
      <CreateableSelect
        defaultValue={value}
        placeholder={placeholder}
        isMulti={isMulti}
        isSearchable={isSearchable}
        options={options}
        onChange={onChange}
        styles={getStyles(width, isMulti)}
        theme={(theme) => getTheme(theme, isMulti)}
      />
    )
    : (
      <Select
        value={value}
        placeholder={placeholder}
        isMulti={isMulti}
        isSearchable={isSearchable}
        options={options}
        onChange={onChange}
        styles={getStyles(width, isMulti)}
        theme={(theme) => getTheme(theme, isMulti)}
      />
    )
);

export default ReactSelect;
