import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  colorBaseBlackLight, colorBaseBlueLight, colorBaseBluePrimary,
  colorBaseGreenPrimary, colorBaseGreyDark, colorPaletteGrayscale50,
  colorBaseRedPrimary, colorBaseWhitePrimary,
} from 'haven-design-system/build/typescript/es6';
import styled, { css } from 'styled-components';

const sharedStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Label = styled.label`
  ${sharedStyles}
`;

export const FilesWrapper = styled.div`
  ${sharedStyles}
`;

export const Name = styled.div`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${colorBaseBlackLight};
`;

export const Input = styled.input`
  border: 1px solid ${colorPaletteGrayscale50};
  border-radius: 2px;
  height: 40px;
  padding-left: 10px;
  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const inputStyle = {
  height: 40,
  borderRadius: 2,
  paddingLeft: 10,
  border: `1px solid ${colorPaletteGrayscale50}`,
};

export const InputArea = styled.textarea`
  border: 1px solid ${colorPaletteGrayscale50};
  border-radius: 2px;
  height: 40px;
  padding-left: 10px;
  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const Select = styled.select`
  border: 1px solid ${colorPaletteGrayscale50};
  border-radius: 2px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${colorBaseBlackLight};
  height: 40px;
  padding-left: 10px;
`;

const sharedButtonStyles = css`
  border-radius: 2px;
  margin-bottom: 15px;
  min-height: 40px;
`;

export const Button = styled.button<{ isDragActive: boolean }>`
  background-color: ${(props) => (props.isDragActive ? colorBaseGreenPrimary : 'transparent')};
  border: 1px dashed ${colorPaletteGrayscale50};
  width: 100%;
  ${sharedButtonStyles}
  &:hover {
    background-color: ${colorPaletteGrayscale50};
  }
`;

export const AttachedStaticFile = styled.button`
  background-color: ${colorPaletteGrayscale50};
  border: 1px solid ${colorBaseGreyDark};
  ${sharedButtonStyles}
  &:hover {
    cursor: default;
  }
`;

export const AttachedFile = styled(AttachedStaticFile)`
  &:hover {
    background-color: ${colorBaseRedPrimary};
    cursor: pointer;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: -2px;
`;

export const Update = styled.button<{ showLightColor: boolean }>`
  border: 1px dashed ${colorPaletteGrayscale50};
  ${sharedButtonStyles};
  background-color: ${(props) => (props.showLightColor ? colorBaseBlueLight : colorBaseBluePrimary)};
  width: 124px;
  border-radius: 2px;
  margin-bottom: 15px;
  height: 40px;
  color: ${colorBaseWhitePrimary};
  margin-right: 25px;
`;
