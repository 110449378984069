import React from 'react';
import './styles.scss';
import { BasicInformation, BasicInformationInputsProps } from './types';
import {
  formatPhoneNumber,
  unformatPhoneNumber,
  validateEmail,
  validateZipCode,
} from '../../../utils/string';
import { StateCodeEnum, StateEnum } from '../../../types/address';

const BasicInformationInputs: React.FC<BasicInformationInputsProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  streetAddress,
  setStreetAddress,
  city,
  setCity,
  state,
  setState,
  zipCode,
  setZipCode,
  showZipCodeError,
  setShowZipCodeError,
  showPhoneError,
  setShowPhoneError,
  showEmailError,
  setShowEmailError,
  addressRef,
  isMobile,
}) => (
  <>
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          <div className="userProfile__section__inputRow__inputContainer__label--required">
            {BasicInformation.Required}
          </div>
          {BasicInformation.FirstName}
        </div>
        <input
          className="userProfile__section__inputRow__inputContainer__input"
          type="text"
          value={firstName}
          name="first_name"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          <div className="userProfile__section__inputRow__inputContainer__label--required">
            {BasicInformation.Required}
          </div>
          {BasicInformation.LastName}
        </div>
        <input
          className="userProfile__section__inputRow__inputContainer__input"
          type="text"
          value={lastName}
          name="last_name"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
    </div>
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          <div className="userProfile__section__inputRow__inputContainer__label--required">
            {BasicInformation.Required}
          </div>
          {BasicInformation.Phone}
        </div>
        <input
          className={
                    showPhoneError
                      ? 'userProfile__section__inputRow__inputContainer__input--error'
                      : 'userProfile__section__inputRow__inputContainer__input'
                  }
          type="tel"
          value={formatPhoneNumber(phoneNumber)}
          onBlur={() => {
            setShowPhoneError(phoneNumber.length > 0 && unformatPhoneNumber(phoneNumber).length < 10);
          }}
          name="phone_number"
          required
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        {showPhoneError && (
        <div className="userProfile__section__inputRow__inputContainer__errorText">
          {BasicInformation.PhoneError}
        </div>
        )}
      </div>
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          <div className="userProfile__section__inputRow__inputContainer__label--required">
            {BasicInformation.Required}
          </div>
          {BasicInformation.Email}
        </div>
        <input
          className={
                      showEmailError
                        ? 'userProfile__section__inputRow__inputContainer__input--error'
                        : 'userProfile__section__inputRow__inputContainer__input'
                    }
          type="email"
          value={email}
          required
          onBlur={
                      () => setShowEmailError(email.length > 0
                        && !validateEmail(email))
                    }
          name="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        {showEmailError && (
        <div className="userProfile__section__inputRow__inputContainer__errorText">
          {BasicInformation.EmailError}
        </div>
        )}
      </div>
    </div>
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          <div className="userProfile__section__inputRow__inputContainer__label--required">
            {BasicInformation.Required}
          </div>
          {BasicInformation.Address}
        </div>
        <input
          className="userProfile__section__inputRow__inputContainer__input"
          type="text"
          ref={addressRef}
          value={streetAddress}
          name="address"
          onChange={(e) => setStreetAddress(e.target.value)}
        />
      </div>
    </div>
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          <div className="userProfile__section__inputRow__inputContainer__label--required">
            {BasicInformation.Required}
          </div>
          {BasicInformation.City}
        </div>
        <input
          className="userProfile__section__inputRow__inputContainer__input"
          type="text"
          value={city}
          name="city"
          onChange={(e) => setCity(e.target.value)}
        />
      </div>
      {isMobile ? (
        <div className="stateZipCodeContainer">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.State}
            </div>
            <select
              className="userProfile__section__inputRow__inputContainer__input"
              value={state}
              name="state"
              onChange={(e) => setState(e.target.value as StateEnum | StateCodeEnum)}
            >
              <option key="blank">{' '}</option>
              {Object.values(StateEnum).map((stateName: StateEnum | StateCodeEnum) => (
                <option key={stateName}>{stateName}</option>
              ))}
            </select>
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.ZipCode}
            </div>
            <input
              className={
                        showZipCodeError
                          ? 'userProfile__section__inputRow__inputContainer__input--error'
                          : 'userProfile__section__inputRow__inputContainer__input'
                      }
              type="text"
              value={zipCode}
              name="zipCode"
              onChange={(e) => setZipCode(e.target.value)}
              onBlur={
                      () => setShowZipCodeError(zipCode.length > 0
                        && !validateZipCode(zipCode))
                    }
            />
            {showZipCodeError && (
            <div className="userProfile__section__inputRow__inputContainer__errorText">
              {BasicInformation.ZipCodeError}
            </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.State}
            </div>
            <select
              className="userProfile__section__inputRow__inputContainer__input"
              value={state}
              name="state"
              onChange={(e) => setState(e.target.value as StateEnum | StateCodeEnum)}
            >
              <option key="blank">{' '}</option>
              {Object.values(StateEnum).map((stateName: StateEnum | StateCodeEnum) => (
                <option key={stateName}>{stateName}</option>
              ))}
            </select>
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.ZipCode}
            </div>
            <input
              className={
                        showZipCodeError
                          ? 'userProfile__section__inputRow__inputContainer__input--error'
                          : 'userProfile__section__inputRow__inputContainer__input'
                      }
              type="text"
              value={zipCode}
              name="zipCode"
              onChange={(e) => setZipCode(e.target.value)}
              onBlur={
                      () => setShowZipCodeError(zipCode.length > 0
                        && !validateZipCode(zipCode))
                    }
            />
            {showZipCodeError && (
            <div className="userProfile__section__inputRow__inputContainer__errorText">
              {BasicInformation.ZipCodeError}
            </div>
            )}
          </div>
        </>
      )}
    </div>
  </>
);

export default BasicInformationInputs;
