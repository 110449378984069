import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import styles from './styles.module.scss';
import { ReportFeedbackStep } from '../../../../types';
import Button from '../../../../components/Button';
import CheckGraphic from './check_graphic.svg?component';
import { isFeedbackNoteValid } from '../../../../utils/report';

interface Props {
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
  reportFeedbackStep: ReportFeedbackStep;
  setReportFeedbackStep: (step: ReportFeedbackStep) => void;
  handleSubmitFeedback: (step: ReportFeedbackStep, feedback?: string) => void;
}

const FeedbackModal: React.FC<Props> = ({
  modalIsOpen,
  setModalIsOpen,
  reportFeedbackStep,
  setReportFeedbackStep,
  handleSubmitFeedback,
}) => {
  const numericalFeedbackValues: string[] = ['1', '2', '3', '4', '5'];
  const [reportFeedbackNote, setReportFeedbackNote] = useState('');
  const [showNoteError, setShowNoteError] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const handleCloseModal = () => {
    if (reportFeedbackStep === ReportFeedbackStep.NOTE) {
      handleSubmitFeedback(reportFeedbackStep, reportFeedbackNote);
    }
    setReportFeedbackStep(ReportFeedbackStep.INITIATION);
    setReportFeedbackNote('');
    setShowNoteError(false);
    setDisableSubmitButton(true);
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (reportFeedbackNote) {
      if (isFeedbackNoteValid(reportFeedbackNote)) {
        setDisableSubmitButton(false);
        setShowNoteError(false);
      } else {
        setDisableSubmitButton(true);
      }
    }
  }, [reportFeedbackNote]);

  const renderReportFeedbackModalContent = (step: ReportFeedbackStep) => {
    switch (step) {
      case ReportFeedbackStep.SCORE:
        return (
          <>
            <div className={styles.feedbackPrimaryText}>
              How satisfied are you with the quality of this report?
            </div>
            <div className={styles.scoreContainer}>
              {numericalFeedbackValues.map((score) => (
                <Button
                  key={score}
                  onClick={() => handleSubmitFeedback(step, score)}
                  className={styles.scoreButton}
                >
                  {score}
                </Button>
              ))}
            </div>
            <div className={styles.secondaryTextContainer}>
              <div className={styles.feedbackSecondaryText}>
                dissatisfied
              </div>
              <div className={styles.feedbackSecondaryText}>
                satisfied
              </div>
            </div>
          </>
        );
      case ReportFeedbackStep.NOTE:
        return (
          <>
            <div className={styles.feedbackPrimaryText}>
              Would you like to elaborate?
            </div>
            <div className={styles.secondaryTextContainer}>
              <div className={styles.feedbackSecondaryText}>
                We would love to hear your feedback and how we might improve in the future.
              </div>
            </div>
            <textarea
              name="note"
              className={showNoteError ? styles.noteContainerError : styles.noteContainer}
              placeholder="Tell us how we can improve here"
              value={reportFeedbackNote}
              onChange={(e) => setReportFeedbackNote(e.target.value)}
              onBlur={() => setShowNoteError(disableSubmitButton)}
            />
            {showNoteError && (
              <div className={styles.errorText}>
                Response required
              </div>
            )}
            <Button
              className={
                disableSubmitButton ? styles.submitButtonDisabled : styles.submitButton
              }
              onClick={() => handleSubmitFeedback(step, reportFeedbackNote)}
              disabled={disableSubmitButton}
            >
              Submit Feedback
            </Button>
          </>
        );
      case ReportFeedbackStep.CONFIRMATION:
        return (
          <>
            { /* TODO GA9-951: add check_graphic to haven */ }
            <CheckGraphic className={styles.checkGraphic} />
            <div className={styles.feedbackPrimaryText}>
              Thank you for your feedback!
            </div>
            <div className={styles.secondaryTextContainer}>
              <div className={styles.feedbackSecondaryText}>
                Our team reviews all your feedback to inform improvements to our reports.
              </div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      ariaHideApp={false}
      preventScroll={false}
      className={styles.feedbackContainer}
      overlayClassName={styles.overlay}
    >
      <div className={styles.closeButtonContainer}>
        <Button onClick={handleCloseModal} className={styles.closeButton}>
          <CloseIcon />
        </Button>
      </div>
      {renderReportFeedbackModalContent(reportFeedbackStep)}
    </Modal>
  );
};

export default FeedbackModal;
