import styled from 'styled-components';

export const ReportRehabHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 80vh;
`;

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  padding-right: 16px;
`;
