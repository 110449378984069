import {
  colorBaseBluePrimary,
  colorBaseGreyDark,
} from 'haven-design-system/build/typescript/es6';
import styled, { css } from 'styled-components';

const CommonTextStyles = css`
  font-family: Nunito Sans,sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;

export const TabDiv = styled.div<{ isFullWidth: boolean }>`
  display: flex;
  flex-direction: row;
  ${(props) => (props.isFullWidth && css`
    width: 100%;
    justify-content: center;
  `)}
`;

interface TabButtonProps {
  readonly selected: boolean;
  readonly isFullWidth?: boolean;
}

export const TabText = styled.div<TabButtonProps>`
  ${CommonTextStyles};
  font-size: 20px;
  line-height: 27px;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};;
  color: ${(props) => (props.selected ? colorBaseBluePrimary : colorBaseGreyDark)};
  margin: 0 20px;
  &:hover {
    color: ${colorBaseBluePrimary};
  }
`;

export const TabButton = styled.button<TabButtonProps>`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  ${(props) => (props.isFullWidth && css`
    width: 100%;
  `)}
`;

export const BorderBottom = styled.div<TabButtonProps>`
  border-bottom: ${(props) => (props.selected ? `2px solid ${colorBaseBluePrimary}` : 'none')};
  width: ${(props) => (props.isFullWidth ? '100%' : '64px')};
  height: 8px;
  margin: auto;
`;
