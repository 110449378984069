import styled from 'styled-components';
import { colorBaseBlackLight, colorBaseGreyDark, colorBaseWhitePrimary } from 'haven-design-system/build/typescript/es6';
import { basePageStyles, baseTitleStyles } from '../shared/styles';

export const FormWrapper = styled.div`
  background-color: ${colorBaseWhitePrimary};
  ${basePageStyles}
  padding: 25px 25px 10px;
`;

export const Title = styled.div`
  ${baseTitleStyles}
  font-weight: bold;
  font-size: 24px;
  color: ${colorBaseBlackLight};
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const SectionTitle = styled.div`
  ${baseTitleStyles}
  font-weight: normal;
  font-size: 20px;
  color: ${colorBaseGreyDark};
`;

export const Row = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-bottom: ${(props) => (props.noMargin ? '0px' : '25px')};
  flex-wrap: wrap;
`;

export const ReverseRow = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row-reverse;
`;
