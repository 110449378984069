import React, { useContext } from 'react';
import CloseIcon from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import { OnboardingTrackerProps, TrackerItemHeader, TrackerItemInstructions } from './types';
import HelloOnboardingImage from '../../../../assets/images/helloOnboardingImage.svg';
import CompleteOnboardingImage from '../../../../assets/images/confetti.svg';
import './styles.scss';
import '../../../Onboarding/styles.scss';
import TrackerItem from './TrackerItem';
import Button from '../../../../components/Button';
import { UPDATE_SURVEYOR_ONBOARDING } from '../../../../graphql/mutations/surveyorOnboarding';
import { captureException } from '../../../../utils/error';
import { DashboardPageTab } from '../../types';
import { InspectorDashboardContext } from '../../context';

const OnboardingTracker: React.FC<OnboardingTrackerProps> = (
  { onboardingData },
) => {
  const history = useHistory();
  const { setPage } = useContext(InspectorDashboardContext);

  const areTrackerItemsComplete: boolean = onboardingData.misaSigned
    && !!onboardingData.backgroundCheckSubmittedAt
    && onboardingData.paymentDetailsFilled && onboardingData.appLoggedIn;

  const [updateSurveyorOnboarding] = useMutation(
    UPDATE_SURVEYOR_ONBOARDING,
    {
      onError: () => {
        captureException(new Error('Graphql failed to update surveyor onboarding'));
      },
    },
  );

  const handleCloseOnboardingTracker = () : void => {
    if (areTrackerItemsComplete) {
      try {
        updateSurveyorOnboarding({
          variables: {
            input: {
              id: onboardingData.id,
              onboardingTrackerDismissedAt: Date.now(),
            },
          },
          context: { headers: { onboarding_id: onboardingData.id } },
        });
      } catch (error) {
        captureException(error, { source: 'User closed onboarding tracker' });
      }
    }
  };

  return (
    <div className="onboardingContainer">
      {areTrackerItemsComplete && (
        <div className="closeButtonContainer">
          <Button onClick={() => handleCloseOnboardingTracker()} className="closeButton">
            <CloseIcon />
          </Button>
        </div>
      )}
      <div className="onboardingContentContainer">
        <div className="onboardingInstructionsContainer">
          {areTrackerItemsComplete ? (
            <>
              <img className="completeOnboardingImage" src={CompleteOnboardingImage} alt="CompleteOnboardingImage" />
              <div className="text__header">
                You&apos;re all set!
              </div>
              <div className="text__subheader">
                Feel free to check out our&nbsp;
                <a className="text__link" href="https://knowledge.inspectify.com/inspectify-community-guidelines-surveyors" target="_blank" rel="noreferrer">
                  Community Guidelines
                </a>
                &nbsp;and&nbsp;
                <a className="text__link" href="https://knowledge.inspectify.com/surveyors" target="_blank" rel="noreferrer">
                  Help Center
                </a>
                &nbsp;if you have any questions.
              </div>
              <Button
                onClick={() => handleCloseOnboardingTracker()}
              >
                Close set up
              </Button>
            </>
          ) : (
            <>
              <img src={HelloOnboardingImage} alt="HelloOnboardingImage" />
              <div className="text__header">
                Welcome to Inspectify!
              </div>
              <div className="text__subheader">
                Complete the checklist to finish setting up your account.&nbsp;
                If you have questions, visit our&nbsp;
                <a className="text__link" href="https://knowledge.inspectify.com/surveyors" target="_blank" rel="noreferrer">
                  Help Center.
                </a>
              </div>
            </>
          )}
        </div>
        <div className="onboardingTrackerItemsContainer">
          <TrackerItem
            isChecked={onboardingData.misaSigned}
            header={TrackerItemHeader.MISA}
            instructions={TrackerItemInstructions.MISA}
            onClick={() => setPage(DashboardPageTab.MasterInspectionAndServiceAgreement)}
          />
          {onboardingData.backgroundCheckRequired && (
            <TrackerItem
              isChecked={!!onboardingData.backgroundCheckSubmittedAt}
              header={TrackerItemHeader.BackgroundCheck}
              instructions={isNull(onboardingData?.submitBackgroundCheckUrl)
                ? TrackerItemInstructions.BackgroundCheckDisabled
                : TrackerItemInstructions.BackgroundCheck}
              disabled={isNull(onboardingData?.submitBackgroundCheckUrl)}
              onClick={() => window.open(onboardingData.submitBackgroundCheckUrl, '_blank')}
            />
          )}
          <TrackerItem
            isChecked={onboardingData.paymentDetailsFilled}
            header={TrackerItemHeader.Payment}
            instructions={TrackerItemInstructions.Payment}
            onClick={() => setPage(DashboardPageTab.TipaltiSetupProcess)}
          />
          <TrackerItem
            isChecked={onboardingData.appLoggedIn}
            header={TrackerItemHeader.App}
            instructions={TrackerItemInstructions.App}
            onClick={() => history.push('/download_app')}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingTracker;
