import { useState, useEffect, useCallback } from 'react';
import { Auth, Hub } from 'aws-amplify';
import humps from 'humps';
import { useHistory } from 'react-router-dom';
import { captureException } from './error';
import {
  AvailableUser,
  CurrentUser,
  CognitoUser,
  User,
  UserStatusEnum,
  UserPermissionEnum,
  PERMISSION_NAMES,
  UserContractor,
} from '../types';
import { useTypedDispatch } from '../redux/store';
import { fetchAllTreatments } from '../redux/actions/splitio';
import { identify } from './analytics';

enum AmplifyEventType {
  SignIn = 'signIn',
  SignOut = 'signOut',
  Custom = 'customOAuthState',
  OAuthSignOut = 'oAuthSignOut',
}

/** Hook to get the COGNITO currently logged-in user */
export const useCurrentUser = (): CurrentUser => {
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState<CurrentUser>(null);

  const getCurrentUserInfo = useCallback(async () => {
    try {
      const user = await Auth.currentUserInfo();
      if (user) {
        const userInfo = humps.camelizeKeys(user.attributes) as any as CognitoUser;
        identify(userInfo.email, { ...userInfo });
        setCurrentUser(userInfo);
        dispatch(fetchAllTreatments(userInfo));
      } else {
        setCurrentUser(false);
        dispatch(fetchAllTreatments(false));
      }
    } catch (e) {
      captureException(e);
    }
  }, []);

  const listener = (data: any) => {
    const { payload } = data;
    switch (payload.event) {
      case AmplifyEventType.SignIn:
        // signin event on completeNewPassword does not have data.attributes
        if (payload?.data?.attributes) {
          const userInfo = humps.camelizeKeys(payload.data.attributes) as any as CognitoUser;
          setCurrentUser(userInfo);
          dispatch(fetchAllTreatments(userInfo));
        }
        break;
      case AmplifyEventType.Custom:
        // Custom redirect from Google signin success
        history.push(payload.data);
        break;
      case AmplifyEventType.SignOut:
        setCurrentUser(false);
        dispatch(fetchAllTreatments(false));
        break;
      case AmplifyEventType.OAuthSignOut:
        setCurrentUser(false);
        dispatch(fetchAllTreatments(false));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getCurrentUserInfo();
  }, []);

  useEffect(() => {
    Hub.listen('auth', listener);
    Hub.listen('authInspectify', listener);

    return () => {
      Hub.remove('auth', listener);
      Hub.remove('authInspectify', listener);
    };
  }, []);

  return currentUser;
};

export const handleSwitchAvailableUser = (
  availableUser: AvailableUser,
) => {
  if (availableUser) localStorage.setItem('inspectifySelectedUser', availableUser.id);
  window.location.reload();
};

export const isUserUsingMobile = () => {
  // User agent string method
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  // Screen resolution method
  if (!isMobile) {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    isMobile = (screenWidth < 768 || screenHeight < 768);
  }

  // Touch events method
  if (!isMobile) {
    isMobile = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
  }

  // CSS media queries method
  if (!isMobile) {
    const bodyElement = document.getElementsByTagName('body')[0];
    isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
  }

  return isMobile;
};

export const checkSelectedUserTeamId = (teamId: string, availableUsers: AvailableUser[]) => {
  const selectedUser: AvailableUser = availableUsers.find((availableUser: AvailableUser) => availableUser.id === localStorage.getItem('inspectifySelectedUser'));
  if (selectedUser?.team) return String(selectedUser.team.id) === teamId;
  return false;
};

export const getFirstName = (name: string) => name.split(' ')[0];

export const getLastName = (name: string) => {
  const names = name.split(' ');
  if (names.length < 2) {
    return '';
  }
  return names.slice(1).join(' ');
};

export const getMergedName = (firstName: string, lastName: string) => `${firstName} ${lastName}`;

export const getUserStatus = (user: User) => {
  if (user.archivedAt) {
    return UserStatusEnum.Archived;
  } if (user.activatedAt) {
    return UserStatusEnum.Active;
  }
  return UserStatusEnum.InviteSent;
};

export const getPermissionNames = (grantedPermissions: string[]) => {
  if (!grantedPermissions.includes('contractor_admin')
  && !grantedPermissions.includes('view_new_jobs_tab')
  && !grantedPermissions.includes('view_your_pay_field')) {
    return [UserPermissionEnum.None];
  }

  return grantedPermissions.map((p: string) => PERMISSION_NAMES[p])
    .filter((p: string) => p);
};

export const getPermissionsForBackend = (userData: any) => {
  const permissions = ['']; // updateUser ignores empty array
  if (userData.offersPermission) permissions.push('view_new_jobs_tab');
  if (userData.pricingPermission) permissions.push('view_your_pay_field');
  return permissions;
};

export const canAccessMSAPage = (
  user: UserContractor,
) => user?.currentUser?.contractor
  && !user.currentUser.contractor.msaSigned
  && ['inspector', 'insurance_surveyor', 'surveyor'].includes(user.currentUser.contractor.contractorType)
  && user.currentUser.grantedPermissions.includes('contractor_admin');
