import {
  Report,
  ReportSystem,
  ReportSubsystemInterface,
  ReportSubsystem,
  sortReportSystemOrSubsystemByDisplayIndex,
} from 'marketplace-common';
import { hideSubsystem } from '../../utils/report';

export const getGroupedSubsystemsByName = (
  systems: ReportSystem[],
  subsystems: ReportSubsystemInterface,
) => (
  systems.reduce((acc: { [name: string]: ReportSubsystem[] }, system) => {
    const includedSubsystems = sortReportSystemOrSubsystemByDisplayIndex(
      system.reportSubsystemIds.filter((id) => {
        const subsystem = subsystems?.[id];
        return subsystem?.includeOnReport && !hideSubsystem(subsystem);
      }).map((id) => subsystems?.[id]),
    ) as ReportSubsystem[];

    includedSubsystems.forEach((subsystem) => {
      acc[subsystem.name] = [...(acc[subsystem.name] || []), subsystem];
    });
    return acc;
  }, {})
);

export const getReportGroupName = (subsystem: ReportSubsystem, report: Report) => {
  const system = report.systems[subsystem.reportSystemId];
  return report?.systemGroups?.[system.reportSystemGroupId]?.name;
};
