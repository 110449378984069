import {
  colorBaseBlackLight, colorBaseBlackPrimary,
  colorBaseGreenPrimary, colorBaseGreyDark,
  colorPaletteGrayscale50, colorBaseGrayscaleOffWhite,
} from 'haven-design-system/build/typescript/es6';
import styled, { css } from 'styled-components';

export const Divider = styled.div`
  height: 0;
  width: 100%;
  border-top: 1px solid ${colorPaletteGrayscale50};
`;

const SharedStyles = css`
  font-size: 16px;
  font-weight: bold;
`;

export const PaymentInfoBox = styled.div`
  background-color: ${colorBaseGrayscaleOffWhite};
  width: 100%;
  padding: 16px;
  color: ${colorBaseBlackLight};
  margin-top: 20px;
  border-radius: 8px;
  ${SharedStyles}
`;

export const PaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 15px;
`;

export const PaymentInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentInfoText = styled.div<{ paid?: boolean }>`
  color: ${(props) => (props.paid ? colorBaseGreenPrimary : colorBaseGreyDark)};
  ${SharedStyles}
`;

export const YourPayInfoText = styled(PaymentInfoText)`
  color: ${colorBaseBlackPrimary};
`;
