import React from 'react';
import Modal from 'react-modal';
import assetIconActionClose from 'haven-design-system/build/assets/svgs/action/close.svg';
import { colorPaletteRed600 } from 'haven-design-system/build/typescript/es6';
import Button from '../../../../../../components/Button';
import {
  BodyContainer,
  ButtonContainer,
  HeaderContainer,
  RemoveRehabBodyText,
  RemoveRehabItemContainer,
  RemoveRehabItemModalStyle,
  RemoveRehabMainText,
} from './styles';
import IconButton from '../../../../../../components/IconButton';

interface Props {
  isRemoveModalOpen: boolean,
  setIsRemoveModalOpen: (bool: boolean) => void,
  handleRemoveWorkItemFromProject: () => void,
}

const RemoveItemModal: React.FC<Props> = ({
  isRemoveModalOpen,
  setIsRemoveModalOpen,
  handleRemoveWorkItemFromProject,
}) => (
  <Modal isOpen={isRemoveModalOpen} ariaHideApp={false} style={RemoveRehabItemModalStyle}>
    <RemoveRehabItemContainer>
      <HeaderContainer>
        <RemoveRehabMainText>Remove work item?</RemoveRehabMainText>
        <IconButton onClick={() => setIsRemoveModalOpen(false)}>
          <img src={assetIconActionClose} alt="close-icon" />
        </IconButton>
      </HeaderContainer>
      <BodyContainer>
        <RemoveRehabBodyText>
          You will lose all information and uploaded media associated to this item.
        </RemoveRehabBodyText>
      </BodyContainer>
      <ButtonContainer>
        <Button data-testid="closeModalButton" secondary onClick={() => setIsRemoveModalOpen(false)}>
          Cancel
        </Button>
        <Button data-testid="closeModalButton" onClick={handleRemoveWorkItemFromProject} style={{ backgroundColor: colorPaletteRed600, border: 'none' }}>
          Remove Item
        </Button>
      </ButtonContainer>
    </RemoveRehabItemContainer>
  </Modal>
);

export default RemoveItemModal;
