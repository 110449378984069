import React, { useEffect, useMemo } from 'react';
import { Order } from 'marketplace-common';
import Paywall from './Paywall';
import { Contractor } from '../../../../types';
import { useTypedSelector } from '../../../../redux/store';
import { selectWorks } from '../../../../redux/selectors';

interface Props {
  order: Order,
  contractor: Contractor,
}

const PaywallLoader: React.FC<Props> = ({
  order, contractor,
}) => {
  const works = useTypedSelector(selectWorks);
  const inspectionProposalsQueryParam = useMemo(
    () => `inspection_proposal_ids[]=${order.inspectionIds.map(
      (inspectionId) => works.inspections[inspectionId].scheduledProposalId,
    ).join('&inspection_proposal_ids[]=')}`,
    [order.inspectionIds, works.inspections],
  );

  const inspectionProposals = useMemo(() => order.inspectionIds.filter(
    (inspectionId) => ['completed', 'scheduled'].includes(works.inspections[inspectionId].status) && !works.inspections[inspectionId].isPaid,
  ).map(
    (
      inspectionId,
    ) => works.inspectionProposals[works.inspections[inspectionId].scheduledProposalId],
  ), [order.inspectionIds, works.inspectionProposals]);

  const totalPrice = useMemo(() => inspectionProposals.reduce(
    (total, inspectionProposal) => total + inspectionProposal.totalFee,
    0,
  ), [inspectionProposals]);

  const paywallUrl = `${process.env.MARKETPLACE_URL}/orders/${order.token || order.readOnlyToken}/service_agreement?${inspectionProposalsQueryParam}&next=${window.location.href}`;

  useEffect(() => {
    // Prevent scrolling
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <Paywall
      contractorBusinessName={contractor?.businessName || '-'}
      paywallUrl={paywallUrl}
      inspectionProposals={inspectionProposals}
      inspections={works.inspections}
      totalPrice={totalPrice}
    />
  );
};

export default PaywallLoader;
