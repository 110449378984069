import { getFormattedString, LocalInspection } from 'marketplace-common';
import React, { useState } from 'react';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon,
  CheckboxText,
} from './style';

interface Props {
  inspection: LocalInspection;
  onClick: (checked: any) => void;
  disabled: boolean;
}

const Checkbox: React.FC<Props> = ({ inspection, onClick, disabled }) => {
  const [checked, isChecked] = useState(false);

  const click = (e: any) => {
    if (!disabled) {
      isChecked(!checked);
      onClick(e.target.checked);
    }
  };

  const content = (
    <CheckboxContainer>
      <HiddenCheckbox disabled />
      <StyledCheckbox
        checked={disabled || checked}
        onClick={(e: any) => click(e)}
        disabled={disabled}
      >
        <Icon viewBox="3 10 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <CheckboxText>{getFormattedString(inspection.inspectionType).split(' ')[0]}</CheckboxText>
    </CheckboxContainer>
  );

  return content;
};

export default Checkbox;
