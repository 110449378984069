import React, { useMemo } from 'react';
import {
  Report,
  OriginalReportDeficiency,
  ReportAttachment,
  ReportDeficiency, ReportLocation,
} from 'marketplace-common';
import styles from './styles.module.scss';
import Attachments from './Attachments';
import IssueBadge from './IssueBadge';
import { WorkItemModalOptions } from '../../RehabToolPage/types';
import Locations from './Locations';
import { RectangularAddWorkItemButton } from '../../RehabToolPage/components/AddWorkItemButton/AddWorkItemButton';

interface Props {
  report: Report
  deficiency: ReportDeficiency
  originalDeficiency: OriginalReportDeficiency | null
  deficiencyLocations: ReportLocation[]
  attachments: { [id: string]: ReportAttachment }
  imageWidth: number
  isRehab?: boolean
  handleWorkItemModal?: (options: WorkItemModalOptions) => void
  systemName: string
  subsystemName: string
  isMediaOnly: boolean
  handleOpenReportMediaUploader?: (itemId: string, itemType: string, reportSystemGroupId?: string | null) => void;
}

const DeficiencyDetails: React.FC<Props> = ({
  report,
  deficiency,
  originalDeficiency,
  deficiencyLocations,
  attachments,
  imageWidth,
  isRehab,
  handleWorkItemModal,
  systemName,
  subsystemName,
  isMediaOnly,
  handleOpenReportMediaUploader,
}) => {
  const subsystem = report?.subsystems[deficiency.reportSubsystemId];
  const system = report?.systems[subsystem?.reportSystemId];
  const deficiencyAttachments = (deficiency?.reportAttachmentIds || [])
    .reduce((acc, id) => {
      if (attachments[id]) { return [...acc, attachments[id]]; }
      return acc;
    }, []);
  const originalDeficiencyAttachments = (originalDeficiency?.reportAttachmentIds || [])
    .reduce((acc, id) => {
      if (attachments[id]) { return [...acc, attachments[id]]; }
      return acc;
    }, []);
  const deficiencyDetail = useMemo(() => {
    if (deficiency.recommendationDescription) {
      return `${deficiency.detail} ${deficiency.recommendationDescription}`;
    }
    return deficiency.detail;
  }, [deficiency.recommendationDescription, deficiency.detail]);

  return (
    <div id={deficiency.id} data-testid="deficiency-details" className={styles.deficiencyContainer}>
      <div className={styles.deficiencyHeaderDetailAndInformationContainer}>
        <hr className={styles.divider} />
        <div className={styles.displayRowSpacedBetween}>
          <IssueBadge type={deficiency?.reInspectionStatus === 'fixed' ? 'fixed' : deficiency.severity} />
          {isRehab && (
            <RectangularAddWorkItemButton
              tag="deficiency-details"
              onClick={() => handleWorkItemModal({
                isOpen: true,
                attachments: deficiencyAttachments.concat(originalDeficiencyAttachments),
                systemName,
                subsystemName,
                noteTitle: deficiency.title,
                noteDetails: deficiency.detail,
                note: deficiency.note,
                severity: deficiency.severity,
                limitation: false,
                itemType: 'ReportDeficiency',
                itemId: deficiency.id,
              })}
            />
          )}
        </div>
        <div className={styles.headerContainer}>
          <p className={styles.labelValue}>{deficiency.title}</p>
        </div>
        {!isMediaOnly && <p className={styles.deficiencyDetailText}>{deficiencyDetail}</p>}
      </div>

      {!isMediaOnly && deficiencyLocations.length > 0 && (
        <Locations
          reportLocations={deficiencyLocations}
          reInspectionStatus={deficiency?.reInspectionStatus}
        />
      )}

      {originalDeficiency ? (
        <>
          <Attachments
            title={(
              <span>
                <b style={{ fontWeight: 700 }}>Original</b> photos / videos
              </span>
            )}
            attachments={originalDeficiencyAttachments}
            imageWidth={imageWidth}
            isRehab={isRehab}
            handleWorkItemModal={handleWorkItemModal}
            systemName={systemName}
            subsystemName={subsystemName}
            noteTitle={deficiency.title}
            noteDetails={deficiency.detail}
            note={deficiency.note}
            badgeSeverity={deficiency.severity}
            isLimitation={false}
            handleOpenReportMediaUploader={() => {
              handleOpenReportMediaUploader(deficiency.id, 'ReportDeficiency', system?.reportSystemGroupId);
            }}
          />
          {originalDeficiency.note && (
            <div className={styles.commentContainer}>
              <p className={styles.labelHeader}><b>Original</b> Note</p>
              <p className={styles.commentText}>{originalDeficiency.note}</p>
            </div>
          )}
          <Attachments
            title={(
              <span>
                <b style={{ fontWeight: 700 }}>New</b> photos / videos
              </span>
            )}
            attachments={deficiencyAttachments}
            imageWidth={imageWidth}
            isRehab={isRehab}
            handleWorkItemModal={handleWorkItemModal}
            systemName={systemName}
            subsystemName={subsystemName}
            noteTitle={deficiency.title}
            noteDetails={deficiency.detail}
            note={deficiency.note}
            badgeSeverity={deficiency.severity}
            isLimitation={false}
            handleOpenReportMediaUploader={() => {
              handleOpenReportMediaUploader(deficiency.id, 'ReportDeficiency', system?.reportSystemGroupId);
            }}
          />
          {deficiency.note && (
            <div className={styles.commentContainer}>
              <p className={styles.labelHeader}><b>New</b> Note</p>
              <p className={styles.commentText}>{deficiency.note}</p>
            </div>
          )}
        </>
      ) : (
        <Attachments
          title="Deficiency photos / videos"
          attachments={deficiencyAttachments}
          imageWidth={imageWidth}
          isRehab={isRehab}
          handleWorkItemModal={handleWorkItemModal}
          systemName={systemName}
          subsystemName={subsystemName}
          noteTitle={deficiency.title}
          noteDetails={deficiency.detail}
          note={deficiency.note}
          badgeSeverity={deficiency.severity}
          isLimitation={false}
          handleOpenReportMediaUploader={() => {
            handleOpenReportMediaUploader(deficiency.id, 'ReportDeficiency', system?.reportSystemGroupId);
          }}
        />
      )}

      {!isMediaOnly && !originalDeficiency && deficiency.note && (
        <div className={styles.commentContainer}>
          <p className={styles.labelHeader}>Note</p>
          <p className={styles.commentText}>{deficiency.note}</p>
        </div>
      )}
    </div>
  );
};

export default DeficiencyDetails;
