import { colorPaletteGrayscale50, colorBaseRedPrimary } from 'haven-design-system/build/typescript/es6';

import styled from 'styled-components';

export const MsaIframe = styled.iframe`
  width: 100%;
  height: ${'calc(100vh - 170px)'};
`;

export const BottomContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const DownloadLink = styled.a`
  padding: 10px;
  float: right;
  top: -80px;
  left: -20px;
  position: relative;
  background: ${colorPaletteGrayscale50};
  width: 60px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0px 1px 5px rgba(43, 43, 43, 0.2), 0px 3px 1px -2px rgba(43, 43, 43, 0.12), 0px 2px 2px rgba(43, 43, 43, 0.14);

`;

export const DownloadIcon = styled.img`
  position: relative;
  width: 20px;
  padding-top: 10px;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.p`
  font-size: 14px;
  margin-bottom: 0px;
`;

export const ErrorDiv = styled.div`
  color: ${colorBaseRedPrimary};
  font-size: 0.75rem;
  min-height: 20px;
`;
