import styled, { css } from 'styled-components';
import {
  colorBaseWhitePrimary,
  colorPaletteBlue200, colorBaseBluePrimary,
} from 'haven-design-system/build/typescript/es6';

export const Icon = styled.svg`
  width: 20px;
  height: 20px;
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<{ checked: boolean, disabled: boolean }>`
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? colorBaseBluePrimary : colorBaseWhitePrimary)};
  background: ${(props) => (props.disabled && colorPaletteBlue200)};
  border: 2px solid ${(props) => (props.disabled ? colorPaletteBlue200 : colorBaseBluePrimary)};
  border-radius: 3px;
  transition: all 150ms;

  ${(props) => props.disabled === false && css`
    &:hover {
      box-shadow: 0 0 0 2px ${colorBaseBluePrimary};
    }
  `}

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const CheckboxText = styled.div`
  font-size: 14px;
  margin-left: 15px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  justify-content: left;
`;

export const CheckboxesContainer = styled(CheckboxContainer)`
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
`;
