import styled from 'styled-components';
import {
  colorBaseBlackLight, colorBaseBlueLight, colorBaseBluePrimary,
  colorBaseRedPrimary, colorBaseGrayscaleOffWhite, colorBaseWhitePrimary,
} from 'haven-design-system/build/typescript/es6';

export const Background = styled.div`
  margin-top: 64px;
  height: 100%;
  width: 100%;
  background-color: ${colorBaseGrayscaleOffWhite};
  display: flex;
  justify-content: center;
`;

export const LoadingContainer = styled.div<{ isLoading: boolean }>`
  height: 464px;
  width: 511px;
  background-color: ${colorBaseWhitePrimary};
  border-radius: 4px;
  margin-top: 40px;
  padding: 24px;
  pointer-events: ${(props) => (props.isLoading ? 'none' : 'auto')};
`;

export const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
  color: ${colorBaseBlackLight};
  white-space: pre-wrap
`;

export const Text = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${colorBaseBlackLight};
  white-space: pre-wrap;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ErrorSection = styled.div`
  height: 24px;
  max-width: 488px;
`;

export const ErrorMessage = styled.h3`
  font-size: 14px;
  font-weight: 400;
  color: ${colorBaseRedPrimary};
  overflow-wrap: break-word;
`;

export const Link = styled.button`
  display: block;
  font-size: 16px;
  font-weight: 700;
  color: ${colorBaseBluePrimary};
  background-color: ${colorBaseWhitePrimary};
  border-width: 0px;
  padding: 0px;
  margin: 6px 0px 12px;
`;

export const SubmitButton = styled.button<{ disabled: boolean }>`
  display: block;
  height: 42px;
  width: 124px;
  background-color: ${(props) => (props.disabled ? colorBaseBlueLight : colorBaseBluePrimary)};
  color: ${colorBaseWhitePrimary};
  border-radius: 2px;
  border-width: 0px;
  margin: 0px auto;
`;

export const CheckMark = styled.img`
  margin: 0px 6px 8px 12px;
`;
