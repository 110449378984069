import { useQuery } from '@apollo/client';
import { UserContractor } from '../../types';
import { USER_CONTRACTOR } from '../../graphql/queries';

/**
 * Returns the GQL current user
 */
export const useCurrentUser = () => {
  const { data: queryData, ...rest } = useQuery<UserContractor>(USER_CONTRACTOR);

  const currentUser = queryData?.currentUser;

  return {
    currentUser,
    ...rest,
  };
};
