import { gql } from '@apollo/client';

export const GENERATE_REHAB_PDF = gql`
  mutation GenerateRehabProjectPdf($input: GenerateProjectPdfInput!) {
    generateRehabProjectPdf(input: $input) {
      rehabProjectPdfActiveStorageBlob {
        id
        contentType
        filename
        url
        byteSize
        createdAt
      }
    }
  }
`;

export const GENERATE_REHAB_CSV = gql`
  mutation GenerateRehabProjectCsv($input: GenerateProjectCsvInput!) {
    generateRehabProjectCsv(input: $input) {
      rehabProjectCsvActiveStorageBlob {
        id
        contentType
        filename
        url
        byteSize
        createdAt
      }
    }
  }
`;
