import { gql } from '@apollo/client';

const DELETE_ATTACHMENT = gql`
    mutation DeleteAttachment($input: DeleteReportAttachmentInput!) {
        deleteReportAttachment(input: $input) {
            reportAttachment {
                id
            }
        }
    }
`;

export default DELETE_ATTACHMENT;
