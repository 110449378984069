import {
  ADD_TEAM, GET_TEAM,
} from './types';
import { Team } from '../../types';
import { ApiAction } from './api';
import { captureException } from '../../utils/error';

export const addTeam = (
  team: Team,
) => (dispatch: any) => {
  dispatch({
    type: ADD_TEAM,
    payload: { team },
  });
};

export interface GetTeamArgs {
  teamToken: string,
}

export const getTeam = ({ teamToken }: GetTeamArgs) => ApiAction({
  method: 'GET',
  url: `/teams/${teamToken}`,
  onSuccess: (team) => addTeam(team),
  onFailure: (error) => {
    captureException(error);
    throw error.response;
  },
  label: GET_TEAM,
});
