import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getJwtToken } from './amplify_auth';
import { captureException } from './error';

/**
 * Add an interceptor that inserts a JWT token and passes it to all non-GET requests.
 */
export const addJwtInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const token = await getJwtToken();

      // eslint-disable-next-line no-param-reassign
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      captureException(error);
      return Promise.reject(error);
    },
  );
};
