import { AxiosRequestConfig } from 'axios';
import {
  API, API_END, API_ERROR, API_START,
} from './types';

export function ApiAction({
  url = '',
  method = 'GET',
  data = null,
  onSuccess,
  onFailure,
  label = '',
  config = {},
}: {
  url?: string;
  method?: string;
  data?: any;
  onSuccess?: (data: any) => any;
  onFailure?: (error: any, dispatch: any) => void;
  label?: string;
  config?: AxiosRequestConfig;
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      onSuccess,
      onFailure,
      label,
      config,
    },
  };
}

export const apiStart = (label: string) => ({
  type: API_START,
  payload: label,
});

export const apiEnd = (label: string) => ({
  type: API_END,
  payload: label,
});

export const apiError = (label: string, error: any) => ({
  type: API_ERROR,
  payload: label,
  error,
});
