import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import {
  Container,
} from 'react-bootstrap';
import {
  IdRef,
  ReportAttachment,
  ReportSystem,
} from 'marketplace-common';
import { uniq } from 'lodash';
import Spinner from '../../components/Spinner';
import styles from '../Reports/components/styles.module.scss';
import SystemDetails from '../Reports/components/SystemDetails';
import { ReportDeliveryFilter, WorkItemAttachment, WorkItemsInterface } from '../../types/models';
import { hideSystem } from '../../utils/report';
import RehabToolLayout from './layout';
import { useReportsPageContext } from '../../utils/reportsPageContext';
import { RehabTabs } from './types';
import WorkItemModal from './components/WorkItemModal';
import RehabWorkItems from './components/RehabWorkItems';
import PageErrorIndicator from '../../components/PageError/PageError';
import { usePageContext } from '../../utils/pageContext';
import ProjectHistoryPanel from './components/ProjectHistoryPanel';
import { ReportContainer, ReportRehabHistoryContainer } from './styles';
import IssueSummaryTable from '../Reports/components/IssueSummaryTable';
import { RehabToolContext } from './context';
import MediaUploader from '../../components/MediaUploader';
import { UploadedMedia } from '../../components/MediaUploader/useUpload';

interface Props {
  loading: boolean,
  handleFetchProjectHistory: (firstFetch: boolean) => void,
  publishProject: () => void,
  handleChangeTeamMarket: (teamMarketId: IdRef) => void;
}

const RehabToolPage: React.FC<Props> = ({
  loading,
  handleFetchProjectHistory,
  publishProject,
  handleChangeTeamMarket,
}) => {
  const { dispatch } = useReportsPageContext();
  const { state: pageState } = usePageContext();
  const summaryRef = useRef();

  const {
    state,
    set,
    setWorkItemModalOptions,
  } = useContext(RehabToolContext);

  const [systemNames, setSystemNames] = useState([] as string[]);

  const media = useMemo(() => Object.values(state?.report?.attachments || {}).reduce((acc, att) => {
    acc.push({ id: att.id, src: att.attachmentUrl, mediaType: att.mediaType });
    return acc;
  }, []), [state?.report?.attachments]);

  const handleAddMedia = (
    { attachmentIds, uploadedMedia }:
    { attachmentIds: string[], uploadedMedia: UploadedMedia[] },
  ) => {
    const workItemAttachments: (ReportAttachment | WorkItemAttachment)[] = [];
    attachmentIds.forEach((id) => {
      const attachment = state.report?.attachments[id];
      if (attachment) workItemAttachments.push(attachment);
    });
    uploadedMedia.forEach((file) => {
      workItemAttachments.push({
        id: file.id,
        workItemId: state.modalWorkItem?.id,
        workItemAssociationId: null,
        s3ObjectKey: file.s3ObjectKey,
        attachmentUrl: file.contentType === 'video' ? null : file.signedUrl,
        thumbnailUrl: file.contentType === 'video' ? file.signedUrl : null,
      });
    });
    set('workItemModalAttachments', [...state.workItemModalAttachments, ...workItemAttachments]);
    set('isUploadMediaModalOpen', false);
  };

  useEffect(() => {
    if (summaryRef.current) {
      dispatch({ type: 'add_ref', payload: summaryRef });
    }

    return () => {
      dispatch({ type: 'clear_refs' });
    };
  }, [summaryRef.current]);

  useEffect(() => {
    if (state.isRehabReadOnly || Object.keys(state.workItems).length > 0 || !state.report) {
      set('tab', RehabTabs.Rehab);
    } else {
      set('tab', RehabTabs.Report);
    }
  }, [state.isRehabReadOnly, state.report]);

  useEffect(() => {
    setSystemNames(
      uniq([...(state.sortedReportSystems as ReportSystem[]).filter(
        (system) => !hideSystem(system, state.report),
      ).map((system) => system.name),
      ...Object.values(state.workItems as WorkItemsInterface).map(
        (workItem) => workItem.systemName,
      )]),
    );
  }, [state.sortedReportSystems, state.workItems]);

  useEffect(() => {
    if (!state.isReInspection) {
      set('filter', ReportDeliveryFilter.FullReport);
    } else {
      set('filter', ReportDeliveryFilter.IssuesOnly);
    }
  }, [state.isReInspection, state.report?.report?.id]);

  if (loading) return <Spinner style={{ width: '100vw', height: '100vh' }} />;

  return (
    <RehabToolLayout
      publishProject={publishProject}
      handleChangeTeamMarket={handleChangeTeamMarket}
    >
      <div>
        <Container fluid className={styles.body}>
          {state.tab === RehabTabs.Report ? (
            <ReportRehabHistoryContainer>
              <ReportContainer>
                <div id="Summary" ref={summaryRef}>
                  <div className={styles.headerRow}>
                    <h1 className={styles.summaryText}>Summary</h1>
                  </div>
                </div>

                <IssueSummaryTable
                  report={state.report}
                  systems={state.sortedReportSystems}
                  filter={state.filter}
                />
                {Object.keys(state?.groupedReportSystemsByName || {}).map((group, index) => (
                  <SystemDetails
                    report={state.report}
                    groupedReportSystemsByName={state.groupedReportSystemsByName}
                    key={`${group}-${index + 1}`}
                    systemName={group}
                    imageWidth={state.imageWidth}
                    index={index}
                    filter={state.filter}
                    isPrint={false}
                    inspectifyAdmin={state.inspectifyAdmin}
                    isRehab
                    handleWorkItemModal={setWorkItemModalOptions}
                    noDeficienciesRequired={state.noDeficienciesRequired}
                    isInspectionWarrantyExpired={false}
                  />
                ))}
              </ReportContainer>
              {pageState.pageState?.isProjectHistoryPanelOpen && (
                <ProjectHistoryPanel
                  projectId={state.projectId}
                  isRehabReadOnly={state.isRehabReadOnly}
                  tab={state.tab}
                  setTab={(value) => set('tab', value)}
                  fetchProjectHistory={handleFetchProjectHistory}
                />
              )}
            </ReportRehabHistoryContainer>
          ) : (
            <ReportRehabHistoryContainer>
              <RehabWorkItems
                projectId={state.projectId}
                report={state.report}
                isRehabReadOnly={state.isRehabReadOnly}
                handleWorkItemModal={setWorkItemModalOptions}
              />
              {pageState.pageState?.isProjectHistoryPanelOpen && (
                <ProjectHistoryPanel
                  projectId={state.projectId}
                  isRehabReadOnly={state.isRehabReadOnly}
                  tab={state.tab}
                  setTab={(value) => set('tab', value)}
                  fetchProjectHistory={handleFetchProjectHistory}
                />
              )}
            </ReportRehabHistoryContainer>
          )}
        </Container>
      </div>
      <WorkItemModal
        systemNames={systemNames}
        close={() => setWorkItemModalOptions({ isOpen: false, attachments: [] })}
      />
      <MediaUploader
        visible={state.isUploadMediaModalOpen}
        media={media}
        title="Select media to add to work item"
        selectFromTitle="Select from inspection report"
        handleClose={() => set('isUploadMediaModalOpen', false)}
        handleAdd={handleAddMedia}
      />
      {state.projectQueryError
        && <PageErrorIndicator errorType={state.projectQueryError} />}
    </RehabToolLayout>
  );
};

export default RehabToolPage;
