import React, { useMemo } from 'react';
import { flatten } from 'lodash';
import { WorkItem } from '../../../../types';
import { formatMoney } from '../../../../utils/money';
import {
  Table, TableBody, TableHead, TableRow,
} from '../../styles';
import {
  SummaryTableHeader,
  SummaryTableSubheader,
} from './styles';
import WorkItemSection from './WorkItemSection';

interface Props {
  workItems: {
    [systemName: string]: WorkItem[],
  },
}

const Summary: React.FC<Props> = ({
  workItems,
}) => {
  const { totalCount, totalCost } = useMemo(() => {
    if (workItems) {
      const items = flatten(Object.values(workItems));
      return {
        totalCount: items.length,
        totalCost: items.reduce((sum, cur) => sum + (cur.totalPrice || 0), 0),
      };
    }
    return { totalCount: null, totalCost: null };
  }, [workItems]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <SummaryTableHeader>Summary</SummaryTableHeader>
        </TableRow>
        <TableRow>
          <SummaryTableSubheader>{totalCount} work items</SummaryTableSubheader>
          <SummaryTableSubheader isCost>
            <strong>Total:</strong> {formatMoney(totalCost)}
          </SummaryTableSubheader>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(workItems).map((systemName) => (
          <WorkItemSection
            key={systemName}
            title={systemName}
            items={workItems[systemName]}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Summary;
