import { SurveyorOnboardingType } from '../../../Onboarding/types';

export interface OnboardingTrackerProps {
  onboardingData: SurveyorOnboardingType;
}

export interface TrackerItemProps {
  header: string;
  instructions: string;
  isChecked: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export enum TrackerItemHeader {
  MISA = 'Sign Master Inspection & Services Agreement',
  BackgroundCheck = 'Submit background Check',
  Payment = 'Setup payments',
  App = 'Download the Inspectify App',
}

export enum TrackerItemInstructions {
  MISA = 'This is necessary before we can start sending you jobs.',
  BackgroundCheck = 'Click here to submit your background check through Checkr. There might be a delay before the submission is reflected here.',
  BackgroundCheckDisabled = 'We are generating your background check invitation. Please check back soon!',
  Payment = 'This is necessary for us to pay you for your work.',
  App = 'Our app can be found in the Apple App store or Google play store.',
}
