import React from 'react';
import { Container } from 'react-bootstrap';

import { Helmet } from 'react-helmet';
import styles from './styles.module.scss';
import InspectifyLayout from '../../../../components/InspectifyLayout';

const PermitsPageLayout: React.FC = ({
  children,
}) => (
  <>
    <Helmet>
      <title>Inspectify - Property Permits</title>
    </Helmet>
    <InspectifyLayout showFooter displayNavBar />
    <Container fluid className={styles.permitsPage}>
      {children}
    </Container>
  </>
);

export default PermitsPageLayout;
