import { IdRef, InspectionProposal, PropertyPhotoInterface } from 'marketplace-common';
import { SurveyorOnboardingType } from '../../pages/Onboarding/types';

export * from './rehab';
export * from './report';

// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
export interface CognitoUser {
  email: string,
  emailVerified: boolean,
  phoneNumber: string,
  phoneNumberVerified: boolean,
  sub: string,
  name: string,
  givenName: string,
  familyName: string,
  picture: string,
}

/**
 * Currently logged in user,
 * `false` if no user is logged in,
 * `null` if the current user hasn't been fetched
 */
export type CurrentUser = CognitoUser | false | null;

export type UserPermission =
  'view_new_jobs_tab' |
  'view_your_pay_field' |
  'view_payment_setup_page' |
  'contractor_admin';

export interface UserContractor {
  currentUser : User
}

export interface User {
  id: string,
  email: string,
  name: string,
  created_at: number,
  admin: boolean,
  teamId: number,
  teamAdmin: boolean,
  canManageUsers: boolean,
  contractorId: string,
  contractor: Contractor,
  archivedAt: number | null,
  activatedAt: number | null,
  grantedPermissions: UserPermission[],
  phoneNumber: string,
  team: {
    allowRehabToolAccess: boolean,
    name: string,
    token: string,
    drawSummaryTableDisplay: string,
    sowSummaryTableDisplay: string,
    rehabTeamMarketIds: string[],
  },
}

// TODO: (GA9-634) move this to marketplace-common
export interface UnauthenticatedContractor {
  id: string,
  businessAddress: string,
  businessName: string,
  city: string,
  state: string,
}

// TODO: (GA9-634) move this to marketplace-common
export interface AvailableUser {
  id: string,
  contractorId?: string,
  grantedPermissions: string[],
  contractor?: UnauthenticatedContractor,
  team?: Team,
}

export type CollectEnum = 'no_collect' | 'collect' | 'required';

export interface Stakeholder {
  id: number,
  name: string,
  phone: string,
  email: string,
  isClient: boolean,
  stakeholderType: string,
  orderId: number,
  collectEmail: CollectEnum,
  collectPhone: CollectEnum,
  collectName: CollectEnum
}

export interface LocalStakeholder extends Stakeholder {
  orderToken: string,
}

export interface StakeholderInterface {
  [stakeholderId: number]: LocalStakeholder
}

export interface StakeholdersInterface {
  [orderToken: string]: StakeholderInterface
}

export type Stakeholders = StakeholdersInterface | null;

export interface InspectionDeficiencyImage {
  id: number,
  link: string,
  name: string,
}

export type Section =
  | 'Roof' | 'Exterior' | 'Basement | Foundation | Crawlspace & Structure' | 'HVAC' | 'Plumbing'
  | 'Electrical' | 'Fireplace' | 'Attic | Insulation & Ventilation' | 'Doors | Windows & Interior'
  | 'Built-In Appliance' | 'Garage';

export type Severity = 'safety' | 'repair' | 'monitor';

export interface InspectionDeficiency {
  id: number,
  title: string,
  detail: string,
  inspectionId: number,
  inspectionDocumentId: number,
  standardDeficiencyId: number,
  quantity: number,
  images: InspectionDeficiencyImage[],
  section: Section,
  reportText: string,
  pageNumber: number,
  severity: Severity,
  futureEvaluationNeeded: boolean,
  repairCost: number,
  remedyTitle: string,
  remedyDetail: string,
  quantityDescriptor: string
}

export interface CreateOrUpdateInspectionDeficiency extends InspectionDeficiency {
  // For creating Standard Deficiency
  addDeficiencyToStandardList: boolean,
  standardDeficiencyRemedyId: number,
  // For creating Remedy
  minPrice: number,
  maxPrice: number,
  newRemedyFutureEvaluationNeeded: boolean,
}

export interface InspectionDocument {
  id: number,
  inspectionId: number,
  name: string,
  url: string,
  externalDocumentUrl: string,
  repairEstimateReadyEmailSentAt: number,
  createdAt: number
}

export interface LocalInspectionProposal extends InspectionProposal {
  orderToken: string,
  inspectionType?: string
}

export interface InspectionProposalInterface {
  [inspectionProposalId: string]: LocalInspectionProposal
}

export interface InspectionProposalsInterface {
  [inspectionId: number]: InspectionProposalInterface
}

export type InspectionProposals = InspectionProposalsInterface | null;

export interface InspectionBid {
  contractorId: number,
  price: string,
  durationHour: string,
  requiresScheduling: boolean | null,
  isAgentPreferred: boolean
}

export interface LocalInspectionBid extends InspectionBid {
  orderToken: string,
  inspectionId: number
}

export interface InspectionBidInterface {
  [inspectionId: number]: LocalInspectionBid[]
}

export type InspectionBids = InspectionBidInterface | null;

export interface Contractor {
  currentOnboarding: SurveyorOnboardingType;
  id: string,
  token: string,
  contractorType: 'inspector' | 'showami' | 'redfin' | 'observa' | 'taskrabbit' | 'team_internal' | 'surveyor',
  businessName?: string,
  businessSite?: string,
  latitude?: number,
  longitude?: number,
  certifications?: string,
  ashiLicenseNumber?: string,
  internachiLicenseNumber?: string,
  otherLicenseNumber?: string,
  stateLicenseNumber?: string,
  inBusinessSince?: string,
  state?: string,
  country?: string,
  logo?: { url: string },
  preferredInspectorStatus: string,
  instantBookingEnabled?: boolean,
  businessContactName?: string,
  businessPhone?: string,
  businessEmail?: string,
  businessAddress?: string,
  schedulerPhone?: string,
  schedulerEmail?: string,
  schedulerName?: string,
  sendAutomatedSms: boolean,
  serviceZipCodes?: string[] | [],
  serviceAreaRadiusMileage?: number,
  hasSupra: boolean,
  reportWritingSoftware?: string,
  msaSigned: boolean,
  tipaltiIsPayable: boolean,
  fileAttachments?: ContractorFileAttachment[] | [],
  contractorLicenseHeld?: boolean,
  inspectorLicenseHeld?: boolean,
  realEstateLicenseHeld?: boolean,
}

export interface ContractorFileAttachment {
  id: string,
  note: string | null,
  category: string,
  createdAt: number,
  updatedAt: number,
  attachment: {
    filename: string | null,
    url: string | null
  }
}

export interface ContractorInterface {
  [contractorId: number]: Contractor
}

export type Contractors = ContractorInterface | null;

export interface UsersInterface {
  [userId: string]: User
}

export type Users = UsersInterface | null;

export interface Property {
  id: IdRef,
  address: string,
  street: string,
  street2: string,
  city: string,
  state: string,
  zipCode: string,
  country: string,
  homeType: string,
  totalSquareFeet: string,
  bedrooms: number,
  bathrooms: number,
  yearBuilt: number,
  photo: PropertyPhotoInterface,
  timezone: string
}

export interface LocalProperty extends Property {
  orderToken: string
}

export interface PropertiesInterface {
  [propertyId: number]: LocalProperty
}

export type Properties = PropertiesInterface | null;

export interface Order {
  id: number,
  status?: string,
  paymentType?: string,
  propertyId?: number,
  property?: Property,
  teamId: number,
  token?: string,
  teamToken?: string,
  readOnlyToken: string,
  adminEditUrl?: string,
  createdAt?: number,
  clientName?: string,
  clientEmail?: string,
  clientPhone?: string,
  agentName?: string,
  agentEmail?: string,
  agentPhone?: string,
  specialInstructions?: string,
}

export interface Team {
  id: number,
  token: string,
  name: string,
  inspectify: boolean,
  logoUrl: string | null,
  rehabTags: string[],
  templateWorkItems: string[],
  canPublishRehabProjects?: boolean,
}

export interface TeamsInterface {
  [teamId: string]: Team
}

export interface Inspection {
  id: number,
  orderId: number,
  status: string,
  inspectionType: string,
  token: string,
  inspectionDocumentReadyEmailSentAt: number,
  scheduledProposalId: number,
  createdAt: number,
  appRequirement: string,
}

export interface LocalInspection extends Inspection {
  orderToken: string
  bids?: LocalInspectionBid[]
  proposals?: LocalInspectionProposal[]
}

export interface InspectionInterface {
  [inspectionId: number]: LocalInspection
}

export interface InspectionsInterface {
  [orderToken: string]: InspectionInterface // LocalInspection[]
}

export type Inspections = InspectionsInterface | null;

export interface StandardDeficiency {
  id: number,
  title: string,
  detail: string,
  section: string,
  severity: string,
  standardDeficiencyRemedyId: number,
}

export interface StandardDeficiencyRemedy {
  id: number,
  title: string,
  detail: string,
  minPrice: number,
  maxPrice: number,
  quantityDescriptor: string,
}

export interface OrdersInterface {
  [token: string]: Order
}

export type Orders = OrdersInterface | null;

export enum FileTypes {
  INSURANCE = 'insurance_certificate',
  CONTRACT = 'contract', // this is the Master Inspection Agreement
}

export interface UpdateContractorReturnDataType {
  updateContractor: {
    success: boolean,
    code: string,
    errors: string,
    message: string,
    contractor: Contractor
  }
}

export type ContractorPaymentMailingAddress = {
  line1: string,
  line2?: string,
  city: string,
  state: string,
  postalCode: string,
  country: string
};

export interface GenerateTipaltiIframeUrlDataType {
  generateTipaltiIframeUrl: {
    iframeUrl: string
  }
}

// TODO: Move this to marketplace-common and add values for other feedback projects
export enum ReviewableType {
  REPORT = 'Report',
}

// TODO: Move this to marketplace-common for use with othe feedback projects
export type UserFeedback = {
  id: IdRef,
  reviewableId: string,
  reviewableType: ReviewableType,
  note?: string,
  score?: string,
  initiatedAt?: number,
  submittedAt?: number,
  userId?: IdRef,
  createdAt: number,
  updatedAt: number,
};

export type OrderByTokenVariables = {
  token: string;
};

export enum UserPermissionEnum {
  Admin = 'Admin',
  Offers = 'Offers',
  Pricing = 'Pricing',
  None = 'None',
}

export const PERMISSION_NAMES: { [permission: string]: UserPermissionEnum } = {
  contractor_admin: UserPermissionEnum.Admin,
  view_new_jobs_tab: UserPermissionEnum.Offers,
  view_your_pay_field: UserPermissionEnum.Pricing,
};

export enum UserStatusEnum {
  Active = 'Active',
  InviteSent = 'Invite sent',
  Archived = 'Archived',
}
