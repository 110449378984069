import { gql } from "@apollo/client";

export const GENERATE_REPORT_PDF = gql`
  mutation GenerateReportPdf($input: GeneratePdfReportInput!) {
    generatePdfReport(input: $input) {
      pdfReportActiveStorageBlob {
        id
        contentType
        filename
        url
        byteSize
        createdAt
      }
    }
  }
`;
