/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable max-len */
import React from 'react';
import cn from 'classnames';
import isNil from 'lodash/isNil';
import { InformationInputType } from 'marketplace-common';
import styles from './styles.module.scss';

interface InformationItemProps {
  label: string;
  value: string | null | undefined;
  isLink?: boolean;
  isSignature?: boolean;
  questionType?: string;
  cannotAnswer?: boolean;
}

const InformationItem: React.FC<InformationItemProps> = ({
  label, value, isLink, isSignature, questionType, cannotAnswer,
}) => {
  const isCannotAnswer = Boolean(cannotAnswer);
  const isApplianceQuestionType = questionType === (InformationInputType.appliance || InformationInputType.applianceTag);
  const renderedValue = isNil(value) ? '--' : value;

  if (isApplianceQuestionType && !isCannotAnswer) return null;

  return (
    <div className={styles.informationContainer}>
      {(!isSignature) && <p className={styles.labelHeader}>{label}</p>}
      <p className={cn(styles.labelValue, isSignature && styles.signature)}>
        {isLink ? <a href={renderedValue}>{renderedValue}</a> : renderedValue}
      </p>
    </div>
  );
};

interface InformationProps {
  header?: string
  isSignature?: boolean
  data: { label: string, value: string | null | undefined, isLink?: boolean }[]
}

const Informations: React.FC<InformationProps> = ({ header, data, isSignature }) => (
  <div className={styles.sectionBody} style={{ marginBottom: header === 'Client info' ? 32 : 0 }}>
    {header && !isSignature && (
      <h3 className={styles.sectionHeader} style={{ paddingLeft: header === 'Client info' ? 18 : 0 }}>
        {header}
      </h3>
    )}

    <div className={styles.informationsContainer}>
      {data.map((info, index) => (
        <InformationItem
          key={`${info.label}-${info.value}-${index + 1}`}
          isSignature={isSignature}
          {...info}
        />
      ))}
    </div>
  </div>
);

export default Informations;
