import React, { useEffect, useState } from 'react';
import { getFormattedString, InspectionDocument, LocalInspection } from 'marketplace-common';
import { FileUploadDetails } from '../../../../types';
import ReportUploadRow from './ReportUploadRow';
import { getFileDetailsToDisplay } from '../../../../../../utils/file_upload';
import { useTypedSelector } from '../../../../../../redux/store';
import { selectWorksReports } from '../../../../../../redux/selectors';

interface Props {
  inspection: LocalInspection;
  inspectionDocuments: InspectionDocument[];
  onUploadClick: (inspection: LocalInspection) => void;
}

const ReportUploadRowLoader: React.FC<Props> = ({
  inspection,
  inspectionDocuments,
  onUploadClick,
}) => {
  const reports = useTypedSelector(selectWorksReports);
  const allowsUploading = (
    inspection?.inspectionType !== 'cancellation_fee'
    && inspection?.inspectionType !== 'trip_fee'
  );
  const reportName = `${getFormattedString(inspection.inspectionType, true)} Report`;
  const [fileUploadDetails, setFileUploadDetails] = useState([] as FileUploadDetails[]);

  useEffect(() => {
    if (inspectionDocuments && inspection && reports) {
      setFileUploadDetails(getFileDetailsToDisplay(inspectionDocuments, inspection, reports));
    }
  }, [inspectionDocuments, inspection, reports]);

  return (
    <ReportUploadRow
      inspection={inspection}
      fileUploadDetails={fileUploadDetails}
      reportName={reportName}
      allowsUploading={allowsUploading}
      onUploadClick={onUploadClick}
    />
  );
};

export default ReportUploadRowLoader;
