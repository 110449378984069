import axios from 'axios';
import humps from 'humps';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';

/**
 * Custom axios instance that formats JSON data correctly and handles session expiration.
 */
const axiosInstance = axios.create({
  baseURL: `${process.env.MARKETPLACE_URL}/api`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest', // needed for `request.xhr?` to work in Rails
  },
  transformRequest: [
    // convert camelCase to snake_case, treating numbers as capital letters
    (data) => JSON.stringify(humps.decamelizeKeys(data, { split: /(?=[A-Z0-9])/ })),
  ],
  transformResponse: [
    // convert snake_case to camelCase
    (data) => humps.camelizeKeys(data),
    // map `null` to `undefined` to align with optional values in TypeScript
    (data) => {
      const transformObject = (object: any) => {
        if (isPlainObject(object)) {
          return mapValues(object, (value) => (value === null ? undefined : value));
        }
        return object;
      };

      if (isArray(data)) {
        return data.map(transformObject);
      }
      return transformObject(data);
    },
  ],
  responseType: 'json',
});

export default axiosInstance;
