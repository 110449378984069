import React from 'react';
import styles from './styles.module.scss';

interface Props {
  title: any,
  externalLinks: { label: string, url: string }[]
}

const ExternalLinks: React.FC<Props> = ({
  title,
  externalLinks,
}) => {
  if (externalLinks.length === 0) { return null; }

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>{title}</h3>

      <div>
        {externalLinks.map(({ label, url }) => (
          <div key={url} className={styles.externalLink}>
            <iframe
              width="360"
              height="240"
              src={url}
              title={label}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExternalLinks;
