import React from 'react';
import NotificationIcon from 'haven-design-system/build/assets/svgs/display/announcement.svg?component';
import OpenLinkIcon from 'haven-design-system/build/assets/svgs/action/open-link.svg?component';
import './styles.scss';

interface Props {
  type?: 'default' | 'error' | 'warning' | 'success'
  header?: string
  body?: string
  action?: string | null
  link?: string | null
  onAction?: (opts: any) => any
  linkHref?: string | null
}

const Banner: React.FC<Props> = ({
  type = 'default',
  header,
  body,
  action,
  link,
  onAction,
  linkHref,
}) => (
  <div className={`banner${type !== 'default' ? `--${type}` : ''}`}>
    <div className="banner__content">
      <div>
        {header && (
          <div className="banner__header">
            <NotificationIcon /> {header}
          </div>
        )}
        {body && (
          <div className="banner__body">
            {body}
          </div>
        )}
      </div>

      {action && (
        <button type="button" className="banner__button" onClick={onAction}>{action}</button>
      )}
      {link && (
        <a className="banner__link" href={linkHref} target="_blank" rel="noreferrer">
          <OpenLinkIcon /> &nbsp; {link}
        </a>
      )}
    </div>
  </div>
);

export default Banner;
