import styled, { createGlobalStyle } from 'styled-components';
import { colorBaseGrayscaleOffWhite } from 'haven-design-system/build/typescript/es6';
import { mediaBreakpointDown } from '../../styles/mediaBreakpoints';

export const InspectorDashboardGlobalStyles = createGlobalStyle`
  body {
    background-color: ${colorBaseGrayscaleOffWhite};
  }
`;

export const InspectorDashboardContent = styled.div`
  padding: 64px 0px 0px 320px;
  height: 100%;
  background-color: ${colorBaseGrayscaleOffWhite};
  
  @media only screen and ${mediaBreakpointDown.md} {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const PageErrorMessage = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  width: 100%;
  justify-content: center;
`;
