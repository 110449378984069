import { groupBy, sortBy } from 'lodash';
import { Permit } from 'marketplace-common';
import React, { useMemo, useState } from 'react';
import { PermitModal } from '../modal/PermitModal';
import PermitRow from './PermitRow';
import styles from './styles.module.scss';

interface TableProps {
  permits: Permit[];
}

const PermitsTable: React.FC<TableProps> = ({ permits }) => {
  const [selectedPermit, setSelectedPermit] = useState<Permit>();
  const [isPermitModalOpen, setIsPermitModalOpen] = useState(false);
  const permitsByIssueDate = useMemo(() => {
    const dictionary = groupBy(permits, ({
      fileDate,
      issueDate,
      finalDate,
    }) => (new Date(finalDate || issueDate || fileDate).getFullYear()));

    Object.keys(dictionary).forEach((key) => {
      dictionary[key] = sortBy(
        dictionary[key],
        [(permit) => permit.issueDate || permit.fileDate || permit.finalDate],
      );
    });
    return dictionary;
  }, [permits]);

  const permitTables = useMemo(() => Object.keys(permitsByIssueDate).reverse().map((issueDate) => (
    <div
      key={issueDate}
      className={styles.tableSection}
    >
      <h2 className={styles.tableTitle}>
        {issueDate === 'null' ? 'N/A' : issueDate}
      </h2>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {permitsByIssueDate[issueDate].map((permit) => (
              <PermitRow
                key={permit.id}
                permit={permit}
                isModalOpen={setIsPermitModalOpen}
                setSelectedPermit={setSelectedPermit}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )), [permitsByIssueDate]);

  return (
    <>
      {permitTables}
      {selectedPermit ? (
        <PermitModal
          permit={selectedPermit}
          isOpen={isPermitModalOpen}
          closeModal={() => setIsPermitModalOpen(false)}
        />
      ) : null}
    </>
  );
};

export default PermitsTable;
