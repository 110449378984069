import { useEffect, useMemo, useReducer } from 'react';
import uniq from 'lodash/uniq';
import { IdRef } from 'marketplace-common';
import {
  Team,
  TemplateWorkItemPricing,
  WorkItem,
} from '../../../../../../types';
import { useTypedSelector } from '../../../../../../redux/store';
import { selectRehab, selectTeam } from '../../../../../../redux/selectors';

export type WorkItemFormState = {
  systemName: string
  title: string
  description: string
  unit: string
  tags: string[]
  unitCost: number
  totalCost: number
  quantity: number
  saveToLibrary: boolean
  useLumpSumCost: boolean
  isRemoveModalOpen: boolean
  teamMarketId?: string
  templateWorkItemPricingId?: string
  pricingExternalId?: string
};

export type WorkItemFormComputedState = {
  team: Team
  tags: string[]
  calculatedTotal: number
  workItemPricing?: TemplateWorkItemPricing | null
  workItemPricings: TemplateWorkItemPricing[]
};

export const defaultExternalTagTeamIds = ['2426', '2853']; // Second Ave, Sovereign

export const createInitialState = (
  systemNameOrigin = '',
  workItem = {} as WorkItem,
  teamId = '' as IdRef,
  modalTemplateWorkItemPricingId?: string | null,
): WorkItemFormState => ({
  systemName: systemNameOrigin || '',
  title: workItem?.title || '',
  description: workItem?.details || '',
  unit: workItem?.unit || '',
  unitCost: workItem?.pricePerUnit || 0,
  totalCost: workItem?.totalPrice || 0,
  tags: workItem?.tags || (defaultExternalTagTeamIds.includes(String(teamId)) ? ['External'] : []),
  quantity: workItem?.quantity || 1,
  saveToLibrary: false,
  useLumpSumCost: !workItem?.quantity || false,
  isRemoveModalOpen: false,
  teamMarketId: '',
  templateWorkItemPricingId: modalTemplateWorkItemPricingId || '',
  pricingExternalId: '',
});

export const reducer = (state: WorkItemFormState, action: any) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.key]: action.value };
    case 'SET':
      return { ...state, ...action.value };
    default:
      return state;
  }
};

type WorkItemFormStateParams = {
  teamId: IdRef
  systemNameOrigin: string
  workItem: WorkItem
  modalTemplateWorkItemPricingId?: string | null
};

export const useWorkItemFormState = ({
  teamId,
  systemNameOrigin,
  workItem,
  modalTemplateWorkItemPricingId,
}: WorkItemFormStateParams) => {
  const initialState = createInitialState(
    systemNameOrigin,
    workItem,
    teamId,
    modalTemplateWorkItemPricingId,
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  const rehab = useTypedSelector(selectRehab);
  const team = useTypedSelector((reduxState) => selectTeam(reduxState, teamId));

  const workItemPricing = useMemo(() => {
    if (!state.templateWorkItemPricingId) return null;
    return rehab.templateWorkItemPricings?.[state.templateWorkItemPricingId];
  }, [state.templateWorkItemPricingId, rehab.templateWorkItemPricings]);

  const templateWorkItem = useMemo(() => {
    if (!workItemPricing?.templateWorkItemId) return null;
    return rehab.templateWorkItems?.[workItemPricing.templateWorkItemId];
  }, [workItemPricing, rehab.templateWorkItems]);

  const workItemPricings = useMemo(() => {
    if (!templateWorkItem) return [];
    return Object.values(rehab.templateWorkItemPricings).filter((pricing) => (
      pricing.templateWorkItemId === templateWorkItem.id
    ));
  }, [templateWorkItem, rehab.templateWorkItemPricings]);

  const tags = useMemo(() => {
    let rehabTags = team?.rehabTags || [];
    const items = Object.values(rehab.workItems);
    if (items.length) {
      rehabTags = uniq(items.reduce((acc, item) => (
        item.tags.length ? [...acc, ...item.tags] : acc
      ), rehabTags));
    }
    return rehabTags;
  }, [team, rehab.workItems]);

  const calculatedTotal = useMemo(() => (
    state.quantity * state.unitCost
  ), [state.quantity, state.unitCost]);

  useEffect(() => {
    if (workItemPricing) {
      dispatch({
        type: 'SET',
        value: {
          teamMarketId: workItemPricing.teamMarketId,
          templateWorkItemPricingId: workItemPricing.id,
          pricingExternalId: workItemPricing.pricingExternalId,
        },
      });
    }
  }, [workItemPricing]);

  const computedState: WorkItemFormComputedState = {
    team,
    tags,
    calculatedTotal,
    workItemPricing,
    workItemPricings,
  };

  return {
    state: state as WorkItemFormState,
    computedState,
    dispatch,
    set: (value: any) => dispatch({ type: 'SET', value }),
    update: (key: string, value: any) => dispatch({ type: 'UPDATE', key, value }),
  };
};
