import React, { useEffect, useRef } from 'react';
import assetIconDisplayArrowCircleUp from 'haven-design-system/build/assets/svgs/display/arrow-circle-up.svg';
import assetIconDisplayArrowCircleDown from 'haven-design-system/build/assets/svgs/display/arrow-circle-down.svg';
import useOnScreen from '../../../../../utils/useOnScreen';
import {
  WorkHistoryCardChangeContainer,
  WorkHistoryCardChangeDirection,
  WorkHistoryCardContainer,
  WorkHistoryCardDate,
  WorkHistoryCardEventContainer,
  WorkHistoryCardEventField,
  WorkHistoryCardHeader,
  WorkHistoryCardNewValue,
  WorkHistoryCardUser,
  WorkHistoryCardValueChange,
} from './styles';
import { ProjectHistoryCard, ProjectHistoryChangeDirectionType, ProjectHistoryItemType } from '../../../../../types';
import { formatCreatedAtForCard } from '../../../../../utils/history';
import { useReportsPageContext } from '../../../../../utils/reportsPageContext';
import { RehabTabs } from '../../../types';

interface Props {
  workHistoryCard: ProjectHistoryCard;
  isLastItemInHistoryPanel: boolean;
  tab: RehabTabs;
  setTab?: (tab: RehabTabs) => void;
  fetchMoreWorkHistory: () => void;
}

const WorkHistoryCard: React.FC<Props> = ({
  workHistoryCard: {
    title,
    itemType,
    itemId,
    eventField,
    newValue,
    changeDirection,
    oldValue,
    user,
    createdAt,
  },
  isLastItemInHistoryPanel,
  tab,
  setTab,
  fetchMoreWorkHistory,
}) => {
  const { dispatch: contextDispatch, state: contextState } = useReportsPageContext();

  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    if (isVisible && isLastItemInHistoryPanel) fetchMoreWorkHistory();
  }, [isVisible, isLastItemInHistoryPanel]);

  return (
    <WorkHistoryCardContainer
      ref={ref}
      isActive={contextState.activeWorkItem === itemId}
      onClick={() => {
        if (itemType === ProjectHistoryItemType.WorkItem) {
          contextDispatch({ type: 'set_active_work_item', payload: itemId });
          if (tab === RehabTabs.Report) {
            setTab(RehabTabs.Rehab);
          }
        } else {
          contextDispatch({ type: 'set_active_work_item', payload: null });
        }
      }}
    >
      {title && (
        <WorkHistoryCardHeader
          id={`work-history-card-${title}`}
          title={title}
          isActive={contextState.activeWorkItem === itemId}
        >
          {title}
        </WorkHistoryCardHeader>
      )}
      <WorkHistoryCardEventContainer>
        <WorkHistoryCardEventField>{eventField}</WorkHistoryCardEventField>
        {newValue && (
          <WorkHistoryCardNewValue>{newValue}</WorkHistoryCardNewValue>
        )}
      </WorkHistoryCardEventContainer>
      {changeDirection && (
        <WorkHistoryCardChangeContainer>
          {(changeDirection === ProjectHistoryChangeDirectionType.increased) && (
            <img src={assetIconDisplayArrowCircleUp} alt="arrow-circle-up" className="mx-3" />
          )}
          {(changeDirection === ProjectHistoryChangeDirectionType.decreased) && (
            <img src={assetIconDisplayArrowCircleDown} alt="arrow-circle-down" className="mx-3" />
          )}
          <WorkHistoryCardChangeDirection>{changeDirection}</WorkHistoryCardChangeDirection>
          <WorkHistoryCardValueChange>{oldValue}</WorkHistoryCardValueChange>
        </WorkHistoryCardChangeContainer>
      )}
      <WorkHistoryCardUser>{user}</WorkHistoryCardUser>
      <WorkHistoryCardDate>{formatCreatedAtForCard(createdAt)}</WorkHistoryCardDate>
    </WorkHistoryCardContainer>
  );
};

export default WorkHistoryCard;
