import React from 'react';
import { Property } from 'marketplace-common';
import { colorBasePurpleLight } from 'haven-design-system/build/typescript/es6';
import CoverPage from '../../../../components/PDF/CoverPage';
import {
  DetailContainer,
  DetailLabel,
  DetailRow,
  DetailValue,
} from '../../../../components/PDF/styles';

const Cover: React.FC<{ property: Property }> = ({ property }) => (
  <CoverPage property={property} title="Rehab Project Report">
    <DetailContainer backgroundColor={colorBasePurpleLight}>
      <DetailRow>
        <DetailLabel>Year (age)</DetailLabel>
        <DetailValue>
          {property?.yearBuilt
            ? `${property.yearBuilt} (${(new Date().getFullYear()) - property.yearBuilt} years)`
            : '--'}
        </DetailValue>
      </DetailRow>
      <DetailRow>
        <DetailLabel>Bedrooms</DetailLabel>
        <DetailValue>
          {property?.bedrooms || '--'}
        </DetailValue>
      </DetailRow>
      <DetailRow>
        <DetailLabel>Bathrooms</DetailLabel>
        <DetailValue>
          {property?.bathrooms || '--'}
        </DetailValue>
      </DetailRow>
      <DetailRow>
        <DetailLabel>Square footage</DetailLabel>
        <DetailValue>
          {property?.totalSquareFeet
            ? `${property.totalSquareFeet} square feet`
            : '--'}
        </DetailValue>
      </DetailRow>
    </DetailContainer>
  </CoverPage>
);

export default Cover;
