import { CognitoUser } from '@aws-amplify/auth';
import { handleSignInWithNewPasswordError } from 'marketplace-common';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormInput } from '../../components/FormElements';
import { completeNewPassword } from '../../utils/amplify_auth';
import { captureException } from '../../utils/error';
import {
  Background, LoadingContainer, Header, Text, SubmitButton, ErrorMessage, ErrorSection,
} from './styles';

interface Props {
  signedInUser: CognitoUser,
  onSetNewPasswordSuccess: () => void,
}

const SetNewPasswordPage: React.FC<Props> = ({ signedInUser, onSetNewPasswordSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSetNewPassword = async () => {
    setIsLoading(true);
    try {
      await completeNewPassword(signedInUser, newPassword);
      onSetNewPasswordSuccess();
    } catch (err: any) {
      setIsLoading(false);
      handleSignInWithNewPasswordError(err, captureException, signedInUser,
        ({ title, subtitle }) => {
          setErrorMessage(`${title} ${subtitle}`);
        });
    }
  };

  useEffect(() => () => {
    setIsLoading(false);
  }, []);

  return (
    <Background>
      <LoadingContainer isLoading={isLoading} style={{ height: 562 }}>
        <Header>Almost there! Complete by setting your new password.</Header>
        <Text>
          This password will be used for accessing your inspector dashboard in the future.
        </Text>
        <form>
          <FormInput
            name="Enter new password"
            value={newPassword}
            type="password"
            onChange={(e) => {
              setNewPassword(e.target.value);
              if (errorMessage) setErrorMessage('');
            }}
            style={{ marginTop: 24, marginBottom: 32 }}
            indicateRequired={errorMessage !== ''}
          />
          <FormInput
            name="Confirm new password"
            value={confirmNewPassword}
            type="password"
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
              if (errorMessage) setErrorMessage('');
            }}
            style={{
              marginTop: 24, marginBottom: 12,
            }}
            indicateRequired={errorMessage !== ''}
          />
          <ErrorSection style={{ marginBottom: 16 }}>
            {errorMessage
              && (
              <ErrorMessage>{errorMessage}</ErrorMessage>
              )}
          </ErrorSection>
          <SubmitButton
            type="submit"
            disabled={newPassword === '' || confirmNewPassword === '' || isLoading}
            onClick={(e) => {
              e.preventDefault();
              if (newPassword === confirmNewPassword) {
                handleSetNewPassword();
              } else {
                setErrorMessage('Passwords do not match!');
              }
            }}
            style={{
              marginTop: 12,
            }}
          >
            {isLoading ? <Spinner animation="border" /> : 'Next'}
          </SubmitButton>
        </form>
      </LoadingContainer>
    </Background>
  );
};

export default SetNewPasswordPage;
