import cn from 'classnames';
import { Property } from 'marketplace-common';
import React from 'react';

import house_icon from '../../assets/icons/house.svg';
import { getAddressLabel } from '../../utils/report';

import styles from './styles.module.scss';

interface Props{
  property: Property,
}

const PropertyImage: React.FC<Props> = ({ property }) => (
  property ? (
    <div className={styles.propertyImageContainer}>
      {property?.photo?.url
        ? (
          <div
            className={cn(styles.propertyImage, styles.propertyImagePresent)}
            style={{ backgroundImage: `url(${property.photo.url})` }}
          />
        ) : (
          <div className={cn(styles.propertyImage, styles.propertyImagePlaceholder)}>
            <img src={house_icon} alt="PropertyImage" />
          </div>
        )}
      <p className={styles.propertyAddressText}>{getAddressLabel(property)}</p>
    </div>
  ) : null);

export const ReportPropertyImage: React.FC<Props> = ({ property }) => (
  <div className={styles.reportPropertyImageContainer}>
    <PropertyImage property={property} />
  </div>
);

export const PermitsPropertyImage: React.FC<Props> = ({ property }) => (
  <div className={styles.permitsPropertyImageContainer}>
    <PropertyImage property={property} />
  </div>
);
