import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { slide as SlideMenu } from 'react-burger-menu';
import { InspectorDashboardContext } from '../context';
import InspectifyCobrandingLogo from '../../../components/InspectifyCobrandingLogo';
import { DashboardPageTab } from '../types';
import {
  Container, MenuButton, LogoContainer, burgerMenuStyles,
} from './styles';
import ContractorDetails from '../Menu/ContractorDetails';
import { signOut } from '../../../utils/amplify_auth';
import Footer from '../../../components/PageLayout/Footer';
import hamburger_icon from '../../../assets/icons/hamburger.svg';
import { UserContractor } from '../../../types';
import AccountSwitcher from '../../../components/AccountSwitcher/AccountSwitcher';
import { canAccessMSAPage } from '../../../utils/user';

interface Props {
  user: UserContractor,
}

const MobileMenu: React.FC<Props> = ({ user }) => {
  const { state, setPage } = useContext(InspectorDashboardContext);
  const [showMenu, setShowMenu] = useState(false);

  const showMSA = useMemo(() => (
    canAccessMSAPage(user)
  ), [user]);

  useEffect(() => {
    // Prevent background scroll when menu is open
    if (showMenu) {
      document.body.setAttribute('style', 'overflow: hidden;');
    } else {
      document.body.setAttribute('style', 'overflow: visible;');
    }
  }, [showMenu]);

  return (
    <Container>
      <LogoContainer isMenuOpen={showMenu}>
        <InspectifyCobrandingLogo
          display="inspectify"
          size="large"
        />
      </LogoContainer>
      <SlideMenu
        right
        isOpen={showMenu}
        onStateChange={({ isOpen }) => setShowMenu(isOpen)}
        styles={burgerMenuStyles}
        customBurgerIcon={<img src={hamburger_icon} alt="hamburger_icon" />}
      >
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
          <ContractorDetails contractor={user.currentUser.contractor} displayForMobile />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            { showMSA && (
              <MenuButton
                selected={state.page === DashboardPageTab.MasterInspectionAndServiceAgreement}
                onClick={() => {
                  setPage(DashboardPageTab.MasterInspectionAndServiceAgreement);
                  setShowMenu(false);
                }}
              >
                {DashboardPageTab.MasterInspectionAndServiceAgreement}
              </MenuButton>
            ) }
            <AccountSwitcher />
            <MenuButton
              selected={state.page === DashboardPageTab.Jobs}
              onClick={() => {
                setPage(DashboardPageTab.Jobs);
                setShowMenu(false);
              }}
            >
              {DashboardPageTab.Jobs}
            </MenuButton>
            <MenuButton
              selected={state.page === DashboardPageTab.BusinessDetails}
              onClick={() => {
                setPage(DashboardPageTab.BusinessDetails);
                setShowMenu(false);
              }}
            >
              {DashboardPageTab.BusinessDetails}
            </MenuButton>
            {user.currentUser.grantedPermissions.includes('view_payment_setup_page')
              && (
              <>
                <MenuButton
                  selected={state.page === DashboardPageTab.TipaltiSetupProcess}
                  onClick={() => {
                    setPage(DashboardPageTab.TipaltiSetupProcess);
                    setShowMenu(false);
                  }}
                >
                  {DashboardPageTab.TipaltiSetupProcess}
                </MenuButton>

                <MenuButton
                  selected={state.page === DashboardPageTab.TipaltiPaymentHistory}
                  onClick={() => {
                    setPage(DashboardPageTab.TipaltiPaymentHistory);
                    setShowMenu(false);
                  }}
                >
                  {DashboardPageTab.TipaltiPaymentHistory}
                </MenuButton>
              </>
              )}
            <MenuButton onClick={() => signOut()}>Log out</MenuButton>
          </div>
          <Footer sidebarFooter />
        </div>
      </SlideMenu>
    </Container>
  );
};

export default MobileMenu;
