import { TemplatesInterface } from 'marketplace-common';
import { ADD_TEMPLATE } from '../actions/templates';

export const initialTemplatesState: TemplatesInterface = {};

function reducer(state = initialTemplatesState, action: any) {
  switch (action.type) {
    case ADD_TEMPLATE: {
      const templateState = { ...state };

      templateState[action.payload.report.id] = action.payload;
      return templateState;
    }
    default:
      return state;
  }
}

export default reducer;
