import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import { groupBy, intersection } from 'lodash';
import { IdRef } from 'marketplace-common';
import { saveRehabProject } from '../../redux/actions/rehab';
import { FETCH_REHAB_PROJECT } from '../../graphql/queries';
import InspectifyLayout from '../../components/InspectifyLayout';
import RehabToolPdfPage from './RehabToolPdfPage';
import { captureException } from '../../utils/error';
import { useUrlQuery } from '../../utils/hooks';
import { selectProperties, selectRehabProject, selectWorkItems } from '../../redux/selectors';
import { useTypedSelector } from '../../redux/store';

interface Props extends RouteComponentProps<{
  projectId: string,
}> {}

const RehabToolPdfPageLoader: React.FC<Props> = ({ match }) => {
  const { projectId } = match.params;
  const query = useUrlQuery();
  const queryParamTags = query.get('tags');
  const dispatch = useDispatch();
  const [displayPrintReady, setDisplayPrintReady] = useState(false);

  const [propertyId, setPropertyId] = useState(null as IdRef);

  const imageWidth = 360;

  const properties = useTypedSelector(selectProperties);
  const project = useTypedSelector((state) => selectRehabProject(state, projectId));
  const workItems = useTypedSelector(selectWorkItems);

  const { loading: loadingRehabProject } = useQuery(FETCH_REHAB_PROJECT, {
    variables: { id: projectId, resizedAttachmentWidths: [imageWidth] },
    context: { headers: { project_id: projectId } },
    onCompleted: (data) => {
      dispatch(saveRehabProject(data));
      setPropertyId(data?.rehabProject?.propertyId);
    },
    onError: (e) => {
      captureException(e, { source: 'Rehab PDF loading', projectId });
    },
  });

  const tags = useMemo(() => (
    queryParamTags?.split(',') || []
  ), [queryParamTags]);

  const property = useMemo(() => {
    if (propertyId && properties?.[propertyId as number]) {
      return properties[propertyId as number];
    }
    return null;
  }, [propertyId, properties]);

  const groupedWorkItems = useMemo(() => {
    if (project && workItems) {
      const projectWorkItems = Object.values(workItems).filter((item) => {
        if (item.projectId !== projectId) return false;
        if (tags.length) return intersection(tags, item?.tags || []).length > 0;
        return true;
      });
      return groupBy(projectWorkItems, 'systemName');
    }
    return null;
  }, [project, workItems, tags]);

  useEffect(() => {
    if (project) {
      setTimeout(() => setDisplayPrintReady(true), 6000);
    }
  }, [project]);

  return (
    <>
      <Helmet>
        <title>{`Rehab Tool - ${property?.street}`}</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showFooter={false} />
      <RehabToolPdfPage
        property={property}
        loading={loadingRehabProject}
        imageWidth={imageWidth}
        workItems={groupedWorkItems}
      />
      {displayPrintReady && (
        <span id="printReady" />
      )}
    </>
  );
};

export default RehabToolPdfPageLoader;
