import React from 'react';

import styles from './styles.module.scss';

type LabelProps = {
  title: string;
  variant?: string;
};

export const Label: React.FC<LabelProps> = ({ title, variant }) => (
  <span className={variant ? styles[variant] : styles.primaryLabel}>
    {title}
  </span>
);
