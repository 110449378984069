import React from 'react';
import {
  Container, LogoImage, ContractorName,
} from './styles';
import account_placeholder from '../../../../assets/icons/account_placeholder.svg';
import { Contractor } from '../../../../types';

interface Props {
  contractor: Contractor,
  displayForMobile?: boolean,
}

const ContractorDetails: React.FC<Props> = ({ contractor, displayForMobile }) => (
  <Container isMobile={displayForMobile}>
    <LogoImage
      src={contractor.logo?.url ? contractor.logo.url : account_placeholder}
      isMobile={displayForMobile}
    />
    <ContractorName isMobile={displayForMobile}>
      {`${contractor.businessName}`}
    </ContractorName>
  </Container>
);

export default ContractorDetails;
