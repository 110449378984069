import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import './styles/global.scss';
import LogRocket from 'logrocket';
import Amplify from 'aws-amplify';
import store from './redux/store';
import App from './App';
import PageLayout from './components/PageLayout';
import awsmobile from './aws-exports';
import { addJwtInterceptor } from './utils/jwt_interceptor';
import axios from './utils/axios';
import createApolloClient from './graphql/createApolloClient';

Sentry.init({
  dsn: 'https://342ee42174464f84909b4b198d1d5e95@o397604.ingest.sentry.io/5644976',
  release: COMMIT_HASH,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.SENTRY_ENVIRONMENT,

  // Might need to reduce this
  tracesSampleRate: 1.0,
});

if (process.env.LOG_ROCKET_APP_ID) {
  LogRocket.init(process.env.LOG_ROCKET_APP_ID, { rootHostname: 'inspectify.com' });
}

addJwtInterceptor(axios);

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

// https://watilde.medium.com/how-to-use-a-custom-domain-of-cognito-hosted-ui-with-amplify-de6b772281ba
// https://github.com/aws-amplify/amplify-cli/issues/2792
awsmobile.oauth = {
  ...awsmobile.oauth,
  domain: process.env.COGNITO_DOMAIN,
  redirectSignIn: `${window.location.origin}/on_sign_in`,
  redirectSignOut: `${window.location.origin}/sign_in`,
};
Amplify.configure(awsmobile);

const render = () => {
  const ApolloClientProvider = createApolloClient();

  ReactDOM.render(
    <Provider store={store}>
      <ApolloClientProvider>
        <BrowserRouter>
          <Helmet>
            <title>Inspectify</title>
            <meta
              name="description"
              content="Request Home Inspection and access Home Inspection Report"
            />
          </Helmet>
          <PageLayout>
            <Elements stripe={stripePromise}>
              <App />
            </Elements>
          </PageLayout>
        </BrowserRouter>
      </ApolloClientProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}
