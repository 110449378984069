import styled from 'styled-components';
import {
  colorBaseBlackLight, colorBaseBlackPrimary,
  colorBaseBluePrimary, colorBaseGreyDark,
  colorBaseGrayscaleOffWhite,
  colorBaseWhitePrimary,
} from 'haven-design-system/build/typescript/es6';
import { mediaBreakpointUp } from '../../../styles/mediaBreakpoints';

export const Container = styled.div`
  height: 64px;
  background-color: ${colorBaseBluePrimary};
  display: flex;
  z-index: 1;
  @media only screen and ${mediaBreakpointUp.lg} {
    display: none;
  }
`;

interface LogoProps {
  readonly isMenuOpen?: boolean
}

export const LogoContainer = styled.div<LogoProps>`
  position: fixed;
  background-color: ${colorBaseBluePrimary};
  width: 100%;
  padding-left: 24px;
  z-index: ${(props) => (props.isMenuOpen ? 0 : 1)};
`;

interface MenuButtonProps {
  readonly selected?: boolean
}

export const MenuButton = styled.a<MenuButtonProps>`
  width: 100%;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? 700 : 500)};
  background-color: ${(props) => (props.selected ? colorBaseGrayscaleOffWhite : 'transparent')};
  color: ${colorBaseGreyDark};
  padding: 16px;
  &:hover {
    cursor: pointer;
    background-color: ${colorBaseGrayscaleOffWhite};
    text-decoration: none;
  }
`;

export const burgerMenuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '32px',
    height: '32px',
    right: '36px',
    top: '22px',
  },
  bmBurgerBars: {
    background: colorBaseWhitePrimary,
  },
  bmCrossButton: {
    height: '38px',
    width: '38px',
    right: '14px',
    top: '7px',
  },
  bmCross: {
    background: colorBaseBluePrimary,
    height: '24px',
    right: '8px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '320px',
  },
  bmMenu: {
    background: colorBaseWhitePrimary,
    paddingTop: '42px',
  },
  bmMorphShape: {
    fill: colorBaseBlackLight,
  },
  bmItemList: {
    color: colorBaseBlackPrimary,
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    width: '100%',
  },
};
