import styled, { css } from 'styled-components';
import {
  colorBaseBlueLight, colorBaseBluePrimary,
  colorBaseGreenDark, colorBaseWhitePrimary,
  colorPaletteBlue400, colorPaletteGreen400,
} from 'haven-design-system/build/typescript/es6';
import { CommonTextStyles } from '../../shared/styles';
import { mediaBreakpointDown } from '../../../../styles/mediaBreakpoints';

export const JobRowButton = styled.button<{
  completed: boolean,
  upload: boolean,
  appRequired: boolean,
  clickable: boolean
}>`
  ${CommonTextStyles};
  width: fit-content;
  align-self: center;
  margin: 16px;
  height: 25px;
  padding: 4px 16px;
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  ${(props) => (!props.clickable && (css`
  padding-right: 0;
  color: ${(props.appRequired && !props.completed ? colorBaseBluePrimary : colorBaseGreenDark)};
  background-color: ${colorBaseWhitePrimary};
  `))}
  ${(props) => (props.clickable && (css`
  color: ${colorBaseWhitePrimary};
  background-color: ${(props.upload ? colorBaseBluePrimary : colorBaseGreenDark)};
  &:hover {
    background-color: ${(props.upload ? colorPaletteBlue400 : colorPaletteGreen400)};
  }
  `))}
`;

export const JobRowCard = styled.div<{
  onClick: any,
  clickable: boolean,
}>`
  margin-top: 24px;
  background: ${colorBaseWhitePrimary};
  box-shadow: 0 0 8px rgba(207, 207, 207, 0.25);
  border-radius: 4px;
  display: flex;
  overflow: auto;
  &:hover {
    cursor: pointer;
    background-color: ${colorBaseBlueLight};
  }
  ${(props) => (!props.clickable) && css`
  &:hover ${JobRowButton} {
    background-color: ${colorBaseBlueLight};
  }  
  `}
 
  @media only screen and (${mediaBreakpointDown.md}) {
    flex-direction: column;
    max-width: 360px;
  }
`;

export const JobRowPic = styled.div`
  position: relative;
  width: 160px;
  height: 120px;

  @media only screen and (${mediaBreakpointDown.md}) {
    width: 100%;
    height: 100%;
    min-width: 160px;
  }
`;

export const JobRowInfo = styled.div`
  flex: 1;
  padding: 16px;
  flex-direction: column;
`;
