import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { DEPRECATED } from '../../graphql/createApolloClient';
import { CREATE_USER_CONTRACTOR } from '../../graphql/mutations';
import { isValidEmailFormat } from '../../utils/contact';
import { captureException } from '../../utils/error';
import { FormInput } from '../../components/FormElements';
import {
  Background, LoadingContainer, Header, Text, SubmitButton, ErrorMessage, ErrorSection,
} from './styles';

interface Props {
  contractorToken: string,
  onSendTemporaryPasswordEmailSuccess: (email: string) => void,
}

const InspectorSignUpPage: React.FC<Props> = ({
  contractorToken,
  onSendTemporaryPasswordEmailSuccess,
}) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [createUser, { loading }] = useMutation(CREATE_USER_CONTRACTOR, {
    context: { DEPRECATED },
    onCompleted: (data) => {
      if (data.createUser.success) {
        onSendTemporaryPasswordEmailSuccess(email.trim());
      } else {
        setErrorMessage('There was an error sending you a temporary password. Please try again later or contact customer support.');
        captureException('Error: Create User Contractor', { source: 'InspectorSignUpPage', data });
      }
    },
    onError: (error) => {
      setErrorMessage('There was an error sending you a temporary password. Please try again later or contact customer support.');
      captureException(error, { source: 'InspectorSignUpPage' });
    },
  });

  const sendEmailWithTemporaryPassword = () => {
    createUser({
      variables: {
        createUserInput: {
          email,
          contractor_token: contractorToken,
          name: fullName,
        },
      },
    });
  };

  const handleSubmitClick = () => {
    if (isValidEmailFormat(email.trim())) {
      sendEmailWithTemporaryPassword();
    } else {
      setErrorMessage('Incorrect Email Format.');
    }
  };

  return (
    <Background>
      <LoadingContainer isLoading={loading}>
        <Header>{'Let\'s get your account set up for your Inspector Dashboard.'}</Header>
        <Text>
          {'We\'ll send you an email with a temporary password.'}
        </Text>
        <form>
          <FormInput
            name="Full Name"
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
              if (errorMessage) setErrorMessage('');
            }}
            style={{ marginTop: 24 }}
          />
          <FormInput
            name="Email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (errorMessage) setErrorMessage('');
            }}
            style={{ marginTop: 24 }}
            indicateRequired={errorMessage !== ''}
          />
          <ErrorSection style={{ marginBottom: 16 }}>
            {errorMessage && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
          </ErrorSection>
          <SubmitButton
            type="submit"
            disabled={email === '' || fullName === '' || loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmitClick();
            }}
          >
            {loading ? <Spinner animation="border" /> : 'Next'}
          </SubmitButton>
        </form>
      </LoadingContainer>
    </Background>
  );
};

export default InspectorSignUpPage;
