import React, { useContext, useState } from 'react';
import { Col } from 'react-bootstrap';
import { slide as SlideMenu } from 'react-burger-menu';
import Filter from 'haven-design-system/build/assets/svgs/action/filter-view.svg?component';
import InspectifyLogo from '../../../../assets/inspectify/Inspectify_Logo_Color.svg';
import { downloadProjectCsv, downloadProjectPdf } from '../../../../utils/rehab';
import { ReportPropertyImage } from '../../../../components/PropertyImage/PropertyImage';
import { Tabs } from '../../../../components/Tabs';
import { RehabTabs } from '../../types';
import RehabMenuContainer from '../RehabMenuContainer';
import MenuItems from './MenuItems';
import { burgerMenuStyles } from '../../../Reports/components/Menu/styles';
import styles from '../../../Reports/components/Menu/styles.module.scss';
import { RehabToolContext } from '../../context';
import { useTypedSelector } from '../../../../redux/store';
import { selectProperties } from '../../../../redux/selectors';

interface Props {
  toggleReportFilterModal: (value: boolean) => void
  publishProject: () => void;
}

const MobileMenu: React.FC<Props> = ({
  toggleReportFilterModal,
  publishProject,
}) => {
  const {
    state, set, setWorkItemModalOptions,
  } = useContext(RehabToolContext);
  const properties = useTypedSelector(selectProperties);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <Col className={styles.mobileHeaderContainer}>
      <div className={styles.mobileHeaderRow}>
        <img src={InspectifyLogo} alt="Inspectify" className={styles.logo} />
        <SlideMenu
          right
          isOpen={isMobileMenuOpen}
          onStateChange={({ isOpen }) => setIsMobileMenuOpen(isOpen)}
          styles={burgerMenuStyles}
        >
          {state.tab === RehabTabs.Report && (
            <button
              id="filter-report"
              type="button"
              className={styles.buttonOutlined}
              onClick={() => toggleReportFilterModal(true)}
            >
              <Filter /> Filter: {state.filter}
            </button>
          )}

          {state.report?.report?.pdfDocumentUrl && (
            <a
              className={styles.buttonOutlined}
              href={state.report.report.pdfDocumentUrl}
              download
            >
              Download inspection report (PDF)
            </a>
          )}

          {state.projectId && (
            <button
              type="button"
              className={styles.buttonOutlined}
              onClick={() => downloadProjectPdf(state.projectId)}
            >
              Download rehab project PDF
            </button>
          )}

          {state.projectId && (
            <button
              type="button"
              className={styles.buttonOutlined}
              onClick={() => downloadProjectCsv(state.projectId)}
            >
              Download rehab project CSV
            </button>
          )}

          <Tabs
            tab={state.tab}
            tabs={Object.values(RehabTabs)}
            setTab={(value) => set('tab', value)}
            isFullWidth
          />

          {state.tab === RehabTabs.Report && (
            <MenuItems
              isMobileView
              onMobileItemPress={() => { setIsMobileMenuOpen(false); }}
            />
          )}

          {state.tab === RehabTabs.Rehab && (
            <RehabMenuContainer
              isRehabReadOnly={state.isRehabReadOnly}
              handleWorkItemModal={setWorkItemModalOptions}
              teamId={state.teamId}
              projectId={state.projectId}
              isPublishing={state.isPublishing}
              handlePublishRehabProject={publishProject}
            />
          )}
        </SlideMenu>
      </div>

      <div className={styles.propertyImageAndButtonSection}>
        {properties && properties[state.propertyId] && (
          <ReportPropertyImage property={properties[state.propertyId]} />
        )}
      </div>
    </Col>
  );
};

export default MobileMenu;
