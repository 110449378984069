import axios, { AxiosError } from 'axios';
import {
  InspectionDocument, LocalInspection, WorksReportsInterface, IdRef,
} from 'marketplace-common';
import SparkMD5 from 'spark-md5';
import { DEPRECATED, client } from '../graphql/createApolloClient';
import { ADD_FILE_ATTACHMENT, ADD_INSPECTION_DOCUMENT, DIRECT_UPLOAD } from '../graphql/mutations';
import { FileUploadDetails } from '../pages/InspectorDashboardPage/types';
import { getDatePublished } from './date';
import { convertUrlToFilename } from './string';

const getChecksum = (file: any): Promise<any> => new Promise((resolve) => {
  const chunkSize = 2097152; // Read in chunks of 2MB
  const spark = new SparkMD5.ArrayBuffer();
  const fileReader = new FileReader();

  let cursor = 0; // current cursor in file

  fileReader.onerror = () => {
    throw new Error('MD5 computation failed - error reading the file');
  };

  // read chunk starting at `cursor` into memory
  function processChunk(chunk_start: number): void {
    const chunkEnd = Math.min(file.size, chunk_start + chunkSize);
    fileReader.readAsArrayBuffer(file.slice(chunk_start, chunkEnd));
  }

  // when it's available in memory, process it
  // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
  // of `any` for `e` variable, otherwise stick with `any`
  // See https://github.com/Microsoft/TypeScript/issues/25510
  fileReader.onload = (e: any): void => {
    spark.append(e.target.result); // Accumulate chunk to md5 computation
    cursor += chunkSize; // Move past this chunk

    if (cursor < file.size) {
      // Enqueue next chunk to be accumulated
      processChunk(cursor);
    } else {
      // Computation ended, last chunk has been processed. Return as Promise value.
      // This returns the base64 encoded md5 hash, which is what
      // Rails ActiveStorage or cloud services expect
      resolve(btoa(spark.end(true)));

      // If you prefer the hexdigest form (looking like
      // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
      // resolve(spark.end());
    }
  };

  processChunk(0);
});

const getUploadURLandBlob = async (file: File, checksum: string) => {
  const { data, errors } = await client.mutate({
    mutation: DIRECT_UPLOAD,
    variables: {
      input: {
        byteSize: file.size,
        checksum,
        filename: file.name,
        contentType: file.type,
      },
    },
  });

  if (errors?.length) throw new Error(errors.toString());

  return data.directUpload;
};

const uploadToS3 = async (file: File, url: string, md5: string) => {
  const headers = {
    headers: {
      'Content-MD5': md5,
      'Content-Type': file.type,
    },
  };

  await axios.put(url, file, headers).catch((error: AxiosError) => {
    throw new Error(error.message);
  });
};

const addContractorAttachment = async (
  blobSignedId: string, category: string, id: string,
) => {
  const { data } = await client.mutate({
    context: { DEPRECATED },
    mutation: ADD_FILE_ATTACHMENT,
    variables: {
      id: parseInt(id, 10),
      input: {
        blob_signed_id: blobSignedId,
        category,
      },
    },
  });

  if (!data?.addFileAttachment?.success) {
    throw new Error(data.addFileAttachment.errors);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, ...names } = data.addFileAttachment.file_attachment;
  return { typename: 'ContractorFileAttachment', ...names };
};

const addInspectionDocument = async (
  blobSignedId: string, inspectionIds: IdRef[],
) => {
  const { data } = await client.mutate({
    context: { DEPRECATED },
    mutation: ADD_INSPECTION_DOCUMENT,
    variables: {
      input: {
        blob_signed_id: blobSignedId,
        inspection_ids: inspectionIds.map((id) => parseInt(id.toString(), 10)),
      },
    },
  });

  if (!data?.addInspectionDocument?.success) {
    throw new Error(data.addInspectionDocument.message);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, ...names } = data.addInspectionDocument.inspection_document;
  return { typename: 'InspectionDocument', ...names };
};

export const uploadContractorFile = async (file: File, category: string, contractorId: string) => {
  const checksum = await getChecksum(file);
  const directUploadResponse = await getUploadURLandBlob(file, checksum);
  await uploadToS3(file, directUploadResponse.url, checksum);
  return addContractorAttachment(directUploadResponse.blobSignedId, category, contractorId);
};

export const uploadInspectionFile = async (file: File, inspectionIds: IdRef[]) => {
  const checksum = await getChecksum(file);
  const directUploadResponse = await getUploadURLandBlob(file, checksum);
  await uploadToS3(file, directUploadResponse.url, checksum);
  return addInspectionDocument(directUploadResponse.blobSignedId, inspectionIds);
};

export const addInspectionUrl = async (
  url: string, inspectionIds: IdRef[],
) => {
  const { data } = await client.mutate({
    context: { DEPRECATED },
    mutation: ADD_INSPECTION_DOCUMENT,
    variables: {
      input: {
        external_document_url: url,
        inspection_ids: inspectionIds.map((id) => parseInt(id.toString(), 10)),
      },
    },
  });

  if (!data?.addInspectionDocument?.success) {
    throw new Error(data.addInspectionDocument.message);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, ...names } = data.addInspectionDocument.inspection_document;
  return { typename: 'InspectionDocument', ...names };
};

export const uploadPropertyOwnershipDocument = async (file: File) => {
  const checksum = await getChecksum(file);
  const directUploadResponse = await getUploadURLandBlob(file, checksum);
  await uploadToS3(file, directUploadResponse.url, checksum);
  return directUploadResponse.blobSignedId;
};

export const uploadClaimDocument = async (file: File) => {
  const checksum = await getChecksum(file);
  const directUploadResponse = await getUploadURLandBlob(file, checksum);
  await uploadToS3(file, directUploadResponse.url, checksum);
  return directUploadResponse.blobSignedId;
};

export const getFileDetailsToDisplay = (
  inspectionDocuments : InspectionDocument[],
  inspection : LocalInspection,
  reports : WorksReportsInterface,
) => {
  const uploadedFilesDetails : FileUploadDetails[] = [];
  if (inspectionDocuments?.length) {
    inspectionDocuments.forEach((doc) => {
      const fileDetails: FileUploadDetails = {
        id: 0,
        url: '',
        name: '',
        source: '',
      };

      if (doc.id) fileDetails.id = doc.id;
      if (doc.inspectionIds && (doc.externalDocumentUrl || doc.url) && doc.id) {
        if (doc.inspectionIds.includes(parseInt(inspection.id.toString(), 10))) {
          fileDetails.url = doc.externalDocumentUrl ? doc.externalDocumentUrl : doc.url;
          fileDetails.source = doc.externalDocumentUrl ? 'app' : 'dashboard';
          if (fileDetails.url.includes('spectora')) fileDetails.source = 'spectora';
          if (doc.name) fileDetails.source = 'other';
          switch (fileDetails.source) {
            case 'app':
              fileDetails.name = getDatePublished(inspection, reports, fileDetails.url);
              break;
            case 'dashboard':
              fileDetails.name = convertUrlToFilename(fileDetails.url);
              break;
            case 'spectora':
              fileDetails.name = doc.externalDocumentUrl;
              break;
            case 'other':
              fileDetails.name = doc.name;
              break;
            default:
              break;
          }
        }
      }
      uploadedFilesDetails.push(fileDetails);
    });
  }
  return uploadedFilesDetails;
};
