import {
  colorBaseBlackPrimary,
  colorPaletteGrayscale50,
  colorBaseGrayscaleOffWhite,
  colorBaseWhitePrimary,
  colorBaseGreyDark,
  colorPaletteOrange300,
} from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

export const WorkHistoryCardContainer = styled.div<{ isActive: boolean }>`
  width: 280px;
  margin: 4px;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.isActive ? colorPaletteOrange300 : colorPaletteGrayscale50)};
  cursor: pointer;
`;

export const WorkHistoryCardHeader = styled.div<{ isActive: boolean }>`
  width: 100%;
  height:  40px;
  padding: 8px 16px 8px 16px;
  font-weight: 700;
  font-size: 16px;
  background: ${(props) => (props.isActive ? colorPaletteOrange300 : colorBaseGrayscaleOffWhite)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WorkHistoryCardBody = styled.div`
  width: 100%;
  height:  100%;
  background: ${colorBaseWhitePrimary};
  display: flex;
  flex-direction: column;
`;

export const WorkHistoryCardEventContainer = styled.div`
  width: 100%;
  padding: 8px 16px 4px 16px;
  display: flex;
  flex-direction: row;
`;

export const WorkHistoryCardEventField = styled.div`
  color: ${colorBaseBlackPrimary};
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  padding-right: 4px;
`;

export const WorkHistoryCardNewValue = styled.div`
  color: ${colorBaseBlackPrimary};
  width: 60%;
  font-weight: 400;
  font-size: 16px;
  flex-wrap: wrap;
`;

export const WorkHistoryCardChangeContainer = styled.div`
  width: 100%;
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: row;
`;

export const WorkHistoryCardChangeDirection = styled.div`
  font-size: 14px;
  padding-right: 4px;
`;

export const WorkHistoryCardValueChange = styled.div`
  font-size: 14px;
  flex-wrap: wrap;
`;

export const WorkHistoryCardUser = styled.div`
  color: ${colorBaseGreyDark};
  font-size: 14px;
  height: 24px;
  padding: 0px 16px 4px 16px;
`;

export const WorkHistoryCardDate = styled.div`
  color: ${colorBaseGreyDark};
  font-size: 12px;
  height: 24px;
  padding: 0px 16px 16px 16px;
`;
