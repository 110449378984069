import { gql } from '@apollo/client';

export const INSPECTION_DOCUMENTS = gql`
  query InspectionDocuments($inspectionIds: [Int!]) {
    inspectionDocuments(inspectionIds: $inspectionIds) {
      id
      inspection_ids
      name
      url
      external_document_url
      created_at
      repair_estimate_ready_email_sent_at
      service_url
      images {
        id
        name
        page_number
        url
        service_url
      }
    }
  }
`;