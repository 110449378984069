import {
  TeamsInterface,
} from '../../types';
import {
  ADD_TEAM,
} from '../actions/types';

export const initialTeamState = {} as TeamsInterface;

export default function contractors(state: any = initialTeamState, action: any) {
  switch (action.type) {
    case ADD_TEAM: {
      return { ...state, [action.payload.team.token]: action.payload.team };
    }
    default:
      return state;
  }
}
