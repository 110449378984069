import { colorBaseBluePrimary, colorBaseGrayscaleOffWhite, colorBaseWhitePrimary } from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';
import { mediaBreakpointDown } from '../../../styles/mediaBreakpoints';

export const Container = styled.div`
  width: 320px;
  height: 100%;
  background-color: ${colorBaseWhitePrimary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  margin-top: 64px;
  padding-bottom: 64px;
  overflow-y: scroll;
  @media only screen and ${mediaBreakpointDown.md} {
    display: none;
  }
`;

interface MenuButtonProps {
  readonly selected: boolean
}

export const MenuButton = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.selected ? colorBaseGrayscaleOffWhite : 'transparent')};
  border: none;
  outline: none;
  width: 100%;
  &:hover {
    background-color: ${colorBaseGrayscaleOffWhite};
  }
`;

export const MenuButtonLabel = styled.div<MenuButtonProps>`
  font-size: 16px;
  color: ${colorBaseBluePrimary};
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  margin: 16px 0px 16px 24px;
  text-align: left;
`;
