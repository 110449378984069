import { colorBaseBlackDark, colorBaseBlueDark } from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

interface MobileProps {
  readonly isMobile?: boolean;
}

export const Container = styled.div<MobileProps>`
  width: 320px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.isMobile ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.isMobile ? '0 32px 16px 16px' : '0 0 38px 0')};
`;

export const LogoImage = styled.img<MobileProps>`
  width: ${(props) => (props.isMobile ? 64 : 104)}px;
  height: ${(props) => (props.isMobile ? 64 : 104)}px;
  border-radius: 50%;
  margin: ${(props) => (props.isMobile ? '0 6px 12px 6px' : '24px 0 12px')};
`;

export const ContractorName = styled.div<MobileProps>`
  text-align: center;
  font-size: ${(props) => (props.isMobile ? 16 : 20)}px;
  font-weight: ${(props) => (props.isMobile ? 400 : 700)};
  color: ${(props) => (props.isMobile ? colorBaseBlackDark : colorBaseBlueDark)};
  margin: ${(props) => (props.isMobile ? '0 32px 16px 0' : '0 24px')};
  text-wrap: wrap;
`;
