import React, { useMemo } from 'react';
import { Permit } from 'marketplace-common';
import styles from '../modal/styles.module.scss';
import { useUrlQuery } from '../../../../utils/hooks';
import { getFormattedMonthDayYear } from '../../../../utils/date';

type PermitDetailsTableProps = {
  permit: Permit
};

export const PermitDetailsTable : React.FC<PermitDetailsTableProps> = ({
  permit,
}) => {
  const query = useUrlQuery();
  const isPrintMode = query.get('print') === 'true';

  const { permitContractor } = permit;

  const isContractorDataAvailable = useMemo(
    () => permitContractor && Object.values(permitContractor).some((value) => value != null),
    [permitContractor],
  );

  const permitInfo = [
    { label: 'Permit Type', value: permit?.permitType || 'N/A' },
    { label: 'Permit Subtype', value: permit?.permitSubtype || 'N/A' },
    { label: 'Date Filed', value: permit.fileDate ? getFormattedMonthDayYear(new Date(permit.fileDate)) : 'N/A' },
    { label: 'Date Issued', value: permit.issueDate ? getFormattedMonthDayYear(new Date(permit.issueDate)) : 'N/A' },
    { label: 'Date Completed', value: permit.finalDate ? getFormattedMonthDayYear(new Date(permit.finalDate)) : 'N/A' },
    { label: 'Permit Status', value: permit?.status || 'N/A' },
    { label: 'Jurisdiction', value: permit?.jurisdiction || 'N/A' },
    { label: 'Job Value', value: permit?.number || 'N/A' },
    { label: 'Total Fees', value: permit?.fees || 'N/A' },
  ];

  const contractorInfo = [
    { label: 'Business Name', value: permit.permitContractor?.businessName || permit.permitContractor?.name || 'N/A' },
    { label: 'License', value: permit.permitContractor?.license || 'N/A' },
    { label: 'Phone', value: permit.permitContractor?.phone || 'N/A' },
    { label: 'Email', value: permit.permitContractor?.email || 'N/A' },
    { label: 'Location', value: `${permit.permitContractor?.city}, ${permit.permitContractor?.state}` || 'N/A' },
  ];

  return (
    <>
      {isPrintMode
        ? (
          <div>
            <h4 className={styles.headerFont}>
              Permit #{permit.number || permit.shovelsPermitId} Details
            </h4>
          </div>
        ) : null}

      <div className={isPrintMode ? styles.printMode : styles.webMode}>
        <section>
          <p>{permit.description}</p>
          <div className={styles.rowGroup}>
            <table>
              <tbody>
                {permitInfo.map(({ label, value }) => (
                  <tr key={label}>
                    <td className={styles.cellTitle}>{label}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        <section>
          <div className={styles.contractorHeaderSection}>
            <h5 className={styles.contractorHeader}>Contractor</h5>
          </div>
          {isContractorDataAvailable
            ? (
              <div className={styles.rowGroup}>
                <table>
                  <tbody>
                    {contractorInfo.map(({ label, value }) => (
                      <tr key={label}>
                        <td className={styles.cellTitle}>{label}</td>
                        <td>{value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <p>No contractor information available.</p>
              </div>
            )}
        </section>
      </div>
    </>
  );
};
