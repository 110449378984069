import { useQuery } from '@apollo/client';
import { ORDER_BY_TOKEN_TEAM } from '../../graphql/queries';
import { OrderByTokenVariables } from '../../types';

export const useOrderByToken = (variables?: OrderByTokenVariables) => {
  const { data: queryData, ...rest } = useQuery(ORDER_BY_TOKEN_TEAM, {
    variables,
  });

  const order = queryData?.order;
  const team = order?.team;

  const displaySowSummary = Boolean(team?.sowSummaryTableDisplay === 'display');
  const isDrawSummaryHidden = Boolean(team?.drawSummaryTableDisplay === 'hidden');
  const displayWithTotal = Boolean(!isDrawSummaryHidden && team?.drawSummaryTableDisplay === 'display_with_disbursement_and_total');
  const displayWithoutTotal = Boolean(!isDrawSummaryHidden && team?.drawSummaryTableDisplay === 'display_without_disbursement_and_total');

  return {
    order,
    displaySowSummary,
    isDrawSummaryHidden,
    displayWithTotal,
    displayWithoutTotal,
    ...rest,
  };
};
