import React from 'react';
import cn from 'classnames';
import { ToastContainer } from 'react-toastify';
import { PageProvider } from '../../utils/pageContext';
import Footer from './Footer';
import Header from './Header';
import styles from './PageLayout.module.scss';
import { PageLayoutProps } from './types';
import { useTypedSelector } from '../../redux/store';
import { selectLayout } from '../../redux/selectors';

/**
 * Renders the header, footer, and page contents (children).
 */
const PageLayout: React.FC<PageLayoutProps> = ({ type = 'regular', children }) => {
  const { showFooter } = useTypedSelector(selectLayout);
  if (type === 'frameless') {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <PageProvider>
      <div className={styles.root}>
        <ToastContainer
          position="top-right"
          newestOnTop
          closeOnClick
          autoClose={5000}
        />
        <Header />
        <div className={cn(styles.contentWrapper)}>
          {children}
        </div>
        {showFooter && <Footer />}
      </div>
    </PageProvider>
  );
};

export default PageLayout;
