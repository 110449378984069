import React, { useState } from 'react';
import AddIcon from 'haven-design-system/build/assets/svgs/action/add.svg?component';
import Button from '../../../components/Button';
import UserTable from './UserTable';
import Checkbox from '../../../components/Checkbox';
import UserForm from './UserForm';
import './styles.scss';
import { ManageUsersView, UserManagementProps } from './types';

const UserManagement: React.FC<UserManagementProps> = ({
  userData,
}) => {
  const [manageUsersView, setManageUsersView] = useState(
    ManageUsersView.ManageUsers,
  );
  const [isShowArchivedUsersChecked, setIsShowArchivedUsersChecked] = useState(false);
  const [singleUser, setSingleUser] = useState(null);

  const handleReturnToManageUsers = () => {
    setManageUsersView(ManageUsersView.ManageUsers);
  };

  const handleAddUser = () => {
    setManageUsersView(ManageUsersView.UserForm);
    setSingleUser(null);
  };

  const renderManageUsersView = (view: ManageUsersView) => {
    switch (view) {
      case ManageUsersView.ManageUsers:
        return (
          <div className="userManagement__manageUsers">
            <div className="userManagement__manageUsers__header">
              <div className="userManagement__manageUsers__header__text">
                {ManageUsersView.ManageUsers}
              </div>
              <Button onClick={handleAddUser} className="userManagement__manageUsers__header__addButton">
                <AddIcon />
                Add user
              </Button>
            </div>
            <UserTable
              userData={userData}
              showArchivedUsers={isShowArchivedUsersChecked}
              setSingleUser={setSingleUser}
              setManageUsersView={setManageUsersView}
            />
            <div className="userManagement__manageUsers__archivedUsers">
              <Checkbox
                isChecked={isShowArchivedUsersChecked}
                toggle={() => setIsShowArchivedUsersChecked(!isShowArchivedUsersChecked)}
              />
              Show archived users
            </div>
          </div>
        );
      case ManageUsersView.UserForm:
        return (
          <div className="userManagement__addUser">
            <UserForm
              handleReturn={handleReturnToManageUsers}
              singleUser={singleUser}
              setSingleUser={setSingleUser}
            />
          </div>
        );
      default:
        return null;
    }
  };
  return renderManageUsersView(manageUsersView);
};

export default UserManagement;
