import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import OnboardingLandingPage from './OnboardingLandingPage';
import InspectifyLayout from '../../../components/InspectifyLayout';
import { captureException } from '../../../utils/error';
import { OnboardingCompletedStep } from '../types';
import { SURVEYOR_ONBOARDING } from '../../../graphql/queries';
import { UPDATE_SURVEYOR_ONBOARDING } from '../../../graphql/mutations/surveyorOnboarding';

const OnboardingLandingPageLoader: React.FC = () => {
  const { onboardingId } = useParams<{ onboardingId: string }>();
  const [completedStep, setCompletedStep] = React.useState<OnboardingCompletedStep>();

  const [updateSurveyorOnboarding] = useMutation(
    UPDATE_SURVEYOR_ONBOARDING,
    {
      onError: () => {
        captureException(new Error('Graphql failed to update surveyor onboarding'));
      },
    },
  );

  const { data: surveyorOnboardingData } = useQuery(SURVEYOR_ONBOARDING, {
    variables: { id: onboardingId },
    context: { headers: { onboarding_id: onboardingId } },
    onCompleted: () => {
      if (surveyorOnboardingData?.surveyorOnboarding?.completedStep) {
        setCompletedStep(surveyorOnboardingData.surveyorOnboarding.completedStep);
      }
    },
    onError: (error) => {
      captureException(error, { source: 'Fetching surveyor onboarding' });
    },
  });

  const handleStartOnboarding = () => {
    try {
      if (completedStep === OnboardingCompletedStep.LandingPageViewed) {
        updateSurveyorOnboarding({
          variables: {
            input: {
              id: onboardingId,
              completedStep: OnboardingCompletedStep.FormViewed,
            },
          },
          context: { headers: { onboarding_id: onboardingId } },
        });
      }
      window.open(`/onboarding/${onboardingId}/form`, '_blank');
    } catch (error) {
      captureException(error, { source: 'User clicked Start onboarding form' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Welcome to Inspectify!</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showSignIn={false} hideHeaderOnMobile />
      <OnboardingLandingPage handleStartOnboarding={handleStartOnboarding} />
    </>
  );
};

export default OnboardingLandingPageLoader;
