import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { colorPaletteRed600, colorBaseBlackLight } from 'haven-design-system/build/typescript/es6';
import FormInput from '../../../../../../components/FormElements/FormInput';
import { Row, SaveToLibraryContainer } from '../styles';
import {
  Container,
  Label,
} from './styles';
import FormInputArea from '../../../../../../components/FormElements/FormInputArea';
import { formatMoney } from '../../../../../../utils/money';
import { onCreateRehabWorkItemCompleted, onUpdateRehabWorkItemCompleted } from '../../../../../../utils/rehab';
import Button from '../../../../../../components/Button';
import {
  CREATE_REHAB_WORK_ITEM,
  CREATE_REHAB_WORK_ITEM_ASSOCIATION,
  CREATE_REHAB_WORK_ITEM_ATTACHMENT,
  DELETE_REHAB_WORK_ITEM,
  DELETE_REHAB_WORK_ITEM_ASSOCIATION,
  DELETE_REHAB_WORK_ITEM_ATTACHMENT,
  UPDATE_REHAB_WORK_ITEM,
} from '../../../../../../graphql/mutations/rehab';
import FormInputNumber from '../../../../../../components/FormElements/FormInputNumber';
import FormInputCurrency from '../../../../../../components/FormElements/FormInputCurrency';
import {
  deleteRehabWorkItemAttachments,
  deleteWorkItem,
  deleteWorkItemAssociations,
  saveWorkItem,
  saveWorkItemAssociation,
  saveWorkItemAttachment,
  updateWorkItem,
} from '../../../../../../redux/actions/rehab';
import RemoveItemModal from './RemoveItemModal';
import { captureException } from '../../../../../../utils/error';
import { useReportsPageContext } from '../../../../../../utils/reportsPageContext';
import { RehabTabs } from '../../../../types';
import ReactSelect from '../../../../../../components/ReactSelect';
import { useWorkItemFormState } from './state';
import ToggleSwitch from '../../../../../../components/ToggleSwitch';
import Checkbox from '../../../../../../components/Checkbox';
import { RehabToolContext } from '../../../../context';
import { useTypedSelector } from '../../../../../../redux/store';
import { selectRehabProject } from '../../../../../../redux/selectors';
import { WorkItemAttachment } from '../../../../../../types';

interface Props {
  systemNames: string[],
  close: () => void,
}

const CreateNewItemFormLoader: React.FC<Props> = ({
  systemNames,
  close,
}) => {
  const dispatch = useDispatch();
  const { state: contextState, set: contextSet } = useContext(RehabToolContext);
  const { dispatch: contextDispatch } = useReportsPageContext();
  const project = useTypedSelector((state) => selectRehabProject(state, contextState.projectId));

  const {
    state,
    computedState,
    update,
    set,
  } = useWorkItemFormState({
    teamId: contextState.teamId,
    systemNameOrigin: contextState.modalSystemName,
    workItem: contextState.modalWorkItem,
    modalTemplateWorkItemPricingId: contextState.modalTemplateWorkItemPricingId,
  });

  const [createRehabWorkItemAssociation, { loading: loadingAssociation }] = useMutation(
    CREATE_REHAB_WORK_ITEM_ASSOCIATION,
    {
      onCompleted: (data) => {
        if (data) {
          dispatch(saveWorkItemAssociation(data));
        } else {
          captureException(new Error('Failed to save new item association'), data);
        }
      },
      onError: () => {
        captureException(new Error('Graphql failed to save new item association'));
      },
    },
  );

  const [createRehabWorkItemAttachment, { loading: loadingAttachment }] = useMutation(
    CREATE_REHAB_WORK_ITEM_ATTACHMENT,
    {
      onCompleted: (data) => {
        if (data) {
          dispatch(saveWorkItemAttachment(data));
        } else {
          captureException(new Error('Failed to save new item attachment'), data);
        }
        close();
      },
      onError: () => {
        captureException(new Error('Graphql failed to save new item attachment'));
      },
    },
  );

  const [createRehabWorkItem, { loading: loadingCreateWorkItem }] = useMutation(
    CREATE_REHAB_WORK_ITEM,
    {
      onCompleted: async (data) => {
        if (data) {
          dispatch(saveWorkItem(data));
          onCreateRehabWorkItemCompleted(
            data,
            contextState.itemType,
            contextState.itemId,
            createRehabWorkItemAssociation,
            createRehabWorkItemAttachment,
            contextState.report,
            contextState.workItemModalAttachments,
          );
          toast.success('Work item was added to your project.');
        } else {
          captureException(new Error('Failed to save new work item'), data);
          toast.error('Failed to create new work item');
        }
        close();
      },
      onError: () => {
        captureException(new Error('Graphql failed to save new work item'));
        toast.error('Failed to create new work item');
      },
    },
  );

  const [updateRehabWorkItem, { loading: loadingUpdate }] = useMutation(
    UPDATE_REHAB_WORK_ITEM,
    {
      onCompleted: async (data) => {
        if (data) {
          const previousAttachments: string[] = (
            data.updateRehabWorkItem.workItem.workItemAttachments.map(
              (attachment: WorkItemAttachment) => attachment.id,
            )
          );
          const newAttachments = contextState.workItemModalAttachments.filter(
            (attachment) => !previousAttachments.includes(attachment.id),
          );
          dispatch(updateWorkItem(data));
          onUpdateRehabWorkItemCompleted(
            data,
            createRehabWorkItemAttachment,
            newAttachments,
          );
          toast.success('Work item was updated');
        } else {
          captureException(new Error('Failed to update work item'), data);
          toast.error('Failed to update work item');
        }
        close();
      },
      onError: () => {
        captureException(new Error('Graphql failed to update work item'));
        toast.error('Failed to update work item');
      },
    },
  );

  const handleAddWorkItemToProject = () => {
    const input = {
      systemName: state.systemName,
      title: state.title,
      details: state.description,
      unit: state.unit,
      quantity: state.quantity,
      pricePerUnit: state.unitCost,
      totalPrice: state.totalCost,
      tags: state.tags,
      templateWorkItemPricingId: state.templateWorkItemPricingId,
      pricingExternalId: state.pricingExternalId,
    };

    if (state.useLumpSumCost) {
      input.unit = null;
      input.quantity = 0;
      input.pricePerUnit = null;
    }

    if (contextState.modalWorkItem) {
      updateRehabWorkItem({
        variables: { input: { id: contextState.modalWorkItem.id, ...input } },
      });
    } else {
      createRehabWorkItem({
        variables: {
          input: {
            projectId: contextState.projectId,
            saveToLibrary: state.saveToLibrary,
            teamMarketId: state.teamMarketId || project.teamMarketId,
            ...input,
          },
        },
      });
    }
    // If the work item is created from the report tab, go back to the report tab
    if (contextState.itemType) {
      contextSet('tab', RehabTabs.Report);
      contextDispatch({ type: 'set_active_item', payload: contextState.itemId });
    }
  };

  const [deleteRehabWorkItemAssociation] = useMutation(
    DELETE_REHAB_WORK_ITEM_ASSOCIATION,
    {
      onError: () => {
        captureException(new Error('Graphql failed to delete work item association'));
      },
    },
  );

  const [deleteRehabWorkItemAttachment] = useMutation(
    DELETE_REHAB_WORK_ITEM_ATTACHMENT,
    {
      onError: () => {
        captureException(new Error('Graphql failed to delete work item attachment'));
      },
    },
  );

  const [deleteRehabWorkItem] = useMutation(
    DELETE_REHAB_WORK_ITEM,
    {
      onCompleted: () => {
        dispatch(deleteRehabWorkItemAttachments(contextState.modalWorkItem.workItemAttachments));
        dispatch(deleteWorkItemAssociations(contextState.modalWorkItem.workItemAssociations));
        dispatch(deleteWorkItem(contextState.modalWorkItem.id));
      },
      onError: () => {
        captureException(new Error('Graphql failed to delete work item'));
      },
    },
  );

  const isAddtoProjectDisabled = () => (
    state.useLumpSumCost
      ? (!state.title || !state.systemName || state.totalCost < 0)
      : (!state.title || !state.systemName || !state.quantity || !state.unit || state.unitCost < 0)
  );

  const handleRemoveWorkItemFromProject = () => {
    contextState.modalWorkItem.workItemAssociations.forEach((id: string) => {
      deleteRehabWorkItemAssociation({ variables: { input: { id } } });
    });

    contextState.modalWorkItem.workItemAttachments.forEach((id: string) => {
      deleteRehabWorkItemAttachment({ variables: { input: { id } } });
    });

    deleteRehabWorkItem({ variables: { input: { id: contextState.modalWorkItem.id } } });

    close();
  };

  const handleSelectedItemTagsChange = (values: { label: string, value: string }[]) => {
    const selectedTags = values?.length ? values.map(({ value }) => value) : [];
    update('tags', selectedTags);
  };

  const handleSelectVariety = (id: string) => {
    const selectedItemPricing = computedState.workItemPricings.find((pricing) => pricing.id === id);
    if (!selectedItemPricing) return;

    set({
      unit: selectedItemPricing.unit,
      totalCost: selectedItemPricing.lumpSumPrice,
      unitCost: selectedItemPricing.pricePerUnit,
      teamMarketId: selectedItemPricing.teamMarketId,
      templateWorkItemPricingId: selectedItemPricing.id,
      pricingExternalId: selectedItemPricing.pricingExternalId,
    });
  };

  const loading = loadingCreateWorkItem || loadingAssociation || loadingAttachment || loadingUpdate;

  return (
    <Container
      loading={loading ? true : undefined}
      style={{ pointerEvents: loading ? 'none' : 'auto', marginTop: 16 }}
    >
      <div>
        <div style={{ fontSize: '14px', color: colorBaseBlackLight }}>System</div>
        <ReactSelect
          value={{ label: state.systemName, value: state.systemName }}
          onChange={(option) => update('systemName', option?.value)}
          options={(systemNames || []).map((name) => ({ label: name, value: name }))}
          placeholder="Select a system"
          width="100%"
          isSearchable
        />
        <FormInput
          name="Title"
          value={state.title}
          style={{ margin: '12px 0px' }}
          onChange={(e) => update('title', e.target.value || '')}
        />
        <FormInputArea
          data-testid="formInputArea"
          name="Description"
          value={state.description}
          style={{ margin: '12px 0px' }}
          inputStyle={{ height: 121, padding: 6 }}
          onChange={(e) => update('description', e.target.value || '')}
        />
        <div style={{ fontSize: '14px', color: colorBaseBlackLight }}>Tags</div>
        <ReactSelect
          value={state.tags.map((tag) => ({ label: tag, value: tag }))}
          onChange={handleSelectedItemTagsChange}
          options={(computedState.tags || []).map((tag) => ({ label: tag, value: tag }))}
          placeholder="Select a tag"
          width="100%"
          isMulti
          isSearchable
          isCreateable
        />

        {computedState.workItemPricings.length > 0 && (
          <>
            <div style={{ fontSize: '14px', color: colorBaseBlackLight }}>Variety</div>
            <ReactSelect
              value={{
                label: state.pricingExternalId,
                value: state.templateWorkItemPricingId,
              }}
              onChange={(option) => handleSelectVariety(option.value)}
              options={computedState.workItemPricings.map(
                (pricing) => ({ label: pricing.pricingExternalId, value: pricing.id }),
              )}
              placeholder="Select a variety"
              width="100%"
              isSearchable
            />
          </>
        )}

        {state.useLumpSumCost ? (
          <Row style={{ margin: '12px 0px', width: '50%' }}>
            <FormInputCurrency
              tag="create-new-item"
              name="Total cost"
              value={state.totalCost}
              onValueChange={(value: number) => update('totalCost', value || 0)}
            />
          </Row>
        ) : (
          <Row style={{ margin: '12px 0px', gap: '10px' }}>
            <FormInputNumber
              name="Quantity"
              value={state.quantity}
              style={{ marginRight: 12 }}
              onChange={(e) => update('quantity', parseInt(e.target.value || '0', 10))}
            />
            <FormInput
              name="Unit"
              value={state.unit}
              style={{ marginRight: 12 }}
              onChange={(e) => update('unit', e.target.value || '')}
            />
            <FormInputCurrency
              name="Unit cost"
              value={state.unitCost}
              onValueChange={(value: number) => update('unitCost', value || 0)}
            />
          </Row>
        )}
        <Row>
          <Row>
            <ToggleSwitch
              tag="create-new-item"
              isOn={state.useLumpSumCost}
              toggle={() => update('useLumpSumCost', !state.useLumpSumCost)}
            />
            <Label style={{ marginBottom: 4, fontSize: 14, marginLeft: 6 }}>
              Use lump sum cost
            </Label>
          </Row>
          {!state.useLumpSumCost && (
            <Label style={{ fontSize: 20 }}>
              Total:{' '}
              <strong>
                {computedState.calculatedTotal > 0 ? formatMoney(computedState.calculatedTotal) : '$0.00'}
              </strong>
            </Label>
          )}
        </Row>
      </div>
      <div>
        {!contextState.modalWorkItem && (
          <SaveToLibraryContainer>
            <Row style={{ width: 140 }}>
              <Checkbox
                id="save-to-library-checkbox"
                isChecked={state.saveToLibrary}
                toggle={() => update('saveToLibrary', !state.saveToLibrary)}
              />
              <Label style={{ marginLeft: 6 }}>Save to Library</Label>
            </Row>
          </SaveToLibraryContainer>
        )}
        <Row style={{ justifyContent: 'space-between' }}>
          <Button
            onClick={() => update('isRemoveModalOpen', true)}
            style={{
              backgroundColor: colorPaletteRed600,
              border: 'none',
              visibility: contextState.modalWorkItem ? 'visible' : 'hidden',
            }}
          >
            Remove
          </Button>
          <Row>
            <Button
              data-testid="closeModalButton"
              secondary
              onClick={close}
              style={{ marginInline: 16 }}
            >
              Cancel
            </Button>
            <Button
              disabled={isAddtoProjectDisabled()}
              onClick={handleAddWorkItemToProject}
            >
              {contextState.modalWorkItem ? 'Save' : 'Add to project'}
            </Button>
          </Row>
        </Row>
      </div>

      <RemoveItemModal
        isRemoveModalOpen={state.isRemoveModalOpen}
        setIsRemoveModalOpen={(value) => update('isRemoveModalOpen', value)}
        handleRemoveWorkItemFromProject={handleRemoveWorkItemFromProject}
      />
    </Container>
  );
};

export default CreateNewItemFormLoader;
