import {
  colorBaseWhitePrimary, colorPaletteGrayscale50, colorPaletteBlue50,
  colorBaseGreyDark, colorBaseBluePrimary,
} from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 50%;
  background-color: ${colorBaseWhitePrimary};
  padding: 24px;
  border-left: 1px solid ${colorPaletteGrayscale50};
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 0px;
  font-weight: 400;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TabButton = styled.button<{isSelected: boolean}>`
  width: 48%;
  border: 1px solid ${(props) => (props.isSelected ? colorBaseBluePrimary : colorPaletteGrayscale50)};
  color: ${(props) => (props.isSelected ? colorBaseBluePrimary : colorBaseGreyDark)};
  background-color: ${(props) => (props.isSelected ? colorPaletteBlue50 : colorBaseWhitePrimary)};
  font-weight: ${(props) => (props.isSelected ? 700 : 400)};
  height: 48px;
  margin: 24px 0px;
  border-radius: 4px;
`;

export const SaveToLibraryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 24px;
`;
