import React, {
  useReducer, createContext, useContext, useEffect, useState,
} from 'react';
import useWindowScrollListener from './useWindowScrollListener';

type Action = (
  { type: 'set_active_item', payload: string } |
  { type: 'set_active_work_item', payload: string | null } |
  { type: 'add_ref', payload: React.ReactNode } |
  { type: 'add_work_item_ref', payload: React.ReactNode } |
  { type: 'clear_refs' } |
  any
);
type Dispatch = (action: Action) => void;
type State = {
  activeMenuItem: string,
  activeWorkItem: string | null,
  refs: React.ReactNode[],
  workItemRefs: React.ReactNode[]
};

const defaultValue: State = {
  activeMenuItem: 'Summary',
  activeWorkItem: null,
  refs: [],
  workItemRefs: [],
};

const ReportsPageContext = createContext<
{ state: State; dispatch: Dispatch } | undefined
>(undefined);

function reportsPageReducer(state: State, action: Action) {
  switch (action?.type) {
    case 'set_active_work_item':
      return { ...state, activeWorkItem: action.payload };
    case 'set_active_item':
      return { ...state, activeMenuItem: action.payload };
    case 'add_ref':
      return { ...state, refs: [...state.refs, action.payload] };
    case 'add_work_item_ref':
      return { ...state, workItemRefs: [...state.workItemRefs, action.payload] };
    case 'clear_refs':
      return { ...state, refs: [], workItemRefs: [] };
    default:
      // throw new Error(`Unhandled action type: ${action?.type || ''}`);
      return state;
  }
}

function ReportsPageProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reportsPageReducer, defaultValue);
  const value = { state, dispatch };
  const [lastYPosition, setLastYPosition] = useState(window.pageYOffset);

  useWindowScrollListener(() => { setLastYPosition(window.pageYOffset); });

  useEffect(() => {
    if (state.refs.length > 0) {
      const inScrollItems = state.refs.sort(
        (refA, refB) => (refA.current?.offsetTop - refB.current?.offsetTop),
      ).filter((ref) => (
        ref.current && (lastYPosition + 200 >= ref.current.offsetTop)
      ));

      if (inScrollItems.length) {
        dispatch({
          type: 'set_active_item',
          payload: inScrollItems[inScrollItems.length - 1].current.id,
        });
      }
    }
  }, [lastYPosition, state.refs]);

  return (
    <ReportsPageContext.Provider value={value}>
      {children}
    </ReportsPageContext.Provider>
  );
}

function useReportsPageContext(isPrint = false) {
  let context = useContext(ReportsPageContext);
  if (context === undefined && !isPrint) {
    throw new Error('useReportsPageContext must be used within a ReportsPageProvider');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (isPrint) context = { state: defaultValue, dispatch: (action: Action) => {} };
  return context;
}

export { ReportsPageProvider, useReportsPageContext };
