import { gql } from '@apollo/client';

const APPLY_ROTATION = gql`
    mutation ApplyRotation($input: TransformReportAttachmentInput!) {
        transformReportAttachment(input: $input) {
            reportAttachment {
                id
            }
        }
    }
`;

export default APPLY_ROTATION;
