import { QueryHookOptions, useQuery } from '@apollo/client';
import { MANAGEABLE_USERS } from '../../graphql/queries/user';
import { User } from '../../types';

export const useManageableUsers = (options?: QueryHookOptions) => {
  const {
    data,
    ...rest
  } = useQuery(MANAGEABLE_USERS, options);

  const usersData = data?.users?.edges?.map(({ node: user }: { node: User }) => user) || [];

  return {
    usersData,
    ...rest,
  };
};
