import React from 'react';
import { InspectorDashboardProvider } from './context';
import Loader from './loader';

const InspectorDashboard: React.FC = () => (
  <InspectorDashboardProvider>
    <Loader />
  </InspectorDashboardProvider>
);

export default InspectorDashboard;
