import React from 'react';
import './styles.scss';
import '../Onboarding/styles.scss';
import '../Onboarding/OnboardingLandingPage/styles.scss';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import InspectifyAppQRCode from '../../assets/qrcodes/inspectify-home-inspection-app.svg';
import GooglePlayStoreBadge from '../../assets/images/googlePlayStoreBadge.svg';
import AppleAppStoreBadge from '../../assets/images/appleAppStoreBadge.svg';

const DownloadAppPage: React.FC = () => {
  const history = useHistory();

  return (
    <div className="backdrop">
      <div className="container">
        <p className="text__header">
          Download the Inspectify App
        </p>
        <p className="text__instructions">
          You&apos;ll use our app to complete all jobs on the Inspectify platform&nbsp;
          as well as receive and accept job offers.
        </p>
        <div className="contentContainer">
          <div className="videoInstructionsContainer">
            <div className="instructionsContainer">
              <p className="downloadText">
                Available on the Google Play store or Apple App store
              </p>
              <div className="downloadButtonsContainer">
                <a href="https://apps.apple.com/us/app/inspectify-home-inspection-app/id1577408351" target="_blank" rel="noreferrer">
                  <img src={AppleAppStoreBadge} alt="AppleAppStoreBadge" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.inspectify.homeInspectionApp&hl=en_US" target="_blank" rel="noreferrer">
                  <img src={GooglePlayStoreBadge} alt="GooglePlayStoreBadge" />
                </a>
              </div>
              <img className="downloadQRCode" src={InspectifyAppQRCode} alt="InspectifyAppQRCode" />
            </div>
            <div className="videoContainer">
              <video
                className="video"
                width="100%"
                border-radius="8px"
                controls
                src="https://public-inspectify.s3.amazonaws.com/videos/surveyor-onboarding/Surveyor+App+Demo.mp4"
              />
            </div>
          </div>
          <div className="divider" />
          <div className="buttonContainer">
            <Button onClick={() => history.push('/dashboard')}>Back to dashboard</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPage;
