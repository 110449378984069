import {
  colorBaseBlackLight, colorBaseBluePrimary,
  colorBaseGreenLight, colorBaseGreenPrimary,
  colorBaseTealLight, colorBaseWhitePrimary,
} from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';
import { mediaBreakpointDown } from '../../../../styles/mediaBreakpoints';
import { CommonTextStyles } from '../../shared/styles';

export const Container = styled.div`
  background-color: transparent;
  padding-top: 40px;
  padding-left: 80px;
  padding-right: 80px;
  height: fit-content; 
  @media only screen and (${mediaBreakpointDown.md}) {
    min-width: 360px;
    margin: 0 auto;
    padding: 0 20px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: ${colorBaseBlackLight};
  margin: 0;

  &:hover {
    cursor: pointer;
    color: ${colorBaseBluePrimary};
  }
`;

export const DetailsContainer = styled.div`
  margin-top: 15px;
  border-radius: 10px;
  overflow: auto;
  background-color: ${colorBaseWhitePrimary};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InfoContainer = styled.div`
  margin: 35px 55px;
  @media only screen and (max-width: 900px) {
    margin: 15px;
  }
`;

export const AddressText = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${colorBaseBlackLight};
`;

export const StatusContainer = styled.div<{ status: string }>`
  margin: 15px 0;
  padding: 15px;
  background-color: ${(props) => (props.status === 'completed' ? colorBaseGreenLight : colorBaseTealLight)};
  border-radius: 4px;
`;

export const TextLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

export const MainText = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${colorBaseBlackLight};
  margin-bottom: 0;
`;

export const SubText = styled(MainText)`
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
`;

export const Details = styled.div`
  flex-direction: column;
`;

export const DetailsButton = styled.button`
  border: none;
  background-color: ${colorBaseWhitePrimary};
  color: ${colorBaseBluePrimary};
  font-size: 16px;
  font-weight: 700;
  padding-right: 10px;
`;

export const AppRequiredContainer = styled.div`
  width: fit-content;
  text-align: center;
  align-self: center;
  height: 25px;
  padding: 4px 0 4px 16px;
  background-color: transparent;
  border-radius: 2px;
  border: 0;
  color: ${colorBaseBluePrimary};
  ${CommonTextStyles};
  font-size: 14px;
  line-height: 19px;
  cursor: default;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

export const HomeImage = styled.div<{ image: string }>`
  object-fit: cover;
  width: 100%;
  height: 260px;
  background-image: linear-gradient(360deg, rgba(19, 19, 19, 0.8) 0%, rgba(0, 0, 0, 0) 49.63%), url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
`;

export const RestrictedWidthMainText = styled(MainText)`
  max-width: 1000px;
`;

export const RestrictedWidthSubText = styled(RestrictedWidthMainText)`
  font-size: 14px;
  font-weight: normal;
  word-break: break-all;
`;

export const FileHyperlink = styled.a`
  max-width: 90%;
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: normal;
`;

export const LayoutContainer2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ModalHeader = styled.div`
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${colorBaseBlackLight};
`;

export const ModalSubText = styled.div<{ shrink?: boolean }>`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colorBaseBlackLight};
  margin-bottom: ${(props) => (props.shrink ? '0px' : '18px')};
`;

export const DividerContainer = styled(LayoutContainer2)`
  align-items: center;
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  height: 0;
  width: 100%;
  border-top: 1px solid ${colorBaseBlackLight};
`;

export const OrDiv = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 0 8px;
`;

export const ButtonContainer = styled(LayoutContainer2)`
  margin-top: 20px;
  justify-content: flex-end;
  gap: 15px;
`;

export const PayText = styled(MainText)<{ paid: boolean }>`
  color: ${(props) => (props.paid ? colorBaseGreenPrimary : colorBaseBlackLight)}
`;
