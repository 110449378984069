// inspired by https://leanpub.com/redux-book
import axios from '../../utils/axios';
import { apiEnd, apiError, apiStart } from '../actions/api';
import { API } from '../actions/types';

const apiMiddleware = ({ dispatch }: any) => (next: any) => async (action: any) => {
  next(action);

  if (action.type !== API) return null;

  const {
    url,
    method,
    params,
    data,
    onSuccess,
    onFailure,
    label,
    headers,
    config,
  } = action.payload;
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  if (label) {
    dispatch(apiStart(label));
  }
  let response;
  try {
    response = await axios.request({
      url,
      method,
      params,
      headers,
      [dataOrParams]: data,
      ...config,
    });

    if (onSuccess) {
      const successAction = onSuccess(response.data);
      if (successAction) dispatch(successAction);
    }
  } catch (error) {
    dispatch(apiError(label, error?.message));

    if (onFailure) {
      onFailure(error, dispatch);
    }
  }

  if (label) {
    dispatch(apiEnd(label));
  }

  return response;
};

export default apiMiddleware;
