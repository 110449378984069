import { useEffect, useMemo, useReducer } from 'react';
import uniq from 'lodash/uniq';
import { IdRef } from 'marketplace-common';
import {
  Project,
  Team,
  TemplateWorkItemPricing,
  TemplateWorkItemPricingsInterface,
  TemplateWorkItemsInterface,
} from '../../../../../../types';
import { filterTemplateWorkItemsBySearchTerms } from '../../../../../../utils/rehab';
import { defaultExternalTagTeamIds } from '../CreateNewItemForm/state';
import { useTypedSelector } from '../../../../../../redux/store';
import {
  selectRehabProject,
  selectTeams,
  selectTemplateWorkItemPricings,
  selectTemplateWorkItems,
  selectWorkItems,
} from '../../../../../../redux/selectors';

export type WorkItemLibraryState = {
  searchTerm: string
  selectedItemId: string
  selectedItemTitle: string
  selectedItemSystemName: string
  selectedItemDescription: string
  selectedItemUnit: string
  selectedItemQuantity: number
  selectedItemTotal: number
  selectedItemPricePerUnit: number
  selectedItemLumpSum: boolean
  selectedItemTags: string[]
  selectedItemPricing: TemplateWorkItemPricing
  selectedItemPricings: TemplateWorkItemPricing[]
};

export type WorkItemLibraryComputedState = {
  project: Project
  team: Team
  tags: string[]
  isLumpSum: boolean
  templateWorkItems: TemplateWorkItemsInterface,
  templateWorkItemPricings: TemplateWorkItemPricingsInterface
  filteredTemplateWorkItemsForSystem: TemplateWorkItemsInterface
};

export const createInitialState = (teamId: IdRef): WorkItemLibraryState => ({
  searchTerm: '',
  selectedItemId: '',
  selectedItemTitle: '',
  selectedItemSystemName: '',
  selectedItemDescription: '',
  selectedItemUnit: '',
  selectedItemQuantity: 0,
  selectedItemTotal: 0,
  selectedItemPricePerUnit: 0,
  selectedItemLumpSum: false,
  selectedItemTags: defaultExternalTagTeamIds.includes(String(teamId)) ? ['External'] : [],
  selectedItemPricing: {} as TemplateWorkItemPricing,
  selectedItemPricings: [],
});

export const reducer = (state: WorkItemLibraryState, action: any) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.key]: action.value };
    case 'SET':
      return { ...state, ...action.value };
    default:
      return state;
  }
};

type WorkItemLibraryStateParams = {
  projectId: string
  templateWorkItemsForSystem: TemplateWorkItemsInterface
  teamId: IdRef
};

export const useWorkItemLibraryState = ({
  projectId,
  templateWorkItemsForSystem,
  teamId,
}: WorkItemLibraryStateParams) => {
  const initialState = createInitialState(teamId);
  const [state, dispatch] = useReducer(reducer, initialState);

  const teams = useTypedSelector(selectTeams);
  const project = useTypedSelector((reduxState) => selectRehabProject(reduxState, projectId));
  const workItems = useTypedSelector(selectWorkItems);
  const templateWorkItems = useTypedSelector(selectTemplateWorkItems);
  const templateWorkItemPricings = useTypedSelector(selectTemplateWorkItemPricings);

  const team = useMemo(() => (
    Object.values(teams || {}).find((t) => t.id === project?.teamId)
  ), [project?.teamId, teams]);

  const tags = useMemo(() => {
    let rehabTags = team?.rehabTags || [];
    const items = Object.values(workItems);
    if (items.length) {
      rehabTags = uniq(items.reduce((acc, item) => (
        item.tags.length ? [...acc, ...item.tags] : acc
      ), rehabTags));
    }
    return rehabTags;
  }, [team, workItems]);

  const filteredTemplateWorkItemsForSystem = useMemo(() => (
    state.searchTerm.length > 2
      ? filterTemplateWorkItemsBySearchTerms(
        templateWorkItems,
        templateWorkItemPricings,
        state.searchTerm.trim().toLowerCase().split(' '),
      )
      : templateWorkItemsForSystem
  ), [templateWorkItemsForSystem, state.searchTerm]);

  const isLumpSum = useMemo(() => (
    (!state.selectedItemPricing?.unit === null && state.selectedItemPricing?.pricePerUnit !== null)
      || state.selectedItemPricing?.lumpSumPrice !== null
  ), [state.selectedItemPricing]);

  useEffect(() => {
    // if NOT lump sum library work item and lump sum is on, show price_per_unit as total
    if (!isLumpSum && state.selectedItemLumpSum) {
      dispatch({ type: 'UPDATE', key: 'selectedItemTotal', value: state.selectedItemPricePerUnit });
    }

    // if IS lump sum library work item and lump sum is off, show lump_sum_price as price per unit
    if (isLumpSum && !state.selectedItemLumpSum) {
      dispatch({
        type: 'SET',
        value: {
          selectedItemPricePerUnit: state.selectedItemPricing.lumpSumPrice,
          selectedItemQuantity: 1,
          selectedItemTotal: state.selectedItemPricing.lumpSumPrice * 1,
        },
      });
    }
  }, [isLumpSum, state.selectedItemLumpSum]);

  const computedState: WorkItemLibraryComputedState = {
    project,
    team,
    tags,
    templateWorkItems,
    templateWorkItemPricings,
    filteredTemplateWorkItemsForSystem,
    isLumpSum,
  };

  return {
    state: state as WorkItemLibraryState,
    computedState,
    dispatch,
    set: (value: any) => dispatch({ type: 'SET', value }),
    update: (key: string, value: any) => dispatch({ type: 'UPDATE', key, value }),
  };
};
