import { isNil } from 'lodash';
import {
  ReportApplianceInformation, ReportApplianceInformationsInterface, ReportInformation,
} from 'marketplace-common';
import { getMonthName } from './date';

export const isApplianceNotFound = (
  applianceInfo: ReportApplianceInformation,
) => isNil(applianceInfo.serialNumber) && isNil(applianceInfo.modelNumber);

export const getApplianceTitle = (applianceInfo: ReportApplianceInformation) => {
  if (applianceInfo.subProductName) return applianceInfo.subProductName;
  if (applianceInfo.productName) return applianceInfo.productName;
  return 'Appliance';
};

export const getRecallStatus = (applianceInfo: ReportApplianceInformation) => {
  if (isNil(applianceInfo.recalls)) return 'Unavailable';
  return applianceInfo.recalls.length === 0 ? 'No' : 'Yes';
};

export const getManufactureDate = (applianceInfo: ReportApplianceInformation) => {
  if (applianceInfo.monthManufactured && applianceInfo.yearManufactured) {
    return `${getMonthName(applianceInfo.monthManufactured)} ${applianceInfo.yearManufactured}`;
  }
  if (applianceInfo.yearManufactured) return applianceInfo.yearManufactured;
  return 'Unavailable';
};

export const getExpectedLife = (applianceInfo: ReportApplianceInformation) => {
  if (isNil(applianceInfo.expectedLife)) return 'Unavailable';
  return `${applianceInfo.expectedLife} ${applianceInfo.expectedLife === 1 ? 'year' : 'years'}`;
};

export const getModelNumber = (applianceInfo: ReportApplianceInformation) => {
  if (isNil(applianceInfo.modelNumber)) return 'Unavailable';
  return applianceInfo.modelNumber;
};

export const getBrandName = (applianceInfo: ReportApplianceInformation) => {
  if (isNil(applianceInfo.brandName)) return 'Unavailable';
  return applianceInfo.brandName;
};

export const getAge = (applianceInfo: ReportApplianceInformation) => {
  if (isNil(applianceInfo.age)) return 'Unavailable';
  return `${applianceInfo.age} ${applianceInfo.age === 1 ? 'year' : 'years'}`;
};

export const getEstimatedRemainingLife = (applianceInfo: ReportApplianceInformation) => {
  if (isNil(applianceInfo.remainingLife)) return 'Unavailable';
  return `${applianceInfo.remainingLife} ${applianceInfo.remainingLife === 1 ? 'year' : 'years'}`;
};

export const getSerialNumber = (applianceInfo: ReportApplianceInformation) => {
  if (isNil(applianceInfo.serialNumber)) return 'Unavailable';
  return applianceInfo.serialNumber;
};

export const getReportApplianceInformations = (
  reportApplianceInformations: ReportApplianceInformationsInterface,
  informations: ReportInformation[],
) => (
  informations.reduce((acc, { reportApplianceInformationId }) => (
    (reportApplianceInformationId && reportApplianceInformations[reportApplianceInformationId])
      ? [...acc, reportApplianceInformations[reportApplianceInformationId]]
      : acc
  ), [])
);
