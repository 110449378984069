import {
  colorPaletteGrayscale50,
  colorBaseWhitePrimary,
  colorBaseBlackLight,
} from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

export const ProjectHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100%
  background: ${colorBaseWhitePrimary};
  border: 1px solid ${colorPaletteGrayscale50};
`;

export const ProjectHistoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  border-bottom: 1px solid ${colorPaletteGrayscale50};
  padding: 16px 0px 16px;
  background: ${colorBaseWhitePrimary};
`;
export const ProjectHistoryHeaderText = styled.div`
  color: ${colorBaseBlackLight};
  font-size: 16px;
  font-weight: 400;
  padding-left: 16px;
`;

export const ProjectHistoryItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: scroll;
  background: ${colorBaseWhitePrimary};
  
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
