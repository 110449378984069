import { getAnswer, ReportAttachment, ReportInformation } from 'marketplace-common';
import { getAttachmentsFromIds } from './reportAttachment';

export const isExternalLink = (value: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const url = new URL(value);
    return true;
  } catch (error) {
    return false;
  }
};

export const isYoutubeLink = (value: string) => {
  if (isExternalLink(value)) {
    const url = new URL(value);
    return url.hostname.includes('yout');
  }
  return false;
};

export const generateYoutubeEmbedUrl = (value: string) => {
  if (!isYoutubeLink(value)) return null;
  try {
    const url = new URL(value);
    const videoId = url.pathname.split('/')[1];
    return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
  } catch (error) {
    return null;
  }
};

export const getInformationDisplayData = (informations: ReportInformation[]) => informations.filter(
  ({ questionType, cannotAnswer }) => questionType !== 'media' || cannotAnswer,
).map((information) => {
  const value = getAnswer(information);
  return ({
    label: information.title,
    value,
    isLink: isExternalLink(value),
    cannotAnswer: information.cannotAnswer,
    questionType: information.questionType,
  });
});

export const getInformationAttachments = (
  informations: ReportInformation[],
  attachments: { [id: string]: ReportAttachment },
) => {
  const informationAttachmentIds = informations.map(
    ({ reportAttachmentIds }) => reportAttachmentIds || [],
  ).reduce((acc, val) => acc.concat(val), []);

  return getAttachmentsFromIds(informationAttachmentIds, attachments);
};
