import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import MediaRecovery from './MediaRecovery';
import InspectifyLayout from '../../components/InspectifyLayout/InspectifyLayout';
import { useCurrentUser } from '../../utils/user';
import { RUN_FIX_MISSING_S3_OBJECT_KEYS_ON_REPORT_ATTACHMENTS } from '../../graphql/mutations/runFixMissingS3ObjectKeysOnReportAttachments';
import { captureException } from '../../utils/error';
import { useUpload } from '../../components/MediaUploader/useUpload';

const MediaRecoveryLoader: React.FC = () => {
  const currentUser = useCurrentUser();
  const history = useHistory();

  const { upload, uploads, remainingCount } = useUpload('public', false);

  useEffect(() => {
    const notSignedIn = currentUser === false;
    if (notSignedIn) history.push('/sign_in?next=/media_recovery');
  }, [currentUser]);

  const [runFixMissingS3ObjectKeysOnReportAttachments, { error }] = useMutation(
    RUN_FIX_MISSING_S3_OBJECT_KEYS_ON_REPORT_ATTACHMENTS,
  );

  useEffect(() => {
    if (error) captureException(error);
  }, [error]);

  const handleFiles = async (acceptedFiles: File[]) => {
    await upload(acceptedFiles, false);

    runFixMissingS3ObjectKeysOnReportAttachments({ variables: { input: { } } });
  };

  return (
    <>
      <Helmet>
        <title>Media Recovery</title>
      </Helmet>
      <InspectifyLayout showFooter />
      <MediaRecovery handleFiles={handleFiles} uploads={uploads} remainingCount={remainingCount} />
    </>
  );
};

export default MediaRecoveryLoader;
