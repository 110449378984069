import React, { useMemo } from 'react';
import { BETTER_INSPECT_PHONE_NUMBER_FORMATTED } from 'marketplace-common';
import { useRouteMatch } from 'react-router-dom';
import { colorBaseGreyDark } from 'haven-design-system/build/typescript/es6';
import InspectifyLogoSvg from '../../assets/inspectify/Inspectify_Logo_Color.svg';
import BetterLogoSvg from '../../assets/better/BetterInspect.svg';
import PhoneIconSvg from '../../assets/icons/phone.svg';
import MailIconSvg from '../../assets/icons/mail.svg';
import { getAddressLabel } from '../../utils/report';
import {
  Container,
  Header,
  Subheader,
  Divider,
  PropertyImage,
  InspectifyContainer,
  InspectifyLogo,
  BetterLogo,
  InspectifyRow,
  InspectifyText,
  CoverPageContainer,
} from './styles';
// needed to import global style
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './styles.module.scss';
import { CoverPageProps } from './types';
import { Label } from '../Label/Label';

const CoverPage: React.FC<CoverPageProps> = ({
  property,
  title,
  children,
}) => {
  const isBetterInspect = useMemo(() => window.location.hostname.includes('betterinspect'), []);
  const isPermitDataPdfReport = useRouteMatch('/orders/:orderToken/permit_report');

  return (
    <Container>
      <Header>{getAddressLabel(property)}</Header>
      {title && <Subheader>{title}</Subheader>}

      {isPermitDataPdfReport ? (
        <div>
          <h3 style={{ color: colorBaseGreyDark }}>
            {'Permit Data Record '}
            <Label title="Beta Feature" />
          </h3>
        </div>

      ) : null}

      <Divider />

      {property?.photo?.url && (
        <PropertyImage src={property.photo.url} alt="PropertyImage" />
      )}

      <CoverPageContainer>
        {children}
        <InspectifyContainer>
          {isBetterInspect ? (
            <>
              <BetterLogo src={BetterLogoSvg} alt="Better Inspect" />
              <InspectifyRow>
                <img src={PhoneIconSvg} alt="PhoneIcon" />
                <InspectifyText>
                  {BETTER_INSPECT_PHONE_NUMBER_FORMATTED}
                </InspectifyText>
              </InspectifyRow>
            </>
          ) : (
            <InspectifyLogo src={InspectifyLogoSvg} alt="Inspectify" />
          )}

          <InspectifyRow>
            <img src={MailIconSvg} alt="MailIcon" />
            <InspectifyText>
              {isBetterInspect
                ? 'betterinspect@inspectify.com'
                : 'hello@inspectify.com'}
            </InspectifyText>
          </InspectifyRow>
        </InspectifyContainer>
      </CoverPageContainer>
    </Container>
  );
};

export default CoverPage;
