import React, {
  useEffect, useMemo, useRef,
} from 'react';
import groupBy from 'lodash/groupBy';
import {
  Report,
  ReportSubsystem,
  ReportDeficiency,
  ReportLimitation,
  ReportStatus,
  ReportApplianceInformation,
  ReportSeverityType,
  sortByDisplayIndex,
  ReportInformation,
  InspectionWarranty,
} from 'marketplace-common';
import cn from 'classnames';
import styles from './styles.module.scss';
import Informations from './Informations';
import DeficiencyDetails from './DeficiencyDetails';
import LimitationDetails from './LimitationDetails';
import Attachments from './Attachments';
import IssueBadge from './IssueBadge';
import { getLocationsFromIds, HOME_INSPECTION_DETAILS_NAME, showBasedOnReportDeliveryFilter } from '../../../utils/report';
import { ReportDeliveryFilter } from '../../../types/models';
import { useReportsPageContext } from '../../../utils/reportsPageContext';

import { WorkItemModalOptions } from '../../RehabToolPage/types';
import ApplianceInfo from './ApplianceInfo';
import { generateYoutubeEmbedUrl, getInformationAttachments, getInformationDisplayData } from '../../../utils/reportInformation';
import { getAttachmentsFromIds } from '../../../utils/reportAttachment';
import { getReportApplianceInformations } from '../../../utils/reportApplianceInformation';
import { getNumIssuesBasedOnFilter, showDeficiencyOnWebReportBasedOnFilter } from '../../../utils/reportDeficiency';
import ExternalLinks from './ExternalLinks';
import { getReportGroupName } from '../utils';
import { RectangularAddWorkItemButton } from '../../RehabToolPage/components/AddWorkItemButton/AddWorkItemButton';
import { includesDrawInspection, includesSowInspection } from '../../../utils/tables';
import { useTypedSelector } from '../../../redux/store';
import { selectWorksOrderUnits } from '../../../redux/selectors';

interface Props {
  index: number
  report: Report
  noDeficienciesRequired: boolean
  subsystem: ReportSubsystem
  imageWidth: number
  filter: ReportDeliveryFilter
  isRehab?: boolean
  isAppliancesOnly?: boolean,
  isPrint?: boolean
  handleWorkItemModal?: (options: WorkItemModalOptions) => void
  systemName: string
  inspectifyAdmin: boolean
  inspectionWarranty?: InspectionWarranty
  isInspectionWarrantyExpired: boolean
  handleOpenReportMediaUploader?: (itemId: string, itemType: string, reportSystemGroupId?: string | null) => void;
}

const SubsystemDetails: React.FC<Props> = ({
  index,
  report,
  noDeficienciesRequired,
  subsystem,
  imageWidth,
  filter,
  isRehab,
  isAppliancesOnly,
  isPrint = false,
  handleWorkItemModal,
  systemName,
  inspectifyAdmin,
  inspectionWarranty,
  isInspectionWarrantyExpired,
  handleOpenReportMediaUploader,
}) => {
  const { dispatch } = useReportsPageContext(isPrint);
  const subsystemRef = useRef<HTMLDivElement>();

  const groupName = useMemo(() => getReportGroupName(subsystem, report), [subsystem, report]);

  const isSignature = useMemo(() => {
    if (report && systemName.includes('Signature') && subsystem.name.includes('Signature')) {
      return subsystem.reportInformationIds.findIndex(
        (id) => report.informations[id]?.dataName?.includes('signature'),
      ) > -1;
    }
    return false;
  }, [report]);

  const isFullReport = useMemo(() => filter === ReportDeliveryFilter.FullReport, [filter]);
  const isMediaOnly = useMemo(() => filter === ReportDeliveryFilter.MediaOnly, [filter]);
  // TODO: in future user filtering and react router query params share single source of truth
  const isAppliancesOnlyFilter = useMemo(
    () => filter === ReportDeliveryFilter.AppliancesOnly, [filter],
  );

  const system = useMemo(() => report.systems[subsystem.reportSystemId], [report.systems, subsystem.reportSystemId]);

  const informations = useMemo(() => (sortByDisplayIndex(
    (subsystem.reportInformationIds || []).map((id) => report.informations[id]),
  ) as ReportInformation[]), [subsystem.reportInformationIds, report.informations]);

  const deficiencies = useMemo(() => (
    (subsystem.reportDeficiencyIds || []).map((id) => report.deficiencies[id])
  ), [subsystem.reportDeficiencyIds, report.deficiencies]);

  const limitations = useMemo(() => (
    (subsystem.reportLimitationIds || []).map((id) => report.limitations[id])
  ), [subsystem.reportLimitationIds, report.limitations]);

  const informationDisplayData = useMemo(() => (
    getInformationDisplayData(informations)
  ), [informations]);

  const informationAttachments = useMemo(() => {
    if (subsystem.status === ReportStatus.NotPresent) return [];
    return getInformationAttachments(informations, report.attachments);
  }, [subsystem, informations, report.attachments]);

  const externalVideosToDisplayForSubsystem = useMemo(() => {
    if (subsystem.status === ReportStatus.NotPresent) return [];
    return informationDisplayData.reduce((acc, data) => (
      data.isLink && generateYoutubeEmbedUrl(data.value)
        ? [...acc, { label: data.label, url: generateYoutubeEmbedUrl(data.value) }] : acc
    ), [] as { label: string, url: string }[]);
  }, [subsystem, informationDisplayData]);

  const attachmentsToDisplayForSubsystem = useMemo(() => {
    const subsystemAttachments = getAttachmentsFromIds(
      subsystem?.reportAttachmentIds || [],
      report.attachments,
    );
    return [...subsystemAttachments, ...informationAttachments];
  }, [informationAttachments, subsystem.reportAttachmentIds]);

  const numSubsystemIssues = useMemo(
    () => getNumIssuesBasedOnFilter(deficiencies, limitations, filter),
    [deficiencies, limitations, filter],
  );

  const subsystemApplianceInformations: ReportApplianceInformation[] = useMemo(
    () => (
      (report.applianceInformations && informations)
        ? getReportApplianceInformations(report.applianceInformations, informations)
        : []),
    [report.applianceInformations, informations],
  );

  const groupedDeficiencies = useMemo(() => {
    if (deficiencies.length) {
      const defects = deficiencies.reduce((acc, defect) => (
        showDeficiencyOnWebReportBasedOnFilter(filter, defect.reInspectionStatus)
          ? [...acc, defect] : acc
      ), [] as ReportDeficiency[]);
      return groupBy(defects, 'severity');
    }
    return null;
  }, [deficiencies, filter]);

  const issues = useMemo(() => ([
    ...(groupedDeficiencies?.[ReportSeverityType.safety] || []),
    ...(groupedDeficiencies?.[ReportSeverityType.repair] || []),
    ...(groupedDeficiencies?.[ReportSeverityType.monitor] || []),
    ...(limitations),
    ...(groupedDeficiencies?.[ReportSeverityType.inspectify_flag] || []),
  ]), [groupedDeficiencies, limitations]);

  useEffect(() => {
    if (subsystemRef.current) dispatch({ type: 'add_ref', payload: subsystemRef });
  }, [subsystemRef.current]);

  const showSubsystem = useMemo(() => (
    showBasedOnReportDeliveryFilter(
      deficiencies,
      limitations,
      attachmentsToDisplayForSubsystem,
      subsystemApplianceInformations,
      filter,
    )
  ), [filter, deficiencies, limitations,
    attachmentsToDisplayForSubsystem, subsystemApplianceInformations]);

  const badgeType = useMemo(() => {
    if (subsystem.status === ReportStatus.NotPresent) return ReportStatus.NotPresent;
    if (subsystem.status === ReportStatus.CannotInspect) return ReportStatus.CannotInspect;
    if (filter === ReportDeliveryFilter.FixedDefectsOnly && numSubsystemIssues > 0) return 'fixed';
    return 'issue';
  }, [subsystem.status, numSubsystemIssues, filter]);

  const orderUnits = useTypedSelector((reduxState) => selectWorksOrderUnits(reduxState));
  const isMultiUnit = orderUnits && (Object.keys(orderUnits).length > 1);
  const isFirstMultiUnitSubystem = isMultiUnit && index === 0;
  const isNotHomeInspectionDetailsAndNotSignature = (
    subsystem.name !== HOME_INSPECTION_DETAILS_NAME
    && (!systemName.includes('Signature') && !subsystem.name.includes('Signature'))
  );
  const isMultiUnitOrDrawOrSowInspection = (
    isMultiUnit || includesSowInspection(report) || includesDrawInspection(report)
  );
  const showSubsystemName = (
    isNotHomeInspectionDetailsAndNotSignature
    && (!isMultiUnit || (isMultiUnitOrDrawOrSowInspection && isFirstMultiUnitSubystem))
  );

  if (!showSubsystem) return null;

  return (
    <div id={subsystem.name} className={styles.subsystemGroupContainer} key={`${subsystem.name}-${subsystem.displayIndex}`}>
      {showSubsystemName && <h3 className={cn(styles.subsystemHeader)}>{subsystem.name}</h3>}

      <div
        id={subsystem.name}
        data-testid={subsystem.name !== HOME_INSPECTION_DETAILS_NAME ? 'subsystem' : 'home-inspection-details'}
        ref={subsystemRef}
        className={cn(styles.subsystemContainer, groupName ? styles.border : null)}
      >
        <div className={styles.subsystemHeaderAndInformationContainer}>
          {subsystem.name !== HOME_INSPECTION_DETAILS_NAME && !isSignature && (
            <div className={styles.displayRowSpacedBetween}>
              <div>
                <IssueBadge
                  type={badgeType}
                  count={numSubsystemIssues}
                  noDeficienciesRequired={noDeficienciesRequired}
                />
              </div>

              {isRehab && (
              <RectangularAddWorkItemButton
                onClick={() => handleWorkItemModal({
                  isOpen: true,
                  attachments: attachmentsToDisplayForSubsystem,
                  systemName,
                  subsystemName: subsystem.name,
                  informations: informationDisplayData,
                  note: subsystem.note,
                  itemType: 'ReportSubsystem',
                  itemId: subsystem.id,
                })}
              />
              )}
            </div>
          )}

          {groupName && (
            <h3 className={cn(styles.subsystemHeader)}>{groupName}</h3>
          )}

          {
          isFullReport
          && subsystem.status !== ReportStatus.NotPresent
          && informations.length > 0
          && <Informations data={informationDisplayData} isSignature={isSignature} />
        }

          {
          isFullReport
          && subsystem.status !== ReportStatus.NotPresent
          && subsystemApplianceInformations.length > 0
          && (
            <ApplianceInfo
              applianceInformations={subsystemApplianceInformations}
              isPrint={isPrint}
              inspectifyAdmin={inspectifyAdmin}
              hasInspectionWarranty={!!inspectionWarranty}
              isInspectionWarrantyExpired={isInspectionWarrantyExpired}
            />
          )
        }

          {(isAppliancesOnly || isAppliancesOnlyFilter)
          && (subsystemApplianceInformations.length > 0 && informations.length > 0) && (
            <>
              <Informations data={informationDisplayData} isSignature={isSignature} />
              <ApplianceInfo
                applianceInformations={subsystemApplianceInformations}
                isPrint={isPrint}
                inspectifyAdmin={inspectifyAdmin}
                hasInspectionWarranty={!!inspectionWarranty}
                isInspectionWarrantyExpired={isInspectionWarrantyExpired}
              />
            </>
          )}

          {isFullReport && subsystem.note !== null && (
            <div className={styles.commentContainer}>
              <p className={styles.labelHeader}>Note</p>
              <p className={styles.commentText}>{subsystem.note}</p>
            </div>
          )}
        </div>

        {(isFullReport || isMediaOnly || (isAppliancesOnly || isAppliancesOnlyFilter)) && (
          <Attachments
            title={`${subsystem.name} photos / videos`}
            attachments={attachmentsToDisplayForSubsystem}
            informations={informations}
            imageWidth={imageWidth}
            isRehab={isRehab}
            handleWorkItemModal={handleWorkItemModal}
            systemName={systemName}
            subsystemName={subsystem.name}
            informationsData={informationDisplayData}
            note={subsystem.note}
            handleOpenReportMediaUploader={() => {
              handleOpenReportMediaUploader(subsystem.id, 'ReportSubsystem', system.reportSystemGroupId);
            }}
          />
        )}

        {(isFullReport || isMediaOnly) && externalVideosToDisplayForSubsystem.length > 0 && (
          <ExternalLinks
            title={`${subsystem.name} external links`}
            externalLinks={externalVideosToDisplayForSubsystem}
          />
        )}

        {issues.map((item) => (
          ('severity' in item) ? (
            <DeficiencyDetails
              report={report}
              key={item.id}
              deficiency={item as ReportDeficiency}
              originalDeficiency={
              (
                report.originalReportDeficiencies
                && report.originalReportDeficiencies[item?.originalReportDeficiencyId]
              )
                ? report.originalReportDeficiencies[item.originalReportDeficiencyId]
                : null
            }
              deficiencyLocations={getLocationsFromIds(item.reportLocationIds, report)}
              attachments={report.attachments}
              imageWidth={imageWidth}
              isRehab={isRehab}
              handleWorkItemModal={handleWorkItemModal}
              systemName={systemName}
              subsystemName={subsystem.name}
              isMediaOnly={isMediaOnly}
              handleOpenReportMediaUploader={handleOpenReportMediaUploader}
            />
          ) : (
            <LimitationDetails
              report={report}
              key={item.id}
              limitation={item as ReportLimitation}
              limitationLocations={getLocationsFromIds(item.reportLocationIds, report)}
              imageWidth={imageWidth}
              isRehab={isRehab}
              handleWorkItemModal={handleWorkItemModal}
              systemName={systemName}
              subsystemName={subsystem.name}
              isMediaOnly={isMediaOnly}
              handleOpenReportMediaUploader={handleOpenReportMediaUploader}
            />
          )
        ))}
      </div>
    </div>
  );
};

export default SubsystemDetails;
