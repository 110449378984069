export enum AuthPageState {
  SignIn,
  ResetPasswordRequest,
  ResetPassword,
  SetNewPassword,
  InspectorSignUp,
  VerifyTempPassword,
}

export enum PageOrigin {
  InspectorDashboard = 'InspectorDashboard',
  RehabTool = 'RehabTool',
}
