import React, { useContext } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { colorPaletteRed600, colorBaseBlackLight } from 'haven-design-system/build/typescript/es6';
import { ReportSeverityType } from 'marketplace-common';
import { useTypedSelector } from '../../../../../../redux/store';
import { selectRehabProject, selectWorkItemAssociations } from '../../../../../../redux/selectors';
import { useReportsPageContext } from '../../../../../../utils/reportsPageContext';
import { formatMoney } from '../../../../../../utils/money';
import FormInput from '../../../../../../components/FormElements/FormInput';
import FormInputArea from '../../../../../../components/FormElements/FormInputArea';
import FormInputNumber from '../../../../../../components/FormElements/FormInputNumber';
import FormInputCurrency from '../../../../../../components/FormElements/FormInputCurrency';
import ReactSelect from '../../../../../../components/ReactSelect';
import ToggleSwitch from '../../../../../../components/ToggleSwitch';
import Checkbox from '../../../../../../components/Checkbox';
import Button from '../../../../../../components/Button';
import { RehabToolContext } from '../../../../context';
import { RehabTabs, WorkItemModalOptions } from '../../../../types';
import RemoveWorkItemModal from './RemoveWorkItemModal';
import { useWorkItemMutations } from '../useWorkItemMutations';
import { WorkItemFormComputedState, WorkItemFormState } from '../types';
import styles from '../styles.module.scss';
import { WorkItem, WorkItemAssociationsMap } from '../../../../../../types';

interface Props {
  systemNames: string[]
  state: WorkItemFormState
  computedState: WorkItemFormComputedState
  setState: (value: any) => void
  updateState: (key: string, value: any) => void
  close: () => void
}

const WorkItemForm: React.FC<Props> = ({
  systemNames,
  state,
  computedState,
  setState: set,
  updateState: update,
  close,
}) => {
  const {
    state: contextState, set: contextSet, setWorkItemModalOptions, completeWorkItemOptions,
  } = useContext(RehabToolContext);
  const { dispatch: contextDispatch } = useReportsPageContext();
  const {
    createRehabWorkItem,
    updateRehabWorkItem,
    deleteRehabWorkItem,
    deleteRehabWorkItemAssociation,
    deleteRehabWorkItemAttachment,
    loading,
  } = useWorkItemMutations({ close });

  const project = useTypedSelector((reduxState) => selectRehabProject(reduxState, contextState.projectId));

  const incompleteWorkItems = contextState?.incompleteWorkItemsForModal;

  const workItemAssociationsMap: WorkItemAssociationsMap = incompleteWorkItems.reduce((acc, item) => {
    const associations = useTypedSelector((reduxState) => selectWorkItemAssociations(reduxState, item.workItem.id));
    return { ...acc, [item.workItem.id]: associations };
  }, {});

  const setNextIncompleteWorkItem = (nextWorkItem: WorkItemModalOptions) => {
    const nextWorkItemAssociations = workItemAssociationsMap[nextWorkItem.workItem.id] || [];

    let itemType = '';
    let title = '';
    let note = '';
    let detail = '';
    let severity: ReportSeverityType;

    if (nextWorkItemAssociations.length > 0) {
      const firstAss = nextWorkItemAssociations[0];
      itemType = firstAss.itemType;
      if (firstAss.data) {
        if ('note' in firstAss.data) note = firstAss.data.note;
        if ('title' in firstAss.data) title = firstAss.data.title;
        if ('detail' in firstAss.data) detail = firstAss.data.detail;
        if ('severity' in firstAss.data) severity = firstAss.data.severity;
      }
    }

    setWorkItemModalOptions({
      ...nextWorkItem,
      noteTitle: title,
      noteDetails: detail,
      note,
      severity: itemType === 'ReportDeficiency' ? severity : undefined,
      limitation: itemType === 'ReportLimitation',
    });
  };

  const isLastIncompleteWorkItem = (workItem: WorkItem) => {
    const currentIndex = incompleteWorkItems.findIndex((item) => item.workItem.id === workItem.id);
    return currentIndex === incompleteWorkItems.length - 1;
  };

  const handleNextIncompleteWorkItem = (workItem: WorkItem) => {
    if (incompleteWorkItems.length > 0) {
      const isAlreadyCompletedItem = !incompleteWorkItems.some((item) => item.workItem.id === workItem.id);

      // if is editing an already completed work item
      // do not complete if already completed (i.e., user edits a complete but still outstanding work items...)
      if (!isAlreadyCompletedItem) {
        // Remove the completed work item
        completeWorkItemOptions(workItem.id);
      }

      const updatedIncompleteWorkItems = incompleteWorkItems.filter(
        (item) => item.workItem.id !== workItem.id,
      );

      if (updatedIncompleteWorkItems.length > 0) {
        const currentIndex = incompleteWorkItems.findIndex(
          (item) => item.workItem.id === workItem.id,
        );
        const isPositionLastIncompleteItem = currentIndex === incompleteWorkItems.length - 1;
        const nextIndex = isPositionLastIncompleteItem || isAlreadyCompletedItem ? 0 : currentIndex;
        const nextIncompleteWorkItem = updatedIncompleteWorkItems[nextIndex];
        setNextIncompleteWorkItem(nextIncompleteWorkItem);
      } else {
        close();
      }
    }
  };

  const handleAddWorkItemToProject = async () => {
    const input = {
      systemName: state.systemName,
      title: state.title,
      details: state.description,
      unit: state.unit,
      quantity: state.quantity,
      pricePerUnit: state.unitCost,
      totalPrice: state.totalCost,
      tags: state.tags,
      templateWorkItemPricingId: state.templateWorkItemPricingId,
      pricingExternalId: state.pricingExternalId,
    };

    if (state.useLumpSumCost) {
      input.unit = null;
      input.quantity = 0;
      input.pricePerUnit = null;
    }

    if (contextState.modalWorkItem) {
      try {
        await updateRehabWorkItem({
          variables: { input: { id: contextState.modalWorkItem.id, ...input } },
        });
        if (!isLastIncompleteWorkItem(contextState.modalWorkItem)) {
          handleNextIncompleteWorkItem(contextState.modalWorkItem);
        } else {
          close();
        }
      } catch (error) {
        toast.error('Failed to update work item');
      }
    } else {
      createRehabWorkItem({
        variables: {
          input: {
            projectId: contextState.projectId,
            saveToLibrary: state.saveToLibrary,
            teamMarketId: state.teamMarketId || project.teamMarketId,
            ...input,
          },
        },
      });
    }
    // If the work item is created from the report tab, go back to the report tab
    if (contextState.itemType) {
      contextSet('tab', RehabTabs.Report);
      contextDispatch({ type: 'set_active_item', payload: contextState.itemId });
    }
  };

  const isAddtoProjectDisabled = () => (
    state.useLumpSumCost
      ? (!state.title || !state.systemName || state.totalCost < 0)
      : (!state.title || !state.systemName || !state.quantity || !state.unit || state.unitCost < 0)
  );

  const handleRemoveWorkItemFromProject = () => {
    contextState.modalWorkItem.workItemAttachments.forEach((id: string) => {
      deleteRehabWorkItemAttachment({ variables: { input: { id } } });
    });
    
    contextState.modalWorkItem.workItemAssociations.forEach((id: string) => {
      deleteRehabWorkItemAssociation({ variables: { input: { id } } });
    });


    deleteRehabWorkItem({ variables: { input: { id: contextState.modalWorkItem.id } } });

    const updatedIncompleteWorkItems = incompleteWorkItems.filter(
      (item) => item.workItem.id !== contextState.modalWorkItem.id,
    );
    if (updatedIncompleteWorkItems.length > 0) {
      const nextIncompleteWorkItem = updatedIncompleteWorkItems[0];
      setNextIncompleteWorkItem(nextIncompleteWorkItem);
      update('isRemoveModalOpen', false);
    } else {
      // note Stef: future work refactor to just call this instead of passing down close everywhere?
      // setWorkItemModalOptions({ isOpen: false, attachments: [] })
      close();
    }
  };

  const handleSelectedItemTagsChange = (values: { label: string, value: string }[]) => {
    const selectedTags = values?.length ? values.map(({ value }) => value) : [];
    update('tags', selectedTags);
  };

  const handleSelectVariety = (id: string) => {
    const selectedItemPricing = computedState.workItemPricings.find((pricing) => pricing.id === id);
    if (!selectedItemPricing) return;

    set({
      unit: selectedItemPricing.unit,
      totalCost: selectedItemPricing.lumpSumPrice,
      unitCost: selectedItemPricing.pricePerUnit,
      teamMarketId: selectedItemPricing.teamMarketId,
      templateWorkItemPricingId: selectedItemPricing.id,
      pricingExternalId: selectedItemPricing.pricingExternalId,
      description: selectedItemPricing.details,
    });
  };

  const getButtonText = () => {
    if (incompleteWorkItems.length > 0 && contextState.modalWorkItem) {
      return isLastIncompleteWorkItem(contextState.modalWorkItem) ? 'Save' : 'Save and Next';
    }
    return contextState.modalWorkItem ? 'Save' : 'Add to project';
  };

  return (
    <div className={cn(styles.workItemForm, loading ? styles.loading : '')}>
      <div>
        <div style={{ fontSize: '14px', color: colorBaseBlackLight }}>System</div>
        <ReactSelect
          value={{ label: state.systemName, value: state.systemName }}
          onChange={(option) => update('systemName', option?.value)}
          options={(systemNames || []).map((name) => ({ label: name, value: name }))}
          placeholder="Select a system"
          width="100%"
          isSearchable
        />
        <FormInput
          name="Title"
          value={state.title}
          style={{ margin: '12px 0px' }}
          onChange={(e) => update('title', e.target.value || '')}
        />
        <FormInputArea
          data-testid="formInputArea"
          name="Description"
          value={state.description}
          style={{ margin: '12px 0px' }}
          inputStyle={{ height: 121, padding: 6 }}
          onChange={(e) => update('description', e.target.value || '')}
        />

        <div style={{ fontSize: '14px', color: colorBaseBlackLight }}>Tags</div>
        <ReactSelect
          value={state.tags.map((tag) => ({ label: tag, value: tag }))}
          onChange={handleSelectedItemTagsChange}
          options={(computedState.rehabTags || []).map((tag) => ({ label: tag, value: tag }))}
          placeholder="Select a tag"
          width="100%"
          isMulti
          isSearchable
          isCreateable
        />

        {computedState.workItemPricings.length > 1 && (
          <>
            <div style={{ fontSize: '14px', color: colorBaseBlackLight }}>Variety</div>
            <ReactSelect
              value={{
                label: state.pricingExternalId,
                value: state.templateWorkItemPricingId,
              }}
              onChange={(option) => handleSelectVariety(option.value)}
              options={computedState.workItemPricings.map(
                (pricing) => ({ label: pricing.pricingExternalId, value: pricing.id }),
              )}
              placeholder="Select a variety"
              width="100%"
              isSearchable
            />
          </>
        )}

        {state.useLumpSumCost ? (
          <div className={styles.row} style={{ margin: '12px 0px', width: '50%' }}>
            <FormInputCurrency
              tag="create-new-item"
              name="Total cost"
              value={state.totalCost}
              onValueChange={(value: number) => update('totalCost', value || 0)}
            />
          </div>
        ) : (
          <div className={styles.row} style={{ margin: '12px 0px', gap: '10px' }}>
            <FormInputNumber
              name="Quantity"
              value={state.quantity}
              style={{ marginRight: 12 }}
              onChange={(e) => update('quantity', parseInt(e.target.value || '0', 10))}
            />
            <FormInput
              name="Unit"
              value={state.unit}
              style={{ marginRight: 12 }}
              onChange={(e) => update('unit', e.target.value || '')}
            />
            <FormInputCurrency
              name="Unit cost"
              value={state.unitCost}
              onValueChange={(value: number) => update('unitCost', value || 0)}
            />
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.row}>
            <ToggleSwitch
              tag="create-new-item"
              isOn={state.useLumpSumCost}
              toggle={() => update('useLumpSumCost', !state.useLumpSumCost)}
            />
            <p className={styles.label} style={{ marginBottom: 4, marginLeft: 6 }}>
              Use lump sum cost
            </p>
          </div>
          {!state.useLumpSumCost && (
            <p className={styles.label} style={{ fontSize: 20 }}>
              Total:{' '}
              <strong>
                {computedState.calculatedTotal > 0 ? formatMoney(computedState.calculatedTotal) : '$0.00'}
              </strong>
            </p>
          )}
        </div>
      </div>

      <div>
        {!contextState.modalWorkItem && (
          <div className={styles.saveToLibraryContainer}>
            <div className={styles.row} style={{ width: 140 }}>
              <Checkbox
                id="save-to-library-checkbox"
                isChecked={state.saveToLibrary}
                toggle={() => update('saveToLibrary', !state.saveToLibrary)}
              />
              <p className={styles.label} style={{ marginLeft: 6 }}>Save to Library</p>
            </div>
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => update('isRemoveModalOpen', true)}
            style={{
              backgroundColor: colorPaletteRed600,
              border: 'none',
              visibility: contextState.modalWorkItem ? 'visible' : 'hidden',
            }}
          >
            Remove
          </Button>

          <div>
            <Button
              data-testid="closeModalButton"
              secondary
              onClick={close}
              style={{ marginInline: 16 }}
            >
              Cancel
            </Button>

            <Button
              disabled={isAddtoProjectDisabled()}
              onClick={handleAddWorkItemToProject}
            >
              {getButtonText()}
            </Button>
          </div>
        </div>
      </div>

      <RemoveWorkItemModal
        isRemoveModalOpen={state.isRemoveModalOpen}
        setIsRemoveModalOpen={(value) => update('isRemoveModalOpen', value)}
        handleRemoveWorkItemFromProject={handleRemoveWorkItemFromProject}
      />
    </div>
  );
};

export default WorkItemForm;
