// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root__3uUbMAEdIqf0B5usFn4BWd{display:flex;justify-content:center;align-items:center;flex-grow:1;padding:3rem 0}.spinner-sm__SmY0Z9LdMkQ69TmEP3iH4{width:1.75rem;height:1.75rem;border-width:.2em}.spinner-lg__v_TTCcEJpXtAgSG0jCms-{width:5rem;height:5rem;border-width:.4em}.spinner-xl__1G8mPKOiIZYG-wNv7g9sJF{width:7rem;height:7rem;border-width:.5em}", "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA,CAIA,mCACE,aAAA,CACA,cAAA,CACA,iBAAA,CAEF,mCACE,UAAA,CACA,WAAA,CACA,iBAAA,CAEF,oCACE,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".root {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-grow: 1;\n  padding: 3rem 0;\n}\n\n.spinner {\n  &-sm {\n    width: 1.75rem;\n    height: 1.75rem;\n    border-width: 0.2em;\n  }\n  &-lg {\n    width: 5rem;\n    height: 5rem;\n    border-width: 0.4em;\n  }\n  &-xl {\n    width: 7rem;\n    height: 7rem;\n    border-width: 0.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root__3uUbMAEdIqf0B5usFn4BWd",
	"spinner-sm": "spinner-sm__SmY0Z9LdMkQ69TmEP3iH4",
	"spinner-lg": "spinner-lg__v_TTCcEJpXtAgSG0jCms-",
	"spinner-xl": "spinner-xl__1G8mPKOiIZYG-wNv7g9sJF"
};
export default ___CSS_LOADER_EXPORT___;
