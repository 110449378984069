import { gql } from '@apollo/client';

export const GENERATE_PERMIT_PDF = gql`
  mutation GenerateRehabProjectPdf($input: GeneratePropertyPermitPdfReportInput!) {
    generatePropertyPermitPdfReport(input: $input) {
      permitPdfReportActiveStorageBlob {
        id
        contentType
        filename
        url
        byteSize
        createdAt
      }
    }
  }
`;
