import { gql } from '@apollo/client';
import { FULL_CONTRACTOR_FIELDS } from '../queries';

export const CREATE_SURVEYOR_ONBOARDING = gql`
  mutation($input: CreateSurveyorOnboardingInput!) {
    createSurveyorOnboarding(input: $input) {
      surveyorOnboarding {
        id
        completedStep
      }
    }
  }
`;

export const UPDATE_SURVEYOR_ONBOARDING = gql`
  ${FULL_CONTRACTOR_FIELDS}
  mutation($input: UpdateSurveyorOnboardingInput!) {
    updateSurveyorOnboarding(input: $input) {
      surveyorOnboarding {
        id
        appLoggedIn
        backgroundCheckRequired
        backgroundCheckSubmittedAt
        canSetPassword
        completedStep
        contractor {
          ...FullContractorFields
        }
        createdAt
        misaSigned
        onboardingTrackerDismissedAt
        paymentDetailsFilled
        status
        submitBackgroundCheckUrl
        updatedAt
      }
    }
  }
`;