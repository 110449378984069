import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { MdOutlineRotate90DegreesCcw } from 'react-icons/md';
import { toast } from 'react-toastify';
import LoadingIcon from 'haven-design-system/build/assets/svgs/display/spinner.svg?component';
import ConfirmIcon from 'haven-design-system/build/assets/svgs/action/confirm.svg?component';
import APPLY_ROTATION from '../../../../../../graphql/mutations/applyRotation';
import { useCurrentUser } from '../../../../../../hooks/user';
import styles from './styles.module.scss';

interface Props {
  show: boolean,
  attachmentId: string,
  degrees: number,
  setRotate: (value: React.SetStateAction<number>) => void
}

const Rotate: React.FC<Props> = ({
  show,
  attachmentId,
  degrees,
  setRotate,
}) => {
  const user = useCurrentUser();
  const inspectifyAdmin = !!user?.currentUser?.admin;

  const [applyRotation, { loading }] = useMutation(APPLY_ROTATION);
  const onSaveRotation = async () => {
    await applyRotation({
      variables: {
        input: {
          id: attachmentId,
          rotationDegree: degrees,
        },
      },
    });
    toast.success('Rotation saved');
  };

  const onRotate = () => {
    setRotate((degrees + 90) % 360);
  };

  // register keyboard shortcut:
  // r for rotate
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (!show) return;

      switch (event.key) {
        case 'r':
          onRotate();
          break;
        case 's':
          if (inspectifyAdmin) {
            await onSaveRotation();
          }
          break;
        default:
          break;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onRotate, show]);

  if (!show) return null;

  return (
    <>
      <button
        title="Rotate media (R)"
        className={styles.controlButton}
        type="button"
        onClick={onRotate}
      >
        {loading ? (
          <LoadingIcon className={styles.controlButtonIcon} />
        ) : (
          <>(R)<MdOutlineRotate90DegreesCcw size={24} color="white" style={{ transform: 'scaleX(-1)' }} /></>
        )}
      </button>
      {inspectifyAdmin && degrees !== 0 && (
        <button
          title="Save file (S)"
          className={styles.controlButton}
          type="button"
          onClick={onSaveRotation}
        >
          (S)<ConfirmIcon className={styles.controlButtonIcon} />
        </button>
      )}
    </>
  );
};

export default Rotate;
