import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        name
        email
        phoneNumber
        grantedPermissions
        archivedAt
        activatedAt
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        name
        email
        phoneNumber
        grantedPermissions
        archivedAt
        activatedAt
      }
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation resendInvite($input: ResendInviteInput!) {
    resendInvite(input: $input) {
      user {
        id
        name
        email
        phoneNumber
        grantedPermissions
        archivedAt
        activatedAt
      }
    }
  }
`;