import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { colorBaseGreenPrimary } from 'haven-design-system/build/typescript/es6';
import { ProgressBar } from 'react-bootstrap';
import styles from './styles.module.scss';
import { UploadsInterface } from '../../components/MediaUploader/useUpload';

interface Props {
  handleFiles: <T extends File>(
    acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent
  ) => void;
  uploads: UploadsInterface;
  remainingCount: number;
}

const MediaRecovery: React.FC<Props> = ({
  handleFiles, uploads, remainingCount,
}) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (e: any) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  return (
    <div className={styles.background}>
      <Dropzone onDrop={handleFiles} disabled={remainingCount !== 0}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div>
            <h1 className={styles.title}>Report media recovery</h1>
            <p className={styles.description}>
              This tool can be used to fix blank images in web reports and
              Inspectify system will match the files using file name to the correct report.
            </p>
            <p className={styles.description}>
              Multiple files can be selected at the same time.
            </p>
            <div {...getRootProps()}>
              <input {...getInputProps()} ref={hiddenFileInput} />
              <button
                type="button"
                className={styles.button}
                style={{ backgroundColor: isDragActive ? colorBaseGreenPrimary : 'transparent' }}
                disabled={remainingCount !== 0}
                onClick={handleClick}
              >
                <FontAwesomeIcon className={styles.icon} icon={faUpload} size="xs" />{' Drop a file to add, or choose files'}
              </button>
            </div>
          </div>
        )}
      </Dropzone>
      {remainingCount !== 0 ? (
        <h5>Remaining uploads: {remainingCount}</h5>
      ) : <></>}
      {Object.entries(uploads).sort(
        ([, { updatedAt }]) => -updatedAt.getTime(),
      ).map(([fileName, { progress, error }]) => (
        <div key={fileName}>{fileName}:
          <ProgressBar key={fileName} now={progress} />
          {error && (<span className={styles.uploadError}>{error}</span>)}
        </div>
      ))}
    </div>
  );
};

export default MediaRecovery;
