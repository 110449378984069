import { Auth, Hub } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { handleSignInError } from 'marketplace-common';
import { captureException } from './error';

export const signIn = async (
  email: string,
  password: string,
  onSignInSuccess: () => void,
  onNewPasswordRequired?: (signedInUser: CognitoUser) => void,
) => {
  try {
    const user = await Auth.signIn(email, password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      onNewPasswordRequired(user);
    } else {
      onSignInSuccess();
    }
  } catch (err: any) {
    handleSignInError(err, captureException, email);
  }
};

export const completeNewPassword = async (
  user: CognitoUser, password: string,
) => {
  await Auth.completeNewPassword(user, password);

  const currentUser = await Auth.currentUserInfo();

  // dispatching our own signin event because completeNewPassword event is missing the
  // user attributesFfe
  Hub.dispatch(
    'authInspectify',
    {
      event: 'signIn',
      data: currentUser,
    },
  );
};

export const signUp = async (
  email: string, name: string, phoneNumber: string, isAgent: boolean,
) => {
  const tempPassword = 'temp_password';
  await Auth.signUp({
    username: email,
    password: tempPassword,
    attributes: {
      name,
      email,
      phone_number: phoneNumber,
      'custom:is_agent': isAgent.toString(),
    },
  });
  await Auth.signIn(email, tempPassword);
};

export const signOut = async () => {
  try {
    await Auth.signOut();
    window.localStorage.removeItem('inspectifySelectedUser');
  } catch (error) {
    captureException(error);
  }
};

export const getJwtToken = async () => {
  try {
    if (window.appInjectedJwtToken) {
      return window.appInjectedJwtToken;
    }

    const currentSession = await Auth.currentSession() as any;
    if (currentSession && currentSession.idToken) {
      return currentSession.idToken.jwtToken;
    }
    return null;
  } catch (error) {
    captureException(error);
    return null;
  }
};

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signIn_failure' && data.payload.data.message.includes('No+account+found+for+this+email+address')) {
    // eslint-disable-next-line no-alert
    alert('No account found for this email address. Please contact your account manager or request to signup at https://www.inspectify.com/');
    window.location.href = '/sign_in';
  }
});
