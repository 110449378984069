import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from 'haven-design-system/build/assets/svgs/display/info_outlined.svg?component';
import { colorBaseBlueDark } from 'haven-design-system/build/typescript/es6';
import { Label, Name, Select } from './styles';

export type FormSelectOption = {
  value: string;
  display: string;
};

interface Props {
  name: string,
  options: FormSelectOption[],
  value: string,
  onChange: (e:any) => void,
  tooltip?: string,
  short?: boolean,
  style?: React.CSSProperties,
}

const FormSelect: React.FC<Props> = ({
  name, options, value, onChange, short, style, tooltip,
}) => (
  <Label style={style}>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
      <Name>{name}</Name>
      {tooltip && (
        <OverlayTrigger overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
          <span style={{ padding: '0 4px' }}><InfoIcon color={colorBaseBlueDark} /></span>
        </OverlayTrigger>
      )}
    </div>
    <Select value={value} onChange={onChange}>
      <option disabled value="" key=""> {short ? '--' : '-- select an option --'} </option>
      {options.map((element) => (
        <option value={element.value} key={element.value}>
          {element.display}
        </option>
      ))}
    </Select>
  </Label>
);

export default FormSelect;
