import cn from 'classnames';
import React from 'react';
import styles from './Spinner.module.scss';
import { SpinnerProps } from './types';

/**
 * A loading spinner, horizontally centered with vertical padding.
 */
const Spinner: React.FC<SpinnerProps> = ({ className, size = 'md', style }) => (
  <div className={cn(styles.root, className)} style={style}>
    <div className={cn('spinner-border text-primary', styles[`spinner-${size}`])} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default Spinner;
