import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { onCreateRehabWorkItemCompleted } from '../../../../../../utils/rehab';
import {
  TemplateWorkItemPricing,
  TemplateWorkItemsInterface,
} from '../../../../../../types';
import {
  CREATE_REHAB_WORK_ITEM,
  CREATE_REHAB_WORK_ITEM_ASSOCIATION,
  CREATE_REHAB_WORK_ITEM_ATTACHMENT,
} from '../../../../../../graphql/mutations/rehab';
import {
  saveWorkItem,
  saveWorkItemAssociation,
  saveWorkItemAttachment,
} from '../../../../../../redux/actions';
import { captureException } from '../../../../../../utils/error';
import { useReportsPageContext } from '../../../../../../utils/reportsPageContext';
import { RehabTabs } from '../../../../types';
import { useWorkItemLibraryState } from './state';
import WorkItemLibrary from './WorkItemLibrary';
import { RehabToolContext } from '../../../../context';

interface Props {
  templateWorkItemsForSystem: TemplateWorkItemsInterface;
  close: () => void;
}

const WorkItemLibraryLoader: React.FC<Props> = ({
  templateWorkItemsForSystem,
  close,
}) => {
  const { state: contextState, set: contextSet } = useContext(RehabToolContext);

  const dispatch = useDispatch();
  const { dispatch: contextDispatch } = useReportsPageContext();

  const {
    state,
    computedState,
    set,
    update,
  } = useWorkItemLibraryState({
    projectId: contextState.projectId,
    templateWorkItemsForSystem,
    teamId: contextState.teamId,
  });

  const [createRehabWorkItemAssociation, { loading: loadingAssociation }] = useMutation(
    CREATE_REHAB_WORK_ITEM_ASSOCIATION,
    {
      onCompleted: (data) => {
        if (data) {
          dispatch(saveWorkItemAssociation(data));
        } else {
          captureException(new Error('Failed to save item association'), data);
        }
      },
      onError: () => {
        captureException(new Error('Graphql failed to save item association'));
      },
    },
  );

  const [createRehabWorkItemAttachment, { loading: loadingAttachment }] = useMutation(
    CREATE_REHAB_WORK_ITEM_ATTACHMENT,
    {
      onCompleted: (data) => {
        if (data) {
          dispatch(saveWorkItemAttachment(data));
        } else {
          captureException(new Error('Failed to save item attachment'), data);
        }
        close();
      },
      onError: () => {
        captureException(new Error('Graphql failed to save item attachment'));
      },
    },
  );

  const [createRehabWorkItem, { loading: loadingCreateWorkItem }] = useMutation(
    CREATE_REHAB_WORK_ITEM,
    {
      onCompleted: async (data) => {
        if (data) {
          dispatch(saveWorkItem(data));
          onCreateRehabWorkItemCompleted(
            data,
            contextState.itemType,
            contextState.itemId,
            createRehabWorkItemAssociation,
            createRehabWorkItemAttachment,
            contextState.report,
            contextState.workItemModalAttachments,
          );
          toast.success('Work item was added to your project.');
        } else {
          captureException(new Error('Failed to save work item'), data);
          toast.error('Failed to save work item');
        }
        close();
      },
      onError: () => {
        captureException(new Error('Graphql failed to save work item'));
        toast.error('Failed to save work item');
      },
    },
  );

  const parseNumberFromInput = (val: string) => {
    const num = parseInt(val, 10);
    return (!Number.isNaN(num) && num >= 0) ? num : 0;
  };

  const handleSelectWorkItem = (id: string) => {
    const item = computedState.filteredTemplateWorkItemsForSystem[id];
    const selectedItemPricings = (item?.templateWorkItemPricings || []).reduce((acc, pId) => {
      const pricing = computedState.templateWorkItemPricings[pId];
      return pricing ? [...acc, pricing] : acc;
    }, [] as TemplateWorkItemPricing[]);

    let selectedItemPricing;
    if (String(contextState.teamId) === '2426') { // Second Avenue default to HIGH
      selectedItemPricing = selectedItemPricings.find((p) => p.pricingExternalId === 'HIGH');
    }
    selectedItemPricing ||= selectedItemPricings.length ? selectedItemPricings[0] : null;

    const selectedItemLumpSum = (
      (!selectedItemPricing?.unit === null && selectedItemPricing?.pricePerUnit !== null)
      || selectedItemPricing?.lumpSumPrice !== null
    );
    const selectedItemTotal = selectedItemLumpSum
      ? (selectedItemPricing?.lumpSumPrice || selectedItemPricing?.pricePerUnit)
      : selectedItemPricing?.pricePerUnit;

    set({
      selectedItemId: id,
      selectedItemSystemName: item.systemName,
      selectedItemTitle: item.title,
      selectedItemQuantity: 1,
      selectedItemPricings,
      selectedItemPricing: selectedItemPricing || {},
      selectedItemDescription: selectedItemPricing?.details || '',
      selectedItemPricePerUnit: selectedItemPricing?.pricePerUnit || 0,
      selectedItemUnit: selectedItemPricing?.unit || '',
      selectedItemLumpSum,
      selectedItemTotal,
    });
  };

  const handleSelectVariety = (id: string) => {
    const selectedItemPricing = computedState.templateWorkItemPricings[id];
    const selectedItemLumpSum = (
      (!selectedItemPricing?.unit === null && selectedItemPricing?.pricePerUnit !== null)
      || selectedItemPricing?.lumpSumPrice !== null
    );
    const selectedItemTotal = selectedItemLumpSum
      ? (selectedItemPricing?.lumpSumPrice || selectedItemPricing?.pricePerUnit)
      : selectedItemPricing?.pricePerUnit;

    set({
      selectedItemPricing,
      selectedItemDescription: selectedItemPricing.details,
      selectedItemPricePerUnit: selectedItemPricing.pricePerUnit,
      selectedItemUnit: selectedItemPricing.unit,
      selectedItemLumpSum,
      selectedItemTotal,
    });
  };

  const handleSelectedItemTagsChange = (values: { label: string, value: string }[]) => {
    const tags = values?.length ? values.map(({ value }) => value) : [];
    update('selectedItemTags', tags);
  };

  const handleAddNewWorkItemToProject = () => {
    const selectedSystemName = (
      contextState.modalSystemName
      || computedState.filteredTemplateWorkItemsForSystem[
        state.selectedItemPricing?.templateWorkItemId
      ]?.systemName
      || state.selectedItemSystemName
    );

    const input = {
      systemName: selectedSystemName,
      title: state.selectedItemTitle,
      details: state.selectedItemDescription,
      projectId: contextState.projectId,
      unit: state.selectedItemUnit,
      quantity: state.selectedItemQuantity,
      pricePerUnit: state.selectedItemPricePerUnit,
      totalPrice: state.selectedItemTotal,
      templateWorkItemPricingId: state.selectedItemPricing.id,
      pricingExternalId: state.selectedItemPricing.pricingExternalId || 'Custom',
      tags: state.selectedItemTags,
    };

    if (state.selectedItemLumpSum) {
      input.unit = null;
      input.quantity = 0;
      input.pricePerUnit = null;
    }

    createRehabWorkItem({ variables: { input } });

    // If the work item is created from the report tab, go back to the report tab
    if (contextState.itemType) {
      contextSet('tab', RehabTabs.Report);
      contextDispatch({ type: 'set_active_item', payload: contextState.itemId });
    }
  };

  const isLoading = loadingCreateWorkItem || loadingAssociation || loadingAttachment;

  const isAddToProjectDisabled = (
    (!state.selectedItemLumpSum && !state.selectedItemQuantity)
    || !state.selectedItemId
    || isLoading
    || (state.selectedItemLumpSum && !state.selectedItemTotal)
    || !state.selectedItemDescription
  );

  return (
    <WorkItemLibrary
      loading={isLoading}
      state={state}
      computedState={computedState}
      onSearch={
        (e: any) => update('searchTerm', e?.target?.value?.length > 2 ? e.target.value : '')
      }
      onDescriptionChange={(e: any) => update('selectedItemDescription', e?.target?.value || '')}
      onQuantityChange={
        (e: any) => update('selectedItemQuantity', parseNumberFromInput(e?.target?.value || ''))
      }
      onTotalChange={(value: number) => update('selectedItemTotal', value || 0)}
      onToggleSelectedItemLumpSum={(toggle) => update('selectedItemLumpSum', toggle)}
      onSelectedItemUnitChange={(e: any) => update('selectedItemUnit', e.target.value)}
      onSelectedItemPricePerUnitChange={(value: number) => update('selectedItemPricePerUnit', value || 0)}
      onSelectedItemTagsChange={handleSelectedItemTagsChange}
      onAddNewWorkItemToProject={handleAddNewWorkItemToProject}
      onSelectWorkItem={handleSelectWorkItem}
      onSelectVariety={handleSelectVariety}
      onClose={close}
      isAddToProjectDisabled={isAddToProjectDisabled}
    />
  );
};

export default WorkItemLibraryLoader;
