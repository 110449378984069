import { LayoutState } from '../../types/layout';
import {
  SET_LAYOUT_COBRANDED_LOGO,
  SET_DISPLAY_HEADER,
  RESET_LAYOUT,
  SET_LAYOUT_IS_LOADING,
  SET_LAYOUT_FADED_FOOTER,
  SET_LAYOUT_SHOW_FOOTER,
  SET_LAYOUT_HIDE_HEADER_ON_MOBILE,
  SET_LAYOUT_SHOW_SIGNIN,
} from '../actions/types';

export const initialLayoutState = {
  displayNavbar: true,
  cobrandingLogoUrl: null,
  isLoading: false,
  showLogin: true,
  fadedFooter: false,
  showFooter: undefined,
  showSignIn: true,
  hideHeaderOnMobile: false,
} as LayoutState;

export default function bids(state: any = initialLayoutState, action: any) {
  switch (action.type) {
    case SET_DISPLAY_HEADER:
      return { ...state, displayNavbar: action.payload };
    case SET_LAYOUT_COBRANDED_LOGO:
      return { ...state, cobrandingLogoUrl: action.payload };
    case SET_LAYOUT_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_LAYOUT_FADED_FOOTER:
      return { ...state, fadedFooter: action.payload };
    case SET_LAYOUT_SHOW_FOOTER:
      return { ...state, showFooter: action.payload };
    case SET_LAYOUT_SHOW_SIGNIN:
      return { ...state, showSignIn: action.payload };
    case SET_LAYOUT_HIDE_HEADER_ON_MOBILE:
      return { ...state, hideHeaderOnMobile: action.payload };
    case RESET_LAYOUT:
      return initialLayoutState;
    default:
      return state;
  }
}
