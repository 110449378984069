import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import './styles.scss';

interface Props {
  name: string,
  value: number,
  tag?: string,
  className?: string,
  includeLabel?: boolean,
  onValueChange: (value: number) => void,
}

const FormInputCurrency: React.FC<Props> = ({
  name, value, tag, className, includeLabel = true, onValueChange,
}) => (
  <div className="inputContainer">
    {includeLabel && <label htmlFor={name} className="label"> {name} </label>}
    <CurrencyInput
      id={name}
      tag={tag}
      className={className || 'input'}
      defaultValue={value}
      value={value}
      prefix="$"
      decimalsLimit={2}
      decimalScale={2}
      onValueChange={(_value: any, _name: string, values: any) => onValueChange(values.float)}
    />
  </div>
);

export default FormInputCurrency;
