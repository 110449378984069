import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { differenceInHours } from 'date-fns';
import {
  FETCH_TEMPLATE,
  getCurrentUnixTimestamp,
  normalizeGraphqlTemplateResponse,
  TemplatesInterface,
} from 'marketplace-common';
import { captureException } from '../../utils/error';
import { client } from '../../graphql/createApolloClient';

export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const PURGE_TEMPLATES = 'PURGE_TEMPLATES';

export const fetchTemplate = (
  templateId: string,
  dontFetchIfExists = true,
) => async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  getState: () => { templates: TemplatesInterface },
): Promise<any> => {
  const { templates } = getState();
  if (dontFetchIfExists) {
    const template = templates[templateId];
    // add the template if it's not local
    // only fetch template if it hasn't been synced more than 4 hours ago
    if (
      template
      && template.lastSync
      && (differenceInHours(getCurrentUnixTimestamp() * 1000, template.lastSync * 1000) < 4)
    ) {
      return Promise.resolve();
    }
  }

  const { data } = await client.query({
    query: FETCH_TEMPLATE,
    variables: { id: templateId },
  });

  if (data.templateReport) {
    const timestamp = getCurrentUnixTimestamp();
    const normalizedTemplate = normalizeGraphqlTemplateResponse(data, { captureException });

    if (normalizedTemplate) {
      const template = {
        ...(normalizedTemplate),
        lastSync: timestamp,
      };

      return dispatch({ type: ADD_TEMPLATE, payload: template });
    }
  }
  return Promise.resolve();
};
