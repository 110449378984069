import React from 'react';
import CheckboxOn from '../../assets/icons/checkbox/on.svg';
import CheckboxOff from '../../assets/icons/checkbox/off.svg';
import { CheckboxProps } from './types';
import './styles.scss';

const Checkbox: React.FC<CheckboxProps> = ({
  id, isChecked, toggle, label,
}) => (
  <button
    id={id}
    onClick={toggle}
    type="button"
    className="checkboxContainer"
  >
    {isChecked ? <img src={CheckboxOn} alt="CheckboxOn" /> : <img src={CheckboxOff} alt="CheckboxOff" />}
    <div className={isChecked ? 'responseTextSelected' : 'responseText'}>{label}</div>
  </button>
);

export default Checkbox;
