import {
  Property,
  Report,
  ReportDeficiency,
  ReportSeverityType,
  ReportStatus,
  ReportSubsystem,
  ReportSystem,
  ReInspectionStatus,
  ReportLimitation,
  ReportAttachment,
  ReportApplianceInformation,
  ReportInformation,
} from 'marketplace-common';
import { IssueCount, ReportDeliveryFilter } from '../types/models';
import { convertEpochUTCSecondsToDate, formatDate } from './date';
import { captureException } from './error';

export const HOME_INSPECTION_DETAILS_NAME = 'Home Inspection Details';

export const getInspectionDateLabel = (inspectionDate: number) => (inspectionDate
  ? formatDate(convertEpochUTCSecondsToDate(inspectionDate), { format: 'MMMM d, yyyy, h:mm a' })
  : '--');

export const getInspectionLabelOnlyDate = (inspectionDate: number) => (inspectionDate
  ? formatDate(convertEpochUTCSecondsToDate(inspectionDate), { format: 'MMMM d, yyyy' })
  : '--');

export const getAddressLabel = (property: Property | null | undefined) => (property
  ? `${property?.street}${property?.street2 ? `, ${property?.street2}` : ''}\n${property?.city || ''}, ${property?.state || ''} ${property?.zipCode || ''}`
  : '--');

export const getIssueCounts = (
  deficiencies: ReportDeficiency[],
  limitations: ReportLimitation[],
): IssueCount => {
  const numLimitations = limitations.length || 0;
  let numSafety = 0;
  let numRepair = 0;
  let numMonitor = 0;
  let numFixed = 0;
  let numNewDefects = 0;
  let numFlagged = 0;

  deficiencies.forEach((deficiency) => {
    if (deficiency.reInspectionStatus === 'fixed') {
      numFixed += 1;
    } else {
      switch (deficiency.severity) {
        case ReportSeverityType.safety:
          numSafety += 1;
          break;
        case ReportSeverityType.repair:
          numRepair += 1;
          break;
        case ReportSeverityType.monitor:
          numMonitor += 1;
          break;
        case ReportSeverityType.inspectify_flag:
          numFlagged += 1;
          break;
        default:
          captureException(`Unknown severity type: ${deficiency.severity}`, { deficiency });
          break;
      }
      if (!deficiency.originalReportDeficiencyId && !deficiency.reInspectionStatus) {
        numNewDefects += 1;
      }
    }
  });

  return {
    safety: numSafety,
    repair: numRepair,
    monitor: numMonitor,
    fixed: numFixed,
    newDefects: numNewDefects,
    flagged: numFlagged,
    limitations: numLimitations,
  };
};

export const getSystemIssueCounts = (
  systems: ReportSystem[],
  report: Report,
  filter?: ReportDeliveryFilter,
): IssueCount => {
  const subsystemIds = systems.reduce((acc: string[], { reportSubsystemIds }) => (
    [...acc, ...(reportSubsystemIds || [])]
  ), []);

  const deficiencyIds = subsystemIds.reduce((acc: string[], id) => (
    [...acc, ...(report.subsystems?.[id]?.reportDeficiencyIds || [])]
  ), []);
  const limitationIds = subsystemIds.reduce((acc: string[], id) => (
    [...acc, ...(report.subsystems?.[id]?.reportLimitationIds || [])]
  ), []);

  let limitations = limitationIds.map((id) => report.limitations[id]);
  let deficiencies = deficiencyIds.map((id) => report.deficiencies[id]);

  switch (filter) {
    case ReportDeliveryFilter.FixedDefectsOnly:
      limitations = [];
      deficiencies = deficiencies.filter(
        (deficiency) => deficiency.reInspectionStatus === ReInspectionStatus.Fixed,
      );
      break;
    case ReportDeliveryFilter.RemainingDefectsOnly:
      deficiencies = deficiencies.filter(
        (deficiency) => deficiency.reInspectionStatus !== ReInspectionStatus.Fixed,
      );
      break;
    default:
      break;
  }

  return getIssueCounts(deficiencies, limitations);
};

export const getAllSystemDefects = (
  subsystems: ReportSubsystem[],
  report: Report,
) => subsystems.reduce(
  (deficiencies, subsystem) => {
    const subsystemDeficiencies: ReportDeficiency[] = [];
    if (subsystem?.reportDeficiencyIds) {
      subsystem.reportDeficiencyIds.forEach((deficiencyId) => {
        if (report.deficiencies[deficiencyId]) {
          subsystemDeficiencies.push(report.deficiencies[deficiencyId]);
        }
      });
    }
    return [...deficiencies, ...subsystemDeficiencies];
  }, [],
);

export const getAllSystemLimitations = (
  subsystems: ReportSubsystem[],
  report: Report,
) => subsystems.reduce(
  (acc, { reportLimitationIds }) => {
    const limitations: ReportLimitation[] = [];
    if (reportLimitationIds) {
      reportLimitationIds.forEach((id) => {
        if (report.limitations[id]) limitations.push(report.limitations[id]);
      });
    }
    return [...acc, ...limitations];
  }, [],
);

export const getAllSystemAttachments = (
  subsystems: ReportSubsystem[],
  report: Report,
) => subsystems.reduce(
  (
    acc,
    {
      reportSystemId,
      reportAttachmentIds,
      reportInformationIds,
      reportLimitationIds,
      reportDeficiencyIds,
    },
  ) => {
    const attachmentIds = [...(reportAttachmentIds || [])];

    (report.systems[reportSystemId].reportAttachmentIds || []).forEach(
      (id) => attachmentIds.push(id),
    );

    (reportInformationIds || []).forEach(
      (id) => attachmentIds.push(...(report.informations?.[id]?.reportAttachmentIds || [])),
    );
    (reportDeficiencyIds || []).forEach(
      (id) => attachmentIds.push(...(report.deficiencies?.[id]?.reportAttachmentIds || [])),
    );
    (reportLimitationIds || []).forEach(
      (id) => attachmentIds.push(...(report.limitations?.[id]?.reportAttachmentIds || [])),
    );

    const attachments: ReportAttachment[] = [];
    attachmentIds.forEach((id) => {
      if (report.attachments[id]) attachments.push(report.attachments[id]);
    });

    return [...acc, ...attachments];
  }, [],
);

export const getAllSystemInformations = (
  subsystems: ReportSubsystem[],
  report: Report,
) => subsystems.reduce(
  (acc, {
    reportInformationIds,
  }) => {
    const information: ReportInformation[] = [];

    if (reportInformationIds) {
      reportInformationIds.forEach((id) => {
        if (report.informations[id]) {
          information.push(report.informations[id]);
        }
      });
    }
    return [...acc, ...information];
  }, [],
);

export const getDeficienciesFromIds = (ids: string[], report: Report) => ids.reduce((acc, id) => (
  report.deficiencies[id] ? [...acc, report.deficiencies[id]] : acc
), []);

export const getLimitationsFromIds = (ids: string[], report: Report) => ids.reduce((acc, id) => (
  report.limitations[id] ? [...acc, report.limitations[id]] : acc
), []);

// TODO (spoon-18): unit tests
export const getLocationsFromIds = (
  reportLocationIds: string[],
  report: Report,
) => reportLocationIds
  .filter((locationId) => report.locations[locationId])
  .map((locationId) => report.locations[locationId]);

export const hideSubsystem = (
  subsystem: ReportSubsystem,
) => subsystem?.status === ReportStatus.NotPresent
  && subsystem.reportLimitationIds.length === 0
  && (!subsystem.note || subsystem.note.length === 0)
  && subsystem.reportAttachmentIds?.length === 0
  && subsystem.reportDeficiencyIds.length === 0
  && !subsystem.includeOnReportIfNotPresent;

export const hideSystem = (system: ReportSystem, report: Report) => system.reportSubsystemIds
  .map(
    (subsystemId) => report.subsystems[subsystemId],
  ).every(
    (subsystem) => hideSubsystem(subsystem),
  );

export const showBasedOnReportDeliveryFilter = (
  deficiencies: ReportDeficiency[],
  limitations: ReportLimitation[],
  attachments: ReportAttachment[],
  applianceInformations: ReportApplianceInformation[],
  filter: ReportDeliveryFilter,
) => {
  switch (filter) {
    case ReportDeliveryFilter.FixedDefectsOnly:
      return deficiencies.some(
        ({ reInspectionStatus }) => reInspectionStatus === ReInspectionStatus.Fixed,
      );
    case ReportDeliveryFilter.RemainingDefectsOnly:
      return deficiencies.some(({ reInspectionStatus }) => (
        !reInspectionStatus || reInspectionStatus !== ReInspectionStatus.Fixed
      )) || limitations.length > 0;
    case ReportDeliveryFilter.IssuesOnly:
      return deficiencies.length > 0 || limitations.length > 0;
    case ReportDeliveryFilter.MediaOnly:
      return attachments.length > 0;
    case ReportDeliveryFilter.AppliancesOnly:
      return applianceInformations?.length > 0;
    default:
      return true;
  }
};

const statesToHideLicense = [
  'Arkansas',
  'California',
  'Colorado',
  'Delaware',
  'Georgia',
  'Iowa',
  'Idaho',
  'Kansas',
  'Maine',
  'Montana',
  'Nebraska',
  'Oregon',
  'Pennsylvania',
  'Utah',
  'Virginia',
  'Vermont',
  'District of Columbia',
  'Wyoming',
  'West Virginia',
];

export const showStateLicense = (state: string) => (!statesToHideLicense.includes(state));

export const isFeedbackNoteValid = (note: string) : boolean => {
  if (note) {
    const cleanNote = note.replace(/\W/g, '');
    return cleanNote.length >= 5;
  }
  return false;
};
