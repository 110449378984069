import React from 'react';
import cn from 'classnames';
import styles from './InspectifyCobrandingLogo.module.scss';
import inspectifyLogo from '../../assets/inspectify/Inspectify_Logo_White.svg';
import inspectifyLogoColor from '../../assets/inspectify/Inspectify_Logo_Color.svg';
import verticalLineSvg from '../../assets/icons/VerticalLine.svg';
import poweredByInspectifyLogo from '../../assets/inspectify/PoweredByInspectify.svg';
import { InspectifyCobrandingLogoProps } from './types';

const InspectifyCobrandingLogo: React.FC<InspectifyCobrandingLogoProps> = ({
  display, customPrimaryLogoSrc, customSecondaryLogoSrc, size = 'normal',
}) => {
  let primaryLogoSrc = customPrimaryLogoSrc;
  let secondaryLogoSrc = customSecondaryLogoSrc;

  if (display === 'inspectify') {
    primaryLogoSrc = inspectifyLogo;
  } else if (display === 'inspectify-color') {
    primaryLogoSrc = inspectifyLogoColor;
  } else if (display === 'cobranded-poweredby') {
    secondaryLogoSrc = poweredByInspectifyLogo;
  }

  return (
    <>
      <img
        src={primaryLogoSrc || inspectifyLogo}
        alt="Inspectify"
        className={cn(styles.logo, size === 'large' ? styles.logoLarge : null)}
      />
      {secondaryLogoSrc && (
      <>
        <img src={verticalLineSvg} alt="Inspectify" className={styles.verticalLine} />
        <img src={secondaryLogoSrc} alt="Inspectify" className={styles.logo} />
      </>

      )}
    </>
  );
};

export default InspectifyCobrandingLogo;
