import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import cn from 'classnames';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Preview: React.FC<{ klassNames?: string[], fileUrl: string }> = ({ klassNames, fileUrl }) => {
  if (!fileUrl) return null;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className={cn(klassNames)}>
      {/*
        Use the pdf worker to handle pdf operations
        https://react-pdf-viewer.dev/docs/basic-usage/
        We can host this on our server if we want to...
      */}
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
        <Viewer
          fileUrl={fileUrl}
          plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
    </div>
  );
};

export default Preview;
