export const UPDATE_SPLITIO_ATTRIBUTES = 'UPDATE_SPLITIO_ATTRIBUTES';

export const updateSplitioAttributes = (attributes: any) => async (
  dispatch: any,
) => {
  dispatch({
    type: UPDATE_SPLITIO_ATTRIBUTES,
    payload: attributes,
  });
};
