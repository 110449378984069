import {
  colorBaseGrayscaleOffWhite, colorBaseWhitePrimary,
  colorBaseBluePrimary, colorPaletteBlue600,
} from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

export const RectangularContainer = styled.button`
  display: flex;
  flex-direction: row;
  border: 0;
  background-color: ${colorBaseGrayscaleOffWhite};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 16px 20px;
  margin: 0px 16px;
`;

export const CircularContainer = styled.button`
  border: 0;
  background-color: ${colorBaseGrayscaleOffWhite};
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  padding: 10px 12px;
  position: absolute;
  right: -12px;
  top: -12px;
  opacity: 0.8;
  z-index: 2;
  &:hover {
    background-color: ${colorBaseGrayscaleOffWhite};
  }
`;

export const RehabContainer = styled.button`
  align-self: center;
  width: 100%;
  color: ${colorBaseWhitePrimary};
  background-color: ${colorBaseBluePrimary};
  margin: 24px 0 0 0;
  border: 2px solid ${colorBaseBluePrimary};
  font-weight: 700;
  border-radius: 2px;
  height: 38px;
  &:hover {
    background-color: ${colorPaletteBlue600};
  }
`;

export const Label = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${colorBaseBluePrimary};
  margin-bottom: 0px;
`;
