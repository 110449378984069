import React, { useEffect, useState } from 'react';
import assetIconActionClose from 'haven-design-system/build/assets/svgs/action/close.svg';
import {
  ProjectHistoryContainer,
  ProjectHistoryHeader,
  ProjectHistoryHeaderText,
  ProjectHistoryItemsContainer,
} from './styles';
import IconButton from '../../../../components/IconButton';
import { usePageContext } from '../../../../utils/pageContext';
import {
  ProjectHistoryCard,
  ProjectHistoryCardsByDate,
  WorkItemsInterface,
} from '../../../../types';
import { createHistoryCards, createHistoryCardsByDate } from '../../../../utils/history';
import CollapsibleHistoryContainer from './CollapsibleHistoryContainer';
import { useReportsPageContext } from '../../../../utils/reportsPageContext';
import { RehabTabs } from '../../types';
import { useTypedSelector } from '../../../../redux/store';
import { selectProjectWorkHistory, selectWorkItems } from '../../../../redux/selectors';

interface Props {
  projectId: string;
  isRehabReadOnly: boolean;
  tab: RehabTabs;
  setTab?: (tab: RehabTabs) => void;
  fetchProjectHistory: (check: boolean) => void;
}

const ProjectHistoryPanel: React.FC<Props> = ({
  projectId,
  isRehabReadOnly,
  tab,
  setTab,
  fetchProjectHistory,
}) => {
  const { dispatch: dispatchPage } = usePageContext();
  const { dispatch: dispatchContext } = useReportsPageContext();

  const workHistory = useTypedSelector((state) => selectProjectWorkHistory(state, projectId));
  const workItems: WorkItemsInterface = useTypedSelector(selectWorkItems);

  const [workHistoryCards, setWorkHistoryCards] = useState([] as ProjectHistoryCard[]);
  const [workHistoryCardsByDate, setWorkHistoryCardsByDate] = useState(
    [] as ProjectHistoryCardsByDate[],
  );

  useEffect(() => (() => {
    dispatchContext({ type: 'set_active_work_item', payload: null });
  }), []);

  useEffect(() => {
    if (projectId) fetchProjectHistory(true);
  }, [projectId]);

  useEffect(() => {
    if (workHistory) setWorkHistoryCards(createHistoryCards(workHistory, workItems));
  }, [workHistory]);

  useEffect(() => {
    if (workHistoryCards) setWorkHistoryCardsByDate(createHistoryCardsByDate(workHistoryCards));
  }, [workHistoryCards]);

  return (
    <>
      {!isRehabReadOnly && (
        <ProjectHistoryContainer>
          <ProjectHistoryHeader>
            <ProjectHistoryHeaderText>History</ProjectHistoryHeaderText>
            <div>
              <IconButton
                onClick={() => {
                  dispatchPage({
                    type: 'set_page_state',
                    payload: { isProjectHistoryPanelOpen: false },
                  });
                }}
              >
                <img src={assetIconActionClose} alt="close-icon" className="mx-3" />
              </IconButton>
            </div>
          </ProjectHistoryHeader>
          <ProjectHistoryItemsContainer>
            {
              workHistoryCardsByDate.map((
                historyCardsByDate: ProjectHistoryCardsByDate,
                index: number,
              ) => (
                <CollapsibleHistoryContainer
                  key={`${historyCardsByDate.date}-${index + 5}`}
                  date={historyCardsByDate.date}
                  workHistoryCards={historyCardsByDate.projectHistoryCards}
                  isOpen={index === 0} // open the first one
                  tab={tab}
                  setTab={setTab}
                  fetchProjectHistory={fetchProjectHistory}
                />
              ))
            }
          </ProjectHistoryItemsContainer>
        </ProjectHistoryContainer>
      )}
    </>
  );
};

export default ProjectHistoryPanel;
