import { InspectionProposal } from 'marketplace-common';
import {
  InspectionProposalInterface,
  InspectionProposals,
} from '../../types';
import {
  ADD_INSPECTION_PROPOSAL,
  SET_INSPECTION_PROPOSALS,
} from '../actions/types';

export const initialProposalState = null as InspectionProposals;

export default function proposals(state: any = initialProposalState, action: any) {
  switch (action.type) {
    case ADD_INSPECTION_PROPOSAL: {
      const proposalsState = { ...state };
      const { orderToken, proposal } = action.payload;
      proposalsState[proposal.inspectionId] = {
        ...proposalsState[proposal.inspectionId],
        [proposal.id]: { ...proposal, orderToken },
      };
      return proposalsState;
    }
    case SET_INSPECTION_PROPOSALS: {
      const proposalsState = { ...state };
      const { orderToken, inspectionId, proposals: pArr } = action.payload;
      proposalsState[inspectionId] = pArr.reduce((
        acc: InspectionProposalInterface,
        proposal: InspectionProposal,
      ) => {
        acc[proposal.id] = { ...proposal, orderToken };
        return acc;
      }, {});
      return proposalsState;
    }
    default:
      return state;
  }
}
