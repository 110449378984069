import React from 'react';
import Modal from 'react-modal';
import Close from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import { Permit } from 'marketplace-common';
import { ModalStyles } from '../../../Reports/components/Menu/styles';
import styles from './styles.module.scss';
import { PermitDetailsTable } from '../table/PermitDetailsTable';

interface PermitModalProps {
  permit: Permit;
  isOpen: boolean;
  closeModal: () => void;
}

export const PermitModal : React.FC<PermitModalProps> = ({
  permit,
  isOpen,
  closeModal,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={ModalStyles}
    ariaHideApp={false}
    preventScroll={false}
  >
    <div className={styles.headerLayout}>
      <h4 className={styles.headerFont}>
        Permit #{permit.number || permit.shovelsPermitId} Details
      </h4>
      <div>
        <Close style={{ cursor: 'pointer', borderWidth: 1 }} onClick={closeModal} />
      </div>
    </div>

    <PermitDetailsTable permit={permit} />

  </Modal>
);
