import { useRef, useCallback, useEffect } from 'react';

const useWindowScrollListener = (handleScroll: any, throttle = 500) => {
  const scrollingTimer: any = useRef();

  const listenToScroll = useCallback(
    (e) => {
      window.removeEventListener('scroll', listenToScroll); // unregister the event
      clearTimeout(scrollingTimer.current); // clear previous timeout instance
      scrollingTimer.current = setTimeout(
        () => window.addEventListener('scroll', listenToScroll), // re-register the event after a certain time
        throttle,
      );
      // call the handler logic (this is asynchronous and will not wait for the setTimeout to run!)
      handleScroll(e);
    },
    [throttle, handleScroll],
  );

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', listenToScroll);
    }
    return () => {
      window?.removeEventListener('scroll', listenToScroll);
      clearTimeout(scrollingTimer.current);
    };
  }, [listenToScroll]);
};

export default useWindowScrollListener;
