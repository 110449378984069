import React from 'react';
import arrow from '../../../../../assets/icons/carousel_arrow.svg';
import {
  LeftArrow,
  RightArrow,
  WorkItemImage,
  WorkItemImageContainer,
} from './styles';

interface Props {
  s3ObjectKey: string;
  attachmentUrl: string;
  thumbnailUrl: string;
  imageIndex: number;
  totalImages: number;
  handleChangeImageIndex: (index: number) => void;
}

const MediaWorkItem: React.FC<Props> = ({
  s3ObjectKey,
  attachmentUrl,
  thumbnailUrl,
  imageIndex,
  totalImages,
  handleChangeImageIndex,
}) => (
  <WorkItemImageContainer>
    {attachmentUrl && (
      s3ObjectKey.includes('mp4')
        ? <WorkItemImage src={thumbnailUrl} />
        : <WorkItemImage src={attachmentUrl} />
    )}
    {imageIndex > 0 && (
      <LeftArrow src={arrow} alt="arrow" onClick={() => handleChangeImageIndex(-1)} />
    )}
    {imageIndex < totalImages && (
      <RightArrow src={arrow} alt="arrow" onClick={() => handleChangeImageIndex(1)} />
    )}
  </WorkItemImageContainer>
);

export default MediaWorkItem;
