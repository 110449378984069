import React, { useEffect, useState } from 'react';
import {
  Report, ReportRecord,
} from 'marketplace-common';
import { sortBy } from 'lodash';
import Select from 'react-select';
import {
  colorBaseBlackLight,
  colorBaseBlueLight,
  colorBaseBluePrimary,
  colorBaseTealLight,
} from 'haven-design-system/build/typescript/es6';
import { formatReportDate } from '../../../utils/date';
import styles from './styles.module.scss';

interface Props {
  report: Report,
  reportRecords: ReportRecord[],
  selectedDate: string,
  selectReport: (id: string) => void,
}

const SelectReport: React.FC<Props> = ({
  report, reportRecords, selectedDate, selectReport,
}) => {
  const [reportOptions, setReportOptions] = useState([] as any);

  useEffect(() => {
    if (reportRecords) {
      setReportOptions(
        sortBy(reportRecords, ['publishedAt', 'updatedAt']).map((record) => ({
          value: record.id,
          label: `${formatReportDate(record)} ${record.publishedAt ? '(Published)' : '(Last updated)'}`,
        })).reverse(),
      );
    }
  }, [reportRecords]);

  return (
    <div>
      <div className={styles.lastEdit}>
        {report?.report.publishedAt ? 'Published at' : 'Last edit'}:
        <Select
          isSearchable={false}
          value={{
            value: report?.report.id,
            label: selectedDate,
          }}
          options={reportOptions}
          onChange={(option: { label: string, value: string; }) => {
            selectReport(option.value);
          }}
          styles={{
            dropdownIndicator: (provided) => ({
              ...provided,
              color: colorBaseBluePrimary,
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none',
            }),
            control: (provided) => ({
              ...provided,
              background: 'none',
              border: 'none',
              boxShadow: 'none',
              cursor: 'pointer',
              width: `${(8 * selectedDate.length) + 100}px`,
            }),
            valueContainer: (provided) => ({
              ...provided,
              justifyContent: 'center',
            }),
            menu: (provided) => ({
              ...provided,
              color: 'red',
              width: `${(9 * selectedDate.length) + 100}px`,
            }),
            singleValue: (provided) => ({
              ...provided,
              fontWeight: 700,
              color: colorBaseBluePrimary,
            }),
            option: (provided: any, state) => ({
              ...provided,
              justifyContent: 'center',
              background: state.isSelected ? colorBaseTealLight : 'none',
              ':focus': {
                ...provided[':focus'],
                background: colorBaseBlueLight,
              },
              ':active': {
                ...provided[':active'],
                background: colorBaseBlueLight,
              },
              color: colorBaseBlackLight,
              cursor: 'pointer',
            }),
          }}
        />
      </div>
    </div>
  );
};

export default SelectReport;
