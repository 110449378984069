import { UPDATE_SPLITIO_ATTRIBUTES } from '../actions/splitioAttributes';

export const initialSplitioAttributesState = {
  attributes: {},
};

export default function splitioAttributes(state: any = initialSplitioAttributesState, action: any) {
  switch (action.type) {
    case UPDATE_SPLITIO_ATTRIBUTES:
      return { ...state, attributes: action.payload };
    default:
      return state;
  }
}
