import React from 'react';
import styles from './styles.module.scss';
import { SowInspectionSummaryProps } from './types';

export const SowInspectionSummary = ({
  report,
}: SowInspectionSummaryProps) => {
  const createRowData = () => {
    const data = report?.informations
      ? Object.values(report.informations).filter(
        (info) => info.dataName.includes('sow_completed'),
      ).map((info) => {
        const subsystem = report?.subsystems[info.reportSubsystemId];
        const system = report?.systems[subsystem.reportSystemId];

        return {
          id: info.id,
          name: system?.name,
          title: subsystem?.name,
          description: info?.detail,
          status: info?.value,
        };
      })
      : [];

    return data;
  };

  const sowRowdata = createRowData();

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.dataCell}>System Name</th>
            <th className={styles.dataCell}>Title</th>
            <th className={styles.dataCell}>Description</th>
            <th className={styles.dataCell}>Status</th>
          </tr>
        </thead>
        <tbody>
          {sowRowdata.map((row) => {
            const status = row?.status && JSON.parse(row.status) === 'Complete' ? 'Complete' : 'Incomplete';

            return (
              <tr key={row.id}>
                <td className={styles.dataCell}>{row.name}</td>
                <td className={styles.dataCell}>{row.title}</td>
                <td className={styles.dataCell}>{row.description}</td>
                <td className={styles.dataCell}>{status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
