import React, { useEffect, useState } from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Alert, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import {
  AttachedFile,
  Button, FilesWrapper, StyledIcon,
} from '../FormElements/styles';

interface Props {
  loading: boolean;
  file?: File;
  setFile?: React.Dispatch<React.SetStateAction<File>>
}

const FileUpload: React.FC<Props> = ({
  file, loading, setFile,
}) => {
  const hiddenFileInput = React.useRef(null);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [errorEditingInfo, setErrorEditingInfo] = useState(false);

  const useHover = () => {
    const [hovering, setHovering] = useState(false);
    const onHoverProps = {
      onMouseEnter: () => setHovering(true),
      onMouseLeave: () => setHovering(false),
    };
    return [hovering, onHoverProps];
  };
  const [buttonIsHovering, buttonAHoverProps] = useHover();

  const handleClick = (e:any) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleChange = (f: any) => {
    setUploadedFile(f[0]);
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  useEffect(() => {
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  }, [uploadedFile]);

  return (
    <>
      {(errorEditingInfo) && (
        <Alert variant="danger" onClose={() => setErrorEditingInfo(null)} dismissible>
          There was an issue uploading the file.
          Please try again or contact customer service for support.
          Error: {errorEditingInfo}.
        </Alert>
      )}
      <FilesWrapper>
        {file && (
          <AttachedFile
            onClick={handleRemoveFile}
            key={file.name} // unique keys
            {...buttonAHoverProps}
          >
            {buttonIsHovering ? 'Click to remove file' : `Attached: ${file.name}`}
          </AttachedFile>
        )}
        {uploadedFile && !errorEditingInfo && loading && (
          <AttachedFile>
            <Spinner animation="border" />
          </AttachedFile>
        )}
        <Dropzone onDrop={(acceptedFiles) => handleChange(acceptedFiles)}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} ref={hiddenFileInput} />
              <Button onClick={handleClick} isDragActive={isDragActive}><StyledIcon icon={faUpload} size="xs" />{' Drop a file to add, or choose file'}</Button>
            </div>
          )}
        </Dropzone>
      </FilesWrapper>
    </>
  );
};

export default FileUpload;
