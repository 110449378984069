import {
  IdRef, Order, Property, Works,
} from 'marketplace-common';
import { Context, Dispatch } from 'react';

import { PageErrorType } from '../../types';
import createDataContext from '../../utils/createDataContext';

export interface PermitsContextType {
  orderId: IdRef | null,
  permitsQueryError: PageErrorType | null,
  orderToken: string,
  order: Order | null,
  orderTeamId: IdRef | null,
  property: Property | null,
}

export interface PermitsActionType {
  type: string,
  payload: { key?: string, value: any },
}

const reducer = (
  context: PermitsContextType,
  action: PermitsActionType,
): PermitsContextType => {
  const { type, payload: { key, value } } = action;
  switch (type) {
    case 'SET':
      return { ...context, [key]: value };
    case 'MULTI_SET':
      return { ...context, ...value };
    default:
      return context;
  }
};

const set = (dispatch: Dispatch<PermitsActionType>) => (key: string, value: any) => {
  dispatch({ type: 'SET', payload: { key, value } });
};

const setOrderProperty = (
  dispatch: Dispatch<PermitsActionType>,
) => (orderId: IdRef, works: Works) => {
  if (works?.orders?.[orderId]) {
    const context = {} as PermitsContextType;
    const order = works.orders[orderId];
    const property = works.properties[order.propertyId];

    context.order = order;
    context.property = property;
    context.orderTeamId = order.teamId;

    dispatch({ type: 'MULTI_SET', payload: { value: context } });
  }
};

export const initialPermitsPageContext: PermitsContextType = {
  orderId: null,
  permitsQueryError: null,
  orderToken: '',
  order: null,
  orderTeamId: null,
  property: null,
};

export const permitsPageActions = {
  set,
  setOrderProperty,
};

type ContextType = { state: PermitsContextType } & {
  set: (key: string, value: any) => void,
  setOrderProperty: (orderId: IdRef, works: Works) => void,
};

export type PermitsContextAndActionsType = {
  Context: Context<ContextType>,
  Provider: ({ children }: any) => JSX.Element,
};

export const {
  Context: PermitsContext,
  Provider: PermitsProvider,
}: PermitsContextAndActionsType = createDataContext(
  reducer,
  permitsPageActions,
  initialPermitsPageContext,
);
