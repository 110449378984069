export type OnboardingFormInput = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  hasReliableModeOfTransportation: boolean;
  hasReliablePhone: boolean;
  sendAutomatedSms: boolean;
  serviceAreaRadiusMileage: number;
  inspectorLicenseHeld: boolean;
  contractorLicenseHeld: boolean;
  realEstateLicenseHeld: boolean;
};

export type PasswordSetInput = {
  password: string;
  passwordConfirmation: string;
};

export type SurveyorOnboardingType = {
  id: string;
  appLoggedIn: boolean;
  backgroundCheckRequired: boolean;
  backgroundCheckSubmittedAt: number;
  canSetPassword: boolean;
  completedStep: OnboardingCompletedStep;
  contractor: any;
  createdAt: number;
  misaSigned: boolean;
  onboardingTrackerDismissedAt: number;
  paymentDetailsFilled: boolean;
  status: OnboardingStatus;
  submitBackgroundCheckUrl: string;
  updatedAt: number;
};

export enum OnboardingCompletedStep {
  LandingPageViewed = 'landing_page_viewed',
  FormViewed = 'intake_form_viewed',
  FormSubmitted = 'intake_form_submitted',
  PasswordSet = 'password_set',
}

export enum OnboardingStatus {
  pending = 'pending',
  started = 'started',
  escalated = 'escalated',
  succeeded = 'succeeded',
  failed = 'failed',
  cancelled = 'cancelled',
}
