import React, { useContext, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { TemplateWorkItemsInterface } from '../../../../types';
import RightPanel from './RightPanel';
import LeftPanel from './LeftPanel';
import {
  Container,
  ModalStyle,
} from './styles';
import { RehabToolContext } from '../../context';
import { useTypedSelector } from '../../../../redux/store';
import { selectTemplateWorkItems } from '../../../../redux/selectors';

interface Props {
  systemNames: string[]
  close: () => void
}

const WorkItemModal: React.FC<Props> = ({ systemNames, close }) => {
  const { state } = useContext(RehabToolContext);

  const templateWorkItems = useTypedSelector(selectTemplateWorkItems);

  const templateWorkItemsForSystem = useMemo(() => {
    if (templateWorkItems) {
      const filtered = Object.keys(templateWorkItems).reduce((hash, key) => {
        if (templateWorkItems[key].systemName === state.modalSystemName) {
          return { ...hash, [key]: templateWorkItems[key] };
        }
        return hash;
      }, {} as TemplateWorkItemsInterface);
      return Object.keys(filtered).length ? filtered : templateWorkItems;
    }
    return {};
  }, [templateWorkItems, state.modalSystemName]);

  const escFunction = (event: KeyboardEvent) => {
    if (event.key === 'Escape') close();
  };

  useEffect(() => {
    if (state.isWorkItemModalOpen) {
      document.addEventListener('keyup', escFunction, false);
    }
    return () => {
      document.removeEventListener('keyup', escFunction, false);
    };
  }, [state.isWorkItemModalOpen]);

  return (
    <Modal
      id="work-item-modal"
      isOpen={state.isWorkItemModalOpen}
      onAfterOpen={() => { document.body.style.overflow = 'hidden'; }}
      onAfterClose={() => { document.body.style.overflow = 'scroll'; }}
      style={ModalStyle}
      ariaHideApp={false}
    >
      <Container data-testid="modalContainer">
        <LeftPanel />
        <RightPanel
          systemNames={systemNames}
          templateWorkItemsForSystem={state.modalSystemName
            ? templateWorkItemsForSystem
            : templateWorkItems}
          close={close}
        />
      </Container>
    </Modal>
  );
};

export default WorkItemModal;
