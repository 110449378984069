import { isNil } from 'lodash';
import { ReportDeficiency, ReInspectionStatus, ReportLimitation } from 'marketplace-common';
import { ReportDeliveryFilter } from '../types';

export const getNumIssuesBasedOnFilter = (
  deficiencies: ReportDeficiency[],
  limitations: ReportLimitation[],
  filter: ReportDeliveryFilter,
) => {
  const isFixedOnly = filter === ReportDeliveryFilter.FixedDefectsOnly;

  const filteredDeficiencies = deficiencies.filter(({ reInspectionStatus }) => {
    if (isFixedOnly && reInspectionStatus === ReInspectionStatus.Fixed) {
      return true;
    }
    return (
      !isFixedOnly && (
        isNil(reInspectionStatus) || reInspectionStatus === ReInspectionStatus.StillExists
      )
    );
  });

  return isFixedOnly
    ? filteredDeficiencies.length
    : filteredDeficiencies.length + limitations.length;
};

export const showDeficiencyOnWebReportBasedOnFilter = (
  filter: ReportDeliveryFilter,
  deficiencyReInspectionStatus?: string,
) => {
  switch (filter) {
    case ReportDeliveryFilter.FullReport:
      return true;
    case ReportDeliveryFilter.IssuesOnly:
      return true;
    case ReportDeliveryFilter.FixedDefectsOnly:
      return deficiencyReInspectionStatus === ReInspectionStatus.Fixed;
    case ReportDeliveryFilter.RemainingDefectsOnly:
      return deficiencyReInspectionStatus !== ReInspectionStatus.Fixed;
    default:
      return false;
  }
};
