import React from 'react';
import {
  Background,
} from './styles';
import GeneralErrorImage from '../../assets/images/generalErrorImage.svg';
import AccessErrorImage from '../../assets/images/accessErrorImage.svg';
import { PageErrorType } from '../../types/error';
import ErrorMessage from '../ErrorMessage';
import { PageErrorProps } from './types';

const PageError: React.FC<PageErrorProps> = ({ errorType }) => {
  const getBodyContent = () => {
    switch (errorType) {
      case PageErrorType.Unauthorized:
        return (
          <ErrorMessage
            header="Access denied."
            message="Please contact your account admin for access."
            imageUrl={AccessErrorImage}
          />
        );
      case PageErrorType.NotFound:
        return (
          <ErrorMessage
            header="Rehab Project Not Found."
            message="Please check the URL, or report the issue to support."
            imageUrl={GeneralErrorImage}
          />
        );
      default: // PageErrorType.GeneralError
        return (
          <ErrorMessage
            header="Something went wrong."
            message="Please try again, or report an issue to support."
            imageUrl={GeneralErrorImage}
            updateButton
          />
        );
    }
  };

  return (
    <Background>
      {getBodyContent()}
    </Background>
  );
};

export default PageError;
