import React, { useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { captureException } from '../../utils/error';
import { CREATE_SURVEYOR_ONBOARDING } from '../../graphql/mutations/surveyorOnboarding';

const OnboardingRedirectPage: React.FC = () => {
  // TODO GA9-1450: update how were retrieving utm source and campaign
  const utmSource = useMemo(() => document.referrer, []);

  const [createSurveyorOnboarding] = useMutation(
    CREATE_SURVEYOR_ONBOARDING,
    {
      onCompleted: (data) => {
        if (data) {
          window.location.href = `/onboarding/${data.createSurveyorOnboarding.surveyorOnboarding.id}`;
        }
      },
      onError: () => {
        captureException(new Error('Graphql failed to create surveyor onboarding'));
      },
    },
  );

  useEffect(() => {
    try {
      createSurveyorOnboarding({
        variables: {
          input: {
            utmSource,
          },
        },
      });
    } catch (error) {
      captureException(error);
    }
  }, []);

  return <div />;
};

export default OnboardingRedirectPage;
