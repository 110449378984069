import React from 'react';
import './styles.scss';
import '../styles.scss';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import {
  BasicInformation, JobSpecificQuestions, OnboardingFormPageProps,
} from './types';
import Spinner from '../../../components/Spinner';
import { calculateSliderWidth } from '../../../utils/onboarding';
import BasicInformationInputs from './BasicInformationInputs';

const OnboardingFormPage: React.FC<OnboardingFormPageProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  streetAddress,
  setStreetAddress,
  city,
  setCity,
  state,
  setState,
  zipCode,
  setZipCode,
  hasModeOfTransportation,
  setHasModeOfTransporation,
  hasReliablePhone,
  setHasReliablePhone,
  sendAutomatedSms,
  setSendAutomatedSms,
  serviceAreaRadiusInMiles,
  setServiceAreaRadiusInMiles,
  inspectorLicenseHeld,
  setInspectorLicenseHeld,
  contractorLicenseHeld,
  setContractorLicenseHeld,
  realEstateLicenseHeld,
  setRealEstateLicenseHeld,
  showEmailError,
  setShowEmailError,
  showPhoneError,
  setShowPhoneError,
  showZipCodeError,
  setShowZipCodeError,
  formComplete,
  isMobile,
  addressRef,
  submitIntakeForm,
  loading,
}) => (
  <div className="backdrop">
    <div className="container">
      <p className="text__header">Sign up for Inspectify</p>
      <p className="text__instructions">
        After you fill out the form below, you&apos;ll be directed to create a
        password, login, and complete your Inspectify account.
      </p>
      <div className="contentContainer">
        <div className="userProfile">
          <div className="userProfile__section">
            <div className="userProfile__section__title">
              {BasicInformation.Title}
            </div>
          </div>
          <div className="userProfile__section--large">
            <BasicInformationInputs
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              email={email}
              setEmail={setEmail}
              streetAddress={streetAddress}
              setStreetAddress={setStreetAddress}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              zipCode={zipCode}
              setZipCode={setZipCode}
              showEmailError={showEmailError}
              setShowEmailError={setShowEmailError}
              showPhoneError={showPhoneError}
              setShowPhoneError={setShowPhoneError}
              showZipCodeError={showZipCodeError}
              setShowZipCodeError={setShowZipCodeError}
              isMobile={isMobile}
              addressRef={addressRef}
            />
          </div>
        </div>
        <div className="divider" />
        <div className="userProfile">
          <div className="userProfile__section">
            <div className="userProfile__section__title">
              {JobSpecificQuestions.Title}
            </div>
          </div>
          <div className="userProfile__section--large">
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {JobSpecificQuestions.Required}
                  </div>
                  {JobSpecificQuestions.Transportation}
                </div>
                <label htmlFor="transportation_yes" className="radioButtonContainer">
                  <input
                    type="radio"
                    id="transportation_yes"
                    className="radioButton"
                    name="transportation"
                    checked={hasModeOfTransportation}
                    onChange={(e) => setHasModeOfTransporation(e.target.checked)}
                  />
                  <div className={hasModeOfTransportation ? 'responseTextSelected' : 'responseText'}>
                    {JobSpecificQuestions.Yes}
                  </div>
                </label>
                <label htmlFor="transportation_no" className="radioButtonContainer">
                  <input
                    type="radio"
                    id="transportation_no"
                    className="radioButton"
                    name="transportation"
                    checked={hasModeOfTransportation === false}
                    onChange={(e) => setHasModeOfTransporation(!e.target.checked)}
                  />
                  <div className={hasModeOfTransportation === false ? 'responseTextSelected' : 'responseText'}>
                    {JobSpecificQuestions.No}
                  </div>
                </label>
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {JobSpecificQuestions.Required}
                  </div>
                  {JobSpecificQuestions.Smartphone}
                </div>
                <label htmlFor="phone_yes" className="radioButtonContainer">
                  <input
                    type="radio"
                    id="phone_yes"
                    className="radioButton"
                    name="smartphone"
                    checked={hasReliablePhone}
                    onChange={(e) => setHasReliablePhone(e.target.checked)}
                  />
                  <div className={hasReliablePhone ? 'responseTextSelected' : 'responseText'}>
                    {JobSpecificQuestions.Yes}
                  </div>
                </label>
                <label htmlFor="phone_no" className="radioButtonContainer">
                  <input
                    type="radio"
                    id="phone_no"
                    className="radioButton"
                    name="smartphone"
                    checked={hasReliablePhone === false}
                    onChange={(e) => setHasReliablePhone(!e.target.checked)}
                  />
                  <div className={hasReliablePhone === false ? 'responseTextSelected' : 'responseText'}>
                    {JobSpecificQuestions.No}
                  </div>
                </label>
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {JobSpecificQuestions.Required}
                  </div>
                  {JobSpecificQuestions.Sms}
                </div>
                <label htmlFor="sms_yes" className="radioButtonContainer">
                  <input
                    type="radio"
                    id="sms_yes"
                    className="radioButton"
                    name="sms"
                    checked={sendAutomatedSms}
                    onChange={(e) => setSendAutomatedSms(e.target.checked)}
                  />
                  <div className={sendAutomatedSms ? 'responseTextSelected' : 'responseText'}>
                    {JobSpecificQuestions.SmsYes}
                  </div>
                </label>
                <label htmlFor="sms_no" className="radioButtonContainer">
                  <input
                    type="radio"
                    id="sms_no"
                    className="radioButton"
                    name="sms"
                    checked={sendAutomatedSms === false}
                    onChange={(e) => setSendAutomatedSms(!e.target.checked)}
                  />
                  <div className={sendAutomatedSms === false ? 'responseTextSelected' : 'responseText'}>
                    {JobSpecificQuestions.SmsNo}
                  </div>
                </label>
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {JobSpecificQuestions.Required}
                  </div>
                  <div className="questionContainer">
                    <span className="questionText">{JobSpecificQuestions.Distance}</span>&nbsp;
                    <span className="questionTextSecondary">{JobSpecificQuestions.DistanceSelect}</span>
                  </div>
                </div>
                <div className="sliderContainer">
                  <div
                    id="handle_follower"
                    className="sliderTooltip"
                    style={{
                      position: 'absolute',
                      left: calculateSliderWidth(isMobile, serviceAreaRadiusInMiles),
                      top: 5,
                    }}
                  >
                    {serviceAreaRadiusInMiles.toString().concat(' miles')}
                  </div>
                  <input
                    id="distance_slider"
                    type="range"
                    min={0}
                    max={200}
                    step={10}
                    value={serviceAreaRadiusInMiles}
                    onChange={(e) => setServiceAreaRadiusInMiles(parseInt(e.target.value, 10))}
                  />
                  <div className="sliderLabelContainer">
                    <div>{JobSpecificQuestions.DistanceMin}</div>
                    <div>{JobSpecificQuestions.DistanceMax}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="userProfile__section__inputRow">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="questionContainer">
                  <span className="questionText">{JobSpecificQuestions.License}</span>&nbsp;
                  <span className="questionTextSecondary">{JobSpecificQuestions.LicenseSelect}</span>
                </div>

                <Checkbox
                  isChecked={inspectorLicenseHeld}
                  toggle={() => setInspectorLicenseHeld(!inspectorLicenseHeld)}
                  label={JobSpecificQuestions.Inspector}
                />
                <Checkbox
                  isChecked={contractorLicenseHeld}
                  toggle={() => setContractorLicenseHeld(!contractorLicenseHeld)}
                  label={JobSpecificQuestions.Contractor}
                />
                <Checkbox
                  isChecked={realEstateLicenseHeld}
                  toggle={() => setRealEstateLicenseHeld(!realEstateLicenseHeld)}
                  label={JobSpecificQuestions.Realtor}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="buttonContainer">
          <Button
            onClick={() => submitIntakeForm()}
            disabled={loading || !formComplete()}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {loading ? <Spinner /> : 'Sign up'}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default OnboardingFormPage;
