import { ReportAttachment } from 'marketplace-common';

export const getAttachmentsFromIds = (
  attachmentIds: string[],
  reportAttachments: { [id: string]: ReportAttachment },
) => {
  if (reportAttachments && attachmentIds) {
    return attachmentIds.reduce(
      (acc: ReportAttachment[], attachmentId: string) => {
        const attachment = reportAttachments?.[attachmentId];
        return attachment ? [...acc, attachment] : acc;
      }, [],
    );
  }
  return [];
};
