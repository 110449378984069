import { gql } from '@apollo/client';

export const FULL_CONTRACTOR_FIELDS = gql`
  fragment FullContractorFields on ContractorType {
    id
    contractorType
    businessName
    businessSite
    latitude
    longitude
    certifications
    ashiLicenseNumber
    internachiLicenseNumber
    otherLicenseNumber
    stateLicenseNumber
    inBusinessSince
    state
    country
    logo {
      url
    }
    preferredInspectorStatus
    instantBookingEnabled
    businessContactName
    businessPhone
    businessEmail
    businessAddress
    schedulerName
    schedulerPhone
    schedulerEmail
    sendAutomatedSms
    serviceZipCodes
    serviceAreaRadiusMileage
    hasSupra
    reportWritingSoftware
    msaSigned
    token 
    tipaltiIsPayable
    currentOnboarding {
      id
      appLoggedIn
      backgroundCheckRequired
      backgroundCheckSubmittedAt
      canSetPassword
      completedStep
      createdAt
      misaSigned
      onboardingTrackerDismissedAt
      paymentDetailsFilled
      status
      submitBackgroundCheckUrl
      updatedAt
    }
  }
`;

export const USER_CONTRACTOR = gql`
  ${FULL_CONTRACTOR_FIELDS}
  query {
    currentUser {
      id
      email
      name
      createdAt
      admin
      teamId
      teamAdmin
      contractorId
      grantedPermissions
      contractor {
        ...FullContractorFields
        fileAttachments {
          category
          createdAt
          attachment {
            filename
            url
          }
        }
      }
      team {
        token
        name
        allowRehabToolAccess
        rehabTags
        drawSummaryTableDisplay
        sowSummaryTableDisplay
      }
    }
  }
`;

export const SINGLE_CONTRACTOR = gql`
  ${FULL_CONTRACTOR_FIELDS}
  query SingleContractor($id: ID!) {
    contractor(id: $id) {
      ...FullContractorFields
    }
  }
`;

export const CONTRACTOR_ALLOW_INITIAL_USER_CREATION = gql`
  query ContractorAllowInitialUserCreation($token: String!) {
    contractorAllowInitialUserCreation(token: $token)
  }
`;
