import { useLocation } from 'react-router-dom';
// A custom hook that builds on useLocation to parse
// the query string for you.
export function useUrlQuery() {
  // NOTE stef: in future move all query checks here. e.g.,:
  // const query = new URLSearchParams(useLocation().search);
  // const isPrintMode = query.get('print') === 'true';
  // return { query, isPrintMode };
  return new URLSearchParams(useLocation().search);
}
