import { colorBaseBlackLight, colorPaletteGrayscale50, colorBaseWhitePrimary } from 'haven-design-system/build/typescript/es6';
import styled from 'styled-components';

export const Container = styled.div<{loading: boolean}>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
`;

export const Label = styled.p`
  font-size: 14px;
  margin-bottom: 0px;
`;

export const RemoveRehabItemModalStyle = {
  overlay: {
    backgroundColor: colorPaletteGrayscale50 + 75, // appending opacity
    zIndex: 6,
  },
  content: {
    backgroundColor: colorBaseWhitePrimary,
    border: 'none',
    padding: 0,
    width: '430px',
    height: '217px',
    top: '35%',
    left: '35%',
  },
};

export const RemoveRehabItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const RemoveRehabMainText = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${colorBaseBlackLight};
`;

export const BodyContainer = styled(HeaderContainer)`
  justify-content: center;
  margin-top: 16px;
`;

export const RemoveRehabBodyText = styled.div`
  font-size: 16px;
`;

export const ButtonContainer = styled(BodyContainer)`
  justify-content: end;
  margin-top: 34px;
  gap: 16px;
`;
