import { isValid } from 'date-fns';
import { isNil } from 'lodash';
import {
  ObjectChangesFieldName,
  ProjectHistory,
  ProjectHistoryCard,
  ProjectHistoryCardsByDate,
  ProjectHistoryChangeDirectionType,
  ProjectHistoryEventType,
  ProjectHistoryFieldType,
  ProjectHistoryItem,
  ProjectHistoryItemType,
  ProjectHistoryObjectChanges,
  ProjectHistoryUser,
  WorkItemsInterface,
} from '../types';
import { convertEpochUTCSecondsToDate, formatDate } from './date';
import { formatMoney } from './money';

export const isLumpSum = (historyItem: ProjectHistoryItem, whichValue: string) => {
  switch (whichValue) {
    case 'old':
      if (historyItem.objectChanges.find((obj) => obj.fieldName === ObjectChangesFieldName.unit)) {
        return isNil(historyItem.objectChanges.find(
          (obj) => obj.fieldName === ObjectChangesFieldName.unitCost,
        )?.oldValue)
        && isNil(historyItem.objectChanges.find(
          (obj) => obj.fieldName === ObjectChangesFieldName.unit,
        )?.oldValue);
      }
      break;
    case 'new':
      if (historyItem.objectChanges.find((obj) => obj.fieldName === ObjectChangesFieldName.unit)) {
        return isNil(historyItem.objectChanges.find(
          (obj) => obj.fieldName === ObjectChangesFieldName.unitCost,
        )?.newValue)
        && isNil(historyItem.objectChanges.find(
          (obj) => obj.fieldName === ObjectChangesFieldName.unit,
        )?.newValue);
      }
      break;
    default:
      return false;
  }
  return false;
};

export const filterObjectChanges = (currentHistoryItem: ProjectHistoryItem) => {
  const objChanges: ProjectHistoryObjectChanges[] = [...currentHistoryItem.objectChanges];
  const updatedAtIndex = objChanges.findIndex(
    (objChange) => objChange.fieldName === ObjectChangesFieldName.updatedAt,
  );
  objChanges.splice(updatedAtIndex, 1);

  if (isLumpSum(currentHistoryItem, 'old')) {
    const totalCostIndex = objChanges.findIndex(
      (objChange) => objChange.fieldName === ObjectChangesFieldName.totalCost,
    );
    if (totalCostIndex > -1) objChanges.splice(totalCostIndex, 1);
  }
  if (isLumpSum(currentHistoryItem, 'new')) {
    const quantityIndex = objChanges.findIndex(
      (objChange) => objChange.fieldName === ObjectChangesFieldName.quantity,
    );
    if (quantityIndex > -1) objChanges.splice(quantityIndex, 1);
    const unitCostIndex = objChanges.findIndex(
      (objChange) => objChange.fieldName === ObjectChangesFieldName.unitCost,
    );
    if (unitCostIndex > -1) objChanges.splice(unitCostIndex, 1);
  }
  return objChanges;
};

export const formatCreatedAtForCard = (createdAt: number) => {
  if (!createdAt || !isValid(createdAt)) return null;
  return formatDate(
    convertEpochUTCSecondsToDate(createdAt),
    { format: 'MMM d, yyyy h:mm a' },
  ).toString();
};

export const formatCreatedAtForCollapsible = (createdAt: number) => {
  if (!createdAt || !isValid(createdAt)) return null;
  const today = formatDate(new Date(), { format: 'EEEE, MMMM d' });
  const date = formatDate(
    convertEpochUTCSecondsToDate(createdAt),
    { format: 'EEEE, MMMM d' },
  ).toString();
  if (date === today) return 'Today';
  return date;
};

export const formatValue = (
  fieldName: string,
  value: string,
  currentHistoryItem: ProjectHistoryItem,
  workItems: WorkItemsInterface,
) : string => {
  if (fieldName === ObjectChangesFieldName.description
    || fieldName === ObjectChangesFieldName.system
    || fieldName === ObjectChangesFieldName.title) {
    return value;
  }
  if (isLumpSum(currentHistoryItem, 'new')) {
    return formatMoney(workItems[currentHistoryItem.itemId].totalPrice);
  }
  if (fieldName === ObjectChangesFieldName.quantity) return parseInt(value, 10).toString();
  if (fieldName === ObjectChangesFieldName.unitCost) return formatMoney(Number(value));
  if (fieldName === ObjectChangesFieldName.unit && isNil(value)) return 'lump sum';
  return value;
};

export const getChangeDirection = (
  objectChange: ProjectHistoryObjectChanges,
  currentHistoryItem: ProjectHistoryItem,
) => {
  if (objectChange.fieldName === ObjectChangesFieldName.description
    || objectChange.fieldName === ObjectChangesFieldName.system
    || objectChange.fieldName === ObjectChangesFieldName.title) {
    return ProjectHistoryChangeDirectionType.previously;
  }
  if (isLumpSum(currentHistoryItem, 'new')) {
    return ProjectHistoryChangeDirectionType.lumpSum;
  }
  if (objectChange.fieldName === ObjectChangesFieldName.unit) {
    return ProjectHistoryChangeDirectionType.previously;
  }
  if (objectChange.fieldName === ObjectChangesFieldName.quantity
    || objectChange.fieldName === ObjectChangesFieldName.unitCost
    || objectChange.fieldName === ObjectChangesFieldName.totalCost) {
    if (objectChange.newValue > objectChange.oldValue) {
      return ProjectHistoryChangeDirectionType.increased;
    }
    return ProjectHistoryChangeDirectionType.decreased;
  }
  return '';
};

export const getEventField = (
  eventType: ProjectHistoryEventType,
  itemType: ProjectHistoryItemType,
) => {
  switch (eventType) {
    case ProjectHistoryEventType.create:
      return itemType === ProjectHistoryItemType.Project
        ? ProjectHistoryFieldType.projectCreated
        : ProjectHistoryFieldType.workItemAdded;
    case ProjectHistoryEventType.delete:
      return ProjectHistoryFieldType.workItemRemoved;
    case ProjectHistoryEventType.update:
      return itemType === ProjectHistoryItemType.Project
        ? ProjectHistoryFieldType.projectUpdated
        : ProjectHistoryFieldType.default;
    default:
      return ProjectHistoryFieldType.default;
  }
};

export const getFieldChanged = (
  objectChange: ProjectHistoryObjectChanges,
) => {
  const field: string = objectChange.fieldName;
  switch (field) {
    case ObjectChangesFieldName.unit:
      if (!objectChange.newValue) return ProjectHistoryFieldType.lumpSum;
      return ProjectHistoryFieldType.unit;
    case ObjectChangesFieldName.title:
      return ProjectHistoryFieldType.title;
    case ObjectChangesFieldName.description:
      return ProjectHistoryFieldType.description;
    case ObjectChangesFieldName.quantity:
      return ProjectHistoryFieldType.quantity;
    case ObjectChangesFieldName.system:
      return ProjectHistoryFieldType.system;
    case ObjectChangesFieldName.totalCost:
      return ProjectHistoryFieldType.totalCost;
    case ObjectChangesFieldName.unitCost:
      return ProjectHistoryFieldType.unitCost;
    default:
      return ProjectHistoryFieldType.default;
  }
};

export const getUser = (user: ProjectHistoryUser = { id: '', name: undefined, email: undefined }) => {
  if (user?.name) return user.name;
  if (user?.email) return user.email;
  return '';
};

export const createHistoryCards = (workHistory: ProjectHistory, workItems: WorkItemsInterface) => {
  const historyCards: ProjectHistoryCard[] = [];
  Object.keys(workHistory).map((id) => {
    const currentHistoryItem = workHistory[id];
    if (
      currentHistoryItem.itemType === ProjectHistoryItemType.Project
      || currentHistoryItem.event === ProjectHistoryEventType.create
      || currentHistoryItem.event === ProjectHistoryEventType.delete
    ) {
      const newCard: ProjectHistoryCard = {
        id: currentHistoryItem.id,
        itemType: currentHistoryItem.itemType,
        itemId: currentHistoryItem.itemId,
        eventField: getEventField(
          currentHistoryItem.event,
          currentHistoryItem.itemType,
        ),
        user: getUser(currentHistoryItem?.user),
        createdAt: currentHistoryItem.createdAt,
      };
      if (
        currentHistoryItem.event === ProjectHistoryEventType.create
        || currentHistoryItem.event === ProjectHistoryEventType.delete
      ) {
        newCard.title = workItems[currentHistoryItem.itemId]?.title;
      }
      return historyCards.push(newCard);
    }

    const objChanges: ProjectHistoryObjectChanges[] = filterObjectChanges(currentHistoryItem);

    objChanges.forEach((objectChange) => {
      const newCard: ProjectHistoryCard = {
        id: currentHistoryItem.id,
        itemType: currentHistoryItem.itemType,
        itemId: currentHistoryItem.itemId,
        eventField: `${getFieldChanged(objectChange)}:`,
        user: getUser(currentHistoryItem?.user),
        createdAt: currentHistoryItem.createdAt,
        title: workItems[currentHistoryItem.itemId]?.title,
        newValue: formatValue(
          objectChange.fieldName,
          objectChange.newValue,
          currentHistoryItem,
          workItems,
        ),
        changeDirection: getChangeDirection(objectChange, currentHistoryItem),
        oldValue: isLumpSum(currentHistoryItem, 'new')
          ? ''
          : formatValue(
            objectChange.fieldName,
            objectChange.oldValue,
            currentHistoryItem,
            workItems,
          ),
      };
      historyCards.push(newCard);
    });
    return historyCards;
  });

  return historyCards;
};

export const createHistoryCardsByDate = (historyCards: ProjectHistoryCard[]) => {
  historyCards.sort((a: ProjectHistoryCard, b: ProjectHistoryCard) => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  });
  const historyCardsByDate: ProjectHistoryCardsByDate[] = [];
  for (let i = 0; i < historyCards.length; i += 1) {
    const date = formatCreatedAtForCollapsible(historyCards[i].createdAt);
    const myIndex = historyCardsByDate.findIndex(
      (historyCardByDate) => historyCardByDate.date === date,
    );
    if (myIndex > -1) {
      historyCardsByDate[myIndex].projectHistoryCards.push(historyCards[i]);
    } else {
      historyCardsByDate.push({
        date,
        projectHistoryCards: [historyCards[i]],
      });
    }
  }
  return historyCardsByDate;
};
