import React, {
  useReducer,
  createContext,
  useContext,
} from 'react';

type Action = { type: 'set_page_state', payload: any } | { type: 'reset' } | any;
type Dispatch = (action: Action) => void;
type State = {
  pageState: any
};

const defaultValue: State = {
  pageState: {},
};

type PageContextType = { state: State; dispatch: Dispatch } | undefined;

const PageContext = createContext<PageContextType>(undefined);

function pageReducer(state: State, action: Action) {
  switch (action.type) {
    case 'set_page_state':
      return { ...state, pageState: action.payload };
    case 'reset':
      return defaultValue;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function PageProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(pageReducer, defaultValue);
  const value = { state, dispatch };

  return (
    <PageContext.Provider value={value}>
      {children}
    </PageContext.Provider>
  );
}

function usePageContext() {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error('usePageContext must be used within a PageProvider');
  }
  return context;
}

export { PageProvider, usePageContext };
