import { Auth } from 'aws-amplify';
import {
  AmplifyStatusCode, handleForgotPasswordError, handleSetNewPasswordError, colors,
} from 'marketplace-common';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormInput } from '../../components/FormElements';
import { captureException } from '../../utils/error';
import {
  Background, LoadingContainer, Header, Text, SubmitButton, ErrorMessage, ErrorSection, Link,
  Row, CheckMark,
} from './styles';
import green_circle_checkmark from '../../assets/icons/green_circle_checkmark.svg';

interface Props {
  email: string,
  onResetPasswordSuccess: () => void,
}

const ResetPasswordPage: React.FC<Props> = ({ email, onResetPasswordSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingVerificationCode, setIsSendingVerificationCode] = useState(false);

  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [showErrorForAll, setShowErrorForAll] = useState(false);
  const [showVerificationCodeError, setShowVerificationCodeError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [sentVerificationCode, setSentVerificationCode] = useState(false);

  const resendVerificationCode = async () => {
    try {
      setSentVerificationCode(false);

      setIsSendingVerificationCode(true);
      await Auth.forgotPassword(email);

      setSentVerificationCode(true);
    } catch (err: any) {
      setSentVerificationCode(false);
      handleForgotPasswordError(err, captureException, email, ({ title, subtitle }) => {
        setErrorMessage(`${title} ${subtitle}`);
      });
    } finally {
      setIsSendingVerificationCode(false);
    }
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      await Auth.signIn(email, newPassword);
      onResetPasswordSuccess();
    } catch (err: any) {
      setIsLoading(false);
      if (err.code === AmplifyStatusCode.ExpiredCodeException
        || err.code === AmplifyStatusCode.CodeMismatchException) {
        setShowVerificationCodeError(true);
      } else if (err.code === AmplifyStatusCode.InvalidPasswordException) {
        setShowPasswordError(true);
      } else {
        setShowErrorForAll(true);
      }
      handleSetNewPasswordError(err, captureException, email, verificationCode,
        ({ title, subtitle }) => {
          setErrorMessage(`${title} ${subtitle}`);
        });
    }
  };

  useEffect(() => () => {
    setIsLoading(false);
  }, []);

  return (
    <Background>
      <LoadingContainer isLoading={isLoading} style={{ height: 562 }}>
        <Header>{`Verification code has been sent${email ? ` to\n${email}` : '.'}`}</Header>
        <Text>
          Enter the verification code below with the new password.
        </Text>
        <form>
          <FormInput
            name="Verification code"
            value={verificationCode}
            onChange={(e) => {
              setVerificationCode(e.target.value);
              if (errorMessage) setErrorMessage('');
              if (showVerificationCodeError) setShowVerificationCodeError(false);
              if (showErrorForAll) setShowErrorForAll(false);
            }}
            style={{ marginTop: 24, marginBottom: 12 }}
            indicateRequired={showErrorForAll || showVerificationCodeError}
          />
          <Row>
            <Text style={{ marginRight: 6 }}>{'Didn\'t get it?'}</Text>
            {isSendingVerificationCode ? (
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                style={{ marginBottom: 8 }}
              />
            )
              : (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    resendVerificationCode();
                  }}
                >Resend it
                </Link>
              )}
            {sentVerificationCode
              && (
              <>
                <CheckMark src={green_circle_checkmark} alt="green_circle_checkmark" />
                <Text style={{ color: colors.mainGray }}>Just now</Text>
              </>
              )}
          </Row>
          <FormInput
            name="Enter new password"
            value={newPassword}
            type="password"
            onChange={(e) => {
              setNewPassword(e.target.value);
              if (errorMessage) setErrorMessage('');
              if (showPasswordError) setShowPasswordError(false);
              if (showErrorForAll) setShowErrorForAll(false);
            }}
            indicateRequired={showErrorForAll || showPasswordError}
            style={{ marginTop: 16 }}
          />
          <FormInput
            name="Confirm new password"
            value={confirmNewPassword}
            type="password"
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
              if (errorMessage) setErrorMessage('');
              if (showPasswordError) setShowPasswordError(false);
              if (showErrorForAll) setShowErrorForAll(false);
            }}
            style={{
              marginTop: 24, marginBottom: 12,
            }}
            indicateRequired={showErrorForAll || showPasswordError}
          />
          <ErrorSection style={{ marginBottom: 16 }}>
            {errorMessage && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
          </ErrorSection>
          <SubmitButton
            type="submit"
            disabled={verificationCode === '' || newPassword.length < 6 || confirmNewPassword.length < 6 || isLoading}
            onClick={(e) => {
              e.preventDefault();
              if (newPassword === confirmNewPassword) {
                handleResetPassword();
              } else {
                setShowPasswordError(true);
                setErrorMessage('Passwords do not match!');
              }
            }}
            style={{
              marginTop: 12,
            }}
          >
            {isLoading ? <Spinner animation="border" /> : 'Next'}
          </SubmitButton>
        </form>
      </LoadingContainer>
    </Background>
  );
};

export default ResetPasswordPage;
