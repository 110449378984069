import { colorPaletteGrayscale50, colorBaseRedPrimary } from 'haven-design-system/build/typescript/es6';
import React from 'react';
import './styles.scss';

interface Props {
  name?: string,
  value: number,
  onChange: (e:any) => void,
  type?: string,
  step?: string,
  inputRef?: any,
  placeholder?: string,
  required?: boolean,
  indicateRequired?: boolean,
  style?: React.CSSProperties,
  inputStyle?: React.CSSProperties,
  phone?: boolean,
  readOnly?: boolean,
}

const FormInputNumber: React.FC<Props> = ({
  name, value, onChange, inputRef, placeholder, required,
  indicateRequired, inputStyle, readOnly,
}) => (
  <div className="inputContainer">
    <label htmlFor={name} className="label">
      {name && `${required ? '*' : ''} ${name}`}
    </label>
    <input
      id={name}
      className="input"
      ref={inputRef}
      type="number"
      step="0.01"
      value={value || ''}
      onChange={(e) => (onChange(e))}
      placeholder={placeholder || ''}
      required={required}
      onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
      onWheel={(e) => e.currentTarget.blur()}
      style={{
        borderColor: indicateRequired
          ? colorBaseRedPrimary
          : colorPaletteGrayscale50,
        ...inputStyle,
      }}
      readOnly={readOnly}
    />
  </div>
);

export default FormInputNumber;
