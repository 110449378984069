import React from 'react';
import styles from './styles.module.scss';
import UpdateButton from '../Button';
import { ErrorMessageProps } from './types';

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  imageUrl,
  header,
  message,
  updateButton,
}) => (
  <div className={styles.container}>
    <img className={styles.image} src={imageUrl} alt="ErrorImage" />
    <h6 className={styles.header}>{header}</h6>
    <p className={styles.subheader}>{message}</p>
    {updateButton && (
      <UpdateButton onClick={() => window.location.reload()}>Try again</UpdateButton>
    )}
  </div>
);

export default ErrorMessage;
