import { Auth } from 'aws-amplify';
import { handleForgotPasswordError } from 'marketplace-common';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormInput } from '../../components/FormElements';
import { captureException } from '../../utils/error';
import {
  Background, LoadingContainer, Header, Text, SubmitButton, ErrorMessage, ErrorSection,
} from './styles';

interface Props {
  onSendResetPasswordEmailSuccess: (email: string) => void,
}

const ResetPasswordRequestPage: React.FC<Props> = ({ onSendResetPasswordEmailSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSendResetPasswordEmail = async () => {
    setIsLoading(true);
    try {
      const trimmedEmail = email.trim();
      await Auth.forgotPassword(trimmedEmail);
      onSendResetPasswordEmailSuccess(trimmedEmail);
    } catch (err: any) {
      setIsLoading(false);
      handleForgotPasswordError(err, captureException, email, ({ title, subtitle }) => {
        setErrorMessage(`${title} ${subtitle}`);
      });
    }
  };

  useEffect(() => () => {
    setIsLoading(false);
  }, []);

  return (
    <Background>
      <LoadingContainer isLoading={isLoading}>
        <Header>Reset Password</Header>
        <Text>
          Please provide the email address associated with your account.
        </Text>
        <form>
          <FormInput
            name="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (errorMessage) setErrorMessage('');
            }}
            style={{ marginTop: 24 }}
            indicateRequired={errorMessage !== ''}
          />
          <ErrorSection style={{ marginBottom: 16 }}>
            {errorMessage && (
              <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
          </ErrorSection>
          <SubmitButton
            type="submit"
            disabled={email === '' || isLoading}
            onClick={(e) => {
              e.preventDefault();
              handleSendResetPasswordEmail();
            }}
          >
            {isLoading ? <Spinner animation="border" /> : 'Next'}
          </SubmitButton>
        </form>
      </LoadingContainer>
    </Background>
  );
};

export default ResetPasswordRequestPage;
