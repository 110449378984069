import React from 'react';
import styles from './styles.module.scss';
import { showStateLicense } from '../../../utils/report';
import { Contractor } from '../../../types';

interface Props {
  contractor: Contractor,
  state: string,
}

const ContractorDetails: React.FC<Props> = ({ contractor, state }) => (
  <div className={styles.contractorDetailsContainer}>
    {contractor?.logo?.url
        && (
        <div className={styles.contractorImageContainer}>
          <img src={contractor?.logo?.url} alt="ContractorImage" className={styles.contractorImage} />
        </div>
        )}
    <div className={styles.contractorInfoContainer}>
      <p className={styles.contractorText}>Inspected by</p>
      <p className={styles.contractorBusinessText}>
        {contractor.businessName}
      </p>
      {contractor?.stateLicenseNumber && showStateLicense(state) && (
        <p className={styles.licenseNumberText}>
          State License # {contractor.stateLicenseNumber}
        </p>
      )}
      {contractor?.ashiLicenseNumber && (
        <p className={styles.licenseNumberText}>
          ASHI License # {contractor.ashiLicenseNumber}
        </p>
      )}
      {contractor?.internachiLicenseNumber && (
        <p className={styles.licenseNumberText}>
          InterNACHI License # {contractor.internachiLicenseNumber}
        </p>
      )}
      {contractor?.otherLicenseNumber && (
        <p className={styles.licenseNumberText}>
          Other License # {contractor.otherLicenseNumber}
        </p>
      )}
    </div>
  </div>
);

export default ContractorDetails;
