/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7a48d8a8-512d-4fd3-843a-f34c2f6a4947",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_z7WxVQp3r",
    "aws_user_pools_web_client_id": "1ev50e7e42nh579joecrcvvvp3",
    "oauth": {
        "domain": "auth.inspectify.com.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://app.inspectify.com/amplify_test_page,https://app.inspectify.com/on_sign_in,https://app.inspectify.pro/amplify_test_page,https://app.inspectify.pro/on_sign_in,https://betterinspect-report.inspectify.com/amplify_test_page,https://betterinspect-report.inspectify.com/on_sign_in,https://reports.inspectify.com/amplify_test_page",
        "redirectSignOut": "https://app.inspectify.com/amplify_test_page,https://app.inspectify.com/sign_in,https://app.inspectify.pro/amplify_test_page,https://app.inspectify.pro/sign_in,https://betterinspect-report.inspectify.com/amplify_test_page,https://betterinspect-report.inspectify.com/sign_in,https://reports.inspectify.com/amplify_test_page",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_user_files_s3_bucket": "report-attachments150401-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
