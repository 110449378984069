import { gql } from '@apollo/client';

export const ADD_FILE_ATTACHMENT = gql`
  mutation AddFileAttachment($id: Int!, $input: FileAttachmentInput!) {
    addFileAttachment(contractorId: $id, fileAttachmentInput: $input) {
      success
      code
      message
      errors
      file_attachment {
        filename
        category
        created_at
        url
      }
    }
  }
`;