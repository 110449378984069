import React, { useContext, useMemo } from 'react';
import {
  ReportDeficiency,
  ReportLimitation,
  ReportSubsystem,
} from 'marketplace-common';
import {
  getDeficienciesFromIds,
  getIssueCounts,
  getLimitationsFromIds,
} from '../../../../utils/report';
import { useReportsPageContext } from '../../../../utils/reportsPageContext';
import { IssueCount } from '../../../../types/models';
import MenuBadge, { MenuBadgeType } from './MenuBadge';
import { BadgeContainer, MenuItem, MenuText } from './styles';
import { ReportContext } from '../../context';

interface Props {
  subsystemName: string
  subsystems: ReportSubsystem[]
  isMobileView?: boolean
  onMobileItemPress?: () => void
}

const MenuSubsystem: React.FC<Props> = ({
  subsystemName,
  subsystems,
  isMobileView,
  onMobileItemPress,
}) => {
  const { state } = useContext(ReportContext);
  const { state: menuState, dispatch } = useReportsPageContext();

  const deficiencies: ReportDeficiency[] = useMemo(() => {
    if (state.selectedReport && subsystems) {
      const deficiencyIds = subsystems.reduce(
        (acc, subsystem) => [...acc, ...(subsystem.reportDeficiencyIds || [])], [],
      );
      return getDeficienciesFromIds(deficiencyIds, state.selectedReport);
    }
    return [];
  }, [subsystems, state.selectedReport]);

  const limitations: ReportLimitation[] = useMemo(() => {
    if (state.selectedReport && subsystems) {
      const limitationIds = subsystems.reduce(
        (acc, subsystem) => [...acc, ...(subsystem.reportLimitationIds || [])], [],
      );
      return getLimitationsFromIds(limitationIds, state.selectedReport);
    }
    return [];
  }, [subsystems, state.selectedReport]);

  const counts: IssueCount = useMemo(() => (
    (deficiencies && limitations) ? getIssueCounts(deficiencies, limitations) : null
  ), [deficiencies, limitations]);

  const onMenuItemClick = () => {
    if (onMobileItemPress) { onMobileItemPress(); }
    dispatch({ type: 'set_active_item', payload: subsystemName });
  };

  return (
    <MenuItem
      key={subsystemName}
      href={`#${subsystemName}`}
      isMobileView={isMobileView}
      isActive={menuState.activeMenuItem === subsystemName}
      style={{
        flexDirection: 'column', alignItems: 'flex-start', padding: '6px 12px 6px 24px', justifyContent: 'flex-end',
      }}
      onClick={onMenuItemClick}
    >
      <MenuText>
        {subsystemName}
      </MenuText>
      {counts !== null && (
        <BadgeContainer style={{ margin: '6px 6px 6px 24px' }}>
          <>
            {counts.fixed > 0 && (
              <MenuBadge type={MenuBadgeType.FixedSubsystemDeficiency} number={counts.fixed} />
            )}
            {counts.safety > 0 && (
              <MenuBadge type={MenuBadgeType.SafetyDeficiency} number={counts.safety} />
            )}
            {counts.repair > 0 && (
              <MenuBadge type={MenuBadgeType.RepairDeficiency} number={counts.repair} />
            )}
            {counts.monitor > 0 && (
              <MenuBadge type={MenuBadgeType.MonitorDeficiency} number={counts.monitor} />
            )}
            {counts.limitations > 0 && (
              <MenuBadge type={MenuBadgeType.Limitation} number={counts.limitations} />
            )}
            {counts.flagged > 0 && (
              <MenuBadge type={MenuBadgeType.FlaggedDeficiency} number={counts.flagged} />
            )}
          </>
        </BadgeContainer>
      )}
    </MenuItem>
  );
};

export default MenuSubsystem;
