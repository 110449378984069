export enum SetPasswordPageText {
  Helmet = 'Set Password',
  Title = 'Set up your password',
  Instructions = 'This password will be used for accessing your Inspectify account.',
  EnterPassword = 'Enter password',
  ConfirmPassword = 'Confirm password',
  CharacterMinimum = '(6 characters minimum)',
  PasswordError = 'Passwords do not match',
}

export interface OnboardingSetPasswordPageProps {
  password: string,
  setPassword: (arg0: string) => void,
  showPasswordError: boolean,
  setShowPasswordError: (arg0: boolean) => void,
  passwordConfirmation: string,
  setPasswordConfirmation: (arg0: string) => void,
  checkPasswordsMatch: () => void,
  setPasswordAndSignIn: () => void,
  loading: boolean,
}
