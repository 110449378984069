import cn from 'classnames';
import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {
  BETTER_INSPECT_PHONE_NUMBER_FORMATTED,
  BETTER_INSPECT_PHONE_NUMBER_LINK,
  INSPECTIFY_PHONE_NUMBER_FORMATTED,
  INSPECTIFY_PHONE_NUMBER_LINK,
} from 'marketplace-common';
import styles from './PageLayout.module.scss';
import { useTypedSelector } from '../../redux/store';
import { selectLayout } from '../../redux/selectors';

/** Page footer with links to company information and legal documents */
const Footer: React.FC<{ sidebarFooter?: boolean }> = ({ sidebarFooter = false }) => {
  const { fadedFooter } = useTypedSelector(selectLayout);

  const isBetterInspect = useMemo(() => window.location.hostname.includes('betterinspect'), []);

  const email = (
    <div className="mt-2">
      <span className="font-weight-light">
        {sidebarFooter ? 'Email:' : 'Email us at'}&nbsp;
      </span>

      <a
        href={isBetterInspect
          ? 'mailto:betterinspect@inspectify.com'
          : 'mailto:hello@inspectify.com'}
        target="_blank"
        rel="noreferrer"
        className={sidebarFooter ? '' : 'text-white'}
      >
        {isBetterInspect ? 'betterinspect@inspectify.com' : 'hello@inspectify.com'}
      </a>
    </div>
  );

  const phone = (
    <div>
      <span className="font-weight-light">
        {sidebarFooter ? 'Text our team at' : 'Or call us at'}&nbsp;
      </span>
      <a
        href={isBetterInspect
          ? BETTER_INSPECT_PHONE_NUMBER_LINK
          : INSPECTIFY_PHONE_NUMBER_LINK}
        target="_blank"
        rel="noreferrer"
        className={sidebarFooter ? '' : 'text-white'}
      >
        {isBetterInspect
          ? BETTER_INSPECT_PHONE_NUMBER_FORMATTED
          : INSPECTIFY_PHONE_NUMBER_FORMATTED}
      </a>
    </div>
  );

  const copyRight = (
    <div className={cn('mt-2', styles.copyrights)}>
      &copy;{new Date().getFullYear()} Inspectify Inc.
    </div>
  );

  if (sidebarFooter) {
    return (
      <div className={cn(styles.sidebarFooter)}>
        <div>Questions? Reach out to us!</div>
        {email}
        {phone}
        {copyRight}
      </div>
    );
  }

  return (
    <Container fluid className={fadedFooter ? styles.footerFaded : styles.footer}>
      <Container className="py-4">
        <Row className="align-items-end">
          <Col lg={6}>
            <h4>Questions? Reach out to us!</h4>
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col lg={6}>
            {email}
            {phone}
          </Col>
          <Col xs={6} className="text-right">{copyRight}</Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
