import React from 'react';
import SearchIcon from '../../../../../../assets/icons/search.svg';
import CheckmarkIcon from '../../../../../../assets/icons/large_checkmark.svg';
import Button from '../../../../../../components/Button';
import Spinner from '../../../../../../components/Spinner';
import {
  Container,
  LibContainer,
  WorkItemContainer,
  WorkItemTitle,
  WorkItemDescription,
  WorkItemPrice,
  SearchLibraryWorkItems,
  Icon,
  SelectedWorkItemContainer,
  TopRow,
  Checkmark,
  WorkItemText,
  DescriptionInput,
  InfoRow,
  QuantityInput,
  WorkItemFinalPrice,
  WorkItemDescriptionText,
  FinalRow,
  LumpSumContainer,
  LumpSumText,
  BottomRow,
} from './styles';
import { WorkItemLibraryComputedState, WorkItemLibraryState } from './state';
import ReactSelect from '../../../../../../components/ReactSelect';
import ToggleSwitch from '../../../../../../components/ToggleSwitch';
import { FormInputCurrency } from '../../../../../../components/FormElements';
import './styles.scss';

interface Props {
  loading: boolean
  state: WorkItemLibraryState
  computedState: WorkItemLibraryComputedState
  onSearch: (event: any) => void
  onDescriptionChange: (event: any) => void
  onQuantityChange: (event: any) => void
  onTotalChange: (value: number) => void
  onToggleSelectedItemLumpSum: (toggle: boolean) => void
  onSelectWorkItem: (id: string) => void
  onSelectVariety: (id: string) => void
  onAddNewWorkItemToProject: () => void
  onSelectedItemUnitChange: (event: any) => void
  onSelectedItemPricePerUnitChange: (value: number) => void
  onSelectedItemTagsChange: (values: { label: string, value: string }[]) => void
  onClose: () => void
  isAddToProjectDisabled: boolean
}

const WorkItemLibrary: React.FC<Props> = ({
  loading,
  state,
  computedState,
  onSearch,
  onDescriptionChange,
  onQuantityChange,
  onTotalChange,
  onToggleSelectedItemLumpSum,
  onSelectWorkItem,
  onSelectVariety,
  onAddNewWorkItemToProject,
  onSelectedItemUnitChange,
  onSelectedItemPricePerUnitChange,
  onSelectedItemTagsChange,
  onClose,
  isAddToProjectDisabled,
}) => (
  <LibContainer>
    <Container>
      <Icon src={SearchIcon} />
      <SearchLibraryWorkItems
        id="select-from-library-search-bar"
        placeholder="Search"
        onChange={onSearch}
      />
      {Object.keys(computedState.filteredTemplateWorkItemsForSystem).map(
        (id) => {
          const templateWorkItem = computedState.filteredTemplateWorkItemsForSystem[id];
          const firstTemplateWorkItemPricingId = templateWorkItem.templateWorkItemPricings.length
            ? templateWorkItem.templateWorkItemPricings[0] : null;
          const templateWorkItemPricing = (
            firstTemplateWorkItemPricingId
            && computedState.templateWorkItemPricings[firstTemplateWorkItemPricingId]
          ) ? computedState.templateWorkItemPricings[firstTemplateWorkItemPricingId] : null;

          return (state.selectedItemId === id ? (
            <React.Fragment key={id}>
              <Checkmark src={CheckmarkIcon} alt="checkmark" />
              <SelectedWorkItemContainer key={`${id}e`}>
                <TopRow>
                  <WorkItemTitle>{templateWorkItem.title}</WorkItemTitle>
                </TopRow>
                <InfoRow>
                  <WorkItemDescriptionText>Description</WorkItemDescriptionText>
                  <DescriptionInput
                    id="select-from-library-description"
                    value={state.selectedItemDescription || ''}
                    placeholder="Description"
                    onChange={onDescriptionChange}
                  />
                </InfoRow>
                <InfoRow>
                  <WorkItemText>Tags</WorkItemText>
                  <ReactSelect
                    value={state.selectedItemTags.map((tag) => ({ label: tag, value: tag }))}
                    onChange={onSelectedItemTagsChange}
                    options={(computedState.tags || []).map((tag) => ({ label: tag, value: tag }))}
                    placeholder="Select a tag"
                    isMulti
                    isSearchable
                    isCreateable
                  />
                </InfoRow>
                <>
                  {state.selectedItemPricings.length > 0 && (
                    <InfoRow>
                      <WorkItemText>Variety</WorkItemText>
                      <ReactSelect
                        value={{
                          label: state.selectedItemPricing.pricingExternalId,
                          value: state.selectedItemPricing.id,
                        }}
                        onChange={(option) => onSelectVariety(option.value)}
                        options={state.selectedItemPricings.map(
                          (pricing) => ({ label: pricing.pricingExternalId, value: pricing.id }),
                        )}
                        placeholder="Select a variety"
                        isSearchable
                      />
                    </InfoRow>
                  )}

                  {!state.selectedItemLumpSum && (
                    <>
                      <InfoRow>
                        <WorkItemText>Unit</WorkItemText>
                        <QuantityInput
                          id="select-from-library-unit"
                          value={state.selectedItemUnit}
                          placeholder="Unit"
                          onChange={onSelectedItemUnitChange}
                          type="text"
                        />
                      </InfoRow>

                      <InfoRow>
                        <WorkItemText>Unit cost</WorkItemText>
                        <FormInputCurrency
                          name="Unit cost"
                          includeLabel={false}
                          className="libraryInput"
                          value={state.selectedItemPricePerUnit}
                          onValueChange={onSelectedItemPricePerUnitChange}
                        />
                      </InfoRow>

                      <InfoRow>
                        <WorkItemText>Quantity</WorkItemText>
                        <QuantityInput
                          id="select-from-library-quantity"
                          value={state.selectedItemQuantity}
                          placeholder="Quantity"
                          onChange={onQuantityChange}
                          type="text"
                          inputMode="numeric"
                        />
                      </InfoRow>
                    </>
                  )}

                  {state.selectedItemLumpSum && (
                    <InfoRow>
                      <WorkItemText>Total</WorkItemText>
                      <FormInputCurrency
                        name="Total cost"
                        includeLabel={false}
                        className="libraryInput"
                        value={state.selectedItemTotal}
                        onValueChange={onTotalChange}
                      />
                    </InfoRow>
                  )}

                  <InfoRow>
                    <WorkItemText>Total</WorkItemText>
                    <FinalRow>
                      <WorkItemFinalPrice>
                        ${state.selectedItemLumpSum
                        ? state.selectedItemTotal
                        : state.selectedItemPricePerUnit * state.selectedItemQuantity}
                      </WorkItemFinalPrice>
                      <LumpSumContainer>
                        <ToggleSwitch
                          tag="select-from-library"
                          isOn={state.selectedItemLumpSum}
                          toggle={() => onToggleSelectedItemLumpSum(!state.selectedItemLumpSum)}
                        />
                        <LumpSumText>Use lump sum cost</LumpSumText>
                      </LumpSumContainer>
                    </FinalRow>
                  </InfoRow>
                </>
              </SelectedWorkItemContainer>
            </React.Fragment>
          ) : (
            <WorkItemContainer
              key={id}
              id="select-from-library-work-item-container"
              onClick={() => onSelectWorkItem(id)}
            >
              <WorkItemTitle>{templateWorkItem.title}</WorkItemTitle>
              <WorkItemDescription>{templateWorkItemPricing?.details}</WorkItemDescription>
              <WorkItemPrice>
                {templateWorkItemPricing?.pricePerUnit != null && `$${templateWorkItemPricing.pricePerUnit}`}
                &nbsp;
                <WorkItemDescription>
                  {templateWorkItemPricing?.unit && `per ${templateWorkItemPricing.unit}`}
                </WorkItemDescription>
              </WorkItemPrice>
            </WorkItemContainer>
          ));
        },
      )}
    </Container>
    <BottomRow>
      <Button secondary onClick={onClose}>Cancel</Button>
      <Button
        disabled={isAddToProjectDisabled}
        onClick={onAddNewWorkItemToProject}
      >
        {loading ? <Spinner style={{ padding: 0 }} /> : 'Add to project'}
      </Button>
    </BottomRow>
  </LibContainer>
);

export default WorkItemLibrary;
