import {
  getCurrentUnixTimestamp,
  JobStatus,
  Works,
} from 'marketplace-common';
import {
  APPEND_WORKS,
  CLEAR_COMPLETED_JOBS,
  CLEAR_UPCOMING_JOBS,
  REPLACE_WORKS,
  SET_WARRANTY,
  UPDATE_JOB,
} from '../actions/types';

export const initialWorksState: Works = {
  jobs: {},
  bundleOffers: {},
  reports: {},
  orders: {},
  properties: {},
  permits: {},
  inspections: {},
  inspectionProposals: {},
  inspectionWarranties: {},
  orderStakeholders: {},
  orderUnits: {},
  lastSync: null,
  lastFilters: [],
};

function reducer(state = initialWorksState, action: any) {
  switch (action.type) {
    case APPEND_WORKS: {
      const {
        jobs,
        reports,
        orders,
        properties,
        inspections,
        inspectionProposals,
        orderStakeholders,
        orderUnits,
      } = state;

      return {
        ...state,
        jobs: { ...jobs, ...action.payload.jobs },
        reports: { ...reports, ...action.payload.reports },
        orders: { ...orders, ...action.payload.orders },
        properties: { ...properties, ...action.payload.properties },
        inspections: { ...inspections, ...action.payload.inspections },
        inspectionProposals: { ...inspectionProposals, ...action.payload.inspectionProposals },
        orderStakeholders: { ...orderStakeholders, ...action.payload.orderStakeholders },
        orderUnits: { ...orderUnits, ...action.payload.orderUnits },
        lastSync: getCurrentUnixTimestamp(),
      };
    }
    case UPDATE_JOB: {
      const job = (state.jobs as any)[action.payload?.job?.id || ''];
      if (job) {
        return {
          ...state,
          jobs: {
            ...state.jobs,
            [job.id]: { ...job, ...action.payload.values },
          },
        };
      }
      return state;
    }
    case CLEAR_UPCOMING_JOBS: {
      const jobs = Object.values(state.jobs).reduce((acc, job) => {
        if (job.status !== JobStatus.Completed) return acc;
        return { ...acc, [job.id]: job };
      }, {});
      return { ...state, jobs };
    }
    case CLEAR_COMPLETED_JOBS: {
      const jobs = Object.values(state.jobs).reduce((acc, job) => {
        if (job.status !== JobStatus.Completed) return { ...acc, [job.id]: job };
        return acc;
      }, {});
      return { ...state, jobs };
    }
    case SET_WARRANTY: {
      return {
        ...state,
        inspectionWarranties: {
          ...state.inspectionWarranties,
          [action.payload.id]: action.payload,
        },
      };
    }
    case REPLACE_WORKS:
      return action.payload;
    default:
      return state;
  }
}

export default reducer;
