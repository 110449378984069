import {
  colorBaseBlueLight,
  colorBaseBluePrimary,
  colorBaseBlueDark,
  colorBaseWhitePrimary,
  colorPaletteGrayscale50,
  colorBaseGreyDark,
  colorBaseBlackLight,
  colorBaseSandLight,
  colorPaletteSand400,
  colorBaseSandPrimary,
} from 'haven-design-system/build/typescript/es6';
import styled, { css } from 'styled-components';

export const MenuText = styled.p<{ isRehab?: boolean }>`
  font-size: 16px;
  line-height: 22px;
  margin: ${(props) => (props.isRehab ? '0px 12px 0px 8px' : '0px 12px 0px 24px')};
  &:hover {
    text-decoration: none;
  }
`;

export const MenuPrice = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  color: ${colorBaseGreyDark};
`;

export const MenuItemsContainer = styled.div`
  width: 100%;
  margin-bottom: 64px;
`;

export const publishProjectButtonStyles = {
  width: '100%',
  'margin-top': '16px',
};

export const MenuLastPublishedAt = styled.div`
  text-align: right;
  font-size: 14px;
  line-height: 18px;
  color: ${colorBaseGreyDark};
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 8px;
`;

interface MenuItemProps {
  isActive: boolean;
  isDisabled?: boolean;
  isMobileView?: boolean;
  isRehab?: boolean;
}

export const MenuItem = styled.a<MenuItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 54px;
  width: 100%;
  cursor: pointer;
  color: ${colorBaseBlueDark};
  background-color: ${(props) => (props.isMobileView ? colorBaseWhitePrimary : '')};
  font-weight: ${(props) => (props.isActive ? 700 : 400)};
  background-color: ${(props) => (props.isActive ? colorBaseSandLight : '')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'default')};

  ${(props) => !props.isRehab
    && css` &:hover {
        font-weight: 700;
        background-color: ${colorPaletteSand400};
        text-decoration: none;
      }
  `}
`;

export const RehabContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const TotalText = styled.div<{ isBold?: boolean }>`
  font-size: 24px;
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
`;

export const burgerMenuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '30px',
    height: '22px',
    right: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: colorBaseBlackLight,
  },
  bmCrossButton: {
    height: '38px',
    width: '38px',
    right: '14px',
    top: '7px',
  },
  bmCross: {
    background: colorBaseBlueLight,
    height: '32px',
    right: '4px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '50%',
    minWidth: '350px',
  },
  bmMenu: {
    background: colorBaseWhitePrimary,
    paddingTop: '42px',
  },
  bmMorphShape: {
    fill: colorBaseBlackLight,
  },
  bmItemList: {
    color: colorBaseSandPrimary,
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    width: '100%',
  },
};

export const ModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: 999,
  },
  content: {
    border: 'none',
    boxShadow: '0px 11px 15px -7px rgba(43, 43, 43, 0.2), 0px 9px 46px 8px rgba(43, 43, 43, 0.12), 0px 24px 38px 3px rgba(43, 43, 43, 0.14)',
    position: 'relative',
    width: 'fit-content',
    maxWidth: '880px',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
    maxHeight: '750px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
};

export const RehabSignupModalStyle = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 5,
  },
  content: {
    border: 'none',
    boxShadow: '0px 11px 15px -7px rgba(43, 43, 43, 0.2), 0px 9px 46px 8px rgba(43, 43, 43, 0.12), 0px 24px 38px 3px rgba(43, 43, 43, 0.14)',
    position: 'relative',
    width: '90vw',
    minWidth: '700px',
    minHeight: '560px',
    height: '75vh',
    background: colorBaseWhitePrimary,
    padding: '80px',
    top: '15%',
    left: '5%',
    zIndex: 5,
  },
};

export const RehabSignupModal = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const RehabSignupActions = styled.div`
  height: 100%;
  width: 45%;
  min-width: 300px;
  background-color: ${colorBaseWhitePrimary};
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const RehabSignupPic = styled.img`
  object-fit: contain;
  width: 55%;
  height: 100%;  
`;

export const Logo = styled.img`
  width: 196px;
  height: 48px;
  margin-bottom: 32px;
`;

export const MainText = styled.div`
  font-weight: 700;
  font-size: 32px;
  color: ${colorBaseBlackLight};
`;

export const SubText = styled.div`
  font-size: 16px;
  color: ${colorBaseBlackLight};
  margin-bottom: 10px;
`;

export const RehabForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const LoginText = styled(SubText)`
  margin-bottom: 0;
`;

export const LoginLink = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${colorBaseBluePrimary};
  margin-left: 16px;
  &:hover {
    color: ${colorBaseBlueLight};
    cursor: pointer;
  }
`;

export const LeaveModalText = styled.div`
  font-size: 16px;
  color: ${colorBaseGreyDark};
  &:hover {
    color: ${colorPaletteGrayscale50};
    cursor: pointer;
  }
`;
