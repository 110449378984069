import {
  Stakeholder,
  StakeholderInterface,
  Stakeholders,
} from '../../types';
import {
  ADD_STAKEHOLDER,
  SET_STAKEHOLDERS,
} from '../actions/types';

export const initialStakeholderState = null as Stakeholders;

export default function stakeholders(state: any = initialStakeholderState, action: any) {
  switch (action.type) {
    case ADD_STAKEHOLDER: {
      const stakeholderState = { ...state };
      const { orderToken, stakeholder } = action.payload;
      stakeholderState[orderToken] = {
        ...stakeholderState[orderToken],
        [stakeholder.id]: { ...stakeholder, orderToken },
      };
      return stakeholderState;
    }
    case SET_STAKEHOLDERS: {
      const stakeholderState = { ...state };
      const { orderToken, stakeholders: sArr } = action.payload;
      stakeholderState[orderToken] = sArr.reduce((
        acc: StakeholderInterface,
        stakeholder: Stakeholder,
      ) => {
        acc[stakeholder.id] = { ...stakeholder, orderToken };
        return acc;
      }, {});
      return stakeholderState;
    }
    default:
      return state;
  }
}
