import React, { useContext, useState } from 'react';
import assetIconActionClose from 'haven-design-system/build/assets/svgs/action/close.svg';
import {
  TemplateWorkItemsInterface,
} from '../../../../../types';
import CreateNewItemForm from './CreateNewItemForm';
import {
  Container,
  Title,
  Row,
  TabButton,
} from './styles';
import WorkItemLibrary from './WorkItemLibrary';
import IconButton from '../../../../../components/IconButton';
import { RehabToolContext } from '../../../context';

enum TabOptions{
  SelectFromLibrary,
  CreateNewItem,
}

interface Props {
  systemNames: string[],
  templateWorkItemsForSystem: TemplateWorkItemsInterface;
  close: () => void,
}

const RightPanel: React.FC<Props> = ({
  systemNames,
  templateWorkItemsForSystem,
  close,
}) => {
  const { state } = useContext(RehabToolContext);

  const [selectedTab, setSelectedTab] = useState(
    Object.keys(templateWorkItemsForSystem).length
      ? TabOptions.SelectFromLibrary
      : TabOptions.CreateNewItem,
  );

  return (
    <Container>
      <Row>
        <Title>{state.modalWorkItem ? 'Edit work item' : 'Add work item'}</Title>
        <IconButton onClick={close}>
          <img src={assetIconActionClose} alt="close-icon" />
        </IconButton>
      </Row>
      {!state.modalWorkItem && (
        <Row>
          <TabButton
            isSelected={selectedTab === TabOptions.SelectFromLibrary}
            onClick={() => {
              setSelectedTab(TabOptions.SelectFromLibrary);
            }}
          >
            Select from library
          </TabButton>
          <TabButton
            isSelected={selectedTab === TabOptions.CreateNewItem}
            onClick={() => {
              setSelectedTab(TabOptions.CreateNewItem);
            }}
          >
            Create new item
          </TabButton>
        </Row>
      )}
      {state.modalWorkItem || selectedTab === TabOptions.CreateNewItem
        ? (
          <CreateNewItemForm
            systemNames={systemNames}
            close={close}
          />
        ) : (
          <WorkItemLibrary
            close={close}
            templateWorkItemsForSystem={templateWorkItemsForSystem}
          />
        )}
    </Container>
  );
};

export default RightPanel;
