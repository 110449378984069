import React from 'react';
import InfoIcon from 'haven-design-system/build/assets/svgs/display/info_outlined.svg?component';
import { colorBaseBluePrimary } from 'haven-design-system/build/typescript/es6';
import { capitalize } from 'lodash';
import { Permit } from 'marketplace-common';
import { getFormattedMonthDayYear } from '../../../../utils/date';
import { formatShortDate } from '../../utils';
import styles from './styles.module.scss';

interface RowProps {
  permit: Permit;
  isModalOpen: (value: boolean) => void;
  setSelectedPermit: (permit: Permit) => void;
}

const PermitRow: React.FC<RowProps> = ({ permit, isModalOpen, setSelectedPermit }) => (
  <tr
    key={permit.id}
    onClick={() => {
      isModalOpen(true);
      setSelectedPermit(permit);
    }}
    style={{ cursor: 'pointer' }}
  >
    <td className={styles.cellText}>{permit.number}</td>
    <td className={styles.cellText}>{permit.permitType}</td>
    <td>
      <div className={styles.issuedCellContainer}>
        <div className={styles.cellText}>
          {permit.issueDate ? `Issued ${formatShortDate(new Date(permit.issueDate))}` : 'N/A'}
        </div>
        {(permit.fileDate !== permit.issueDate) && (
          <div className={styles.cellSubtext}>
            Filed {
            permit.fileDate
              ? formatShortDate(new Date(permit.fileDate))
              : getFormattedMonthDayYear(new Date(permit.fileDate))
          }
          </div>
        )}
      </div>
    </td>
    <td>
      <div className={styles.statusCellContainer}>
        <div>
          <div className={styles.cellText}>
            {capitalize(permit.status)}
          </div>
          {permit.finalDate && (
            <div className={styles.cellSubtext}>
              Inspected {formatShortDate(new Date(permit.finalDate))}
            </div>
          )}
        </div>
        <InfoIcon color={colorBaseBluePrimary} />
      </div>
    </td>
  </tr>
);

export default PermitRow;
