import { Contractor, ContractorFileAttachment } from '../../../types';
import { StateCodeEnum, StateEnum } from '../../../types/address';

export interface ContractorState {
  contractor: Contractor,
  hasStateChanged: boolean,
}

export enum BusinessDetailsSections {
  BasicInformation = 'Basic information',
  Certifications = 'Certifications',
  Documents = 'Documents',
  AccessAndSoftware = 'Access and software',
  ServiceableArea = 'Serviceable area',
}

export enum BusinessDetailsText {
  SupraAccess = 'Do you have Supra access?',
  SentriLockAccess = 'Do you have SentriLock access?',
}

export interface BusinessDetailsLoaderProps {
  contractor: Contractor,
  certificationsRef: React.RefObject<HTMLDivElement>,
  basicInformationRef: React.RefObject<HTMLDivElement>,
  serviceableAreaRef: React.RefObject<HTMLDivElement>,
  accessAndSoftwareRef: React.RefObject<HTMLDivElement>,
  documentsRef: React.RefObject<HTMLDivElement>,
}
export interface BusinessDetailsProps {
  loading: boolean,
  certificationsRef: React.RefObject<HTMLDivElement>,
  basicInformationRef: React.RefObject<HTMLDivElement>,
  serviceableAreaRef: React.RefObject<HTMLDivElement>,
  accessAndSoftwareRef: React.RefObject<HTMLDivElement>,
  documentsRef: React.RefObject<HTMLDivElement>,
  certificationFiles?: ContractorFileAttachment[],
  misaFiles: ContractorFileAttachment[],
  addFile?: (uploadedFile: File, category: string) => void;
  loadingNewFile?: boolean;
  firstName: string,
  setFirstName: (value: string) => void,
  lastName: string,
  setLastName: (value: string) => void,
  email: string,
  setEmail: (value: string) => void,
  phoneNumber: string
  setPhoneNumber: (value: string) => void,
  streetAddress: string,
  setStreetAddress: (value: string) => void,
  city: string,
  setCity: (value: string) => void,
  state: StateEnum | StateCodeEnum,
  setState: (value: StateEnum | StateCodeEnum) => void,
  zipCode: string,
  setZipCode: (value: string) => void,
  showEmailError: boolean,
  setShowEmailError: (e: boolean) => void,
  showPhoneError: boolean,
  setShowPhoneError: (e: boolean) => void,
  showZipCodeError: boolean,
  setShowZipCodeError: (e: boolean) => void,
  addressRef: React.RefObject<HTMLInputElement>,
  serviceAreaRadiusInMiles?: number,
  setServiceAreaRadiusInMiles: (value: number) => void,
  hasSupra: boolean,
  setHasSupra: (value: boolean) => void,
  // TODO GA9-1507: will be added as part of Inspector Onboarding
  // hasSentriLock: boolean,
  // setHasSentriLock: (value: boolean) => void,
  isMobile: boolean,
  inspectorLicenseHeld: boolean,
  setInspectorLicenseHeld: (e: boolean) => void,
  contractorLicenseHeld: boolean,
  setContractorLicenseHeld: (e: boolean) => void,
  realEstateLicenseHeld: boolean,
  setRealEstateLicenseHeld: (e: boolean) => void,
  msaSigned: boolean,
  updateBusinessDetails: () => void,
  businessDetailsComplete: () => boolean,
}
