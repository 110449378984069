import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Property } from 'marketplace-common';
import { showStateLicense, getInspectionDateLabel } from '../../../../utils/report';
import CoverPage from '../../../../components/PDF/CoverPage';
import {
  DetailContainer,
  DetailLabel,
  DetailRow,
  DetailValue,
} from '../../../../components/PDF/styles';
import { Contractor } from '../../../../types';

const Cover: React.FC<{
  property: Property,
  contractor: Contractor,
  scheduledDate: number,
  completedDate: number,
  clientName: string,
  inspectionType: string,
  teamExternalId: string | null | undefined,
  communityName: string | null | undefined,
  floorPlanName: string | null | undefined,
}> = ({
  property,
  contractor,
  scheduledDate,
  completedDate,
  clientName,
  inspectionType,
  teamExternalId,
  communityName,
  floorPlanName,
}) => (
  <CoverPage property={property}>
    <DetailContainer>
      <DetailRow>
        <DetailLabel>Client</DetailLabel>
        <DetailValue>{clientName || '--'}</DetailValue>
      </DetailRow>
      {!isEmpty(teamExternalId) && (
        <DetailRow>
          <DetailLabel>Reference id</DetailLabel>
          <DetailValue>{teamExternalId}</DetailValue>
        </DetailRow>
      )}
      {!isEmpty(communityName) && (
        <DetailRow>
          <DetailLabel>Community</DetailLabel>
          <DetailValue>{communityName}</DetailValue>
        </DetailRow>
      )}
      {!isEmpty(floorPlanName) && (
        <DetailRow>
          <DetailLabel>Floor Plan</DetailLabel>
          <DetailValue>{floorPlanName}</DetailValue>
        </DetailRow>
      )}
      <DetailRow>
        <DetailLabel>Type</DetailLabel>
        <DetailValue>{inspectionType}</DetailValue>
      </DetailRow>
      <DetailRow>
        <DetailLabel>Inspected by</DetailLabel>
        <DetailValue>{contractor?.businessName || '--'}</DetailValue>
      </DetailRow>
      {contractor?.stateLicenseNumber && showStateLicense(property.state) && (
        <DetailRow>
          <DetailLabel>State license</DetailLabel>
          <DetailValue>{contractor.stateLicenseNumber}</DetailValue>
        </DetailRow>
      )}
      {contractor?.ashiLicenseNumber && (
        <DetailRow>
          <DetailLabel>ASHI</DetailLabel>
          <DetailValue>{contractor.ashiLicenseNumber}</DetailValue>
        </DetailRow>
      )}
      {contractor?.internachiLicenseNumber && (
        <DetailRow>
          <DetailLabel>InterNACHI</DetailLabel>
          <DetailValue>{contractor.internachiLicenseNumber}</DetailValue>
        </DetailRow>
      )}
      {contractor?.otherLicenseNumber && (
        <DetailRow>
          <DetailLabel>Other license</DetailLabel>
          <DetailValue>{contractor.otherLicenseNumber}</DetailValue>
        </DetailRow>
      )}
      <DetailRow>
        <DetailLabel>Scheduled</DetailLabel>
        <DetailValue>{getInspectionDateLabel(scheduledDate)}</DetailValue>
      </DetailRow>
      <DetailRow>
        <DetailLabel>Completed</DetailLabel>
        <DetailValue>{getInspectionDateLabel(completedDate)}</DetailValue>
      </DetailRow>
    </DetailContainer>
  </CoverPage>
);

export default Cover;
