import { ReportLocation } from 'marketplace-common';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  reportLocations: ReportLocation[];
  reInspectionStatus?: string;
}

const Locations: React.FC<Props> = ({ reportLocations, reInspectionStatus }) => {
  const locationNames = reportLocations.map((reportLocation) => reportLocation.name);

  return (
    <div className={styles.commentContainer}>
      <p className={styles.locationLabelheader}>Location</p>
      <p className={reInspectionStatus === 'fixed' ? styles.locationLabelValueFixed : styles.locationLabelValue}>
        {locationNames.join(', ')}
      </p>
    </div>
  );
};

export default Locations;
