import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { handleSignInError } from 'marketplace-common';
import OnboardingSetPasswordPage from './OnboardingSetPasswordPage';
import InspectifyLayout from '../../../components/InspectifyLayout';
import { captureException } from '../../../utils/error';
import { OnboardingCompletedStep } from '../types';
import { SURVEYOR_ONBOARDING } from '../../../graphql/queries';
import { UPDATE_SURVEYOR_ONBOARDING } from '../../../graphql/mutations/surveyorOnboarding';
import { handleOnboardingPageNavigation } from '../../../utils/onboarding';
import { signIn } from '../../../utils/amplify_auth';

const OnboardingSetPasswordPageLoader: React.FC = () => {
  const history = useHistory();
  const { onboardingId } = useParams<{ onboardingId: string }>();
  const email = localStorage.getItem('inspectifyUsername');
  const [completedStep, setCompletedStep] = React.useState<OnboardingCompletedStep>();
  const [canSetPassword, setCanSetPassword] = React.useState<boolean>();
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [showPasswordError, setShowPasswordError] = React.useState(false);

  const { data: surveyorOnboardingData } = useQuery(SURVEYOR_ONBOARDING, {
    variables: { id: onboardingId },
    context: { headers: { onboarding_id: onboardingId } },
    onCompleted: () => {
      if (surveyorOnboardingData?.surveyorOnboarding) {
        setCompletedStep(surveyorOnboardingData.surveyorOnboarding.completedStep);
        setCanSetPassword(surveyorOnboardingData.surveyorOnboarding.canSetPassword);
      }
    },
    onError: (error) => {
      captureException(error, { source: 'Fetching surveyor onboarding' });
    },
  });

  // Prevent the user from returning to the set password page once set
  useEffect(() => {
    if (completedStep) {
      handleOnboardingPageNavigation(completedStep, canSetPassword, onboardingId, history);
    }
  }, [completedStep]);

  const [updateSurveyorOnboarding, { loading }] = useMutation(
    UPDATE_SURVEYOR_ONBOARDING,
    {
      onCompleted: (data) => {
        setCompletedStep(data?.updateSurveyorOnboarding?.surveyorOnboarding.completedStep);
      },
      onError: () => {
        captureException(new Error('Graphql failed to update surveyor onboarding'));
      },
    },
  );

  const checkPasswordsMatch = () => {
    if (password !== passwordConfirmation) {
      setShowPasswordError(true);
    } else {
      setShowPasswordError(false);
    }
  };

  const handleSignIn = async () => {
    try {
      await signIn(
        email,
        password,
        () => history.push('/dashboard'),
      );
      localStorage.removeItem('inspectifyUsername');
    } catch (error) {
      handleSignInError(error, captureException, email);
    }
  };

  const handleSetPassword = () => {
    try {
      updateSurveyorOnboarding({
        variables: {
          input: {
            id: onboardingId,
            passwordSet: {
              password,
              passwordConfirmation,
            },
            completedStep: OnboardingCompletedStep.PasswordSet,
          },
        },
        context: { headers: { onboarding_id: onboardingId } },
      });
    } catch (error) {
      captureException(error, { source: 'Setting password' });
    }
    handleSignIn();
  };

  return (
    <>
      <Helmet>
        <title>Welcome to Inspectify!</title>
      </Helmet>
      <InspectifyLayout displayNavBar={false} showSignIn={false} hideHeaderOnMobile />
      <OnboardingSetPasswordPage
        password={password}
        setPassword={setPassword}
        showPasswordError={showPasswordError}
        setShowPasswordError={setShowPasswordError}
        passwordConfirmation={passwordConfirmation}
        setPasswordConfirmation={setPasswordConfirmation}
        checkPasswordsMatch={checkPasswordsMatch}
        setPasswordAndSignIn={handleSetPassword}
        loading={loading}
      />
    </>
  );
};

export default OnboardingSetPasswordPageLoader;
