import { combineReducers } from 'redux';
import { ISplitState, splitReducer } from '@splitsoftware/splitio-redux';
import active, { initialActiveState } from './active';
import api, { initialApiState } from './api';
import contractors, { initialContractorState } from './contractors';
import orders, { initialOrderState } from './orders';
import properties, { initialPropertyState } from './properties';
import inspections, { initialInspectionState } from './inspections';
import proposals, { initialProposalState } from './proposals';
import stakeholders, { initialStakeholderState } from './stakeholders';
import reports, { initialReportsState } from './reports';
import layout, { initialLayoutState } from './layout';
import teams, { initialTeamState } from './team';
import works, { initialWorksState } from './works';
import templates, { initialTemplatesState } from './templates';
import rehab, { initialRehabState } from './rehab';
import splitioAttributes, { initialSplitioAttributesState } from './splitioAttributes';

export const initialState = {
  active: initialActiveState,
  api: initialApiState,
  contractors: initialContractorState,
  orders: initialOrderState,
  properties: initialPropertyState,
  inspections: initialInspectionState,
  proposals: initialProposalState,
  stakeholders: initialStakeholderState,
  reports: initialReportsState,
  layout: initialLayoutState,
  teams: initialTeamState,
  works: initialWorksState,
  templates: initialTemplatesState,
  rehab: initialRehabState,
  splitio: {} as ISplitState,
  splitioAttributes: initialSplitioAttributesState,
};

export type MarketplaceFrontendReduxState = typeof initialState;

export default combineReducers({
  splitio: splitReducer,
  active,
  api,
  contractors,
  orders,
  properties,
  inspections,
  proposals,
  stakeholders,
  reports,
  layout,
  teams,
  works,
  templates,
  rehab,
  splitioAttributes,
});
