import { CognitoUser } from '@aws-amplify/auth';
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormInput } from '../../components/FormElements';
import { signIn } from '../../utils/amplify_auth';
import {
  Background, LoadingContainer, Header, Link, SubmitButton, ErrorMessage, ErrorSection,
} from './styles';
import { PageOrigin } from './types';
import GoogleButton from './GoogleButton';

interface Props {
  pageOrigin: PageOrigin,
  onSignInSuccess: () => void,
  onForgotPassword: () => void,
  onGoogleSignIn: () => void,
  onNewPasswordRequired: (signedInUser: CognitoUser) => void,
}

const SignInPage: React.FC<Props> = ({
  onSignInSuccess, onForgotPassword, onGoogleSignIn,
  onNewPasswordRequired, pageOrigin,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const headerLabel = useMemo(() => {
    switch (pageOrigin) {
      case PageOrigin.RehabTool:
        return 'Login to your\nRehab Tool';
      case PageOrigin.InspectorDashboard:
        return 'Login to your\nInspector Dashboard';
      default:
        return 'Login to your\nInspectify Account';
    }
  }, [pageOrigin]);

  const handleSignIn = async () => {
    setIsLoading(true);
    try {
      await signIn(
        email.trim(),
        password,
        onSignInSuccess,
        onNewPasswordRequired,
      );
    } catch (err: any) {
      setIsLoading(false);
      setErrorMessage(err.message);
    }
  };

  useEffect(() => () => {
    setIsLoading(false);
  }, []);

  return (
    <Background>
      <LoadingContainer isLoading={isLoading}>
        <Header>{headerLabel}</Header>
        <form>
          <FormInput
            name="Email"
            value={email}
            style={{ marginTop: 32 }}
            onChange={(e) => {
              setEmail(e.target.value);
              if (errorMessage) setErrorMessage('');
            }}
            indicateRequired={errorMessage !== ''}
          />
          <FormInput
            name="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              if (errorMessage) setErrorMessage('');
            }}
            type="password"
            style={{ marginTop: 24 }}
            indicateRequired={errorMessage !== ''}
          />
          <ErrorSection>
            {errorMessage
              && <ErrorMessage id="errorMessage">{errorMessage}</ErrorMessage>}
          </ErrorSection>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            type="button"
            onClick={onForgotPassword}
          >
            Forgot password?
          </Link>
          <SubmitButton
            type="submit"
            disabled={email === '' || password === '' || isLoading}
            onClick={(e) => {
              e.preventDefault();
              handleSignIn();
            }}
          >
            {isLoading ? <Spinner animation="border" /> : 'Sign in'}
          </SubmitButton>
        </form>
        <GoogleButton onClick={onGoogleSignIn} />
      </LoadingContainer>
    </Background>
  );
};

export default SignInPage;
