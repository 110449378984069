import React from 'react';
import EditIcon from 'haven-design-system/build/assets/svgs/action/edit_outlined.svg?component';
import {
  ManageUsersView,
  UserRowContent,
} from '../../types';
import './styles.scss';
import Button from '../../../../../components/Button';
import { UserRowProps } from './types';
import { UserStatusEnum } from '../../../../../types';

const UserRow: React.FC<UserRowProps> = ({
  singleUserData,
  showArchivedUsers,
  setSingleUser,
  setManageUsersView,
}) => {
  const userRowContentState = () => {
    if (singleUserData.status !== UserStatusEnum.Archived) return UserRowContent.Standard;
    return showArchivedUsers ? UserRowContent.ArchivedOn : UserRowContent.ArchivedOff;
  };

  const handleEditUser = () => {
    setSingleUser(singleUserData);
    setManageUsersView(ManageUsersView.UserForm);
  };

  const renderUserRowContent = (userRowContent: UserRowContent) => {
    switch (userRowContent) {
      case UserRowContent.ArchivedOff:
        return null;
      case UserRowContent.ArchivedOn:
        return (
          <div className="userRow">
            <div className="userRow__section">
              <div className="userRow__section__initials--Archived">
                {`${singleUserData.firstName.charAt(0)}${singleUserData.lastName.charAt(0)}`}
              </div>
              <div className="userRow__section__textName--Archived">
                {`${singleUserData.firstName} ${singleUserData.lastName}`}
              </div>
            </div>
            <div className="userRow__section">
              <div className="userRow__section__status--Archived" />
              <div className="userRow__section__textStatus--Archived">
                {singleUserData.status}
              </div>
            </div>
            <div className="userRow__section">
              <div className="userRow__section__permissionsContainer">
                {singleUserData?.permissions.map((permission) => (
                  <div key={permission} className="userRow__section__permissionsContainer__permission--Archived">
                    {permission}
                  </div>
                ))}
              </div>
            </div>
            <div className="userRow__placeholder">
              <Button onClick={handleEditUser} className="userRow__placeholder__edit">
                <EditIcon />
              </Button>
            </div>
          </div>
        );
      case UserRowContent.Standard:
        return (
          <div className="userRow">
            <div className="userRow__section">
              <div className={`userRow__section__initials--${singleUserData.iconColor}`}>
                {`${singleUserData.firstName.charAt(0)}${singleUserData.lastName.charAt(0)}`}
              </div>
              <div className="userRow__section__textName">
                {`${singleUserData.firstName} ${singleUserData.lastName}`}
              </div>
            </div>
            <div className="userRow__section">
              <div className={`userRow__section__status--${singleUserData.status.split(' ')[0]}`} />
              <div className="userRow__section__textStatus">
                {singleUserData.status}
              </div>
            </div>
            <div className="userRow__section">
              <div className="userRow__section__permissionsContainer">
                {singleUserData?.permissions.map((permission) => (
                  <div key={permission} className={`userRow__section__permissionsContainer__permission--${permission}`}>
                    {permission}
                  </div>
                ))}
              </div>
            </div>
            <div className="userRow__placeholder">
              <Button onClick={handleEditUser} className="userRow__placeholder__edit">
                <EditIcon />
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    renderUserRowContent(userRowContentState())
  );
};

export default UserRow;
