import React, { useMemo } from 'react';
import { DrawTotalRowProps } from './types';
import DrawRow from './DrawRow';

const DrawTotalRow: React.FC<DrawTotalRowProps> = ({
  data,
  displayWithTotal,
  displayFinancialColumns,
  displayUnitColumns,
}) => {
  const id = (data.length + 1).toString();
  const division = 'Total';
  const description = '';
  const unit = '';

  const budget = useMemo(() => (
    displayFinancialColumns ? data.reduce((acc, row) => acc + (row?.budget || 0), 0) : 0
  ), [data, displayFinancialColumns]);

  const disbursement = useMemo(() => (
    displayFinancialColumns ? data.reduce((acc, row) => acc + (row?.disbursement || 0), 0) : 0
  ), [data, displayFinancialColumns]);

  const progress = useMemo(() => (
    displayFinancialColumns ? data.reduce((acc, row) => {
      const numerator = (row.progress / 100) * (row?.budget || 0);
      return acc + (numerator / budget) * 100;
    }, 0) : data.reduce((acc, row) => acc + (row.progress || 0), 0) / data.length
  ), [data, displayFinancialColumns, budget]);

  const remaining = useMemo(() => (
    displayFinancialColumns ? data.reduce((acc, row) => acc + (row?.remaining || 0), 0) : 0
  ), [data, displayFinancialColumns]);

  return (
    <DrawRow
      data={{
        id,
        division,
        description,
        unit,
        budget,
        disbursement,
        progress,
        remaining,
      }}
      displayUnitColumns={displayUnitColumns}
      displayWithTotal={displayWithTotal}
      displayFinancialColumns={displayFinancialColumns}
    />
  );
};

export default DrawTotalRow;
