import { gql } from '@apollo/client';

export const SUBMIT_WARRANTY_CLAIM = gql`
  mutation SubmitWarrantyClaim($input: WarrantyClaimInput!) {
    warrantyClaim(input: $input) {
      inspectionWarranty {
        createdAt
        expirationDate
        id
        inspectionId
        startDate
        status
      }
    }
  }
`;