export interface IssueCount {
  safety: number;
  repair: number;
  monitor: number;
  fixed: number;
  flagged: number;
  newDefects: number;
  limitations: number;
}

export enum ReportDeliveryFilter {
  FullReport = 'Full report',
  FixedDefectsOnly = 'Fixed issues only',
  RemainingDefectsOnly = 'Remaining issues only',
  IssuesOnly = 'Issues only',
  MediaOnly = 'Media only',
  AppliancesOnly = 'Appliances only',
}

export enum ReportFeedbackStep {
  INITIATION = 'Initiation',
  SCORE = 'Score',
  NOTE = 'Note',
  CONFIRMATION = 'Confirmation',
}
