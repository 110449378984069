import React from 'react';
import './styles.scss';
import '../styles.scss';
import { Helmet } from 'react-helmet';
import InspectifyLogo from '../../../assets/inspectify/Inspectify_Logo_Color.svg?component';
import InspectifyLayout from '../../../components/InspectifyLayout';
import { OnboardingSetPasswordPageProps, SetPasswordPageText } from './types';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';

const OnboardingSetPasswordPage: React.FC<OnboardingSetPasswordPageProps> = ({
  password,
  setPassword,
  showPasswordError,
  setShowPasswordError,
  passwordConfirmation,
  setPasswordConfirmation,
  checkPasswordsMatch,
  setPasswordAndSignIn,
  loading,
}) => (
  <div className="passwordBackdrop">
    <Helmet>
      <title>{SetPasswordPageText.Helmet}</title>
    </Helmet>
    <InspectifyLayout displayNavBar={false} showSignIn={false} hideHeaderOnMobile />
    <div className="passwordContainer">
      <div className="logoContainer">
        <InspectifyLogo className="passwordLogo" />
      </div>
      <div className="passwordSection">
        <p className="passwordTitle">
          {SetPasswordPageText.Title}
        </p>
        <p className="text__instructions">
          {SetPasswordPageText.Instructions}
        </p>
      </div>
      <div className="passwordSection">
        <div className="userProfile__section__inputRow__inputContainer">
          <div className="userProfile__section__inputRow__inputContainer__label">
            {SetPasswordPageText.EnterPassword}
          </div>
          <input
            className="userProfile__section__inputRow__inputContainer__input"
            type="password"
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="errorText">
            {SetPasswordPageText.CharacterMinimum}
          </div>
        </div>
        <div className="userProfile__section__inputRow__inputContainer">
          <div className="userProfile__section__inputRow__inputContainer__label">
            {SetPasswordPageText.ConfirmPassword}
          </div>
          <input
            className={
                showPasswordError
                  ? 'userProfile__section__inputRow__inputContainer__input--error'
                  : 'userProfile__section__inputRow__inputContainer__input'
              }
            type="password"
            value={passwordConfirmation}
            name="password_confirmation"
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            placeholder="Re-enter password"
            onFocus={() => setShowPasswordError(false)}
            onBlur={() => checkPasswordsMatch()}
          />
          {showPasswordError && (
          <div className="userProfile__section__inputRow__inputContainer__errorText">
            {SetPasswordPageText.PasswordError}
          </div>
          )}
        </div>
      </div>
      <Button
        onClick={setPasswordAndSignIn}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',
        }}
        disabled={
          loading || showPasswordError || password.length < 6 || passwordConfirmation.length <= 0
        }
      >
        {loading ? <Spinner /> : 'Sign in'}
      </Button>
      <p className="text__instructions">
        By signing up you agree to the&nbsp;
        <a
          className="text__link"
          href="https://www.inspectify.com/company/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>
        &nbsp;and&nbsp;
        <a
          className="text__link"
          href="https://www.inspectify.com/company/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Data & Privacy Policy
        </a>
        .
      </p>
    </div>
  </div>
);

export default OnboardingSetPasswordPage;
