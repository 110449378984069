import React, { useContext, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-modal';
import BetterLogo from '../../../assets/better/BetterInspect.svg';
import InspectifyLogo from '../../../assets/inspectify/Inspectify_Logo_Color.svg';
import { getAddressLabel, getInspectionLabelOnlyDate } from '../../../utils/report';
import { ReportDeliveryFilter } from '../../../types/models';
import Footer from '../../../components/PageLayout/Footer';
import { FooterPrint, HeaderPrint, FooterPrintText } from '../../../components/PDF/styles';
import Menu from './Menu/Menu';
import ReportFilter from './Menu/ReportFilter';
import MobileMenu from './Menu/MobileMenu';
import { ModalStyles } from './Menu/styles';
import styles from './styles.module.scss';
import { ReportContext } from '../context';

interface Props {
  filter: ReportDeliveryFilter;
  changeFilter: (filter: ReportDeliveryFilter) => void;
  groupNames: string[] | null;
  changeGroupNames: (groupName: string[] | null) => void;
  teamId?: number;
  createRehabProject?: () => void;
}

const ReportsPageLayout: React.FC<Props> = ({
  children,
  filter,
  changeFilter,
  groupNames,
  changeGroupNames,
  teamId,
  createRehabProject,
}) => {
  const [isFilterReportModalOpen, setIsFilterReportModalOpen] = useState(false);
  const isBetterInspect = useMemo(() => window.location.hostname.includes('betterinspect'), []);
  const { state } = useContext(ReportContext);

  const groupNameOptions = useMemo(() => {
    const options = Object.values(state.selectedReport?.systemGroups || {}).map(({ name }) => name);
    return options?.length > 0 ? [null, ...options] : [];
  }, [state.selectedReport?.systemGroups]);

  return (
    <Container fluid className={styles.reportsPage}>
      {state.printMode ? (
        <table>
          <thead>
            <tr>
              <td>
                <HeaderPrint className="hideOnFirstPage">
                  {isBetterInspect
                    ? <img src={BetterLogo} alt="Better Inspect" className={styles.betterLogo} />
                    : <img src={InspectifyLogo} alt="Inspectify" />}

                  <div className="float-right">
                    {isBetterInspect && (
                      <>
                        <div className={styles.menuPoweredBytext}>Powered by</div>
                        <a href="https://www.inspectify.com"><img src={InspectifyLogo} alt="Inspectify" /></a>
                      </>
                    )}
                  </div>
                </HeaderPrint>
              </td>
            </tr>
          </thead>
          <tbody className={styles.body}>
            <tr>
              <td>
                {children}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <FooterPrint>
                  <FooterPrintText>
                    {getAddressLabel(state.property)} {state.inspectionDate && `- ${getInspectionLabelOnlyDate(state.inspectionDate)}`}
                  </FooterPrintText>
                </FooterPrint>
              </td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <>
          <Modal
            isOpen={isFilterReportModalOpen}
            onRequestClose={() => setIsFilterReportModalOpen(false)}
            ariaHideApp={false}
            style={ModalStyles}
            preventScroll={false}
          >
            <ReportFilter
              filter={filter}
              groupNames={groupNames}
              groupNameOptions={groupNameOptions}
              changeGroupNames={changeGroupNames}
              changeFilter={changeFilter}
              close={() => setIsFilterReportModalOpen(false)}
              isReInspection={state.isReInspection}
            />
          </Modal>
          <Menu
            filter={filter}
            groupNames={groupNames}
            toggleReportFilterModal={setIsFilterReportModalOpen}
            teamId={teamId}
            createRehabProject={createRehabProject}
          />
          <MobileMenu
            filter={filter}
            groupNames={groupNames}
            toggleReportFilterModal={setIsFilterReportModalOpen}
          />
          {children}
          <div className={styles.footerContainer}>
            <Footer />
          </div>
        </>
      )}
    </Container>
  );
};

export default ReportsPageLayout;
